import React, { useEffect, useState } from 'react'
import { TagContainer, TagHeader, TagIcon, TagItem, TagText, TagWrapper } from './style'
import { ListWrapper, Spinner } from '../style';
import { useSelector, useDispatch } from 'react-redux';
import { setTag } from '../../../../features/pageSlice/page';
import { useNavigate } from 'react-router-dom';
import { getHandle } from "../../../../components/utility/drag_drop";
import pencil from "../../../../assets/icons/pencil.svg"
import moon from '../../../../assets/moon.png'
import { Back } from '../btn';


const RoomTags = () => {
    const tags = useSelector((state) => state.page.tagData);
    // const user = useSelector((state) => state.signin.user);
    // const dispatch = useDispatch();
    const navigate = useNavigate();
    // const [loading, setLoading] = useState(true);
    // const config = {
    //     "Content-Type": "application/json",
    //     "Authorization": `JWT ${user.tokens.access}`
    // }
    // const retrieve = async () => {
    //     const tag_list = await getHandle({url: `${process.env.REACT_APP_BASE_APP_URL}/role/tag/`, config: config})
    //     if(tag_list.status === 200){
    //         dispatch(setTag(tag_list.data));
    //     }
    //     setLoading(false);
    // }
    // useEffect(() => {
    //     retrieve();
    // }, [])
  return (
    <ListWrapper wt='21%'>
    {/* {loading?
        <TagItem dir="column" ht="10rem">
            <Spinner
                color={"rgba(17, 115, 171, 1)"}
                loading={true}
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </TagItem>
      :
      <> */}
        {tags.length > 0?
            <TagWrapper>
                {tags.map((item, key) => (
                    <TagContainer key={key} onClick={() => navigate(`/list/tags/${item.id}`)}>
                        <TagItem dir="column">
                            <TagHeader>{item.title}</TagHeader>
                            <TagText>N {item.price}</TagText>
                        </TagItem>
                        <TagIcon src={pencil} alt="edit"/>
                    </TagContainer>
                ))
                }
                <Back path={"/list/1"}/>  
            </TagWrapper>
            :
            <TagItem dir="column">
                <TagIcon src={moon} />
                <TagHeader>No Tags</TagHeader>
                <Back path={"/list/1"}/>
            </TagItem>
        }
        {/* </>
    } */}
    </ListWrapper>
  )
}

export default RoomTags