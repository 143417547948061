import styled from 'styled-components';
import ClipLoader from "react-spinners/ClipLoader";

export const LoadingCont = styled.section`
    height: 300px;
    margin: auto;
    width: 100%;
    display: grid;
`

export const Spinner = styled(ClipLoader)`
    align-self: center;
    justify-self: center;
`

export const ProfileWrapper = styled.section`
    width: 65%;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2.5rem;
`
export const ProfileContainer = styled.section`
    display: flex;
    flex-direction: row;
    width: 77%;
    margin: auto;
    justify-content: space-between;
    @media screen and (max-width: 655px){
        flex-direction: column;
        width: 80%;
        margin: 5rem auto;
        gap: 6rem;
        align-items: center;
        justify-content: center;
    }
`

export const ProfileHeader = styled.h2`
    border-bottom: 1px solid rgba(217, 217, 217, 1);
    padding: 2%;
`

export const ProfilePerties = styled.img``

export const ProfileText = styled.p`
    text-align: ${({ps}) => ps? "left" : "center"};
    font-size: ${({sz}) => sz};
    font-weight: ${({wt}) => wt};
    margin-top: 5px;
`
export const ProfileStrong = styled.strong``

export const ProfileForm = styled.form`
    width: ${({wt}) => wt? wt : '40%'};
    display: flex;
    margin: auto auto 2rem auto;
    flex-direction: column;
    @media screen and (max-width: 590px){
        width: 55%;
    }
    @media screen and (max-width: 450px){
        width: 85%;
    }
    @media screen and (max-width: 330px){
        width: 85%;
    }
`
export const ProfileLabel = styled.label`
    margin-top: 20px;
    margin-bottom: 11px;

`

export const ProfileInput = styled.input`
 width: 97%;
 background: rgba(238, 238, 238, 1);
 border-radius: 6px;
 height: 48px;
 border: 1px solid rgba(0, 0, 0, 0.5);
 padding-left: 3%;
 outline: none;
 &:focus {
    outline: rgba(17, 115, 171, 1);
 }
`
export const ProfileArea = styled.textarea`
 margin-bottom: 10px;
 resize: none;
 height: 100px;
 background: rgba(217, 217, 217, 0.2);
`

export const ProfileList = styled.div`
    width: ${({wt}) => wt? "98%":"38%"};
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: auto;
    @media screen and (max-width: 850px){
        width: ${({rket}) => rket? "48%":"98%"};
    }
    @media screen and (max-width: 800px){
        width: ${({wt}) => wt? "100%":"48%"};
        flex-direction: column;
        margin-top: 2rem;
        overflow: scroll;
        flex-wrap: nowrap;
    }
    @media screen and (max-width: 640px){
        width: ${({wt}) => wt? "100%":"60%"};
    }
    @media screen and (max-width: 520px){
        width: ${({wt}) => wt? "100%":"75%"};
    }
    @media screen and (max-width: 420px){
        width: 100%;
    }
`

export const PropertySlot = styled.div`
    background: rgba(217, 217, 217, 0.6);
    display: flex;
    width: 41%;
    height: 60px;
    margin: auto;
    border-radius: 6px;
    align-items: center;
    padding: 2%;
    flex-direction: row;
    margin-bottom: 1rem;
    gap: 1%;
    @media screen and (max-width: 800px){
        width: 51%;
    }
    @media screen and (max-width: 680px){
        width: 65%;
    }
    @media screen and (max-width: 500px){
        width: 84%;
    }
    /* @media screen and (max-width: 400px){
        width: 100%;
    } */
`

export const PropertyImg = styled.img`
    border-radius: 999px;
    width: 50px;
    height: 50px;
`

export const PropertyIcon = styled.img`
    align-self: center;
    width: 20px;
    height: 20px;
    cursor: pointer;
`

export const PropertyBlocks = styled.span`
    display: flex;
    flex-direction: column;
    width: ${({wt}) => wt};
`

export const SupportRocket = styled.span`
    display: flex;
    color: white;
    background: rgba(17, 115, 171, 1);
    padding: 2%;
    border-radius: 6px;
    align-items: center;
`

export const ProfileButton = styled.button`

    border: none;
    border-radius: 22px;
    margin: ${({otp}) => otp? otp : "auto"};
    height: 46px;
    width: ${ props => props.wt? props.wt : '18%'};
    color: white;
    cursor: pointer;
    text-align: center;
    background: ${props => props.left? "rgba(0, 0, 0, 0.5)": "rgba(17, 115, 171, 1)"};
    margin-top: ${({er}) => er?  "1rem" : "" };
`

export const ProfileCount = styled.p`

    margin: auto auto 1rem auto;
    height: 48px;
    text-align: center;
`

export const DisplayButton = styled.button`
    height: 16rem;
    width: 24rem;
    border: none;
    background: transparent;
`
export const ButtonImg = styled.img`
    width: 100%;
    height: auto;
`
export const TutVid = styled.iframe`
    width: 350px;
    height: 200px;
`
export const PropertyWrapper = styled.section`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`
export const Text = styled.input`
    /* padding: 0 10px; */
    height: 48px;
    outline: none;
    background: rgba(238, 238, 238, 1);
    &:focus {
        outline: rgba(17, 115, 171, 1);
    }
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    padding-left: 3%;
    width: ${props => props.size ? props.size : "33%"};
    margin-right: ${props => props.position === "right" ? "6px" : "none"};
    margin: auto;
    margin-bottom: ${props => props.adrs ? "26px" : "none"};
    @media screen and (max-width: 600px){
        &.address {
            margin: 0 auto;
            height: 60px;
        }
    }
`
export const UserSection = styled.section`
    display: flex;
    gap: 2%;
    width:100%;
`