import { createGlobalStyle } from 'styled-components'


const GlobalStyle = createGlobalStyle`
    *{
        margin: 0;
        /* padding: 0;
        box-sizing: border-box; */
        font-size: 15px;
        font-family: 'Nunito Sans', sans-serif;
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; /* Optional */
    }
    @media screen and (max-width: 1233px){
        *{  
            font-size: 13px;
         }
    }
    @media screen and (max-width: 1167px){
        *{  
            font-size: 12px;
         }
    }
    @media screen and (max-width: 1024px){
        *{  
            font-size: 9px;
         }
    }
    @media screen and (max-width: 600px){
        *{  
            font-size: 15px;
         }
    }
   

`



export default GlobalStyle;