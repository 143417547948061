import { Partners } from "../components";
import { Outlet } from "react-router-dom";



const LandingPage = () => {
    return (
        <>
            <Outlet />
            <Partners />
        </>
    )
}

export default LandingPage