import { FillBtn, Icon, Button, DragWrapper, Spinner } from "../../style";
import image from "../../../../assets/icons/images.svg";
import doc from '../../../../assets/icons/docs.svg';
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { packToForm3 } from "../../../../components/utility/drag_drop";
import { postData, errorClear, successClear } from "../../../../features/postSlice/post";
import { setAlert } from "../../../../features/searchSlice/search";
import { fornext } from '../../../../features/pageSlice/page'

const Id = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/user/register/`
    const config = { "Content-Type": "multipart/form-data" }
    const data = useSelector((state) => state.data.user);
    const [img, setImg] = useState({
      upload_id: data["profile.id"],
    })
    
    function uploadImg(val){
      dispatch(fornext({id: val, nextPage: "/upload/id"}));
      navigate("/uppath");
    }

    const user = useSelector((state) => state.post);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const text = ["address"]
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = await packToForm3(data, text);
        // formData.append("profile.upload_id", img.upload_id);
        dispatch(postData({url:url, data:formData, config:config}));
      }
      useEffect(() => {
        // if(page.prevPage !== "/address" || page.prevPage !== "/uppath" || page.prevPage !== "/preview"){
        //   navigate("/sign up");
        // }
        dispatch(successClear());
        if (user.loading === false && user.error !== ""){
          if(user.status === 400){
            dispatch(setAlert({alert: true, message: user.error}));
            dispatch(errorClear());
          }else{
            dispatch(setAlert({alert: true, message: 'Network Error'}));
            dispatch(errorClear());
          }
        } else if(user.loading === false && user.error === "" && user.success === true){
          navigate("/created");
          dispatch(successClear())  ;
        }
      }, [user.loading])
    
    
    return(
      <>
        <DragWrapper data-name="upload_id" >
          <Icon sm="sm" src={img.upload_id != null? doc: image} />
          <Button  onClick={img.upload_id != null? () => navigate('/preview') : () => uploadImg(10)} src="black" type= "button"> Upload </Button>
          {/* <Up ref={fileInputRef} name="upload_id" onChange={handleFileInputChange} accept="image/*" /> */}
        </DragWrapper>
        <FillBtn variant="Fill" onClick={handleSubmit} >
        {user.loading?
          <Spinner
           color={"white"}
           loading={true}
           size={25}
           aria-label="Loading Spinner"
           data-testid="loader"
          />
          :
          "Done"
        }
        </FillBtn>
      </>
    )
}

export default Id;