import { ListButton, ListRow, ListHeader, ListWrapper, Spinner, ListSelect, ListOption, ListLabel, ListStrong } from '../style'
import DummyCard from '../../../../components/carousel/homecard/dummy_card'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { errorClear, postData, successClear } from '../../../../features/postSlice/post'
import { useState, useEffect } from 'react'
import { packToForm2 } from '../../../../components/utility/drag_drop'
import NumProgress from '../../../../components/progress'
import { store } from "../../../../app/store";
import { signoutRequest } from "../../../../features/purge/purge";
import Cookies from 'js-cookie'
import { setAlert } from '../../../../features/searchSlice/search'
import { Back, Next } from '../btn'
import { listClear, listAdd } from '../../../../features/registerSlice/rdataSlice'
import { states } from '../../../../data/area'
import {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";


const ListSixth = () => {
  const user = useSelector((state) => state.signin.user)
  const url = `${process.env.REACT_APP_BASE_APP_URL}/property/create/`
  const config = {
     "Content-Type": "multipart/form-data",
     "Authorization": `JWT ${user.tokens.access}`
  }

  const navigate = useNavigate()
  const [location, setLocation] = useState({
    state: ""
  })

  const val = useSelector((state) => state.data.list);
  const post = useSelector((state) => state.post);
  const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setLocation({state: event.target.value})
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(val.latitude === 0){
      getGeocode({ address: val.address }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        dispatch(listAdd({latitude: lat, longitude: lng}));
      });
    }
    const text = ['uhouse_view', 'uliving_room', 'ubed_room', 'utoilet', 'ukitchen', 'udocuments', 'uhouse_plan', 'usize' ]
    const isarr = 'uappliances'
    const sin = []
    const formData = await packToForm2(val, text, isarr, sin)
    formData.append("state", location.state)
    dispatch(postData({url:url, data:formData, config:config}))
  }

  useEffect(() => {
    if (post.loading === false && post.error !== ''){
      if(post.status === 400){
        dispatch(setAlert({alert: true, message: post.error}));
        dispatch(errorClear());
      }else if (post.status === 401){
        dispatch(setAlert({alert: true, message: 'Session has expired'}));
        dispatch(postData({url: lout, config: {"Content-Type": "application/json"}, data: user.tokens}));
        dispatch(errorClear());
        Cookies.remove("data");
        store.dispatch(signoutRequest());
      }else{
        dispatch(setAlert({alert: true, message: 'Network Error'}));
        dispatch(errorClear());
      }
    } else if(post.loading === false && post.error === '' && post.success === true){
      dispatch(listClear());
      navigate('/list/done');  
      dispatch(successClear());
    }
  }, [post.loading])

  return (
    <ListWrapper>
      <ListHeader>Here's a preview</ListHeader>
      <NumProgress wt={'90%'} />
        <DummyCard data={val} />
        <ListLabel for="state"><ListStrong>State</ListStrong></ListLabel>
        {/* <ListInput type="text" name="state" id="state" placeholder={val.state? val.state : "E.g Rivers"} onChange={handleChange} required /> */}
        <ListSelect name="state" id="state" defaultValue={val.state} onChange={handleChange}>
          {states.map((item, index) => (
              <ListOption key={index} value={item}>{item}</ListOption>
          ))}
        </ListSelect>
        <ListRow>
          <Back path={'/list/5'} />
          <Next func={handleSubmit} loading={post.loading}  text ="Continue" />
        </ListRow>
    </ListWrapper>
  )
}

export default ListSixth 