import { useDispatch, useSelector } from "react-redux";
import { FormButton, Icons, FormDrag, FormInput, FormLabel, FormWrapper, FormStrong, FormSpan, FormP, FormWarn, FormMini, FormIcon, DisplayContainer, ImgContainers, FormHeader, Spinner, VarIcon, Imagewrap, LoadingCont, Button } from "../style"
import { useNavigate, useParams } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import bright from "../../../../assets/icons/alert.svg";
import nin from "../../../../assets/dutchid.jpg";
import cancel from '../../../../assets/icons/cancel.svg';
// import datas from "../../../../data.json"
import { store } from "../../../../app/store";
import { fornext } from "../../../../features/pageSlice/page";
import { signoutRequest } from "../../../../features/purge/purge";
import Cookies from 'js-cookie';
import { packToForm, packToForm2, postHandle, delHandle, handleRequest } from "../../../utility/drag_drop";
import { errorClear, putData, successClear } from "../../../../features/putSlice/put";
import { postData } from "../../../../features/postSlice/post";
import { setAlert } from "../../../../features/searchSlice/search";
import { roleClear, viewImage } from "../../../../features/registerSlice/rdataSlice";
import { profileData } from "../../../../features/profileSlice/profile";


const EdStart = () => {
    const[reg, setReg] = useState({
        name: '',
        email: '',
        address: '',
        phone_no: '',
        company_name: '',
        company_reg: '',
        company_id: nin,
        state: '',
        about_you: "",
        specialties: "",
    })
    const [loading, setLoading] = useState(false);
    const user = useSelector((state) => state.signin.user);
    // const user = {role: "EventCenter"}
    let { roles } = useParams();
    const data = useSelector((state) => state.profile.profiledata);
    const proload = useSelector((state) => state.profile.loading);
    const page = useSelector((state) => state.page);
    // const data = datas.users[0]
    const uploads = useSelector((state) => state.data.role.iphotos)
    const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`
    const url = `${process.env.REACT_APP_BASE_APP_URL}/role/${["agent", "eventcenter"].includes(roles)? "an " : "a "}${roles}/${data.id}/` 
    const roleData = `${process.env.REACT_APP_BASE_APP_URL}/property/roles/`
    const config = { 
                    "Content-Type": "multipart/form-data",
                    "Authorization": `JWT ${user.tokens.access}`
                }
    const error = useSelector((state) => state.put);
    const navigate = useNavigate();
    const handleChange = (e) => {
        e.preventDefault()
        const target = e.target
        setReg({...reg, [target.name]: target.value})
    }

    const fileInputRef = useRef(null);
    const handleUploadButtonClick = () => {
        fileInputRef.current.click(); // Trigger the file input click event
      };
 
    const handleFileInputChange = (event) => {
        event.preventDefault();
        const prof = window.URL.createObjectURL(event.target.files[0])
        const target = event.target
        setReg({...reg, [target.name]: prof})
    }
    
    const dispatch = useDispatch();
    const handleSubmit = async (e) => {
        e.preventDefault();
        const text = ['company_id']
        const form = await packToForm(reg, text);
        dispatch(putData({url:url, data:form, config:config}));
    }
    function uploadImg(val){
        dispatch(fornext({id: val, nextPage: `/profile/edit/${user.role}/profile`}))
        navigate("/uppath");
    }

    async function del(id, index){
        const detstatus = await delHandle({url: `${process.env.REACT_APP_BASE_APP_URL}/role/removephoto/${['Hotel'].includes(user.role) ? 'hotel' : 'eventcenter'}/${id}/`, config: config});
        const response = handleRequest(detstatus, dispatch, config, user?.tokens.refresh)
        if(response === "success"){
            uploads.slice(index, 1)
            dispatch(profileData({url: roleData, config: config})); 
        }
    }

    async function saveUploads(e){
        e.preventDefault();
        setLoading(true);
        const text = ['iphotos']
        const isarr = []
        const single = []
        const data = await packToForm2({'iphotos': uploads}, text, isarr, single, []);
        const phot = await postHandle({url: `${process.env.REACT_APP_BASE_APP_URL}/role/${['Hotel'].includes(user.role) ? 'addphoto/hotel/' : 'addphoto/eventcenter/'}`, data: data, config: config});
        const response = handleRequest(phot, dispatch, config, user?.tokens.refresh)
        if(response === "success"){
            dispatch(profileData({url: roleData, config: config}));
        }
        setLoading(false);
    }

    function previewOpen(e, data, index){
        e.preventDefault();
        dispatch(viewImage({open: true, data: data, index: index}));
    }

    useEffect(() => {
        if(page.prevPage !== "/preview" && page.prevPage !== '/uppath'){
            dispatch(roleClear());
        }
    }, [])

    useEffect(() => {
        if (error.loading === false && error.error !== ""){
            if(error.status === 400){
                dispatch(setAlert({alert: true, message: error.error}));
                dispatch(errorClear());
            }else if (error.status === 401){
                dispatch(setAlert({alert: true, message: 'Session has expired'}));
                dispatch(postData({url: lout, config: {"Content-Type": "application/json"}, data: user.tokens}));
                dispatch(errorClear());
                Cookies.remove("data");
                store.dispatch(signoutRequest());
            }else{
                dispatch(setAlert({alert: true, message: 'Network Error'}));
                dispatch(errorClear());
            }
        } else if (error.loading === false && error.error === "" && error.success === true){
            dispatch(setAlert({alert: true, message: "Your details have been Saved", color: '#FF5733'}));
            dispatch(successClear());
            dispatch(profileData({url: roleData, config: config}));
        }
    }, [error.loading])

    return(
        <>
            {proload?
                <LoadingCont>
                    <Spinner 
                        color={"white"}
                        loading={true}
                        size={50}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                            />
                </LoadingCont>
                :
                <FormWrapper onSubmit={handleSubmit}>
                    <FormSpan>
                        {/* {['Agent', 'Developer'].includes(user.role) && 
                            <>
                                <FormLabel for='name'><FormStrong>Name</FormStrong></FormLabel>
                                <FormInput type="text" onChange={handleChange} id='name' name="name" placeholder={data.name} spec />
                            </>
                        } */}
                        {['Short-let'].includes(user.role) && 
                            <>
                                <FormLabel for='company_name'><FormStrong>Name</FormStrong></FormLabel>
                                <FormInput type="text" onChange={handleChange} id='company_name' name="company_name" placeholder={data.company_name} spec />
                            </>
                        }
                        {['Hotel', 'Bank'].includes(user.role) && 
                            <>
                            <FormLabel for='name'><FormStrong>Name</FormStrong></FormLabel>
                            <FormInput type="text" onChange={handleChange} id='name' name="name" placeholder={data.name} spec />
                            </>
                        }
                        {['EventCenter', 'Landlord', 'Short-let'].includes(user.role) && 
                            <>       
                            <FormLabel for='phone_no'><FormStrong>Phone Number</FormStrong></FormLabel>
                            <FormWarn>Contact support to edit your phone number</FormWarn>
                            <FormMini>
                                <FormInput type="text" onChange={handleChange} id='phone_no' name="phone_no" placeholder={data.phone_no} spec />
                                <FormIcon src={bright} alt="alert" />
                            </FormMini>
                            </>
                        }
                        <FormLabel for='email'><FormStrong>Email</FormStrong></FormLabel>
                        <FormInput type="email" onChange={handleChange} id='email' name="email" placeholder={data.email} spec />
                        
                        <FormLabel for='address'><FormStrong>Address</FormStrong></FormLabel>
                        <FormInput type="text" onChange={handleChange} id='address' name="address" placeholder={data.address} spec />
                        {['Agent', 'Developer', 'Bank', 'Hotel'].includes(user.role) && 
                            <>
                                <FormLabel for='about_you'><FormStrong>About Yourself</FormStrong></FormLabel>
                                <FormInput type="text" onChange={handleChange} id='about_you' name="about_you" placeholder={data.about_you} spec />
                            </>
                        }
                        {['Agent', 'Developer'].includes(user.role) && 
                            <>
                                <FormLabel><FormStrong>Specialties</FormStrong></FormLabel>
                                <FormInput type="text" onChange={handleChange} name="specialties" placeholder={data.specialties} spec /> 
                            </>
                        }
                        <FormLabel for='state'><FormStrong>State</FormStrong></FormLabel>
                        <FormInput type="text" onChange={handleChange} id='state' name="state" placeholder={data.state} spec />
                        {['Hotel'].includes(user.role) && 
                            <>
                            <FormLabel for='company_id'><FormStrong>Company ID</FormStrong></FormLabel>
                            <FormDrag onClick={handleUploadButtonClick}>
                                <FormP>Edit</FormP>
                                <Icons src={reg.company_id != null? reg.company_id : data.company_id} upP={true} alt="company id"/>
                                <FormInput type="file" onChange={handleFileInputChange} ref={fileInputRef} name="company_id" none accept="image/*" />
                            </FormDrag>
                            </>
                        }
                    </FormSpan>
                    <FormSpan>
                        {['Landlord'].includes(user.role) && 
                            <>
                                <FormLabel for='about_you'><FormStrong>About Yourself</FormStrong></FormLabel>
                                <FormInput type="text" onChange={handleChange} id='about_you' name="about_you" placeholder={data.about_you} spec />
                            </>
                        }
                        {!['Hotel'].includes(user.role) && 
                            <>
                            <FormLabel for='company_id'><FormStrong>Company ID</FormStrong></FormLabel>
                            <FormDrag onClick={handleUploadButtonClick}>
                                <FormP>Edit</FormP>
                                <Icons src={reg.company_id != null? reg.company_id : data.company_id} upP={true} alt="company id"/>
                                <FormInput type="file" onChange={handleFileInputChange} ref={fileInputRef} name="company_id" none accept="image/*" />
                            </FormDrag>
                            </>
                        }
                        {['EventCenter', 'Hotel'].includes(user.role) &&
                            <>
                                <FormLabel for='photos'><FormStrong>Images of the Event Center</FormStrong></FormLabel>
                                <FormWarn color="red">*images must be clear</FormWarn>
                                <DisplayContainer>
                                    {data.photos.length > 0?
                                        data.photos.map((item, index) => (
                                        <Imagewrap>
                                            <VarIcon src={cancel} onClick={() => del(item.id, index)} alt="delete" />
                                            <ImgContainers src={item.photo} key={item.id} onClick={(e) => previewOpen(e, data.photos, index)} />
                                        </Imagewrap>
                                        ))
                                        :
                                        <FormHeader>No Photos</FormHeader>
                                    }
                                </DisplayContainer>
                                <FormButton wt="43%" onClick={uploads.length > 0? (e) => saveUploads(e): () => uploadImg(8)}>
                                    {loading? 
                                        <Spinner 
                                        color={"white"}
                                        loading={true}
                                        size={25}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                            />
                                    :
                                    uploads.length > 0? "Save uploads" : "Upload"}
                                </FormButton>
                            </>
                        }
                        {!['Landlord'].includes(user.role) &&
                            <>
                                <FormLabel for='company_name'><FormStrong>Company Name</FormStrong></FormLabel>
                                <FormInput type="text" onChange={handleChange} id='company_name' name="company_name" placeholder={data.company_name} spec />
                            </>
                        }
                        {['Agent', 'Developer', 'Bank', 'Hotel'].includes(user.role) &&
                            <>
                                <FormLabel for='company_reg_no'><FormStrong>Company Reg Number</FormStrong></FormLabel>
                                <FormInput type="text" onChange={handleChange} id='company_reg_no' name="company_reg_no" placeholder={data.company_reg} spec />
                                
                                <FormLabel for='phone_no'><FormStrong>Phone Number</FormStrong></FormLabel>
                                <FormWarn>Contact support to edit your phone number</FormWarn>
                                <FormMini>
                                <FormInput type="text" onChange={handleChange} id='phone_no' name="phone_no" placeholder={data.phone_no} spec />
                                <FormIcon src={bright} alt="alert" />
                                </FormMini>
                            </>
                        }
                        {["Hotel"].includes(user.role) && <Button onClick={() => navigate('/list/1')}>Edit Room Tags</Button>}
                
                    </FormSpan>
                    
                    <FormButton type="submit">
                        {error.loading? 
                            <Spinner 
                            color={"white"}
                            loading={true}
                            size={25}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                                />
                        :
                        "Save"}</FormButton>
                </FormWrapper>
            }
        </>
    )
}

export default EdStart;