import styled from 'styled-components'
import ClipLoader from "react-spinners/ClipLoader";


export const FormWrapper = styled.div`
 display: flex;
 flex-direction: column;
 width: ${({wt}) => wt? wt:"30%"};
    @media screen and (max-width: 811px) {
        width: ${({wt}) => wt? wt:"39%"};
    }
    @media screen and (max-width: 632px) {
        width: ${({wt}) => wt? wt:"43%"};
    }
    @media screen and (max-width: 600px) {
        width: 78%;
        align-items: center;
        margin-top: 5rem;
    }
`
export const FormContainer = styled.form`
 width: 100%;
 display: flex;
 flex-direction: column;
 border: 1px solid rgba(0, 0, 0, 0.5);
 border-radius: 3px;
 margin-bottom: 3rem;
 padding: 8%;
`
export const FormInput = styled.input.attrs(({ variant }) =>({
    type: variant
}))`
 width: 98%;
 background: rgba(217, 217, 217, 0.2);
 border: 1px solid rgba(0, 0, 0, 0.5);
 border-radius: 2px;
 height: 50px;
 margin-bottom: 10px;
 -webkit-appearance: ${props => props.no? "none": ""};
        appearance: none;
 -moz-appearance: ${props => props.no? "textfield": ""};
 @media screen and (max-width: 600px){
    font-size: 1.3rem;
 }
`
export const FormLabel = styled.label`
 font-weight: 700;
 padding-bottom: 10px;
 @media screen and (max-width: 600px){
    font-size: 1.3rem;
 }
`
export const FormSpan = styled.span`
 color: grey;
`
export const FormArea = styled.textarea`
 margin-bottom: 10px;
 resize: none;
 height: 100px;
 background: rgba(217, 217, 217, 0.2);
 @media screen and (max-width: 600px){
    font-size: 1.3rem;
 }
`
export const FormButton = styled.button`
    color: white;
    background: ${props => props.variant === "blue"? "rgba(17, 115, 171, 1)" : "rgba(222, 24, 24, 1)"};
    border-radius: 6px; 
    border: none;
    width: 60%;
    margin-bottom: 1rem;
    margin-top: 2rem;
    height: 42px;
    align-self: center;
    cursor: pointer;
    &:hover{
        background: ${props => props.variant === "blue"? "rgb(50 167 233)" : "rgba(222, 24, 24, 1)"};
    }
    @media screen and (max-width: 600px){
        font-size: 1.3rem;
        height: 61px;
    }
`
export const FormHead = styled.h1`
 font-size: 1.7rem;
 margin-bottom: 10px;
`
export const FormP = styled.p`
 text-align: left;
 @media screen and (max-width: 600px){
    font-size: 1.3rem;
 }
`
export const SmallText = styled.p`
    font-size: 0.5rem;
    color: grey;
`

export const Spinner = styled(ClipLoader)`
    align-self: center;
    justify-self: center;
`