import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'



const initialState = {

  loading: false,
  data: [],
  error: '',
  errmessage: '',
  status: '',
  success: false,
}


export const postData  = createAsyncThunk('data/postData', async (objdata, { rejectWithValue }) => {
    const {url, data, config} = objdata;
    try {
      const result = await axios.post(url, data, {headers: config})
      return result.data
    }catch(err){
      return rejectWithValue({status: err.response?.status, data: err.response?.data?.error || err.response?.data, text: err.message})
    }
  })
const postSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
          errorClear: (state, action) => {
              state.error = ""
          },
          successClear: (state, action) => {
            state.success = false
        }
    },
    extraReducers: builder => {
          builder.addCase(postData.pending, state => {
            state.loading = true
            state.error= ''
            state.errmessage= ''
            state.success = false
          })
          builder.addCase(postData.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
            state.error = ''
            state.errmessage= ''
            state.success = true
          })
          builder.addCase(postData.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.payload.data
            state.status = action.payload.status
            state.success = false
          });
    },
});


export default postSlice.reducer
export const { errorClear, successClear } = postSlice.actions