import { Carousel } from "../../components";
import { Filler } from '../../components/utility';
import { Header } from '../../layout';
import slide2 from "../../assets/header2.png";
import fill from '../../assets/filler2.png';
import google from '../../assets/icons/googles.svg';
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getData, clear } from "../../features/getSlice/get";
import { Spinner, LoadingCont } from "./style";
import { clearS } from "../../features/searchSlice/search"
import { useFetch } from "../../hooks/useFetch";

const Buy = () => {
    const dispatch = useDispatch();
    const set = useSelector((state) => state.get);
    const url = `${process.env.REACT_APP_BASE_APP_URL}/property/search/?property_status=For sale`
    const config = {"Content-Type": "application/json"}
    const [loading, data, error] = useFetch(`${process.env.REACT_APP_BASE_APP_URL}/property/search/?state=${set.address}&property_status=For sale`)

    useEffect(() => {
        dispatch(clearS());
        dispatch(clear());
        dispatch(getData({url: url, config: config}));
    },[])
    return(
        <>
         <Header link={slide2} text={'PURCHASE A HOUSE ANYWHERE IN NIGERIA'} />
         {set.loading?
           <LoadingCont>
            <Spinner
           color={"rgba(17, 115, 171, 1)"}
           loading={true}
           size={50}
           aria-label="Loading Spinner"
           data-testid="loader"
           />
           </LoadingCont>
            :
            <Carousel
                Htext='Buy Properties'
                txt='For sale'
                data={set.data} 
                data2={data}
                action='Buy it'
                tag="Close to you"
            />
           }
            <Filler 
               head='Interested in renting a property? '
               ptext='Don"t miss out on the opportunity to find your ideal rental property. Join the Pinearth community today and embark on an exciting journey towards finding your dream home. Visit our website now and start exploring the endless possibilities!'  
               btntext ='Sign up' 
               location="/sign up"
               btntext2='Sign up with Google'
               find='Find rentals'
               imsrc={fill} 
               isrc={google}  
            /> 
        </>
    )
}


export default Buy