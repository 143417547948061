import axios from "axios";
import { setAlert } from "../../../features/searchSlice/search";
import { postData } from "../../../features/postSlice/post";
import { store } from "../../../app/store";
import { signoutRequest } from "../../../features/purge/purge";
import Cookies from 'js-cookie'



// export const special = /[!@#$%^&*()_+\-=\[/]{};':"\\|,.<>\/?~]/g;
// special[Symbol.match] = false
const imagePreview = document.getElementById('imagePreview');
const previewImage = document.getElementById('previewImage');

function handleFile(file) {
    const reader = new FileReader();

    reader.onload = function() {
        previewImage.src = reader.result;
        imagePreview.style.display = 'block';
    }

    reader.readAsDataURL(file);
}

export function handleDragOver(event) {
    event.preventDefault();
};

export function handleDragLeave(event) {
    event.preventDefault();
};

export function handleDrop(event) {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    // handleFile(file);
};

export function handleChange() {
    const file = this.files[0];
    handleFile(file);
};


export function fileToObjectURL(file, callback) {
    const reader = new FileReader();

    reader.onload = function(event) {
        const objectURL = event.target.result;
        callback(objectURL);
    }

    reader.readAsDataURL(file);
}

// Function to convert an Object URL back to a file
export function objectURLToFile(objectURL, fileName, mimeType) {
    return fetch(objectURL)
        .then(response => response.blob())
        .then(blob => new File([blob], fileName, { type: mimeType }));
}

// function objectURLToData(objectURL) {
//     return fetch(objectURL)
//         .then(response => response.json())
//         .catch(error => console.error('Error:', error));
// }

export async function packToForm(data, text){
    const formData = new FormData()
    const promises = []
    console.log("fulldata:", data)
    console.log("filepath:", text)
    Object.entries(data).forEach(([key, value]) => {
        if(text.includes(key)){
            console.log("key:", key)
            const filename = value.split('/').pop()
            const promise = objectURLToFile(value, filename, "image/png");
            promises.push(promise);
            formData.append(key, null);
        } else {
            formData.append(key, value)
        }
    })
    return Promise.all(promises)
    .then((files) => {
        console.log("converts:", files)
        files.forEach((file, index) => {
            const key = text[index];
            formData.set(key, file);
            console.log("file:", key, file)
        });

        return formData;
    });
}

// export function packToForm2(data, text){
//     const formData = new FormData();
//     const files = []
//     Object.keys(data).map((key, index) => {
//         if(text.includes(key)){
//                 data[key].map((item) => {
//                     const filename = item.split('/').pop();
//                     objectURLToFile(data[key], filename, ('image/*'))
//                     .then( file => files.push(file));
//                 })
//                     formData.append(key, files)
//                     console.log("formdone:", formData)
            
//         }else { formData.append(key, data[key])}
//     })
//     for (const entry of formData.entries()) {
//         console.log(entry[0], entry[1]);
//       }
//     return formData;
// }
export function confirmValues(data){
    for (const [key, value] of Object.entries(data)) {
        if(value === "" || (Array.isArray(value) && value.length === 0)){
            alert("Fill out all fields");
            return false;
        }
        return true;
    }
}

// export function confirmState(data, values){
//     Object.keys(data).map((key, index) => {
//         Object.keys(values).forEach((key1, index1) => {
//           if(key === key1){
//             if(values[key1] === "" || (Array.isArray(values[key1]) && values[key1].length === 0 || values[key] === 0)){
//               alert("Fill out all fields");
//               return false;
//             }
//             return true;
//           }
//         })
//     })
// }
// || (Array.isArray(value) && value.length === 0) 
export function ConfirmState(data, values) {
    let isValid = true;
    let theKey = "";
    for(let key of data) {
        if (values.hasOwnProperty(key)) {
            const value = values[key];

            if (value === "" || value === 0) {
                isValid = false;
                theKey = key.replace("_", " ")
                break;
            }
        }
    };

    return {valid: isValid, key: theKey};
}

export async function packToForm2(data, text, isarr, single, note_keys){
    const formData = new FormData();
    
    for (const [key, value] of Object.entries(data)) {
        if(note_keys.includes(key) || note_keys?.length === 0){    
            if (text.includes(key)) {
                if(single.includes(key)){
                    const filename = value.split('/').pop()
                    try {
                        const file = await objectURLToFile(value, filename, "image/png");
                        formData.append(`${key}`, file);
                    } catch (error) {
                        console.error(`Error processing ${key}:`, error);
                    }
                } else {
                    const promises = value.map(async (item) => {
                        const filename = item.split('/').pop();
                        return await objectURLToFile(item, filename, 'image/png');
                    });
                    try {
                        const files = await Promise.all(promises);
                        files.forEach((file, index) => {
                            formData.append(`${key}[${index}]`, file);
                        })
                    } catch (error) {
                        console.error(`Error processing ${key}:`, error);
                    }
                }
            } else if(key === isarr){
                    value.forEach((item, index) => {
                        formData.append(`${key}[${index}]`, item);
                    })
            } else {
                formData.append(key, value);
            }
        }else{
            continue
        }
    }

    return formData;
}

export async function packToForm3(data, text, file){
    const formData = new FormData();
    
    for (const [key, value] of Object.entries(data)) {
        if (text.includes(key)) {
            if(key === file){
                console.log("val:", value)
                const filename = value.split('/').pop();
                const fileObj = await objectURLToFile(value, filename, 'image/*');
                formData.append(`profile.${key}`, fileObj);
            } else {
                formData.append(`profile.${key}`, value);
            }
        } else {
            formData.append(key, value);
        }
    }

    console.log("form done", formData);
    return formData;
}

export function checkPassword(pass){
    let str = pass.toString();
    const specialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const numeric = /[0-9]/;
    const uppercase = /[A-Z]/;

    const hasSpecialChar = specialChars.test(str);
    const hasNumeric = numeric.test(str);
    const hasUppercase = uppercase.test(str);
    const isLongEnough = str.length >= 8;

    return [hasSpecialChar, hasNumeric, hasUppercase, isLongEnough]
}

// const packFiles = (files)=> {
//     const data = new FormData();

//     [...files].forEach((file, index) => {
//         data.append(`file-${index}`, file)
//     })
//     return data
// }


export const fetchHandle = async (data) => {
        const {url, body} = data
        return   axios
                .post(url, body, 
                    {
                        headers:{"Content-Type": "application/json"}
                    })
            
                .then( response =>  {
                    return { data: response.data, error: null, status: response.status}
                })
                .catch(error => {
                    return {
                        data: null,
                        error: error.response? error.response.data.error : error.message,
                        status: error.response?.status
                    }
                })
}

export const postHandle = async (objdata) => {
    const {url, data, config} = objdata
    return   axios
            .post(url, data, 
                {
                    headers: config
                })
        
            .then( response =>  {
                return { data: response.data, error: null, status: response.status}
            })
            .catch(error => {
                return {
                    data: null,
                    error: error.response? error.response.data?.error || error.response.data : error.message,
                    status: error.response?.status
                }
            })
}

export const getHandle = async (data) => {
    const {url, config} = data
    return   axios
            .get(url, 
                {
                    headers: config
                })
        
            .then( response =>  {
                return { data: response.data, error: null, status: response.status}
            })
            .catch(error => {
                return {
                    data: null,
                    error: error.response? error.response.data.error : error.message,
                    status: error.response?.status
                }
            })

}

export const putHandle = async (objdata) => {
    const {url, data, config} = objdata
    return   axios
            .put(url, data, 
                {
                    headers: config
                })
        
            .then( response =>  {
                return { data: response.data, error: null, status: response.status}
            })
            .catch(error => {
                return {
                    data: null,
                    error: error.response? error.response.data?.error || error.response.data : error.message,
                    status: error.response?.status
                }
            })
}

export const delHandle = async (objdata) => {
    const {url, data, config} = objdata
    return   axios
            .delete(url, data, 
                {
                    headers: config
                })
        
            .then( response =>  {
                return { data: response.data, error: null, status: response.status}
            })
            .catch(error => {
                return {
                    data: null,
                    error: error.response? error.response.data.error : error.message,
                    status: error.response?.status
                }
            })
}

export const isObjectEmpty = (objectName) => {
    return JSON.stringify(objectName) === "{}";
  };

export const viewPassword = (name) => {
    const input = document.querySelector(`.${name}`)
    if(input.type === "password"){
        input.type = "text"
    } else {
        input.type = "password"
    }
}

// Example usage
// const inputElement = document.getElementById('fileInput');

// inputElement.addEventListener('change', function() {
//     const file = this.files[0];

//     // Convert file to Object URL
//     fileToObjectURL(file, function(objectURL) {
//         console.log('Object URL:', objectURL);

//         // Convert Object URL back to file
//         objectURLToFile(objectURL, file.name, file.type)
//             .then(newFile => {
//                 console.log('File:', newFile);
//             });
//     });
// });
export function isVideo(src="/x/x/x.jpg"){
    let newSrc = src.toString();
    return newSrc.includes("mp4")? true : false
}

export const convertToThumbnail = (url) => {
    let Str = url.toString()
    let newSrc;
    newSrc = Str.replace("video", "https://res.cloudinary.com/dkykwpryb/video").replace("mp4", "jpg")
    return newSrc
}
export const replaceStr = (url) => {
    let Str = url.toString()
    let newSrc;
    if(Str.includes('video')){
        newSrc = Str.replace("video", "https://res.cloudinary.com/dkykwpryb/video")   
    }
    else {
        newSrc = Str.replace("image", "https://res.cloudinary.com/dkykwpryb/image")   
    }
    return newSrc
}

export const ShortenStr = (text, char) => {
    if(text !== "" && text !== null && text !== undefined ){
        let str = text.toString()
        if (str.length > char){
            str = `${str.slice(0, char)}...`
        }
        return str
    }else 
        return text
}

export function formatNumberWithCommas(number) {
    return number.toString().replace(/\\B(?=(\\d{3})+(?!\\d))/g, ",");
  }
  
export function addCommasToNumber(number) {
    // Convert number to string
    let numStr = number.toString();
  
    // Use regex to add commas
    numStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    return numStr;
}

export  function copyToClipboard(value) {
    // Get the text field
  
    // Copy the text inside the text field
    navigator.clipboard.writeText(value);
    
    // Alert the copied text
    // alert("Copied the text: " + value);
}


/**
 * Generate a range of characters based on strings
 *
 * @param string startCharacter
 * @param string endCharacter
 * @return string[]
 */
function generateCharacterRange(startCharacter, endCharacter) {
	let letters = [];

	for (let i = startCharacter.charCodeAt(0), l = endCharacter.charCodeAt(0); i <= l; i++) {
		letters.push(String.fromCharCode(i));
	}
	
	return letters;
}

function atPosition(arrayA, arrayB, arrayC){
    const val = new Date();
    let newStr = val.getMilliseconds().toString()
    let strTo;
    if(newStr.length > 2){
        strTo = newStr;
    }else if(newStr.length === 2){
        strTo = newStr + '9'
    }else if(newStr.length === 1){
        strTo = newStr + '27'
    }
    let newCode = []
    newCode.push(arrayA[parseInt(strTo.charAt(0))]);
    newCode.push(arrayC[parseInt(strTo.charAt(0))]);
    newCode.push(arrayB[parseInt(strTo.charAt(1))]);
    newCode.push(arrayA[parseInt(strTo.charAt(2))]);
    const reversedA = arrayA.reverse();
    const reversedB = arrayB.reverse();
    const reversedC = arrayC.reverse();
    newCode.push(reversedA[parseInt(strTo.charAt(0))]);
    newCode.push(reversedC[parseInt(strTo.charAt(0))]);
    newCode.push(reversedA[parseInt(strTo.charAt(1))]);
    newCode.push(reversedB[parseInt(strTo.charAt(2))]);
    newCode.push(reversedB[parseInt(strTo.charAt(2))]);
    return newCode;
}
export function randomcodegenerator(position){
    const lowercaseLetters = generateCharacterRange('a', 'z');
    const uppercaseLetters = generateCharacterRange('A', 'Z');
    const numbers = generateCharacterRange('0', '9');
    const val = atPosition(lowercaseLetters, numbers, uppercaseLetters);

    return val.join().replace(/,/g, "") + `${position}`;
}

export function decode(val){
    return val.slice(9)
}
// function isZero(char){
//     if(char === 0){
//         return true
//     }else {
//         return false
//     }
// }

// function ArrayChallenge(strArr){
//     let newStrArr = [];
//     for(let i=0; i< strArr.length; i++){

//         if(newStrArr.includes(strArr[i])){

//             newStrArr.splice(newStrArr.indexOf(strArr[i]), 1);
//         }
//         newStrArr.push(strArr[i]);

//         if(newStrArr.length > 5){

//             newStrArr.shift();
//         }
//     }
        
//         return newStrArr.join(', ');
//     }
//     strArr.forEach((item, index) => {
//         if(newStrArr.includes(item)){
//             newStrArr.filter(sitem => sitem !== item);
//             newStrArr.unshift(item);
//         } else {
//             newStrArr.unshift(item);
//         }
// })
// export function CommaSeperator(text){
//     let str = text.toString()
//     let count = 0
//     let indexes = []
//     for(let i = str.length; i > 0; i--){
//         count++;
//         if(count === 3){
//             count = 0;
//             indexes.push(i);
//         }
//     }
//     let prevIndex;
//     let newText = []
//     indexes.forEach((index) => {
//         prevIndex = index
//         newText.push(str.slice(index, str.length))
//     })

// }

// function repeatedLetters(str){
//     let words = str.split(" ")
//     let repeated = 0
//     for(let i=0; i<words.length; i++){
//         let word = words[i]
//         let count = {}
//         for(let j=0; j<word.length; j++){
//             for (const [key, value] of Object.entries(count)) {
//                 if(word.includes(key)){
//                     value + 1
//                 }
//             }
//         }
//     }
// }

export function capitalizeEveryWord(string) {
    return string.replace(/\b\w/g, match => match.toUpperCase());
}

export function eventcenter({address, state, hall_capacity, parking_space, iname, iphotos, company_id}){
    const obj = {address, state, hall_capacity, parking_space, iname, iphotos, company_id}
    return obj
}

export function shortlet({email, phone_no, state, address}){
    const obj = {email, phone_no, state, address}
    return obj
}

// "2023-11-16T10:10:32.110434Z"

// export function timeconvert(date){
//     let newDate = date.toString().split("-")
//     let year = Number(newDate[0])
//     let month = Number(newDate[1])
//     let newDay = newDate[2].split("T")
//     let day = Number(newDay[0])
//     let newTime = newDay[1].split(":")
//     let hr = Number(newTime[0])
//     let min = Number(newTime[1])
//     let sec = Number(newTime[2].split(".")[0])

//     // stick to years months days then time,if current year and year doesn't match if years is greater than 1 return years -1 years ago else if it's 1 ? then check months add them.. if = 12 ? return a year ago else return months ago
//     // if current and year matches check if current and month matches ...... else subtract and return 
//     let current = new Date();
//     let months = Math.abs(current.getMonth() - month )
//     let years = Math.abs(current.getFullYear() - year)
//     let t_cu = Calendar[current.getMonth()]
//     let t_m = Calendar[month]
//     let final = t_m - day + t_cu - current.getDay()
//     let days = Math.abs(current.getDay() - day)
//     let hrs = Math.abs(current.getHours - hr)
//     let mins = Math.abs(current.getMinutes - min)
//     let secs = Math.abs(current.getSeconds - sec)
//     if(current.getFullYear() === year || (years === 1 && months !== 0)){
//         if(months === 1 || month === current.getMonth()){
//             if(final >= 30){
//                 return 'a month ago'
//             }
//             else if(current.getDay() === day){
//                 if(current.getHours === hr){
//                     if(current.getMinutes === min){
//                         return `${secs > 1 ? secs + ' seconds' : 'a second'} ago`
//                     }else {
//                         return `${mins > 1 ? mins + ' minutes' : 'a minute'} ago`
//                     }
//                 }else {
//                     return `${hrs > 1 ? hrs + ' hours' : 'an hour'} ago`
//                 }
//             } else {
//                 return `${days > 1 ? days + ' days' : 'a day'} ago`
//             }
//         }else {
//             return `${months > 1 ? months + ' months' : 'a month'} ago`
//         } 
        
//     } else {
//         return `${years > 1 ? years + ' years' : 'a year'} ago`
//     }
// }

export function timeconvert(date) {
    const newDate = date.toString().split("-");
    const year = Number(newDate[0]);
    const month = Number(newDate[1]);
    const newDay = newDate[2].split("T");
    const day = Number(newDay[0]);
    const newTime = newDay[1].split(":");
    const hr = Number(newTime[0]);
    const min = Number(newTime[1]);
    const sec = Number(newTime[2].split(".")[0]);

    const dateObj = new Date(year, month - 1, day, hr, min, sec);
    const current = new Date();

    const diffInSeconds = Math.floor((current - dateObj) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInMonths / 12);

    if (diffInYears > 1) {
        return `${diffInYears} years ago`;
    } else if (diffInYears === 1) {
        return `a year ago`;
    } else if (diffInMonths > 1) {
        return `${diffInMonths} months ago`;
    } else if (diffInMonths === 1) {
        return `a month ago`;
    } else if (diffInDays > 1) {
        return `${diffInDays} days ago`;
    } else if (diffInDays === 1) {
        return `a day ago`;
    } else if (diffInHours > 1) {
        return `${diffInHours} hours ago`;
    } else if (diffInHours === 1) {
        return `an hour ago`;
    } else if (diffInMinutes > 1) {
        return `${diffInMinutes} minutes ago`;
    } else if (diffInMinutes === 1) {
        return `a minute ago`;
    } else {
        return `${diffInSeconds > 1 ? diffInSeconds + ' seconds' : 'a second'} ago`;
    }
}


export function MassReplaceStr(other) {
    return other.map(item => replaceStr(Object.values(item)));
  }

export function doGetCaretPosition (oField) {

    // Initialize
    var iCaretPos = 0;
  
    // IE Support
    if (document.selection) {
  
      // Set focus on the element
      oField.focus();
  
      // To get cursor position, get empty selection range
      var oSel = document.selection.createRange();
  
      // Move selection start to 0 position
      oSel.moveStart('character', -oField.value.length);
  
      // The caret position is selection length
      iCaretPos = oSel.text.length;
    }
  
    // Firefox support
    else if (oField.selectionStart || oField.selectionStart === '0')
      iCaretPos = oField.selectionDirection ==='backward' ? oField.selectionStart : oField.selectionEnd;
  
    // Return results
    return iCaretPos;
  }

export function returnStrong(target = "", items = []) {
    // Split the target text at each '@' and filter out empty strings
    return target.split('@').filter(n => n).map((text, outerIndex) => {
        // Loop through each item to check and replace
        for (let i = 0; i < items.length; i++) {
            // If the text includes the item, process further
            if (text.includes(items[i].name)) {
                // Split the text at spaces and replace matched items
                return text.split(" ").filter(n => n).map((str, innerIndex) => {
                    return str === items[i].name
                        ? (<strong style={{ color: 'black' }} key={`${outerIndex}-${innerIndex}`}>{`@${str} `}</strong>)
                        : str + " "; // Adding a space back between words
                });
            } else if (text === items[i].name) {
                // If text exactly matches the item, return with strong tag
                return (<strong style={{ color: 'black' }} key={outerIndex}>{`@${items[i].name}`}</strong>);
            }
        }
        // If no match found, return the text as is
        return text;
    });
}
    
        
    // target = target.replace(regex, `${<strong style="color:black">$1</strong>}k`)
    // text.innerHTML = target;

export function SignOut(config, token, dispatch){
    dispatch(postData({url: `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`, config: config, data: {'refresh': token}}));
    Cookies.remove('data');
    store.dispatch(signoutRequest());
}

export function handleRequest(response, dispatch, config, refresh){
    if(response.status === 200 || response.status === 201){
        return "success"
    }else if(response.status ===  401){
        dispatch(setAlert({alert: true, message: 'Session has expired'}));
        SignOut(config, refresh, dispatch);
        return "unauthorized"
    }else if (response.status ===  400){
        dispatch(setAlert({alert: true, message: response.error}));
        return "bad request"
    }else{
        dispatch(setAlert({alert: true, message: 'Network Error'}));
        return "network"
    }
}