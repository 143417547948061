import { Loader, Spinner } from "../style";
import { clear, searchData } from "../../../features/searchSlice/search";
import { useSelector, useDispatch } from "react-redux";
import { DisplayAgents } from "../index"
import { useEffect, useState } from "react";
import { preSet } from "../../../features/pageSlice/page";
import SearchedAgents from "../search";

const BusinessList = ({role}) => {
    const search = useSelector((state) => state.search);
    const prepage = useSelector((state) => state.page.prevPage);
    const dispatch = useDispatch();
    const [ isClicked, setIsClicked] = useState(false);
    const url = `${process.env.REACT_APP_BASE_APP_URL}/role/search_${role.toLowerCase()}/`
    const config = {"Content-Type": "application/json"}

    function toUp(rdata){
        return rdata.replace(rdata[0], rdata[0].toUpperCase());
    }
    
    useEffect(() => {
        dispatch(clear());
        dispatch(preSet('/find'));
        dispatch(searchData({url: url, config: config}));
        setIsClicked(search.clicked);
    }, [search.clicked, role])
    
    return (
       <>
        {!isClicked ?
            <>
            {(search.loading && search.data.length === 0) || (search.data.length > 0 &&  (prepage === '/' || prepage.split("/").includes('search')))?
                <Loader>
                    <Spinner
                    color={"rgba(17, 115, 171, 1)"}
                    loading={true}
                    size={50}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    />
                </Loader>
                :
                (<DisplayAgents data={search.data} role={['agent', 'event center'].includes(role)? 'an ' + role : role} extra={toUp(role) + 's'}/>)
            }
            </>
            :
            <>
            {search.loading?
                <Loader>
                    <Spinner
                    color={"rgba(17, 115, 171, 1)"}
                    loading={true}
                    size={50}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    />
                </Loader>
                :
                <>
                <SearchedAgents data={search.data} role={['agent', 'event center'].includes(role)? 'an ' + role : role} extra={toUp(role) + 's'} />
                </>    
            }
            </>
        }
       </>
    )
}


export default BusinessList