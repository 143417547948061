import { useDispatch, useSelector } from 'react-redux'
import { Outlet, Navigate } from "react-router-dom";
import { createContext, useEffect, useState } from 'react';
import { listClear } from '../features/registerSlice/rdataSlice'


export const PrivateRoutes = () => {
    // if(navigate(-1) === "/sell" && !user.hasRole){
    //   alert("you need a role to access this page")
    // }
    // const isLoggedIn = true
    const isLoggedIn = useSelector((state) => state.signin.loggedIn);
    // let auth = {'token': false}
    return (
            isLoggedIn?
            <Outlet />
            :
            <Navigate to="/sign in" />
            
    )
}

export const RemoveLog = () => {
    const isLoggedIn = useSelector((state) => state.signin.loggedIn);
    
    // let auth = {'token': false}
    return (
            !isLoggedIn?
            <Outlet />
            :
            <Navigate to="/" />
            
    )
}

export const NoRole = () => {
  const user = useSelector((state) => state.signin.user);
  const page = useSelector((state) => state.page);
  const dispatch = useDispatch();
    useEffect(() => {
      if(!['/profile/manage%20rentals', '/sell', '/list/1', '/list/2', '/list/3', '/list/4', '/list/5', '/list/6', '/uppath', '/preview'].includes(page.prevPage)){
        dispatch(listClear());
      }
    }, [])
    return (
        user.has_role && !['EventCenter', 'Bank'].includes(user.role)?
        <Outlet />
        :
        <Navigate to="/" />
      
    )
}

export const Page2 = () => {
  const list = useSelector((state) => state.data.list);
  const user = useSelector((state) => state.signin.user);
  return (
    ['Land', 'Tools', 'Short-let'].includes(list.property_status) || ['Short-let'].includes(user.role)?
    <Navigate to="/list/1" />
    :
    <Outlet />
    
  )
}

export const Page3 = () => {
  const list = useSelector((state) => state.data.list);
  const user = useSelector((state) => state.signin.user);
  return (
    ['Land', 'Tools', 'Room'].includes(list.property_status) || ['Hotel', 'Short-let'].includes(user.role)?
    <Navigate to="/list/1" />
    :
    <Outlet />
    
  )
}

export const Page4 = () => {
  const list = useSelector((state) => state.data.list);
  const user = useSelector((state) => state.signin.user);
  return (
    ['Land', 'Tools'].includes(list.property_status)?
    <Navigate to="/list/1" />
    :
    <Outlet />
    
  )
}

export const Page5 = () => {
  const list = useSelector((state) => state.data.list);
  const user = useSelector((state) => state.signin.user);
  return (
    ['Tools', 'Room', 'Short-let'].includes(list.property_status) || ['Hotel', 'Short-let'].includes(user.role)?
    <Navigate to="/list/1" />
    :
    <Outlet />
    
  )
}

export const Page6 = () => {
  const list = useSelector((state) => state.data.list);
  const user = useSelector((state) => state.signin.user);
  return (
    ['Land', 'Tools', 'Room', 'Short-let'].includes(list.property_status) || ['Hotel', 'Short-let'].includes(user.role)?
    <Navigate to="/list/1" />
    :
    <Outlet />
    
  )
}

// FileUploadContext.js


const FileUploadContext = createContext();

const FileUploadProvider = ({ children }) => {
  const [uploadedFiles, setUploadedFiles] = useState({
    id: 0,
    uhouse_view: [],
    uliving_room: [],
    ubed_room: [],
    utoilet: [],
    ukitchen: [],
    udocuments: [],
    uhouse_plan: [],
    usize: []
  });

  const addFile = (filename, file) => {
    setUploadedFiles({...uploadedFiles, [filename]: file});
  };
  return (
    <FileUploadContext.Provider value={{ uploadedFiles, addFile }}>
      {children}
    </FileUploadContext.Provider>
  );
};

export { FileUploadProvider, FileUploadContext };
