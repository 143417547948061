import React from 'react'
import { ChoiceButton, ChoiceContainer, ChoiceImg, ChoiceWrapper, Header } from '../style'
import user from "../../../assets/user.svg"
import business from "../../../assets/business.svg"
import { useNavigate } from 'react-router-dom'


const RoleChoice = () => {
  const navigate = useNavigate();
  return (
    <ChoiceWrapper>
      <Header>Which these best describe you?</Header>
      <ChoiceContainer>
        <ChoiceButton onClick={() => navigate('/')}>
          <ChoiceImg src={user} alt="I'm a user"/>
        </ChoiceButton>
        <ChoiceButton onClick={() => navigate('')}>
          <ChoiceImg src={business} alt="I'm a business" />
        </ChoiceButton>
      </ChoiceContainer>
    </ChoiceWrapper>
  )
}

export default RoleChoice