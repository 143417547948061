import styled from "styled-components";



export const TagWrapper = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 0;
    list-style: none;
    margin: 0;
    width: 100%;
    gap: 0.5rem;
`
export const TagItem = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: ${props => props.dir};
    width: ${props => props.dir? "max-content" : ""};
    height: ${props => props.ht? "10rem" : ""};
`
export const TagContainer = styled.li`
    background: rgba(238, 238, 238, 1);
    height: 3rem;
    border: none;
    border-radius: 4px;
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 3%;
`

export const TagHeader = styled.h3`
    color: rgba(17, 115, 171, 1);
`

export const TagText = styled.p`

`
export const TagIcon = styled.img`
    height: 20px;
    width: 20px;
`