import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
// import sessionStorage from "redux-persist/es/storage/session";
// import storageSession from 'reduxjs-toolkit-persist/lib/storage/session'
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';


import postReducer from '../features/postSlice/post'
import putReducer from '../features/putSlice/put'
import pageReducer from '../features/pageSlice/page'
import dataReducer from '../features/registerSlice/rdataSlice'
import singleReducer from '../features/singleSlice/single'
import signinReducer from '../features/signinSlice/signin'
import getReducer from '../features/getSlice/get'
import profileReducer from '../features/profileSlice/profile'
import searchReducer from '../features/searchSlice/search'
import { SIGNOUT_REQUEST } from "../features/purge/purge";


const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['post', 'search', 'put'],
    stateReconciler: autoMergeLevel2
  }


// const userPersistConfig = {
//     key: 'user',
//     storage: storageSession,
// }


  const appReducer = combineReducers({ 
    post: postReducer,
    put: putReducer,
    page: pageReducer,
    data: dataReducer,
    single: singleReducer,
    signin: signinReducer,
    get: getReducer,
    search: searchReducer,
    profile: profileReducer,
  })

  // const SIGNOUT = {
  //   type: SIGNOUT_REQUEST
  // }

  const rootReducer = (state, action) => {
    if (action.type === SIGNOUT_REQUEST) {
        // for all keys defined in your persistConfig(s)
        storage.removeItem('persist:root')
        // storage.removeItem('persist:otherKey')

        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};
  
  const persistedReducer = persistReducer(persistConfig, rootReducer)
  
 export const store = configureStore({
    reducer: persistedReducer
  })
  
  export const persistor = persistStore(store)
