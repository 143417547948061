import styled from 'styled-components'
import { NavLink as Link } from 'react-router-dom'



export const SellWrapper = styled.div`
    display: flex;
    margin: auto;
    margin-top: 2rem;
    width: 90%;
    flex-direction: column;
    gap: 2rem;
`
export const SellMini = styled.div`
 display: flex;
  @media screen and (max-width: 700px){
    flex-direction:column;
    gap: 2rem;
}
`

export const SellContainer = styled.div`
 display: ${props => props.top ? "contents" : "flex"};
 flex-direction: column;
 gap: 1rem;
 width: ${props => props.variant ? "30%" : "40%"};
 margin: auto;
 text-align: ${props => props.align};
 @media screen and (max-width: 700px){
    width: ${props => !props.top ? "71%" : "40%"};
 }

 @media screen and (max-width: 600px){
    width: ${props => !props.top ? "86%" : "40%"};
 }

 @media screen and (max-width: 500px){
    width: ${props => props.variant ? "100%" : "100%"};
    padding-top: 20px;
}
`

export const SellHeader = styled.h1`
 color: rgba(17, 115, 171, 1);
 font-size: 2rem;
 @media screen and (max-width: 700px){
    font-size: 2rem;  
 }
`
export const SellImage = styled.img`
    height: ${props => props.ht === "md" ? "180px" : "360px"};
    margin: auto;
    width: ${props => props.ht === "md" ? "30%" : ""} ;
    @media screen and (max-width: 700px) {
        height: ${props => props.ht === "md" ? "196px" : "360px"};
        margin-left: ${props => props.ht === "md" ? "10rem" : ""};
        width: 56%;
    }

    @media screen and (max-width: 600px){
        margin-left: ${props => props.ht === "md" ? "3rem" : ""};
    }

    @media screen and (max-width: 520px){
        height: ${props => props.ht === "md" ? "200px" : "360px"};
        width: 100%;
        margin: 0;
    }
`
export const SellP = styled.p`
    font-size: 1.5rem;
`

export const SellButton = styled.button`
    background: rgba(17, 115, 171, 1);
    color: white;
    width: ${props => props.size ? props.size : '70%'};
    float: left;
    font-size: 13px;
    height: 40px;
    border-radius: 4px;
    border: none;
    @media screen and (max-width: 690px){
    width: ${props => props.size ? '42%' : '89%'};
    }
    @media screen and (max-width: 500px){
    width: ${props => props.size ? "50%" : "50%"};   
    }
`
export const SellLink = styled(Link)``