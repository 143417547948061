import React from 'react'
import { CommText, CommWrapper, TopIcon } from '../style'
import comment from "../../../../assets/icons/comment.svg"

const Comment = ({number, pk}) => {
  function open(){
    const thisClass = `.rev${pk}`
    const vew = document.querySelector(thisClass)
    vew.style.display = "flex"
  }
  return (
    <CommWrapper onClick={() => open()}>
        <TopIcon src={comment} alt="comments" />
        <CommText>View ({number}) comments</CommText>
    </CommWrapper>
  )
}

export default Comment