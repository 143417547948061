import { AgentP, AgentImage, AgentButton } from "../style";
import moon from '../../../assets/moon.png'
import { useNavigate } from "react-router-dom";



const NotFound = ({text, wt}) => {
  const navigate = useNavigate()
  function Link(){
    navigate("/sell")
  }
    return(
        <>
          <AgentImage src={moon} />
          <AgentP thick='true' pd='true' size='true' >{text? `No ${text} found`: 'Search not found'}</AgentP>
          <AgentButton wt={wt} onClick={() => Link()}>Sell your home yourself</AgentButton>
        </>
    )
}

export default NotFound