import { ProfilePerties, ProfileText, Spinner, PropertyWrapper, LoadingCont, ProfileWrapper } from "../style"
import moon from '../../../assets/moon.png'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postData } from "../../../features/postSlice/post"; 
import { store } from "../../../app/store";
import { signoutRequest } from "../../../features/purge/purge";
import { setAlert } from "../../../features/searchSlice/search";
import Cookies from 'js-cookie'
import { getHandle } from "../../utility/drag_drop";
import Card from "../../carousel/homecard";



const Saved = () => {
    const user = useSelector((state) => state.signin.user);
    // const data = useSelector((state) => state.get);
    const url = `${process.env.REACT_APP_BASE_APP_URL}/property/view_saved/`
    const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`
    const [data, setData]= useState([]);
    const [status, setStatus]= useState(false);
    const dispatch = useDispatch();
    const config = {
        "Content-Type": "application/json",
        "Authorization": user.tokens? `JWT ${user.tokens.access}` : null
    }
    
    async function retrieve(){
        const state = await getHandle({url: url, config: config})
        setStatus(false);
        if(state.status === 200){
            setData(state.data);
        }else if(state.statusText === 'Network Error'){
            dispatch(setAlert({alert: true, message: 'Network issues'}));
            setData([]);
        }else if(state.status === 401){
            dispatch(setAlert({alert: true, message: 'Session has expired'}));
            dispatch(postData({url: lout, config: config, data: user.tokens}));
            Cookies.remove("data");
            store.dispatch(signoutRequest());
        }
    }

    useEffect(() => {
        setStatus(true);
        retrieve();
    }, [])

    return (
        <PropertyWrapper>
            {status?
                <LoadingCont>
                    <Spinner
                        color={"rgba(17, 115, 171, 1)"}
                        loading={true}
                        size={50}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </LoadingCont>
                :
                data.length !== 0 ?
                    <>
                        {data.map((item, index) => (
                            <Card item={item} />
                        ))}
                    </>
                    :
                    <>
                        <ProfileWrapper>
                            <ProfilePerties src={moon} />
                            <ProfileText sz='1rem'>You have no saved homes, like a home for it to appear here</ProfileText>
                        </ProfileWrapper>
                    </>
            }
        </PropertyWrapper>
    )
}

export default Saved;