import styled from 'styled-components'
import { NavLink as Link } from 'react-router-dom'

export const ListPoint = styled(Link)`
    width: 10%;
    text-align: center;
    font-weight: 700;
    height: fit-content;
    border-radius: 6px;
    background: ${({color}) => color? "rgba(17, 115, 171, 1)" : "rgba(230, 241, 247, 1)"};
    color: ${({color}) => color? "white":"black"};
    text-decoration: none;
`

export const ListRow = styled.span`
    display: flex;
    justify-content: space-between;
`

export const ListBar = styled.div`
    width: ${({wt}) => wt? wt : '100%'};
    background: ${({filler}) => filler? '#60b7e9' : '#D9D9D9'};
    height: 0.3rem;
`