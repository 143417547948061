import styled from "styled-components";


export const CommentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const CommentEmpty = styled.p`
    text-align: center;
`