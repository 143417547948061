import styled from 'styled-components';
import ClipLoader from "react-spinners/ClipLoader";



export const Icons = styled.img`
    align-self: center;
    filter: ${({upP}) => upP? "rgba(0, 0, 0, 0.6)" : ""};
    /* height: 4rem; */
`
export const FormWrapper = styled.form`
    width: 100%;
    margin: auto;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    gap: 6rem;
    padding: 3% 0;
    @media screen and (max-width: 800px){
        flex-direction: column;
        width: 66%;
        gap: 0;
    }
    /* @media screen and (max-width: 600px){
        width: 50%; 
    }
    @media screen and (max-width: 300px){
        width: 65%;
        flex-direction: column;
    } */
`
export const FormP = styled.p`
    color: white;
    font-size: 1.2rem;
    position: absolute;
    cursor: pointer;
    transform: translate(10px, 10px);
`
export const FormWarn = styled.p`
    color: ${({color}) => color? color : 'grey'};
    font-size: 0.9rem;
`
export const FormMini = styled.div`
    position: relative;
    height: 40px;
    display: flex;
    width: 100%;
    margin-bottom: 4rem;
`
export const FormIcon = styled.img`
    position: absolute;
    z-index: 1;
    right: 0;
    margin-top: 1rem;
    margin-right: 1rem;
    height: 20px;
    cursor: pointer;
`

export const FormLabel = styled.label`
    display: flex;
`
export const FormStrong = styled.strong`
    font-size: 1.2rem;
`

export const FormDrag = styled.div`
    width: 100%;
    height: 2.8rem;
    padding: 0 1% 0 2%;
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 30px;
    background: rgba(238, 238, 238, 1);
`

export const FormInput = styled.input`
    display: ${props => props.none? "none": "block"};
    width: 100%;
    height: 40px;
    background: rgba(238, 238, 238, 1);
    border: 1px solid black;
    border-radius: 3px;
    padding: 0 1% 0 2%;
    margin-bottom: 30px;
    @media screen and (max-width: 600px){
        height: 30px;
    }
`

export const FormSpan = styled.span`
    display: flex;
    flex-direction: column;
    width: 34%;
    margin: 0 auto;
    @media screen and (max-width: 1269px){
        width: 40%;
    }
    @media screen and (max-width: 800px){
        width: 100%;
    }
`

export const FormButton = styled.button`
    border: none;
    border-radius: 19px;
    margin: auto;
    height: 40px;
    width: ${({wt}) => wt? wt : '18%'}; 
    color: white;
    cursor: pointer;
    text-align: center;
    background: ${props => props.left? "rgba(0, 0, 0, 0.5)": "rgba(17, 115, 171, 1)"};
    align-self: ${({er}) => er?  "center" : "" };
    @media screen and (max-width: 600px){
        height: 32px;
        width: 32%;
        margin: 1rem auto;
    }
`
export const Button = styled(FormButton)`

    border-radius: 0;
    height: 60px;
    width: 100%; 
    padding: 3%; 
    @media screen and (max-width: 600px){
        height: 40px;
    }
`

export const DisplayContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 3%;
    padding: 0 1% 0 2%;
    width: 100%;
`
export const ImgContainers = styled.img`
    height: 6.5rem;
    width: 5.62rem;
    border-radius: 7px;
    margin-bottom: 0.5rem;
`
export const FormHeader = styled.h1`

`
export const Spinner = styled(ClipLoader)`
    align-self: center;
    justify-self: center;
`
export const VarIcon = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    cursor: pointer;
`
export const Imagewrap = styled.div`
    position: relative;
    height: max-content;
    width: max-content;
`
export const LoadingCont = styled.section`
    height: 100vh;
    display: grid;
`