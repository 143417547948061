import { useNavigate } from "react-router-dom"
import lost from "../../../assets/icons/lost.svg"
import { FormButton, ListedImg, FormWrapper, FormHeader } from "../style"

const Error = () => {
    const navigate = useNavigate()
    return (
      <FormWrapper>
        <FormHeader>You seem to be lost</FormHeader>
      <ListedImg er src={lost} />
      <FormButton er var set onClick={() => navigate('/')} >Back to home</FormButton>
      </FormWrapper>
    )
}

export default Error