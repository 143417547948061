import { Carousel, Services } from "../../components";
import { Filler } from '../../components/utility';
import { Header } from '../../layout';
import slide1 from "../../assets/header1.png";
import fill from "../../assets/filler1.png";
import google from "../../assets/icons/googles.svg";
import { useDispatch, useSelector } from "react-redux";
import { getData, googCheck, clear, updateData } from "../../features/getSlice/get";
import { cook, updateUserData } from "../../features/signinSlice/signin";
import { useEffect } from "react";
import { profileData } from "../../features/profileSlice/profile";
import { Spinner, LoadingCont } from "./style";
import Cookies from 'js-cookie'
import { setAlert, clearS } from "../../features/searchSlice/search"
import { getHandle } from "../../components/utility/drag_drop";
// import datas from '../../data.json'




const Home = () => {
    const isLoggedIn = useSelector((state) => state.signin.loggedIn)
    const dispatch = useDispatch();
    const user = useSelector((state) => state.signin.user);
    const page = useSelector((state) => state.page);
    const url = `${process.env.REACT_APP_BASE_APP_URL}/property/featured/`
    const roleData = `${process.env.REACT_APP_BASE_APP_URL}/property/roles/`
    const data_user = `${process.env.REACT_APP_BASE_APP_URL}/user/info/`
    const set = useSelector((state) => state.get);
    const googleLog = useSelector((state) => state.get.googleLog);
    // const set = datas
    const config = {
        "Content-Type": "application/json"
    }
    const config2 = {
        "Content-Type": "application/json",
        "Authorization": `JWT ${user.tokens? user.tokens.access: null}` 
    }

    async function updateClause(){
        const user_data = await getHandle({url: data_user, config: config2});
        dispatch(updateUserData(user_data.data));
    }
    async function updateProps(){
        const data3 = await getHandle({url: `${process.env.REACT_APP_BASE_APP_URL}/property/search/?property_status=Developed`, config: config2});
        const data4 = await getHandle({url: `${process.env.REACT_APP_BASE_APP_URL}/property/search/?property_type=Room`, config: config2});
        const data5 = await getHandle({url: `${process.env.REACT_APP_BASE_APP_URL}/role/an event center/`, config: config2});
        const data6 = await getHandle({url: `${process.env.REACT_APP_BASE_APP_URL}/property/search/?property_status=Shortlet`, config: config2});
        dispatch(updateData({data3: data3.data, data4: data4.data, data5: data5.data, data6: data6.data}));
    }
    
    useEffect(() => {
        dispatch(clearS());
        dispatch(clear());
        dispatch(getData({url: url, config: config}));
        // if(page.prevPage === "/role created" && postdata.data?.error === "This user has a role"){
        //     dispatch(setAlert({alert: true, message: "This user has a Business account"}))
        // } else 
        if(page.prevPage === "/role created"){
            dispatch(setAlert({alert: true, message: "Your Business account was created successfully", color: '#90EE90'}));
        }
        if(isLoggedIn){
            updateProps();
            dispatch(profileData({url: roleData, config: config2}));
            updateClause();
        }

    }, [isLoggedIn])

    useEffect(() => {
        if(googleLog){
            let user = Cookies.get('data');
            if (user !== undefined){
            let new_user = user.replace(/\\054/g, ",").replace(/'/g, '"');
            dispatch(cook(JSON.parse(new_user)));
            dispatch(profileData({url: roleData, config: config2}));
            updateClause();
            dispatch(googCheck(false));
        } 
        }
    }, [])

    return(
        <>
           <Header link={slide1} text={'TAKE CHARGE OF YOUR HOUSING EXPERIENCE'}/>
           {set.loading?
                <LoadingCont>
                    <Spinner
                        color={"rgba(17, 115, 171, 1)"}
                        loading={true}
                        size={50}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </LoadingCont>
                :
                <Carousel
                    Htext='Featured listings'
                    txt='For rent'
                    data={set.data}
                    action='Rent it'
                    data3={set.data3}
                    data4={set.data4}
                    data5={set.data5}
                    data6={set.data6}
                />
            }
            { 
             isLoggedIn
            ?<>
            </>
            : <>
            <Services />
             <Filler 
               head='Real estate at your ease'
               ptext='Find your place with an immersive photo experience and the most listings, including things you won"t find anywhere else'
               btntext ='Sign up'
               location="/sign up" 
               btntext2='Sign up with Google'
               imsrc={fill} 
               isrc={google}  
              /> 
            </>
            }
        </>
    )
}


export default Home