import { ProfileWrapper, ProfilePerties, ProfileText } from "../style";
import moon from '../../../assets/moon.png'




const ProBase = () => {



    return (
        <ProfileWrapper>
        <ProfilePerties src={moon} alt="profile" />
        <ProfileText>Tap an option to see properties</ProfileText>
        </ProfileWrapper>
    )
}

export default ProBase;