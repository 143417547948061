import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';



export const FooterContainer = styled.div`
 display: flex;
 padding-top: 70px;
 @media screen and (max-width: 759px){
  flex-wrap: wrap;
 }
 @media screen and (max-width: 700px){
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: auto;
    gap: 2rem;
  }
 @media screen and (max-width: 600px){
    padding-top: 103px;
 }
`
export const FooterWrap = styled.section`
  background: rgba(23, 27, 42, 1);
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
`
export const FooterP = styled.p`
  text-align: center;
  padding: 10px 0px;
`

export const ListContainer = styled.ul`
 list-style: none;
 display: flex;
 flex-direction: column;
 width: ${props => props.wit === "md" ? "26%" : ""};
 padding-top: ${props => props.pd === "true" ? "2%" : ""};
 @media screen and (max-width: 700px){
    width: 60%;
    padding-top: 0;
 }
`
export const List = styled.li`
  margin-bottom: 9px;
  display: ${({ gap }) => gap ? "flex" : "block"};
  align-items: center;
  gap: ${({ gap }) => gap ? "10%" : ""};
  @media screen and (max-width: 700px){
    font-size: 1.8rem;
 }
 @media screen and (max-width: 600px){
  gap: ${({ gap }) => gap ? "4%" : ""};
 }
 @media screen and (max-width: 500px){
    font-size: 1.4rem;
 }
 @media screen and (max-width: 385px){
    font-size: 1.3rem;
 }
 @media screen and (max-width: 361px){
    font-size: 1.1rem;
}
`
export const ListLink = styled(Link)`
  margin-bottom: 9px;
  display: ${({ gap }) => gap ? "flex" : "block"};
  gap: ${({ gap }) => gap ? "10%" : ""};
  text-decoration: none;
  color: white;
  @media screen and (max-width: 700px){
    font-size: 1.8rem;
 }
 @media screen and (max-width: 500px){
    font-size: 1.4rem;
 }
 @media screen and (max-width: 385px){
    font-size: 1.3rem;
 }
 @media screen and (max-width: 361px){
    font-size: 1.1rem;
}
`

export const Icons = styled.img`
 height: 17px;
 padding-right: ${({ rt }) => rt ? "2%" : ""};
 @media screen and (max-width: 600px){
  height: ${({ ht }) => ht ?'17px' : '25px'};
 }
`
export const Logo = styled.img`
 height: 62px;
`
export const FooterFiller = styled.img`
  height: 17rem;
  @media screen and (max-width: 759px){
    padding-left: 5%;
 }
`

export const FooterSearch = styled.input.attrs({
  type: 'email'
})`
    border-radius: 17px;
    /* height: 36px; */
    width: 65%;
    border: none;
    outline: none;
`
export const FooterButton = styled.button.attrs({
  type: 'submit'
})`
    background: rgba(17, 115, 171, 1);
    display: flex;
    border: none;
    border-radius: 17px;
    width: 30%;
    height: 37px;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 849px) {
      border-radius: 16px;
      width: 31%;
      height: 35px;
    }
      /*@media screen and (max-width: 770px) {
        padding-left: 4.9%; 
        bottom: 31px;
        height: 30px; 
      }
      @media screen and (max-width: 700px){
        width: 12%;
        left: 53%;
      }
      @media screen and (max-width: 450px){
        width: 16%;
        left: 53%;
        height: 29px;
      } */
`

export const FooterPara = styled.p`
  color: white;
  @media screen and (max-width: 372px){
    font-size: 0.9rem;
  }
`

export const FooterFloat = styled.div`
 display: flex;
 background: rgba(17, 115, 171, 1);
 width: 60%;
 height: 115px;
 margin: auto;
 border-radius: 73px;
 position: relative;
 top: 4rem;
 /* @media screen and (max-width: 1277px){
    width: 55%;
 } */
 @media screen and (max-width: 700px){
    height: 86px;
    width: 60%;
 }
 @media screen and (max-width: 600px){
    height: 171px;
    width: 93%;
    top: 5rem;
 }
 @media screen and (max-width: 500px){
    height: 112px;
    top: 5rem;
    padding: 2%;
  }
  @media screen and (max-width: 406px){
    padding: 4%;
  }
 /* @media screen and (max-width: 470px){
    height: 79px;
    width: 67%;
    padding: 2.5%;
}
@media screen and (max-width: 400px){
    height: 73px;
    width: 72%;
    padding: 4%;
    top: 5rem;
}
@media screen and (max-width: 320px){
  padding: 6%;
} */
`
export const Header = styled.h1`
  font-size: 1.9rem;
  color: white;
  @media screen and (max-width: 500px){
    font-size: 1.3rem;
}
`
export const FlipSide = styled.div`
  width: ${props => props.size === "text" ? "52%" : ""};
  margin: auto;
  @media screen and (max-width: 401px){
    width: ${props => props.size === "text" ? "55%" : ""};
  }

  @media screen and (max-width: 375px){
    width: ${props => props.size === "text" ? "55%" : "32%"};
  }

  @media screen and (max-width: 318px){
    width: ${props => props.size === "text" ? "63%" : "32%"};
  }
`
export const FooterFloatButton = styled.button`
  margin: auto;
  width: 24%;
  height: 40px;
  background: white;
  border: none;
  border-radius: 17px;
  @media screen and (max-width: 600px){
    width: 32%;
  }
  @media screen and (max-width: 584px){
    height: 53px;
    border-radius: 21px;
  }
`

export const FootP = styled.p`

`
export const Flip = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: black;
  font-size: 0.9rem;
  font-weight: 700;
  padding: 4%;
  @media screen and (max-width: 375px){
    padding: 6%;
  }
`
// export const Link = styled.a`
//   text-decoration: none;
// `

export const FooterLetter = styled.div`
  display: flex;
  position: relative;
  width: 70%;
  height: 36px;
  padding: 0.9%;
  background: white;
  border: none;
  border-radius: 17px;
  justify-content: space-between;
  @media screen and (max-width: 865px){
    width: 86%;
  }
`