import { HeaderWrapper, Header } from "./style";
import Filter from "../../components/filter/index"


const HeaderPage = ({link, text}) => {
    return(
    <>
    <HeaderWrapper src={link}>
        <Header>{text}</Header>
        <Filter/>
    </HeaderWrapper>
    </>
    )
}

export default HeaderPage;