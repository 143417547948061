import { useNavigate } from 'react-router-dom'
import { ListInput, ListLabel, ListRow, ListFilter, ListHeader, ListSelect, ListStrong, ListWrapper, ListOption, AppsWrapper, AppsContainer, AppsIcon, ExtraText, ListP, SpecCol } from '../style'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { print } from '../../../../data/years'
import close from "../../../../assets/icons/closew.svg"
import { listAdd, listClear } from '../../../../features/registerSlice/rdataSlice'
import NumProgress from '../../../../components/progress'
import { ConfirmState, packToForm2 } from '../../../../components/utility/drag_drop'
import { setAlert } from "../../../../features/searchSlice/search";
import { Back, Next } from '../btn'
import { postData, errorClear, successClear } from '../../../../features/postSlice/post'
import { store } from "../../../../app/store";
import { signoutRequest } from "../../../../features/purge/purge";
import Cookies from 'js-cookie'
import { shortlet, room } from '../../../../data/formFields'
import {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import AutoComplete from '../../../../components/autocomplete'
// import datas from  '../../../../data.json'


const ListFourth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const years = print(21);

  const user = useSelector((state) => state.signin.user);
  // const user = datas.users[3]
  const val = useSelector((state) => state.data.list);
  const post = useSelector((state) => state.post);
  const url = `${process.env.REACT_APP_BASE_APP_URL}/property/create/`
  const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`
  const config = {
    "Content-Type": "multipart/form-data",
    "Authorization": `JWT ${user.tokens.access}`
  }
  const[data, setData] = useState({
    property_price: val.property_price, 
    income_per_month: val.income_per_month,
    years_built: val.years_built,
    years_renovated: val.years_renovated,
    years_reconstructed: val.years_reconstructed,
    parking_space: val.parking_space,
    appliance: val.appliance,
    lr: val.lr,
    wifi: val.wifi,
    heater: val.heater,
    aircon: val.aircon,
    uappliances: val.edit? [...val.appliances].map((item, index) => item.name) : [...val.uappliances]
  })

  const handleChange = (event)=>{
    const target = event.target;
    setData({
      ...data, [target.name]: target.value
    })
  } 
  useEffect(() => {
    // if(data.appliance !== "" || data.years_built !== 0 || data.parking_space !== "" || data.income_per_month !== 0){
      dispatch(listAdd(data));
    // }
  }, [data])
  
  const handleSubmit = async (e) =>{
    e.preventDefault();
    const key = user.role === 'Short-let' || val.property_status === "Short-let"?['lr', 'appliance', 'wifi'] : ["Agent", "Developer"].includes(user.role)? ['income_per_month', 'years_renovated', 'years_built', 'years_reconstructed', 'parking_space', 'address', 'appliance'] : ["Hotel"].includes(user.role)? ['aircon', 'heater', 'appliance'] : ['wifi', 'lr', 'appliance']
    if(ConfirmState(key, data).valid){
      if(['Short-let', 'Hotel'].includes(user.role) || ["Short-let", "Room"].includes(val.property_status)){
        if(val.latitude === 0){
          getGeocode({ address: val.address }).then((results) => {
            const { lat, lng } = getLatLng(results[0]);
            dispatch(listAdd({latitude: lat, longitude: lng}));
          });
        }
        const text = ['Hotel'].includes(user.role)? ['ubed_room', 'utoilet']:['uhouse_view']
        const isarr = 'uappliances'
        const sin = []
        const formData = await packToForm2(val, text, isarr, sin, ['Hotel'].includes(user.role)? room: shortlet);
        dispatch(postData({url:url, data:formData, config:config}));
      }else{
        val.property_type !== "Land" && val.edit? navigate('/list/6') : navigate('/list/5')
      }
    } else { 
      dispatch(setAlert({alert: true, message: `${ConfirmState(key, data).key} is missing`}));
    }
  }

  const AddApps = (event) => {
    if(event.key === 'Enter'){
      setData((PrevArray) => ({
        ...PrevArray,
        uappliances:  [...PrevArray.uappliances, event.target.value]
      }))
    }
    
  }

  const removeUappliance = (applianceToRemove) => {
    setData((prevData) => ({
      ...prevData,
      uappliances: prevData.uappliances.filter(appliance => appliance !== applianceToRemove)
    }));
  };

  useEffect(() => {
    if (post.loading === false && post.error !== ''){
      if(post.status === 400){
        dispatch(setAlert({alert: true, message: post.error}));
        dispatch(errorClear());
      }else if (post.status === 401){
        dispatch(setAlert({alert: true, message: 'Session has expired'}));
        dispatch(postData({url: lout, config: {"Content-Type": "application/json"}, data: user.tokens}));
        dispatch(errorClear());
        Cookies.remove("data");
        store.dispatch(signoutRequest());
      }else{
        dispatch(setAlert({alert: true, message: 'Network Error'}));
        dispatch(errorClear());
      }
    }else if(post.loading === false && post.error === '' && post.success === true){
      dispatch(listClear());
      navigate('/list/done');  
      dispatch(successClear());
    }
  
  }, [post.loading])
  return (
    <ListWrapper>
        <ListHeader>Tell us about this property</ListHeader>
        <NumProgress wt={'60%'} />
        {["Agent", "Developer"].includes(user.role) && val.property_status !== 'Short-let'
          &&
          <>
          {val.property_status === "For sale" &&
          <>
          <ListLabel for="income"><ListStrong>Income Generated From The Property Every Month</ListStrong></ListLabel>
          <ListInput type="number" name="income_per_month" id="income" wt="60%" onChange={handleChange} placeholder={val.income_per_month? val.income_per_month : "E.g 100,000 naira/mothly"}/>
          </>}
          <ListLabel for="years"><ListStrong>Years Built, Renovated and Reconstructed</ListStrong></ListLabel>
          <ListFilter id="years">
            <ListSelect wt="48%" name='years_built' onChange={handleChange} defaultValue={"Years Built"}>
              <ListOption disabled value="Years Built">Years Built</ListOption>
            {years.map((item, index) => (
              <ListOption key={index} value={item}>{item}</ListOption>
            ))}
            </ListSelect>
            <ListSelect wt="48%" name='years_renovated' onChange={handleChange} defaultValue={"Years Renovated"}>
            <ListOption disabled value="Years Renovated">Years Renovated</ListOption>
            {years.map((item, index) => (
              <ListOption key={index} value={item}>{item}</ListOption>
            ))}
            </ListSelect>
            <ListSelect bts name='years_reconstructed' onChange={handleChange} defaultValue={"Years Reconstructed"}>
            <ListOption disabled value="Years Reconstructed">Years Reconstructed</ListOption>
            {years.map((item, index) => (
              <ListOption key={index} value={item}>{item}</ListOption>
            ))}
            </ListSelect>
          </ListFilter>
          <ListLabel for="parking"> <ListStrong>Is There Availble Parking Space?</ListStrong></ListLabel>
          <ListSelect name='parking_space' id="parking" onChange={handleChange} defaultValue={"Select desired option"}>
            <ListOption disabled value="Select desired option">Select desired option</ListOption>
            <ListOption value="Yes">Yes</ListOption>
            <ListOption value="No">No</ListOption>
          </ListSelect>
          </>
        }
        {(["Short-let"].includes(user.role) || val.property_status === 'Short-let')
          &&
          <>
          <ListLabel for="lr"> <ListStrong>Is There a Living Room?</ListStrong></ListLabel>
          <ListSelect name='lr' id="lr" pd onChange={handleChange} defaultValue={"Select desired option"}>
            <ListOption value="Yes">Yes</ListOption>
            <ListOption value="No">No</ListOption>
          </ListSelect>
          </>
        }
          <ListLabel for="appliance"><ListStrong>Are There Appliance's On The Property</ListStrong></ListLabel>
          <ListSelect name='appliance' pd id="appliance" onChange={handleChange} defaultValue={"Select option"}>
            <ListOption value="Yes">Yes</ListOption>
            <ListOption value="No">No</ListOption>
          </ListSelect>
        {(["Short-let"].includes(user.role) || val.property_status === 'Short-let')
          &&
          <>
          <ListLabel for="wifi"><ListStrong>Is There Wifi On The Property</ListStrong></ListLabel>
          <ListSelect name='wifi' pd id="wifi" onChange={handleChange} defaultValue={"Yes"}>
            <ListOption value="Yes">Yes</ListOption>
            <ListOption value="No">No</ListOption>
          </ListSelect>
          </>
        }
        {["Hotel"].includes(user.role)
          &&
          <>
          <ListLabel for="aircon"><ListStrong>Is There An Air Conditioner?</ListStrong></ListLabel>
          <ListSelect name='aircon' pd id="aircon" onChange={handleChange} defaultValue={"Select desired option"}>
            <ListOption value="Yes">Yes</ListOption>
            <ListOption value="No">No</ListOption>
          </ListSelect>
          <ListLabel for="heater"><ListStrong>Is There A Water Heater?</ListStrong></ListLabel>
          <ListSelect name='heater' pd id="heater" onChange={handleChange} defaultValue={"Yes"}>
            <ListOption value="Yes">Yes</ListOption>
            <ListOption value="No">No</ListOption>
          </ListSelect>
          </>
        }
        {!val.edit &&
          <>
            <SpecCol className='hcol' gp='0' >
              <ListLabel for="uappliances"><ListStrong>What appliances are on the property</ListStrong></ListLabel>
              <ListP role='hotel' color='gray' lt>Press enter to add item to list</ListP>
            </SpecCol>
            <ListInput type="text" name="uappliances" id="uappliances" onKeyDown={AddApps} placeholder={"E.g a Fridge"}/>
            {data.uappliances.length > 0 && 
              <AppsWrapper>
                {data.uappliances.map((item, index) => (
                    <AppsContainer key={index}>
                      <AppsIcon src={close} alt='close' onClick={() => removeUappliance(item)} />
                      <ExtraText>{item}</ExtraText>
                    </AppsContainer>
                  ))}
              </AppsWrapper>
            }
          </>
        }
        {(["Agent", "Developer"].includes(user.role)  && val.property_status !== 'Short-let')
          &&
          <>
          <ListLabel for="address"><ListStrong>Address</ListStrong></ListLabel>
          <AutoComplete />
          {/* <ListInput type="text" name="address" id="address" onChange={handleChange} placeholder={val.address? val.address : "E.g This property is haunted"}/> */}
          </>
        }
        <ListRow>
          <Back path={["Hotel"].includes(user.role) ? '/list/2' : '/list/3'}  />
          <Next func={handleSubmit} loading={post.loading} text = {val.property_status ===  'Short-let'? 'Finish': 'Next'} />
        </ListRow>
    </ListWrapper>
  )
}

export default ListFourth