import { styled } from "styled-components";


export const PartnerWrapper = styled.div`
 display: flex;
 flex-direction: column;
 width: 80%;
 margin: auto;
`
export const PartnerHead = styled.h3`
    font-size: 1.6rem;
    padding-bottom: 1rem;
`

export const PartnerContainer = styled.ul`
 list-style: none;
 display: flex;
 width: 100%;
 padding: 0;
 gap: 4%;
 justify-content: space-between;
`
export const PartnerList = styled.li`
`

export const PartnerLogo = styled.img`
    width: 100%;
    height: 2.5rem;
    @media screen and (max-width: 650px){
    height: 2.5rem;
 }
`