import styled, {keyframes} from 'styled-components'
import { NavLink as Link } from 'react-router-dom'



const open = keyframes`
    from {
        width: 0%;
    }
    to {
        width: 22%;
    }
`
const open2 = keyframes`
    from {
        width: 0%;
    }
    to {
        width: 30%;
    }
`
const open3 = keyframes`
    from {
        width: 0%;
    }
    to {
        width: 40%;
    }
`
const open4 = keyframes`
    from {
        width: 0%;
    }
    to {
        width: 45%;
    }
`
const open5 = keyframes`
    from {
        width: 0%;
    }
    to {
        width: 50%;
    }
`
const open6 = keyframes`
    from {
        width: 0%;
    }
    to {
        width: 55%;
    }
`
const open7 = keyframes`
    from {
        width: 0%;
    }
    to {
        width: 60%;
    }
`
const open8 = keyframes`
    from {
        width: 0%;
    }
    to {
        width: 70%;
    }
`
const open9 = keyframes`
    from {
        width: 0%;
    }
    to {
        width: 83%;
    }
`

export const PersonWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
    background: white;
    padding: 3.1%;
    padding-top: 6%;
    @media screen and (max-width: 800px){
        display: ${({stat}) => stat};
        z-index: 1;
        animation: ${open} 1s ease;
        animation-fill-mode: forwards;
        position: absolute;
        width: 35%;
        right: 0;
        top: 15rem;
        box-shadow: 1px 1px 3px grey;
    }
    @media screen and (max-width: 720px){
        animation: ${open2} 1s ease;
    }
    /* @media screen and (max-width: 600px){
        width: 60%;
    } */
    @media screen and (max-width: 600px){
        width: 40%;
        animation: ${open3} 1s ease;
    }
    @media screen and (max-width: 527px){
        width: 45%;
        animation: ${open4} 1s ease;
    }
    @media screen and (max-width: 468px){
        width: 50%;
        animation: ${open5} 1s ease;
    }
    @media screen and (max-width: 425px){
        display: ${({on}) => on ? 'none' : 'flex'};
        width: 78%;
        margin: auto;
        position: relative;
        top: 0;
        right: 0;
        box-shadow: none;
        animation: none;
    }
    /* @media screen and (max-width: 421px){
        width: 55%;
        animation: ${open6} 1s ease;
    }
    @media screen and (max-width: 383px){
        width: 60%;
        animation: ${open7} 1s ease;
    }
    @media screen and (max-width: 351px){
        width: 70%;
        animation: ${open8} 1s ease;
    }
    @media screen and (max-width: 301px){
        width: 83%;
        animation: ${open9} 1s ease;
    } */
`

export const PersonPhoto = styled.img`
    border-radius: 999px;
    width: 9rem;
    height: 9rem;
    align-self: center;
`
export const PersonText = styled.p`
    text-align: center;
    font-size: 1.2rem;
    font-weight: 700;
`
export const PersonIcon = styled.img`
    padding-right: ${props => props.rt ? "3%":""};
    padding-left: ${props => props.lt ? "3%":""};
    margin-left: ${props => props.mgn};
    position: ${({pos}) => pos };
    z-index: ${({pos}) => pos ? "1" : ""};
    right: ${({pos}) => pos ? "20%" : ""};
    bottom: ${({pos}) => pos ? "0" : ""};
    width: ${({pos}) => pos ? "3.5rem" : "" };
    cursor: pointer;
    @media screen and (max-width: 425px){
        right: ${({pos}) => pos ? "22%" : ""};    
    }
    @media screen and (max-width: 375px){
        right: ${({pos}) => pos ? "20%" : ""};    
    }
    @media screen and (max-width: 320px){
        right: ${({pos}) => pos ? "17%" : ""};    
    }
`

export const PersonButton = styled(Link)`
    display: flex;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid rgba(217, 217, 217, 1);
    text-decoration: none;
    color: black;
    margin-top: 1rem;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`
export const PersonSpan = styled.span`
    display: flex;
    gap: 7%;
    width: 64%;
    width: 100%;
    align-items: center;
`
const rotate = keyframes`
 from{
    transform: rotate(0deg);
 }
 to{
    transform: rotate(360deg);
 }
`

export const MobSpan = styled.span`
    display: none;
    @media screen and (max-width: 800px) {
        display: flex;
    }
    @media screen and (max-width: 425px){
        display: none;
    }
`
export const MobIcon = styled.img`
    height: 40px;
    &.rotate {
        animation: ${rotate} 1s ease;
    }
    &.reverse {
        animation: ${rotate} 1s ease-in-out;
        animation-direction: reverse;
    }
`

export const Up = styled.input.attrs({
    type: "file"
})`
   width: 30%;
   border: 1px solid black;
   border-radius: 2px;
   display: none;
`

export const Wrap = styled.span`
    position: relative;
    display: flex;
    flex-direction: column;
`
export const ProfileButton = styled.button`
    color: white;
    background: rgba(222, 24, 24, 1);
    border: none;
    border-radius: 6px;
    margin-top: 1rem;
    width: 50%;
    height: 4rem;
    text-align: center;
    padding: 3px;
    align-self: center;
    cursor: pointer;
`