import Person from "../../../components/persondata";
import Top from "../../../components/profile/top"
import { ColumnContainer, RowContainer } from "../style";
import { Outlet, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
// import datas from "../../../data.json"



const Profile = () => {
    const location = useLocation();
    const page = location.pathname;
    const data = useSelector((state) => state.signin.user)
    // const data = datas.users[0]
    

    return (
       <RowContainer>
         <ColumnContainer ht={page === `/profile/edit/${data.role.toLowerCase()}/profile`? true: false}>
         <Top />
         <Outlet />
         </ColumnContainer>
         <Person on url={data.profile? data.profile.avatar: null}/>
       </RowContainer>
    )
}


export default Profile;