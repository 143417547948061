import { useNavigate } from 'react-router-dom'
import { 
  ListButton, 
  ListInput, 
  ListLabel, 
  ListHeader, 
  ListRow, 
  ListStrong, 
  ListWrapper, 
  ListForm, 
  Find,
  FindWrapper, 
  MinorIcon, 
  MinorButton, 
  DropDown,
  DropDownColumn, ExtraText, Spinner, ListSelect, ListOption } from '../style'
import { useDispatch, useSelector } from 'react-redux'
import { listAdd } from '../../../../features/registerSlice/rdataSlice'
import { useEffect, useState } from 'react'
import { states } from '../../../../data/area'
import NumProgress from '../../../../components/progress'
import { ConfirmState } from '../../../../components/utility/drag_drop'
import sear from '../../../../assets/icons/owner_search.svg'
import help from '../../../../assets/icons/help.svg'
import avatars from "../../../../assets/defaulta.png"
import { searchData, clear, setAlert } from '../../../../features/searchSlice/search'
import { replaceStr, ShortenStr } from '../../../../components/utility/drag_drop'
import { Back } from '../btn'
import AutoComplete from '../../../../components/autocomplete'
// import users from '../../../../data.json'


const ListThird = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const val = useSelector((state) => state.data.list);
  const found = useSelector((state) => state.search);
  // const found = {"data": users.users}

  const [data, setData] = useState({
    property_type: val.property_type,
    no_of_rooms: val.no_of_rooms,
    no_of_bathrooms: val.no_of_bathrooms,
    lot_size: val.lot_size,
    // address: val.address,
    state: val.state,
    student: val.student,
    owner_name: val.owner_name,
    owner_email: val.owner_email,
    avatar: null
  })
  const [vis, setVis] = useState(false);

  const handleChange = (event) => {
    const target = event.target
    setData({
      ...data, [target.name]: target.value
    })
  }
  const user = useSelector((state) => state.signin.user);
  // const user = {role: 'Agent'}

  const handleSelect = (e, fullname, avatar, email) => {
    e.preventDefault();
    setData({
      ...data, owner_name: fullname, owner_email: email, avatar: avatar
    });
    dispatch(clear());
    setVis(false);
  }

  const handleDeSelect = (e) => {
    e.preventDefault();
    setData({
      ...data, owner_name: "", owner_email: "", avatar: null
    });
  }

  const Search = (event) => {
    setVis(true);
    const target = event.target
    dispatch(searchData({url: `${process.env.REACT_APP_BASE_APP_URL}/user/search/?search=${target.value}`, config: {"Content-Type": "application/json"}}));
  }

  useEffect(() => {
    // if(data.property_type !== ""){
      dispatch(listAdd(data));
    // }
  }, [data])

  const handleSubmit = (e) => {
    e.preventDefault();
    const key = ['Short-let'].includes(val.property_status)? ["property_type", "no_of_rooms", "no_of_bathrooms", "lot_size"]: val.property_status === 'For rent'? ["property_type", "no_of_rooms", "no_of_bathrooms", "lot_size", "student"] : ["property_type", "no_of_rooms", "no_of_bathrooms", "lot_size", "owner_email", "owner_name"]
    if(ConfirmState(key, data).valid){
      navigate('/list/4');
    }else { 
      dispatch(setAlert({alert: true, message: `${ConfirmState(key, data).key} is missing`}));
    }
    dispatch(clear());
  }

  return (
    <ListWrapper pos="relative">
      <ListHeader>Tell us about the property</ListHeader>
        <NumProgress wt={'45%'} />
        <ListForm>
          <ListLabel for="type"><ListStrong>Property Type</ListStrong></ListLabel>
          <ListInput type="text" name="property_type" id="type" placeholder={val.property_type? val.property_type : "E.g (bungalow etc)"} onChange={handleChange} />
          <ListLabel for="roomno"><ListStrong>Number of Rooms</ListStrong></ListLabel>
          <ListInput type="text" name="no_of_rooms" placeholder={val.no_of_rooms? val.no_of_rooms : 'E.g 1 room'}  onChange={handleChange} />
          <ListLabel for="bath"> <ListStrong>{["Short-let"].includes(user.role)? "Number of toilet's":"Number of Bathroom's"}</ListStrong></ListLabel>
          <ListInput type="text" name="no_of_bathrooms" id="bath" placeholder={val.no_of_bathrooms? val.no_of_bathrooms : "E.g 3 bathrooms" } onChange={handleChange} />
          <ListLabel for="size"><ListStrong>Lot Size(Sqft)</ListStrong></ListLabel>
          <ListInput type="text" name="lot_size" placeholder={val.lot_size? val.lot_size : 'E.g 173 square feet'}   onChange={handleChange} />
          <ListLabel for="state"><ListStrong>State</ListStrong></ListLabel>
          <ListSelect name="state" id="state" defaultValue={val.state} onChange={handleChange}>
            {states.map((item, index) => (
                <ListOption key={index} value={item}>{item}</ListOption>
            ))}
          </ListSelect>
          <ListLabel for="adrs"><ListStrong>Address of Property</ListStrong></ListLabel>
          <AutoComplete />
          {/* <ListInput type="text" name="address" placeholder={val.address? val.address : '2118 Thornridg...'}  onChange={handleChange} /> */}
          {["Agent", "Landlord", "Developer"].includes(user.role) && val.property_status === 'For rent'
            &&
            <>
            <ListLabel for="student"><ListStrong>Is This A Student Lodge?</ListStrong></ListLabel>
            <ListSelect name='student' pd='true' id="student" onChange={handleChange} defaultValue={"Yes"}>
              <ListOption value="Yes">Yes</ListOption>
              <ListOption value="No">No</ListOption>
            </ListSelect>
            </>
          }
          {["Agent", "Developer"].includes(user.role) && !['Tools', 'Land', 'Short-let'].includes(val.property_status) && !val.edit 
            &&
            <>
            <ListLabel for="owner"><ListStrong>Owner of Property</ListStrong></ListLabel>
            {val.owner_email !== "" || data.owner_email !== ""?
              <DropDown>
                <MinorIcon src={avatars} av='true' pl='true' alt= "user profile" />
                <ExtraText>{data.owner_name || val.owner_name}</ExtraText>
                <MinorButton wt='28%' red='true' onClick={(e) => handleDeSelect(e)}>Unselect</MinorButton>
              </DropDown>
              :
              <FindWrapper>
                <Find wt='100%'>
                  <MinorIcon src={sear} pl='true' alt="search for user"/>
                  <ListInput type="text" name="search" onBlur={() => setVis(false)} placeholder='George Hawk'  autocomplete="off" onChange={Search} />
                  <MinorButton wt='14%'>
                    <MinorIcon src={help} mg='true' alt="search for user"/>
                  </MinorButton>
                </Find>
                <>
                {vis &&
                  <>
                    {found.loading?
                      <Spinner
                      color={"rgba(17, 115, 171, 1)"}
                      loading={true}
                      size={20}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                      />
                      :
                      <>
                      {/* 16  */}
                      {found.data && found.data.length > 0?
                        <DropDownColumn ht='max-content' className='dropdown'>
                          {found.data.map((item, index) => {
                            const full_name = item.first_name + ' ' + item.last_name
                            const avatar = item.profile && item.profile.avatar
                            return (
                            <DropDown key={index}>
                              <MinorIcon src={item.profile && item.profile.avatar != null? replaceStr(item.profile.avatar) : avatars} av alt= "owner avatar" />
                              <ExtraText>{ShortenStr(full_name, 14)}</ExtraText>
                              <MinorButton wt='27%' onMouseDown={(e) => handleSelect(e, full_name, avatar, item.email)} >Select</MinorButton>
                            </DropDown>
                          )}
                          )}
                        </DropDownColumn>
                      :
                        <DropDownColumn className='dropdown'>
                          <ExtraText>No user found</ExtraText>
                        </DropDownColumn>
                      }
                      </>
                    }
                  </>
                }
              </>
              </FindWrapper>
            }
            </>
          }
        </ListForm>
        <ListRow>
          <Back path={["Short-let"].includes(user.role) || ['Short-let'].includes(val.property_status)? '/list/1' : '/list/2'}  /><ListButton var onClick={handleSubmit} >Next</ListButton></ListRow>
    </ListWrapper>
  )
}

export default ListThird