import { 
    Close, 
    MainImg, 
    Swipe, 
    Wrapper, 
    Count, 
    MiniImg, 
    Header, 
    SpecCont, 
    Tag, 
    BookBtn, 
    PropertyDetails,
    PropertyDetailsHeader,
    PropertyDetailsTab} from "./style";
import { useState, useEffect } from "react";
import { putHandle, replaceStr, convertToThumbnail, handleRequest } from "../utility/drag_drop";
import bookmark from "../../assets/icons/bookmark.svg"
import bookmarked from "../../assets/icons/bookmarked.svg"
import nextbtn from "../../assets/icons/nextwh.svg"
import prevbtn from "../../assets/icons/prevwh.svg"
import { useDispatch, useSelector } from "react-redux"
import { setAlert } from "../../features/searchSlice/search";
import { reload } from "../../features/singleSlice/single";
import DisplayView from "./display";
import { Outlet, useLocation } from "react-router-dom";


const Display = ({ data, main }) => {
    const user = useSelector((state) => state.signin.user);
    const config = {
        "Content-Type": "application/json",
        "Authorization": user.tokens? `JWT ${user.tokens.access}` : null
    }

    const [value, setValue] = useState(0);
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) => state.signin.loggedIn);
    const dsrc = main.main[value]
    const thb = Array.from(document.getElementsByClassName('thumbnails'));
    function clear() {
        thb.forEach((thumb) => thumb.classList.remove('active'));
    }
    const reloadImg = (event) => {
        const target = event.target;
        const newIndex = parseInt(target.getAttribute('data-key'));
        console.log("index1", newIndex)
        setValue(newIndex);
        clear();
        target.className.includes('thumbs')?
            target.parentElement.classList.add('active')
            : target.classList.add('active')
    }

    // function passClick(passedName){
    //     const target = document.querySelector(`.${passedName}`)
    //     const newIndex = parseInt(target.getAttribute('data-key'));
    //     console.log("index2", newIndex, target)
    //     setValue(newIndex);
    //     clear();
    //     target.classList.add('active')
    // }
    const MediaList = [
        {index: 'thumb0', range: [...main.house_view]},
        {index: 'thumb1', range: [...main.living_room]},
        {index: 'thumb2', range: [...main.bed_room]},
        {index: 'thumb3', range: [...main.toilet]},
        {index: 'thumb4', range: [...main.kitchen]},
        {index: 'thumb5', range: [...main.documents]},
        {index: 'thumb6', range: [...main.house_plan]},
        {index: 'thumb7', range: [...main.size]},
        {index: 'thumb8', range: [...main.land_image]},
        {index: 'thumb9', range: [...main.video]}
    ]

    useEffect(() => {
        for(let i=0; i < MediaList.length; i++){
            if(MediaList[i].range.includes(dsrc)){
                const thClass = `.${MediaList[i].index}`
                const th = document.querySelector(thClass)
                th.classList.add('active')
            }
        }
    }, [])
    const next = () => {
        clear();
        const newIndex = (value + 1) % main.main.length;
        setValue(newIndex);
        for(let i=0; i < MediaList.length; i++){
            if(MediaList[i].range.includes(main.main[newIndex])){
                const thClass = `.${MediaList[i].index}`
                const th = document.querySelector(thClass)
                th.classList.add('active')
            }
        }
    }
    const prev = () => {
        clear();
        const newIndex = (value - 1 + main.main.length) % main.main.length;
        setValue(newIndex);
        for(let i=0; i < MediaList.length; i++){
            if(MediaList[i].range.includes(main.main[newIndex])){
                const thClass = `.${MediaList[i].index}`
                const th = document.querySelector(thClass)
                th.classList.add('active')
            }
        }
    }

    const Save = async () => {
        const status = await putHandle({url: `${process.env.REACT_APP_BASE_APP_URL}/property/save/${data.id}/`, config: {'Content-Type': 'application/json', 'Authorization': `JWT ${user.tokens? user.tokens.access : null}`}})
        const response = handleRequest(status, dispatch, config, user?.tokens.refresh)
        if(response === "success"){ 
            // dispatch(reload(true));
            window.location.reload();
        }
    }

    const location = useLocation();
    useEffect(() => {
        if(location.pathname.endsWith('comments')){
            document.querySelector('.features').classList.remove('active')
        }else {
            document.querySelector('.features').classList.add('active')
        }
    }, [location.pathname])
    return (
        <Wrapper col="true" wt="60%" pdlf='true' >
            <MainImg>
                <DisplayView src={dsrc} />
                <SpecCont>
                    <Header col="white" className='top'>
                        Photos of the property
                    </Header>
                    <Tag>{data.property_status}</Tag>
                </SpecCont>
                <SpecCont>
                    <Swipe onClick={() => prev()}><Close src={prevbtn} alt="previous" /></Swipe>
                    <Swipe onClick={() => next()}><Close src={nextbtn} alt="next" /></Swipe>
                </SpecCont>
                {isLoggedIn && <BookBtn onClick={() => Save()}>
                        <Close src={data.saved? bookmarked : bookmark} alt="bookmark" pos='true'  />
                    </BookBtn>}
            </MainImg>

            <Wrapper pd='true' thb='true'>
                {data.house_view.length !== 0 && (<MiniImg data-key={0} className="thumb0 thumbnails" src={replaceStr(data.house_view[0].house_view)} onClick={reloadImg}>
                    <Count>+{data.house_view.length}</Count>
                    <Header sm="0.6rem" col="white" className='thumbs' 
                    data-key={0}>Photos of the Property</Header>
                </MiniImg>)}

                {data.living_room.length !== 0 && (<MiniImg data-key={data.house_view.length} className="thumb1 thumbnails" src={replaceStr(data.living_room[0].living_room)} onClick={reloadImg}>
                    <Count>+{data.living_room.length}</Count>
                    <Header sm="0.6rem" col="white" className='thumbs'
                    data-key={data.house_view.length}>Photos of the Living room</Header>
                </MiniImg>)}

                {data.bed_room.length !== 0 && (<MiniImg data-key={data.house_view.length + data.living_room.length} className="thumb2 thumbnails" src={replaceStr(data.bed_room[0].bed_room)} onClick={reloadImg}>
                    <Count>+{data.bed_room.length}</Count>
                    <Header sm="0.6rem" col="white" className='thumbs'
                    data-key={data.house_view.length + data.living_room.length}>Photos of the Bedroom</Header>
                </MiniImg>)}

                {data.toilet.length !== 0 && (<MiniImg data-key={data.house_view.length + data.living_room.length + data.bed_room.length} className="thumb3 thumbnails" src={replaceStr(data.toilet[0].toilet)} onClick={reloadImg}>
                    <Count>+{data.toilet.length}</Count>
                    <Header sm="0.6rem" col="white" className='thumbs' 
                     data-key={data.house_view.length + data.living_room.length + data.bed_room.length}>Photos of the Bathroom</Header>
                </MiniImg>)}

                {data.kitchen.length !== 0 && (<MiniImg data-key={data.house_view.length + data.living_room.length + data.bed_room.length + data.toilet.length} className="thumb4 thumbnails" src={replaceStr(data.kitchen[0].kitchen)} onClick={reloadImg}>
                    <Count>+{data.kitchen.length}</Count>
                    <Header sm="0.6rem" col="white" className='thumbs'
                    data-key={data.house_view.length + data.living_room.length + data.bed_room.length + data.toilet.length}>Photos of the Kitchen</Header>
                </MiniImg>)}

                {data.documents.length !== 0 && (<MiniImg data-key={data.house_view.length + data.living_room.length + data.bed_room.length + data.toilet.length + data.kitchen.length} className="thumb5 thumbnails" src={replaceStr(data.documents[0].documents)} onClick={reloadImg}>
                    <Count>+{data.documents.length}</Count>
                    <Header sm="0.6rem" col="white" className='thumbs' 
                    data-key={data.house_view.length + data.living_room.length + data.bed_room.length + data.toilet.length + data.kitchen.length}>Photos of House Documents</Header>
                </MiniImg>)}

                {data.house_plan.length !== 0 && (<MiniImg data-key={data.house_view.length + data.living_room.length + data.bed_room.length + data.toilet.length + data.kitchen.length + data.documents.length} className="thumb6 thumbnails" src={replaceStr(data.house_plan[0].house_plan)} onClick={reloadImg}>
                    <Count>+{data.house_plan.length}</Count>
                    <Header sm="0.6rem" col="white" className='thumbs'
                    data-key={data.house_view.length + data.living_room.length + data.bed_room.length + data.toilet.length + data.kitchen.length + data.documents.length}>Photos of House Plan</Header>
                </MiniImg>)}

                {data.size.length !== 0 && (<MiniImg data-key={data.house_view.length + data.living_room.length + data.bed_room.length + data.toilet.length + data.kitchen.length + data.documents.length + data.house_plan.length} className="thumb7 thumbnails" src={replaceStr(data.size[0].size)} onClick={reloadImg}>
                    <Count>+{data.size.length}</Count>
                    <Header sm="0.6rem" col="white" className='thumbs' 
                    data-key={data.house_view.length + data.living_room.length + data.bed_room.length + data.toilet.length + data.kitchen.length + data.documents.length + data.house_plan.length}>Photos of House Size and dimensions</Header>
                </MiniImg>)}

                {data.land_image.length !== 0 && (<MiniImg data-key={data.house_view.length + data.living_room.length + data.bed_room.length + data.toilet.length + data.kitchen.length + data.documents.length + data.house_plan.length + data.size.length} className="thumb8 thumbnails" src={replaceStr(data.land_image[0].land_image)} onClick={reloadImg}>
                    <Count>+{data.land_image.length}</Count>
                    <Header sm="0.6rem" col="white" className='thumbs' 
                    data-key={data.house_view.length + data.living_room.length + data.bed_room.length + data.toilet.length + data.kitchen.length + data.documents.length + data.house_plan.length + data.size.length}>Photos of Land</Header>
                </MiniImg>)}

                {data.property_video.length !== 0 && (<MiniImg data-key={data.house_view.length + data.living_room.length + data.bed_room.length + data.toilet.length + data.kitchen.length + data.documents.length + data.house_plan.length + data.size.length + data.land_image.length} className="thumb9 thumbnails" src={convertToThumbnail(data.property_video[0].video, true)} onClick={reloadImg}>
                    <Count>+{data.property_video.length}</Count>
                    <Header sm="0.6rem" col="white" className='thumbs' 
                    data-key={data.house_view.length + data.living_room.length + data.bed_room.length + data.toilet.length + data.kitchen.length + data.documents.length + data.house_plan.length + data.size.length + data.land_image.length}>Video of the Property</Header>
                </MiniImg>)}

            </Wrapper>

            <PropertyDetails>
                <PropertyDetailsHeader>
                    <PropertyDetailsTab to="" className='features'>Features</PropertyDetailsTab>
                    <PropertyDetailsTab to="comments">Comments({data.reviews.length})</PropertyDetailsTab>
                </PropertyDetailsHeader>
                <Outlet />
            </PropertyDetails>
           
        </Wrapper>
    )
}

export default Display