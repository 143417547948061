import default_profile from "../../../../../assets/default2.png"
import { returnStrong, ShortenStr } from '../../../../utility/drag_drop'
import { 
  CardProfile, 
  CommText, 
  PopHeader,  
  PopMessage, 
  PopWrapper } from '../../style'

const TextBubble = ({item, user, alt, wt, position}) => {
  return (
    <PopWrapper dir={item.user.first_name === user.first_name ? "row-reverse" : "row"} wt={wt} alt={alt} position={position} rev={item.user.first_name === user.first_name ? true : false}>
        <CardProfile src={default_profile} alt="profile"/>
        <PopWrapper dir="column" wt="80%" position={position}>
            <PopHeader sz='true' rev={item.user.first_name === user.first_name ? 'true' : 'false'}>{ShortenStr(`${item.user.first_name + " " + item.user.last_name}${item.user.first_name === user.first_name ? " (you)" : ""}`, 19)}</PopHeader>
            <PopMessage rev={item.user.first_name === user.first_name ? 'true' : 'false'}>
                <CommText size="0.85rem" >{returnStrong(item.comment, item.tags)}</CommText>
            </PopMessage>
        </PopWrapper>
    </PopWrapper>
  )
}

export default TextBubble