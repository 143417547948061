import styled from 'styled-components' 


export const NotificationWrapper = styled.section`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
    background: white;
    width: 26%;
    position: absolute;
    top: 5rem;
    height: 33.5rem;
    /* width: ${({wt}) => wt? wt : ''}; */
    box-shadow: 0 2px 3px grey;
    border-radius: 4px;
    overflow: auto;
    &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    }
    &::-webkit-scrollbar-track {
    background: #f1f1f1; 
    }
    &::-webkit-scrollbar-thumb {
    background: #888; 
    }
    &::-webkit-scrollbar-thumb:hover {
    background: #555;       
    }
    @media screen and (max-width: 890px){
        width: 32%;
        height: 42.5rem;
    }
    @media screen and (max-width: 731px){
        width: 39%;
    }
    @media screen and (max-width: 596px){
        width: 65%;
    }
    @media screen and (max-width: 490px){
        width: 80%;
        min-width: 350px;
    }
`
export const SubWrapper = styled.section`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`
export const NotificationItem = styled.div`
    display: flex;
    gap: 3%;
    align-items: center;
    border-bottom: ${({bd}) => bd? '1px solid grey': ''};
    padding: 2% 0;
    justify-content: ${({center}) => center? "center": ""};
`
export const NotificationP = styled.p`
    color: ${({color}) => color};
    width: ${({wt}) => wt? "67%":""};
    font-size: 0.85rem;
    &:hover {
        color: ${({color}) => color ? "rgb(18 159 239)" : ""};
    }
`
export const NotificationImg = styled.img`
    border-radius: 999px;
    height: 2rem;
    width: 2rem;
`
export const NotificationHeader = styled.h1``

export const NotificationBtn = styled.button`
    border: none;
    background: transparent;
    cursor: pointer;
`

export const BubbleWrap = styled.div`
    display: flex;
    gap: 1rem;
    border: none;
    /* position: fixed; */
    /* width: ${({ht}) => ht? '29%' : 'max-content'};
    height: ${({ht}) => ht? '39.5rem' : ''}; */
    /* bottom: 0;
    right: 0; */
    /* padding: 2%; */
    z-index: 4;
    justify-content: flex-end;
`
export const BubbleIcon = styled.img`
    cursor: pointer;
    height: 2rem;
    width: 2rem;

`
export const BubbleIconWrapper = styled.div`
    display: flex;
    position: relative;
    height: max-content;
    align-self: flex-end;
`

export const BubbleBadge = styled.div`
    display: flex;
    border-radius: 999px;
    justify-content: center;
    height: max-content;
    width: 46%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    color: white;
`

export const BubbleBadgeText = styled.p``