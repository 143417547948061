import styled from 'styled-components';
import ClipLoader from "react-spinners/ClipLoader";


export const EmptyCont = styled.section`
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: ${({dir}) => dir};
    justify-content: center;
    padding: 4% 0;
    height: ${({ht}) => ht? "" : "200px"};
`

export const EmptyP = styled.p`
    font-size: 4rem;
    font-weight: 700;
    color: #efebeb;
`
export const LoadingCont = styled.section`
    height: 200px;
    display: grid;
`

export const Spinner = styled(ClipLoader)`
    align-self: center;
    justify-self: center;
`
export const Headers = styled.h1`
    color: grey;
    font-size: 1.3rem;
    padding-left: ${props => props.guide? "0%" : "3%"};
    align-self: ${props => props.center? "" : "flex-start"};
    text-align: ${props => props.center};
`

export const Wrapper = styled.div`
    height: ${props => props.ht};
    display: flex;
    flex-direction: column;
    align-items: ${props => props.ht === '100vh'? "center":""};
    justify-content: ${props => props.ht === '100vh'? "center":""};
    padding: ${props => props.ht === '100vh'? "":"1% 4%"};
`

export const Text = styled.p``

export const ListContainer = styled.ul`
    padding: 0;
`

export const ListItem = styled.li`
    list-style: inside circle;
    padding-left: 1.5%;
`