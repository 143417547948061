import styled from "styled-components";
import ClipLoader from "react-spinners/ClipLoader";

export const Wrapper = styled.section`
    display: flex;
    margin-top: 3rem;
    gap: 5%;
    @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    }
`

export const LoadingCont = styled.section`
    height: 100vh;
    display: grid;
`
// npx update-browserslist-db@latest
export const Spinner = styled(ClipLoader)`
    align-self: center;
    justify-self: center;
`