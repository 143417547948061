import { AgentWrapper, AgentIcon, AgentImage, AgentWrap, AgentContainer, AgentP, AgentNext, BluP, AgentSpan } from "../../style";
import call from '../../../../assets/icons/phone.png'
import next from '../../../../assets/icons/blu-right.svg'
import Rating from "../../../utility/rating";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import pinlogo from "../../../../assets/pinlogo.png"
import { setAlert } from "../../../../features/searchSlice/search";
import { randomcodegenerator } from "../../../utility/drag_drop";



export const AgentCard = ({rating, address, profile, phone, person, id, text, title, jads}) => {
    const cardRef = useRef(null);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoggedIn = useSelector((state) => state.signin.loggedIn);
    function handleClick(){
      if(isLoggedIn){
        const newId = randomcodegenerator(id);
        navigate(`/contact/${title}/${person}?c=${newId}`);
      } else {
        dispatch(setAlert({alert: true, message: "You must be signed in"}));
        navigate("/sign in");
      }
    }


    return (
        <> 
          <AgentContainer width={!jads? "no rate":""} key={id} jc={!jads? "space-between":""} gp="5%" onClick={()=> handleClick()} >
            {jads?
                <>
                  <AgentImage variant="profile" src={profile || pinlogo} alt={person} />
                  <AgentWrapper top='true' size='true'>
                    <AgentP gin="4px" thick='true' ref={cardRef}>{person}</AgentP>
          
                    {!text? 
                      <>
                        <AgentP gin="4px">
                          <Rating rating={rating} />
                        </AgentP>
                        <AgentP gin="4px"><AgentIcon src={call} />{phone}</AgentP>
                      </>
                      : 
                      <>
                        <AgentP gin="4px"><AgentIcon src={call} />{phone}</AgentP>
                        <BluP gin="4px" thick='true'>View{" "+text}</BluP>
                      </>
                    }
                  </AgentWrapper>
                </>
                :
                <AgentSpan>
                  <AgentImage variant="profile" src={profile || pinlogo} bt='true' alt={person} />
                  <AgentWrap top='true' no='true'>
                    <AgentP gin="2px" thick ref={cardRef}>{person}</AgentP>
                    <AgentP gin="2px"><AgentIcon src={call} />{phone}</AgentP>
                    <AgentP gin="2px">
                      <Rating rating={rating} />
                    </AgentP>
                    <AgentP gin="2px">{address}</AgentP>
                  </AgentWrap>
                </AgentSpan>
            }
            {jads? <></> : <AgentNext><AgentIcon right="50px" wt='40px' pd='true' src={next} /></AgentNext>}
            
          </AgentContainer>
        </>
    )
}