import { useState, useRef, useEffect } from 'react'
import { ListColumn, ListDrag, ListPred, ListFlexed, ListHeader, ListRow, ListStrong, ListWrapper, Up, ListIcon, ImgCount, UButton, ListInput, SpecCol, ListLabel, AppsContainer, AppsIcon, ExtraText, ListP, AppsWrapper } from '../style'
import galy from '../../../../assets/galy.png'
import doc from '../../../../assets/icons/docs.svg'
import close from "../../../../assets/icons/closew.svg"
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import NumProgress from '../../../../components/progress'
import { fornext } from '../../../../features/pageSlice/page'
import { postData, errorClear, successClear } from '../../../../features/postSlice/post'
import { setAlert } from "../../../../features/searchSlice/search";
import { listClear, listAdd } from '../../../../features/registerSlice/rdataSlice'
import { handleDragOver, handleDragLeave, packToForm2 } from "../../../../components/utility/drag_drop";
import { Back, Next } from '../btn'
import { store } from "../../../../app/store";
import { signoutRequest } from "../../../../features/purge/purge";
import Cookies from 'js-cookie'
import {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { land } from '../../../../data/formFields'
// import datas from  '../../../../data.json'

const ListFifth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const values = useSelector((state) => state.data.list);
  const user = useSelector((state) => state.signin.user);
  // const user = datas.users[3]
  const post = useSelector((state) => state.post);
  const url = `${process.env.REACT_APP_BASE_APP_URL}/property/create/`
  const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`
  const config = {
    "Content-Type": "multipart/form-data",
    "Authorization": `JWT ${user.tokens.access}`
  }

  const [img, setImg] = useState({
    udocuments: values.udocuments,
    uhouse_plan: values.uhouse_plan,
    usize: values.usize,
    uvideo: null,
    extra_notes: "",
    uextra_charges: [...values.uextra_charges]
  })

  function uploadImg(val){
    dispatch(fornext({id: val, nextPage: "/list/5"}));
    navigate("/uppath");
  }

  useEffect(() => {
    if(values.edit && values.property_type != 'Land'){
      navigate('/list/6');
    }
    dispatch(listAdd(img));
  }, [img])

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(['Land'].includes(values.property_type)){
      if(values.latitude === 0){
        getGeocode({ address: values.address }).then((results) => {
          const { lat, lng } = getLatLng(results[0]);
          dispatch(listAdd({latitude: lat, longitude: lng}));
        });
      }
      const text = ['uland_image', 'udocuments']
      const isarr = 'uextra_charges'
      const sin = []
      const formData = await packToForm2(values, text, isarr, sin, land)
      dispatch(postData({url:url, data:formData, config:config}))
    }else {
      navigate('/list/6');
    }
  }

  const fileInputRef = useRef(null);

  const handleFileInputChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0]
    const target = event.target
    setImg({
      ...img, [target.name]: file
    });
    }
  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0]
    const target = event.target
    setImg({
      ...img, [target.getAttribute('data-name')]: file
    });
  }

  const AddCharge = (event) => {
    if(event.key === 'Enter'){
      let charge = event.target.value.split('-')
      let obj = {
        title: charge[0],
        price: charge[1]
      }
      setImg((PrevArray) => ({
        ...PrevArray,
        uextra_charges:  [...PrevArray.uextra_charges, obj]
      }))
    }
    
  }

  const removeUextracharge = (chargeToRemove) => {
    setImg((prevData) => ({
      ...prevData,
      uextra_charges: prevData.uextra_charges.filter(charge => charge !== chargeToRemove)
    }));
  };

  const handleChange = (event) => {
    const target = event.target
    setImg({
        ...img, [target.name]: target.value
      })
  }

  useEffect(() => {
    if (post.loading === false && post.error !== ''){
      if(post.status === 400){
        dispatch(setAlert({alert: true, message: post.error}));
        dispatch(errorClear());
      }else if (post.status === 401){
        dispatch(setAlert({alert: true, message: 'Session has expired'}));
        dispatch(postData({url: lout, config: {"Content-Type": "application/json"}, data: user.tokens}));
        dispatch(errorClear());
        Cookies.remove("data");
        store.dispatch(signoutRequest());
      }else{
        dispatch(setAlert({alert: true, message: 'Network Error'}));
        dispatch(errorClear());
      }
    } else if(post.loading === false && post.error === '' && post.success === true){
        dispatch(listClear());
        navigate('/list/done');  
        dispatch(successClear());
    }
  }, [post.loading])
  return (
    <ListWrapper>
      <ListHeader>Ownership documents</ListHeader>
      <NumProgress wt={'80%'} />
      <ListFlexed dir='column'>
        <ListColumn wt="90%" >
          <ListColumn none ><ListStrong >{values.property_status !== 'Land'?'Upload image of Documents for house':'Image of the documents'}</ListStrong><ListPred lt>*10 images max</ListPred></ListColumn>
          <ListDrag data-name="udocuments"  onClick={ img.udocuments.length > 0 ? () => navigate('/preview') : () => uploadImg(5)}>
            <ListIcon src={img.udocuments.length > 0 ? doc : galy} alt="drag and drop" />
            {img.udocuments.length > 0 ? <ImgCount>{img.udocuments.length}</ImgCount>: <UButton>Upload</UButton>}
          </ListDrag>
        </ListColumn>
        {(values.property_type === 'Land' || values.property_status === 'Land') &&
          <>
            <SpecCol className='hcol' gp='0' >
              <ListLabel for="uextra_charges"><ListStrong>Extra Charges</ListStrong></ListLabel>
              <ListP role='hotel' color='gray' lt>Write the extra charge and it's cost then press enter to add item to list</ListP>
            </SpecCol>
            <ListInput type="text" name="uextra_charges" id="uextra_charges" onKeyDown={AddCharge} placeholder={"E.g Marching ground - 100000"}/>
            {img.uextra_charges && 
              <AppsWrapper>
                {img.uextra_charges.map((item, index) => (
                  <AppsContainer key={index}>
                    <AppsIcon src={close} alt='close' onClick={() => removeUextracharge(item)} />
                    <ExtraText>{`${item.title} - ${item.price}`}</ExtraText>
                  </AppsContainer>
                ))}
              </AppsWrapper>}
            <ListLabel for="extra_notes">Extra notes</ListLabel>
            <ListInput type="text" name="extra_notes" onChange={handleChange} id="extra_notes" placeholder={values.extra_notes? values.extra_notes : "E.g This land is my father..."} />
          </>
        }
        {values.property_status !== 'Land' && !values.edit &&
          <>
            <ListColumn wt="90%" >
              <ListColumn none ><ListStrong >Upload images of House Plan</ListStrong><ListPred lt>*10 images max</ListPred></ListColumn>
              <ListDrag data-name="uhouse_plan"  onClick={ img.uhouse_plan.length > 0 ? () => navigate('/preview') : () => uploadImg(6)}>
                <ListIcon src={img.uhouse_plan.length > 0 ? doc : galy} alt="drag and drop" />
                {img.uhouse_plan.length > 0 ? <ImgCount>{img.uhouse_plan.length}</ImgCount>: <UButton>Upload</UButton>}
              </ListDrag>
            </ListColumn>
            <ListColumn wt="90%" >
              <ListColumn none ><ListStrong >Upload image of House size & dimensions</ListStrong><ListPred lt>*10 images max</ListPred></ListColumn>
              <ListDrag data-name="usize"  onClick={ img.usize.length > 0 ? () => navigate('/preview') : () => uploadImg(7)}>
                <ListIcon src={img.usize.length > 0 ? doc : galy} alt="drag and drop" />
                {img.usize.length > 0 ? <ImgCount>{img.usize.length}</ImgCount>: <UButton>Upload</UButton>}
              </ListDrag>
            </ListColumn>
          </>
        }
          {!values.edit &&
            <ListColumn wt="90%" >
            <ListColumn none ><ListStrong >Videos of the property</ListStrong></ListColumn>
              <ListDrag data-name="uvideo"  onDragLeave={handleDragLeave} onDragOver={handleDragOver} onDrop={handleDrop}>
                <ListIcon src={img.uvideo != null? doc : galy} alt="drag and drop" />
                <Up ref={fileInputRef} name="upload_id" onChange={handleFileInputChange} accept="video/mp4,video/x-m4v,video/" />
              </ListDrag>
          </ListColumn>}
      </ListFlexed>
      <ListRow>
        <Back path={['Land'].includes(values.property_status)? '/list/1' : ["Landlord"].includes(user.role) ? '/list/3' : '/list/5'} />
        <Next func={handleSubmit} loading={post.loading} text={['Land'].includes(values.property_status)? "Finish" : "Next"} />
      </ListRow>
    </ListWrapper>
  )
}

export default ListFifth