import { CarouselWrapper, CarouselHeader } from "./style";
import { useSelector } from "react-redux"
import PropertyCard from "./homecard";
import { Tag } from "../utility";
import SearchBar from "../searchbar";
import { useLocation } from "react-router-dom";
// import datas from "../../data.json";

const Carousel = ({ Htext, txt, data, action, tag, data2, data3, data4, data5, data6 }) => {
    const location = useLocation();
    const page = location.pathname;
    const isLoggedIn = useSelector((state) => state.signin.loggedIn);
    // const props = datas.properties
    return(
    <CarouselWrapper>
        <Tag text="Properties"/>
        {/* <PropertyCard data={props} size={3} /> */}
        <CarouselHeader>{Htext}</CarouselHeader>
        {["/buy", "/rent"].includes(page)  && 
        <>
        <SearchBar btm left="2.4%"/>
        <Tag text="Popular" rt="80%"/>
        </>}
        <PropertyCard data={data} text={txt} action={action} size={3} />
        {["/buy", "/rent"].includes(page)  && data2 &&
        <>
        <Tag text={tag} rt="80%"/>
        <PropertyCard data={data2} text={txt} action={action} size={3} />
        </>
        }
        {["/"].includes(page)  && isLoggedIn &&
        <>
            <Tag text="Our Services"/>
            <CarouselHeader>Developed</CarouselHeader>
            <PropertyCard data={data3} text={txt} action={action} size={3} />
        </>}
        {["/"].includes(page)  && isLoggedIn &&
        <>
            <Tag text="Our Services"/>
            <CarouselHeader>Hotels</CarouselHeader>
            <PropertyCard data={data4} text={txt} action={action} size={3} />
        </>}
        {["/"].includes(page)  && isLoggedIn &&
        <>
            <Tag text="Our Services"/>
            <CarouselHeader>Event centers</CarouselHeader>
            <PropertyCard data={data5} text={txt} action={action} size={3} />
        </>}
        {["/"].includes(page)  && isLoggedIn &&
        <>
            <Tag text="Our Services"/>
            <CarouselHeader>Shortlets</CarouselHeader>
            <PropertyCard data={data6} text={txt} action={action} size={3} />
        </>}
    </CarouselWrapper>
    )
} 

export default Carousel;