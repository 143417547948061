import { useEffect, useState } from "react";
import { FormButton, FormInput, FormLabel, FormWrapper, FormSpan, FormStrong, Spinner } from "../../style"
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { packToForm } from "../../../utility/drag_drop";
import { putData, errorClear } from "../../../../features/putSlice/put";
import { setAlert } from "../../../../features/searchSlice/search";
import { store } from "../../../../app/store";
import { signoutRequest } from "../../../../features/purge/purge";
import { postData } from "../../../../features/postSlice/post";
import Cookies from 'js-cookie'






const EdEnd = () => {
    const [reg, setReg]=useState({
        address: "",
        website: "",
    })
    const user = useSelector((state) => state.signin.user);
    const data = useSelector((state) => state.profileData)
    const roles = useSelector((state) => state.get.extras.role);
    const url = `${process.env.REACT_APP_BASE_APP_URL}/role/${roles.toLowerCase()}/${data.id}/` 
    const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`
    const config = { 
                    "Content-Type": "multipart/form-data",
                    "Authorization": `JWT ${user.tokens.access}`
                }
    const val = useSelector((state) => state.data.role);
    const error = useSelector((state) => state.put);
    const navigate = useNavigate()
    const handleChange = (e) => {
        e.preventDefault()
        const target = e.target
        setReg({...reg, [target.name]: target.value});
    }

    const handleInput = (e) => {
        e.preventDefault()
        const target = e.target
        setReg({...reg, [target.name]: target.value});
    }

    const dispatch = useDispatch();
    function back(){
        navigate(-1);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
            const FullData = {...val, ...reg}
            const text = ['profile_photo']
            const form = await packToForm(FullData, text);
            dispatch(putData({url:url, data:form, config:config}));
        }

    useEffect(() => {
        if (error.loading === false && error.error !== ""){
            if(error.status === 400){
                dispatch(setAlert({alert: true, message: error.error}));
                dispatch(errorClear());
              }else if (error.status === 401){
                dispatch(setAlert({alert: true, message: 'Session has expired'}));
                dispatch(postData({url: lout, config: {"Content-Type": "application/json"}, data: user.tokens}));
                dispatch(errorClear());
                Cookies.remove("data");
                store.dispatch(signoutRequest());
              }else{
                dispatch(setAlert({alert: true, message: 'Network Error'}));
                dispatch(errorClear());
              }
        } else if (error.loading === false && error.error === "" && error.success === true){
            navigate("/role created")
        }
    }, [error.loading])
    

    return(
        <FormWrapper>
            <FormLabel for="adrs"><FormStrong>Office Address</FormStrong></FormLabel>
            <FormInput type="text" placeholder={data.address} name="address" onChange={handleChange} id="adrs" required />
            <FormLabel for="websites"><FormStrong>Website</FormStrong></FormLabel>
            <FormInput type="url" placeholder={data.website} name="website" onInput={handleInput} id="websites" spec />
            <FormSpan><FormButton left onClick={() => back()} >Back</FormButton>
            <FormButton onClick={handleSubmit}>
                {error.loading?<Spinner
           color={"white"}
           loading={true}
           size={25}
           aria-label="Loading Spinner"
           data-testid="loader"
           />: "Finish"}</FormButton></FormSpan>
        </FormWrapper>
    )
}

export default EdEnd;