import { MobIcon, MobSpan, PersonButton, PersonIcon, PersonPhoto, PersonSpan, PersonText, PersonWrap, Up, Wrap, ProfileButton } from "./style"
import profile from '../../assets/icons/profile.svg';
import building from '../../assets/icons/building.svg';
import safe from '../../assets/icons/safe.svg';
import blusaved from '../../assets/icons/blusaved.svg';
import role from '../../assets/icons/roleProfile.svg';
import how from '../../assets/icons/how.svg';
import mic from '../../assets/icons/mic.svg';
import edit from '../../assets/icons/edit.svg';
import right from '../../assets/icons/rightbl.svg';
import defaultp from '../../assets/default.png'
import gear from '../../assets/icons/gears.svg'
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { putData, errorClear, successClear } from "../../features/putSlice/put";
import { postData } from "../../features/postSlice/post";
// import datas from "../../data.json"
import { updateUserData } from "../../features/signinSlice/signin";
import { setAlert } from "../../features/searchSlice/search";
import { getHandle, handleRequest, putHandle, SignOut } from "../utility/drag_drop";
import { store } from "../../app/store";
import { signoutRequest } from "../../features/purge/purge"
import Cookies from 'js-cookie'
import { useLocation } from "react-router-dom";


const Person = ({ url, on }) => {
    // , name
    const [open, setOpen] = useState(false)
    const [img, setImg] = useState({
        image: null,
        profile_photo: null,
    })
    const user = useSelector((state) => state.signin.user)
    // const user = datas.users[0]
    const data = useSelector((state) => state.profile.profiledata);
    const error = useSelector((state) => state.put);
    const location = useLocation();
    const currentpage = location.pathname;
    const roles = user.role.toLowerCase();
    const reg = currentpage === `/profile/edit/${roles}/profile`? `${process.env.REACT_APP_BASE_APP_URL}/role/${["Agent", "EventCenter"].includes(data.role)? "an ":"a "}${["eventcenter"].includes(data.role.toLowerCase())? "event center":data.role.toLowerCase()}/${data.id}/` : `${process.env.REACT_APP_BASE_APP_URL}/user/${user.id}/update/`;
    const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`;
    const data_user = `${process.env.REACT_APP_BASE_APP_URL}/user/info/`
    const dispatch = useDispatch();
    const config = {
        "Content-Type": "application/json",
        "Authorization": `JWT ${user.tokens.access}`
    }
    const config2 = {
        "Content-Type": "multipart/form-data",
        "Authorization": `JWT ${user.tokens.access}`
    }
    const fileInputRef = useRef(null);

    const handleUploadButtonClick = () => {
        fileInputRef.current.click(); // Trigger the file input click event
    };

    async function updateClause(){
        const user_data = await getHandle({url: data_user, config: config2});
        const response = handleRequest(user_data, dispatch, config, user?.tokens.refresh)
        if(response == "success"){
            dispatch(updateUserData(user_data.data));
        }
        
    }

    const handleFileInputChange = (event) => {
        event.preventDefault();
        currentpage === `/profile/edit/${user.role.toLowerCase()}/profile`?
        setImg({
            image: event.target.files
        })
        :
        setImg({
            profile_photo: event.target.files
        });
    }
    async function imageResend(){
        const imageChange = await putHandle({url: reg, data: img, config: config2});
        const response = handleRequest(imageChange, dispatch, config, user?.tokens.refresh)
        if(response === "success"){
            updateClause();
        }
    }
    useEffect(() => {
        if(img.image != null){
            imageResend();
        }
    }, [img.image])
    
    const gearRef = useRef(null);
    function spin() {
        const target = gearRef.current
        if (open === true) {
            setOpen(false);
            target.classList.add('reverse')
            setTimeout(() => {
                target.classList.remove('reverse')
            }, 1000)
        } else {
            setOpen(true);
            target.classList.add('rotate')
            setTimeout(() => {
                target.classList.remove('rotate')
            }, 1000)
        }
    }
    
    return (
        <>
            <MobSpan>
                {/* {!open? */}
                <MobIcon src={gear} ref={gearRef} alt="settings" onClick={() => spin()} className="gear" />
            </MobSpan>
            <PersonWrap stat={!open ? "none" : "flex"} on={on}>
                <Wrap>
                <PersonPhoto src={currentpage === `/profile/edit/${user.role.toLowerCase()}/profile`? data.profile_photo || defaultp : url || defaultp} alt={user.first_name + ' ' + user.last_name} />
                <PersonIcon src={edit} alt="edit profile" onClick={handleUploadButtonClick} pos="absolute"/>
                </Wrap>
                <Up type='file' ref={fileInputRef} onChange={handleFileInputChange} accept="image/*" />
                <PersonText>{user.first_name + ' ' + user.last_name}</PersonText>
                <PersonButton to="/profile/edit profile" ><PersonSpan><PersonIcon src={profile} alt="edit profile" />Edit profile</PersonSpan><PersonIcon src={right} alt="click me" /></PersonButton>
                {user.has_role && <PersonButton to={`/profile/edit/${user.role.toLowerCase()}/profile`} ><PersonSpan><PersonIcon src={role} alt={`edit ${user.role} profile`} />Edit Business profile</PersonSpan><PersonIcon src={right} alt="click me" /></PersonButton>}
                {user.has_role && !['EventCenter', 'Bank'].includes(user.role) && 
                <PersonButton to="/profile/manage rentals" >
                    <PersonSpan>
                        <PersonIcon src={building} alt="properties" />
                            My Listed Properties
                        </PersonSpan>
                        <PersonIcon src={right} alt="click me" />
                    </PersonButton>
                }
                <PersonButton to="/profile/saved" ><PersonSpan><PersonIcon src={blusaved} alt="saved" />Saved homes</PersonSpan><PersonIcon src={right} alt="click me" /></PersonButton>
                <PersonButton to="/profile/security" ><PersonSpan><PersonIcon src={safe} alt="security" />Security</PersonSpan><PersonIcon src={right} alt="click me" /></PersonButton>
                <PersonButton to="/profile/how to use" ><PersonSpan><PersonIcon src={how} alt="how to use" />How to use?</PersonSpan><PersonIcon src={right} alt="click me" /></PersonButton>
                <PersonButton to="/profile/help" ><PersonSpan><PersonIcon src={mic} alt="support" />Customer support</PersonSpan><PersonIcon src={right} alt="click me" /></PersonButton>
                <ProfileButton onClick={() => SignOut(config, user?.tokens.refresh, dispatch)}>Signout</ProfileButton>
            </PersonWrap>
        </>
    )
}


export default Person;