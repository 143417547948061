import { ContactForm } from "../../../components";
import PropertyCard from "../../../components/carousel/homecard";
import Rating from "../../../components/utility/rating";
import { 
    ContactContainer, 
    ContactHeader, 
    ContactBar, 
    ContactInnerWrapper, 
    ContactHighlight, 
    ContactImg, 
    ContactP, 
    ContactWrapper, 
    ContactWrite, 
    ContactIcon, Spinner, LoadingCont, ContactInfoBox, CenterContainer, CenterIcon, CenterInner, CenterItem, CenterHeader, CenterText, CenterImage, CopyBtn, ContactAcross } from "./style";
import { FormLabel, FormStrong, DisplayContainer, ImgContainers } from "../../../components/inputs/role/style";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import moon from '../../../assets/moon.png'
import back from '../../../assets/icons/arrow-left.svg'
import appliance from "../../../assets/icons/appliance.svg"
import verified from "../../../assets/icons/verified.svg"
import price from "../../../assets/icons/price.svg"
import features from "../../../assets/icons/features.svg"
import address from "../../../assets/icons/address.svg"
import { useNavigate } from "react-router-dom";
import { ShortenStr, isObjectEmpty, addCommasToNumber, copyToClipboard, decode } from "../../../components/utility/drag_drop";
import { clear, singleData } from "../../../features/singleSlice/single";
import pinlogo from "../../../assets/pinlogo.png"
import { viewImage } from "../../../features/registerSlice/rdataSlice";


const ContactPage = () => {
    const[limit, setLimit] = useState(true);
    const [searchparams, setSearchParams] = useSearchParams();
    let { role, name } = useParams();
    const user = useSelector((state) => state.signin.user);
    const rId = searchparams.get('c')
    const [copy, setCopy] = useState(false);
    const single = useSelector((state) => state.single);
    const dispatch = useDispatch();
    const config = {
        "Content-Type": "application/json", 
        "Authorization": `JWT ${user && user.tokens? user.tokens.access : null}`
      }
    const  [roledata, setRoleData] = useState("");
    const url = `${process.env.REACT_APP_BASE_APP_URL}/property/${role}/${decode(rId)}/`

    useEffect(() => {
        dispatch(clear());
        dispatch(singleData({url: url, config: config}));
    }, [])

    useEffect(() => {
        if(!isObjectEmpty(single.singledata)){
            switch (single.singledata.role) {
                case 'Hotel':
                    setRoleData(single.singledata.name);
                    break;
                case 'Landlord':
                case 'Short-let':
                case 'Agent':
                    setRoleData(single.singledata.user.first_name + ' ' + single.singledata.user.last_name);
                    break;
                default:
                    setRoleData(single.singledata.company_name);
                    break;
            }
        }
    }, [single.loading])

    function setUnavailable(items){ 
        const rentedOut= []
        for(var i=0; i<items.length; i++){
            if(!items[i].available){
                rentedOut.push(items[i])
            }
        }
        return rentedOut
    }

    function viewOpen(data, index){
        dispatch(viewImage({open: true, data: data, index: index}))
    }
    const navigate = useNavigate();

    function clickCopy(){
        setCopy(!copy);
        copyToClipboard(single.singledata.phone_no);
        setTimeout(() => {
            setCopy(!copy)
        }, 5000)
    }
  return (
    <>
    {single.loading || JSON.stringify(single.singledata) === '{}'?
        <LoadingCont>
            <Spinner
                color={"rgba(17, 115, 171, 1)"}
                loading={true}
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </LoadingCont>
       :
        <>
        <ContactBar><ContactIcon src={back} alt="back" onClick={() => navigate(-1) } />Find {["Agent", "EventCenter"].includes(single.singledata.role)?"an" :"a"}{" " + single.singledata.role.toLowerCase()}{" / "+ single.singledata.address}{" / "+ roledata}</ContactBar>
        <ContactWrapper width="80%" place="auto" top='true' vt="column" >
            <ContactContainer width="50%" pos="flex" wt="80%" gp='true'>
                <ContactInnerWrapper>
                    <ContactImg src={single.singledata.profile_photo? single.singledata.profile_photo : pinlogo} alt={single.singledata.name} />
                    <ContactContainer pos="flex">
                        <ContactHeader>{roledata}</ContactHeader>
                        <ContactP>
                            {single.singledata.address}
                            {
                            single.singledata.is_verified?
                                <ContactIcon src={verified} alt="verified badge" />
                                :
                                <></>
                            }
                        </ContactP>
                        <ContactP>{single.singledata.phone_no}<CopyBtn onClick={() => clickCopy()}>{copy? 'copied!':'copy'}</CopyBtn></ContactP>
                        <><Rating rating={single.singledata.ratings} /> {single.singledata.ratings === 0 && "(No ratings)"}</>
                    </ContactContainer>
                </ContactInnerWrapper>
                {['EventCenter'].includes(single.singledata.role) && 
                    <CenterContainer dir="column">
                        <CenterContainer dir="column">
                            <CenterContainer dir="row">
                                <CenterIcon src={features} alt="center features"/>
                                <CenterContainer dir="column">
                                    <CenterHeader>Property features</CenterHeader>
                                    <CenterInner>
                                        <CenterItem>
                                            <CenterText>{single.singledata.hall_capacity+" "}hall capacity</CenterText>
                                        </CenterItem>
                                        <CenterItem>
                                            <CenterText>{single.singledata.is_security? "Security": "No Security"}</CenterText>
                                        </CenterItem>
                                        <CenterItem>
                                            <CenterText>{single.singledata.parking_space + " "}parking capacity</CenterText>
                                        </CenterItem>
                                        <CenterItem>
                                            <CenterText>{single.singledata.additional_services? "Additional Services": "No Additional Services"}</CenterText>
                                        </CenterItem>
                                    </CenterInner>
                                </CenterContainer>
                            </CenterContainer>
                        </CenterContainer>
                        <CenterContainer dir="column">
                            <CenterContainer dir="row">
                                <CenterIcon src={appliance} alt="appliances" />
                                <CenterContainer dir="column">
                                    <CenterHeader>Appliances</CenterHeader>
                                    <CenterInner>
                                        {single.singledata.appliances.map((item, index) => (
                                        <CenterItem key={index}><CenterText>{item.name}</CenterText></CenterItem>
                                        ))}
                                    </CenterInner>
                                </CenterContainer>
                            </CenterContainer>
                        </CenterContainer>
                        <CenterContainer dir="column">
                            <CenterContainer dir="row">
                                <CenterIcon src={address} alt="location" />
                                <CenterContainer dir="column">
                                    <CenterHeader>State</CenterHeader>
                                    <CenterInner>
                                        <CenterItem><CenterText>{single.singledata.state}</CenterText></CenterItem>
                                    </CenterInner>
                                </CenterContainer>
                            </CenterContainer>
                        </CenterContainer>
                        <CenterContainer dir="column">
                            <CenterContainer dir="row">
                                <CenterIcon src={price} alt="price per day" />
                                <CenterContainer dir="column">
                                    <CenterHeader>Price Per Day</CenterHeader>
                                    <CenterInner>
                                        <CenterItem><CenterText>{addCommasToNumber(single.singledata.price_per_day)}</CenterText></CenterItem>
                                    </CenterInner>
                                </CenterContainer>
                            </CenterContainer>
                        </CenterContainer>
                        <FormLabel for='photos'><FormStrong>Images of the Event Center</FormStrong></FormLabel>
                        <DisplayContainer>
                            {single.singledata.photos.length > 0?
                                single.singledata.photos.map((item, index) => (
                                <ImgContainers src={item.photo} key={item.id} onClick={() => viewOpen(single.singledata.photos, index)} />
                                ))
                                :
                                <CenterContainer dir="column" al="center">
                                    <CenterImage src={moon} alt="no photos" />
                                    <CenterHeader>No Photos</CenterHeader>
                                </CenterContainer>
                            }
                        </DisplayContainer>
                    </CenterContainer>
                }
                {!['Short-let', 'EventCenter'].includes(single.singledata.role) && 
                    <ContactInfoBox>
                        <ContactHeader>About {['Hotel', 'Bank'].includes(single.singledata.role)? " us":" me"}</ContactHeader>
                        {!['Hotel', 'Bank'].includes(single.singledata.role) && <ContactP bt >Specialties:{single.singledata.specialties? " " + single.singledata.specialties : "None"}</ContactP>}
                        <ContactP>{limit? ShortenStr(single.singledata.about_you, 616) : single.singledata.about_you}</ContactP>
                        {single.singledata.about_you.length > 616 && <ContactHighlight onClick={() => limit? setLimit(false): setLimit(true)} >{limit? "See more": "hide"}</ContactHighlight>}
                    </ContactInfoBox>
                }
                {!['EventCenter'].includes(single.singledata.role) && 
                <ContactInfoBox>
                    <ContactHeader>
                    {
                        ['Developer'].includes(single.singledata.role)? 
                            "Developed properties" :
                            ['Hotel'].includes(single.singledata.role)? 
                                "Available rooms":
                                "Available"
                    }
                    </ContactHeader>
                    {single.singledata.property && single.singledata.property.length > 0 ?
                        <>
                            <PropertyCard data={single.singledata.property} wtw={"100%"} wtc={"50%"} size={1} />
                            {/* {single.singledata.property.length > 1 && <ContactHighlight>See more</ContactHighlight>} */}
                        </>
                        :
                        <>
                            <ContactP>None</ContactP>
                        </>
                    }
                </ContactInfoBox>}
                {['Hotel'].includes(single.singledata.role) &&
                    <>
                        <FormLabel for='photos'><FormStrong>Photos of the Hotel</FormStrong></FormLabel>
                        <DisplayContainer>
                            {single.singledata.photos.length > 0?
                                single.singledata.photos.map((item, index) => (
                                <ImgContainers src={item.photo} key={item.id} onClick={() => viewOpen(single.singledata.photos, index)} />
                                ))
                                :
                                <CenterContainer dir="column" al="center">
                                    <CenterImage src={moon} alt="no photos" />
                                    <CenterHeader>No Photos</CenterHeader>
                                </CenterContainer>
                            }
                        </DisplayContainer>
                    </>
                }
                {() => setUnavailable(single.singledata.property) &&
                <>
                {/* {personInfo.properties.available && personInfo.properties} */}
                <ContactHeader lf>Rented Out</ContactHeader>
                <PropertyCard data={() => setUnavailable(single.singledata.property)} gray={"grayscale(100%)"} spanwt={"100%"} size={1} />
                </>
                }
            <>
            {!["Landlord", "EventCenter", "Hotel"].includes(single.singledata.role)
                &&
                <ContactInfoBox>
                    <ContactAcross>
                        <ContactHeader>Reviews({single.singledata.reviews.length > 0? single.singledata.reviews.length: "none"}) {"  "}  </ContactHeader>
                        <ContactWrite>write a review</ContactWrite>
                    </ContactAcross>
                    { single.singledata.reviews.length > 0?
                        <>
                        {single.singledata.reviews.map((item, index) => (
                        <ContactP key={index}>
                            {item.date + " - "}{item.review}
                        </ContactP>
                        ))}
                        </>
                        :
                        <ContactP>No Reviews</ContactP>
                    }
                </ContactInfoBox>
                }
            </>
            </ContactContainer>
            <ContactForm name={roledata} address={single.singledata.address} phone={single.singledata.phone_no} url={single.singledata.website} date={single.singledata.created_at} email={single.singledata.email} role={single.singledata.role} />
        </ContactWrapper>
        </>
    }
    </>
  )
}

export default ContactPage;