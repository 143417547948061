import { FillBtn, Header, Label, Text, UserImage, UserWrapper, LoaderSecondary, LoaderPrimary } from "../style"
import adrs from '../../../assets/adrs.png'
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { update } from "../../../features/registerSlice/rdataSlice";
import { useNavigate } from "react-router-dom";
import { setAlert } from "../../../features/searchSlice/search";

const Adrs = () => {
    const [adrrs, setAdrrs] = useState(
        {  
            address: "",
        }
        )
        const navigate = useNavigate();
        const dispatch = useDispatch();
        const handleSubmit = (event) => {
            event.preventDefault()
            if(adrrs.address){
                dispatch(update(adrrs));
                navigate("/upload");
            } else {
                dispatch(setAlert({alert: true, message: "Please add your address"}));
            }
        }
        const handleChange =  (event) => {
            const target = event.target;
            setAdrrs({
                ...adrrs, [target.name]: target.value
            });
        }
        
    return(
       <>
          <LoaderPrimary >
            <LoaderSecondary loadwt="70%">
            </LoaderSecondary>
          </LoaderPrimary>
        <UserWrapper  set="profile" onSubmit={handleSubmit}>
            <UserImage src={adrs} alt="address" />
            <Header adrs="adrs">YOU'RE ALMOST DONE...</Header>
            <Label for="address" adrs="adrs" >Address</Label>
            <Text placeholder="Where do you live?" name="address" id="address" size="80%" adrs="adrs" className='address' onChange={handleChange} />
            <FillBtn>Next</FillBtn>
        </UserWrapper>
       </>
    )
}

export default Adrs;