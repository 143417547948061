import { styled, keyframes } from "styled-components"
import ClipLoader from "react-spinners/ClipLoader";




export const SearchWrapper = styled.div`
  display: flex;
  position: relative;
  background: rgb(238, 238, 238);
  width: 28%;
  border: none;
  border-radius: 11px;
  height: 30px;
  gap: 2%;
  padding-left: 1%;
  font-size: 10px;
  align-items: center;
  @media screen and (max-width: 900px){
    width: ${({ wt }) => wt ? "28%" : "38%"};
    /* margin-left: ${({ wt }) => wt ? "-22%" : ""}; */
 }
 @media screen and (max-width: 757px){
    width: ${({ wt }) => wt ? "28%" : "42%"};
 }

 @media screen and (max-width: 807px){
    display: ${({mb}) => mb? 'none': 'flex'};
    width: ${({ wt }) => wt ? "96%" : "52%"};
    min-height: 41px;
    border-radius: 21px;
    padding-left: 4%;
 }
 @media screen and (max-width: 555px){
    width: ${({ wt }) => wt ? "96%" : "60%"};
 }
 @media screen and (max-width: 500px){
  width: ${({ wt }) => wt ? "96%" : "85%"};
    margin-left: 0;
 }
 @media screen and (max-width:350px){
    /* width: 73%; */
    width: ${({ wt }) => wt ? "96%" : "90%"};
    /* padding-left: 2%;
    height: 26px;
    min-height: 26px; */
    /* margin-left: -3%; */
 }
`

export const SearchInput = styled.input.attrs({
  type: 'text'
})`
  background: rgba(238, 238, 238, 1);
  width: 90%;
  padding: 0;
  margin: 0;
  border: none;
  &:focus {
    outline: none;
  }
  /* @media screen and (max-width: 900px){
   padding-left: ${({ role }) => role ? "14%" : "8%"}; 
  } */
`
export const SearchIcon = styled.img`
  /* top: ${props => props.up ? "1.65rem" : ""};
  margin-left: ${props => props.lf ? props.lf : "2.4%"};
  margin-top: ${({ btm }) => btm ? "0.7rem" : ""}; */
  height : 13px;
  /* margin-top: 0.7rem;
  margin-left: 2.4%; */
  cursor: pointer;

  @media screen and (max-width: 709px){
    font-size: 1.8rem;
  }
  @media screen and (max-width: 612px) {
    font-size: 1.7rem;
  }
  @media screen and (max-width: 592px) {
    font-size: 1.6rem;
  }
  @media screen and (max-width: 540px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 524px) {
    font-size: 1.4rem;
  }
  @media screen and (max-width: 464px) {
    font-size: 1.3rem;
  }
  @media screen and (max-width: 425px) {
    font-size: 1.6rem;
  }
  @media screen and (max-width: 413px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 320px) {
    font-size: 1.2rem;
  }
`
export const Spinner = styled(ClipLoader)`
  // position: absolute;
  /* margin-top: 0.7rem; */
  // margin-right: 2.4%;
  /* @media screen and (max-width: 1024px){
    margin-top: 1rem;
  } */
  // @media screen and (max-width: 950px) {
  //   margin-right: ${({ role }) => role ? "2.1%" : "5.4%"};
  // }
  // @media screen and (max-width: 500px) {
  //   margin-right: ${({ role }) => role ? "5%" : "5.4%"};
  // }
  /* @media screen and (max-width: 400px) {
    size: 1.2rem;
  } */
`


const fade = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`

export const Notify = styled.span`
    position: absolute;
    right: 0;
    justify-self: end;
    align-self: flex-end;
    z-index: 1;
    color: red;
    display: block;
    background: white;
    width: fit-content;
    box-shadow: 1px 1px 2px grey;
    padding: 0.2rem;
    animation: ${fade} 5s ease;
`

export const DropDownColumn = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 2rem;
  padding: 3%;
  width: 86%;
  background: white;
  box-shadow: 0 1px 13px grey;
  border: none;
  border-radius: 7px;
  gap: 0.5rem;
  @media screen and (max-width: 709px){
    top: 3rem;
  }
`
export const DropDownTab = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid grey;
  cursor: pointer;
  &:hover{
    background: #CDCDCD;
  }
`
export const DropDownText = styled.p`
  text-align: center;
  color: black;
`