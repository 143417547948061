import React, { useEffect } from 'react'
import { ListColumn, ListDrag, ListPred, ListButton, ListHeader, ListFlexed, ListRow, ListStrong, ListWrapper, ListIcon, ImgCount, UButton, ListLabel, ListSelect, ListOption, ListRounded, ListInput, SpecCol } from '../style'
import galy from '../../../../assets/galy.png'
import doc from '../../../../assets/icons/docs.svg'
import { useNavigate } from 'react-router-dom'
import { useState } from "react"
import { ConfirmState } from "../../../../components/utility/drag_drop";
import { useDispatch, useSelector } from 'react-redux'
import { listAdd } from '../../../../features/registerSlice/rdataSlice'
import NumProgress from '../../../../components/progress'
import { fornext } from '../../../../features/pageSlice/page'
import { setAlert } from "../../../../features/searchSlice/search";
import { Back } from '../btn'
// import datas from  '../../../../data.json'


const ListSecond = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.signin.user);
    // const user = datas.users[0]
    const values = useSelector((state) => state.data.list);
    const tags = useSelector((state) => state.page.tagData);
    
    function uploadImg(val){
        dispatch(fornext({id: val, nextPage: "/list/2"}));
        navigate("/uppath");
    }


    const [img, setImg] = useState({
        uliving_room: values.uliving_room,
        ubed_room: values.ubed_room,
        utoilet: values.utoilet,
        ukitchen: values.ukitchen,
        tag_id: values.tag_id,
        title: values.title,
        room_no: values.room_no,
        room_price: values.room_price,
        property_price: values.property_price
    })

    useEffect(() => {
        if(values.edit && user.role !== 'Hotel'){
            navigate('/list/3');
        }
        if(img.tag_id !== 0){
            dispatch(listAdd({
                "tag_id": img.tag_id,
                "room_no": img.room_no,
                "title": `Room ${img.room_no}`,
                "room_price": img.room_price,
                "property_price": img.property_price}));
        }
    }, [img])

    const handleChange = (event) => {
        event.preventDefault();
        const target = event.target;
        setImg({
            ...img, [target.name]: target.value 
        });
    }

    function handleSelect(e){
        e.preventDefault();
        const tag = JSON.parse(e.target.value);
        setImg({
            ...img, tag_id: tag.id,
            room_price: tag.price,
            property_price: tag.price
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const key =  ["room_no", "title", "property_price", "tag_id"]
        if(["Hotel"].includes(user.role)){
            if(ConfirmState(key, img).valid){
                navigate('/list/4');
            } else { 
                dispatch(setAlert({alert: true, message: `${ConfirmState(key, img).key} is missing`}));
            }
        }else {
            navigate('/list/3');
        }
      }
  return (
    <>
    <ListWrapper wt={["Hotel"].includes(user.role)? '27%':'46%'}>
    {["Hotel"].includes(user.role)?  <ListHeader>Property's Photo's</ListHeader> : <ListHeader>Tell us about this property</ListHeader>}
        <NumProgress wt={'30%'} />
        {["Hotel"].includes(user.role)
            &&
            <>
            <ListRow className='hrow'>
            <SpecCol className='hcol'>
                <ListLabel>What type of room is this?</ListLabel>
                <ListSelect id="propn" name='tag_id' onChange={handleSelect} wt={["Hotel"].includes(user.role)? 'auto': 'max-content'} defaultValue={"E.g premium rooms"} >
                    {tags && tags.map((item, index) => (
                        <ListOption key={item.id} defaultValue={values.room_tag !== "" && item.title === values.room_tag.title? values.room_tag.title : ""} value={JSON.stringify(item)} >{item.title}</ListOption>
                    ))
                    }
                </ListSelect>
            </SpecCol>
            <ListRounded>{values.property_price > 0? values.property_price : 0}</ListRounded>
            </ListRow>
            <ListLabel>Room Number</ListLabel>
            <ListInput type='number' id="propn" name='room_no' onChange={handleChange} placeholder={values.room_no? values.room_no : "E.g (bungalow etc)"} />
            </>
        }
        <ListFlexed row={["Hotel", "Landlord"].includes(user.role)? false : true} dir={["Hotel", "Landlord"].includes(user.role)? 'column' : 'row'} >
            {
            ["Agent", "Landlord", "Developer"].includes(user.role) && !values.edit
            &&
            <ListColumn wt={["Landlord"].includes(user.role)? "100%":"47%"}>
                <ListRow><ListStrong sz="0.95rem">Detailed living room photo's</ListStrong><ListPred>*10 images max</ListPred></ListRow>
                <ListDrag  data-name="uliving_room" onClick={img.uliving_room.length > 0 ? () => navigate('/preview') : () => uploadImg(1)} >
                    <ListIcon src={img.uliving_room.length > 0? doc: galy} alt="drag and drop"/>
                    {img.uliving_room.length > 0 ?
                     <ImgCount> {img.uliving_room.length}</ImgCount>
                     : 
                     <UButton>Upload</UButton>}
                </ListDrag>
            </ListColumn>
            }
            
            {!values.edit 
            &&
            <>
            <ListColumn wt={["Hotel", "Landlord"].includes(user.role)? "100%":"47%"}>
                <ListRow><ListStrong sz={["Hotel"].includes(user.role)? "0.95rem":"0.9rem"}>{["Hotel"].includes(user.role)? "Detailed room photo's":"Detailed bedroom photo's"}</ListStrong><ListPred>*10 images max</ListPred></ListRow>
                <ListDrag  data-name="ubed_room"  onClick={img.ubed_room.length > 0 ? () => navigate('/preview') : () => uploadImg(2)} >
                <ListIcon src={img.ubed_room.length > 0? doc: galy} alt="drag and drop"/>
                {img.ubed_room.length > 0 ?
                <ImgCount> {img.ubed_room.length}</ImgCount>
                : 
                <UButton>Upload</UButton>}
                </ListDrag>
            </ListColumn>
            
            <ListColumn wt={["Hotel", "Landlord"].includes(user.role)? "100%":"47%"}>
                <ListRow><ListStrong>{["Hotel"].includes(user.role)? "Detailed bathroom photo's" : "Detailed toilet photo's"} </ListStrong><ListPred>*10 images max</ListPred></ListRow>
                <ListDrag  data-name="utoilet"  onClick={img.utoilet.length > 0 ? () => navigate('/preview') : () => uploadImg(3)} >
                    <ListIcon src={img.utoilet.length > 0? doc: galy} alt="drag and drop"/>
                    {img.utoilet.length > 0 ?
                        <ImgCount> {img.utoilet.length}</ImgCount>
                    : 
                        <UButton>Upload</UButton>}
                </ListDrag>
            </ListColumn>
            </>
            }
            {
            ["Agent", "Landlord", "Developer"].includes(user.role) && !values.edit
            &&
            <>
                <ListColumn wt={["Landlord"].includes(user.role)? "100%":"47%"}>
                    <ListRow><ListStrong>Detailed kitchen photo</ListStrong><ListPred>*10 images max</ListPred></ListRow>
                    <ListDrag  data-name="ukitchen"  onClick={img.ukitchen.length > 0 ? () => navigate('/preview') : () => uploadImg(4)} >
                        <ListIcon src={img.ukitchen.length > 0? doc: galy} alt="drag and drop"/>
                        {img.ukitchen.length > 0 ?
                        <ImgCount> {img.ukitchen.length}</ImgCount>
                        : 
                        <UButton>Upload</UButton>}
                    </ListDrag>
                </ListColumn>
                </>
        
            }
            </ListFlexed>
            <ListRow btn="65%"><Back path={'/list/1'} /> <ListButton var onClick={handleSubmit} >Next</ListButton></ListRow>
    </ListWrapper>
    </>
  )
}

export default ListSecond