import React from 'react'
import { ListButton, Spinner } from '../style';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { listClear } from '../../../../features/registerSlice/rdataSlice'


export const Back = ({path}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    function backBtn(e){
        e.preventDefault();
        if(path === "/"){
          dispatch(listClear());
        } 
        navigate(`${path}`);
    }
  return (
    <ListButton onClick={backBtn}>Back</ListButton>
  )
}

export const Next = ({func, text, loading}) => {
return (
  <ListButton onClick={func} var>
    {loading?
    <Spinner
    color={"white"}
    loading={true}
    size={25}
    aria-label="Loading Spinner"
    data-testid="loader"
    />
    :text}</ListButton>
)
}
