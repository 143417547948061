import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setAlert } from "../../../../features/searchSlice/search";
import { handleRequest, putHandle } from "../../../../components/utility/drag_drop";
import { ListButton, 
    ListInput,
    ListRow, 
    ListP, ListBoxWrapper, ListBox, ListConnector, ListSubHeader, SpecCol, 
    ListWrapper} from '../style'

const EditRoomTag = () => {
    const [data, setData] = useState({
        title: '',
        price: ''
    })
    let { pk } = useParams();
    const navigate = useNavigate();
    const user = useSelector((state) => state.signin.user);
    const dispatch = useDispatch();
    const config = {
        "Content-Type": "multipart/form-data",
        "Authorization": `JWT ${user.tokens.access}`
    }
    const editTag = async (e) => {
        e.preventDefault();
        if(data.title !== "" || data.price !==""){
          const tgdata = await putHandle({url: `${process.env.REACT_APP_BASE_APP_URL}/role/tag/?pk=${pk}`, data: {'title': data.title, 'price': data.price}, config: config });
          const response = handleRequest(tgdata, dispatch, config, user?.tokens.refresh);
        if(response === "success"){
            dispatch(setAlert({alert: true, message: "Edited successfully", color: '#90EE90'}));
            navigate("/list/tags");
          }
        }else {
          dispatch(setAlert({alert: true, message: "Fill out all fields"}));
        }
      }
    
      const handleChange = (e) => {
        e.preventDefault();
        const target = e.target;
        setData({
            ...data, [target.name]: target.value
          })
      }
  return (
    <ListWrapper wt='21%'>
        <ListSubHeader>Edit Created Room tags</ListSubHeader>
        <ListP>Kindly modify selected Tags</ListP>
        <ListRow btn="100%">
          <ListBoxWrapper>
            <ListBox>
              <ListP role='hotel' color='white'>0</ListP>
            </ListBox>
              <ListConnector></ListConnector>
            <ListBox>
              <ListP role='hotel' color='white'>NGN</ListP>
            </ListBox>
          </ListBoxWrapper>
          <SpecCol>
            <ListInput type="text" name="title" onChange={handleChange} id="propn" role='hotel' wt='15rem' placeholder={"E.g premium rooms"} />
            <ListInput type="number" name="price" onChange={handleChange} id="propn" role='hotel' wt='15rem' placeholder={"#8000 Price per night"} />
          </SpecCol>
        </ListRow>
        <ListButton var className='across' set onClick={editTag} >Save</ListButton>
    </ListWrapper>
  )
}

export default EditRoomTag