import { UserWrapper, Header, FillBtn, Password, MinorWrap, Icon, Warn, Spinner } from "../style";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import eye from "../../../assets/icons/eye.svg";
import eyes from "../../../assets/icons/eye-slash.svg";
import { viewPassword } from "../../../components/utility/drag_drop";
import Cookies from "js-cookie";
import { putData } from "../../../features/putSlice/put";
import { setAlert } from "../../../features/searchSlice/search";


const Reset = () => {
    let iddata = Cookies.get('reset');
    let new_id = iddata.replace(/\\054/g, ",").replace(/'/g, '"');
    const url = `${process.env.REACT_APP_BASE_APP_URL}/user/password_reset/${new_id}/`
    const config = { "Content-Type": "application/json" }
    const post = useSelector((state) => state.put);
    const[check, setCheck] = useState(false);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [loginInfo, setLoginInfo] = useState(
        {password: "", password2: ""}
        )
    const dispatch = useDispatch();
    // useEffect(()=> {
    //   console.log("previ:", prevPage);
    //   let data = Cookies.get('reset');
    //   console.log('reset:', data);
    //   let ndata = user.replace(/\\054/g, ",").replace(/'/g, '"');
    //   if(ndata === undefine){
    //     dispatch(setAlert({alert: true, message: "You need to sign in or sign up to access this page"}));
    //     navigate
    //   }
    // }, [])
    
    const handleSubmit = (event) => {
            event.preventDefault();
            dispatch(putData({url: url, data: loginInfo, config: config}));
    }

    useEffect(() => {
      if(post.error === "Request failed with status code 400"){ 
          setCheck(true);
      }else if(post.data.message === "password changed successfully"){
        dispatch(setAlert({alert: true, mesage: post.data, color: "#FF5733"}));
        navigate('/sign in');
      }
    }, [post.loading])

    const handleChange =  (event) => {
      setCheck(false);
        const target = event.target;
        setLoginInfo({
            ...loginInfo, [target.name]: target.value
        });
        }
    
    return(
        <UserWrapper onSubmit={handleSubmit}>
          <Header bt="14px" >Create New Password</Header>
          <MinorWrap>
            <Password placeholder="New password" warn={check} name="password" className="passin" onChange={handleChange} required />
            <Icon position="absolute" btm src={show?eyes:eye} alt={show?"hide password":"show password"} onClick={() => {viewPassword("passin"); show? setShow(false):setShow(true)}}/>
          </MinorWrap>
          {check && <Warn wr={"email"} sin warn={"weak"}>Password mismatch</Warn>}
          <MinorWrap>
            <Password placeholder="Confirm password" warn={check} name="password2" className="passin2" onChange={handleChange} required />
            <Icon position="absolute" btm src={show2?eyes:eye} alt={show2?"hide password":"show password"} onClick={() => {viewPassword("passin2"); show? setShow2(false):setShow2(true)}}/>
          </MinorWrap>
          <FillBtn>
            {post.loading?
            <Spinner
           color={"white"}
           loading={true}
           size={25}
           aria-label="Loading Spinner"
           data-testid="loader"
           />:"Reset password"}</FillBtn>
        </UserWrapper>
    )
}

export default Reset;
