import { UserWrapper, Header, GooLog, StrLink, FillBtn, Email, Password, SpecSpan, MinorWrap, Icon, Warn, Spinner } from "../style";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import eye from "../../../assets/icons/eye.svg";
import eyes from "../../../assets/icons/eye-slash.svg";
import { clearError, signinData } from "../../../features/signinSlice/signin";
import { googCheck } from "../../../features/getSlice/get";
import { viewPassword } from "../../../components/utility/drag_drop";
import { setAlert } from "../../../features/searchSlice/search";
import { errorClear } from "../../../features/postSlice/post";
import { setEmail } from "../../../features/pageSlice/page";



const SignIn = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/user/login/`
    const config = { "Content-Type": "application/json" }
    const [show, setShow] = useState(false)
    const prevPage = useSelector((state) => state.page.prevPage)
    const [loginInfo, setLoginInfo] = useState(
        {email: "", password: ""}
        )
    const onGoogleLoginSuccess = () => {
        const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
        const redirectUri = 'user/v1/auth/login/google/';
        const apiBaseURL = `${process.env.REACT_APP_BASE_APP_URL}`;
        // Scope indicates the info of the user needed from google, I have
        // only asked for the email, profile picture and full name
        const scope = [
          'https://www.googleapis.com/auth/userinfo.email',
          'https://www.googleapis.com/auth/userinfo.profile'
        ].join(' ');
      
        const params = {
          response_type: 'code',
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          redirect_uri: `${apiBaseURL}/${redirectUri}`,
          prompt: 'select_account',
          access_type: 'offline',
          scope
        };
      
        const urlParams = new URLSearchParams(params).toString();
        window.location = `${googleAuthUrl}?${urlParams}`;
      };
    const dispatch = useDispatch();
    const[check, setCheck] = useState(false);
    const user = useSelector((state) => state.signin);

    const log = () => {
      onGoogleLoginSuccess();
      dispatch(googCheck(true));
    }

    useEffect(()=> {
      dispatch(clearError());
      setCheck(false);
        if(['/contact', '/register role-i'].includes(prevPage) && !user.loggedIn){
          dispatch(setAlert({alert: true, message: "You need to sign in or sign up to access this page"}));
        }
    }, [])

    const error = useSelector((state) => state.signin.error);   
    const navigate = useNavigate();
    const handleSubmit = (event) => {
      event.preventDefault();
      dispatch(signinData({url: url, loginInfo: loginInfo, config: config}))
      dispatch(setEmail(loginInfo.email));
    }
    useEffect(() => {
      if(user.loggedIn){
          navigate("");
      }else if(user.status === 400){ 
        if(error === "This account is inactive. Please activate your account."){
          dispatch(setAlert({alert: true, message: error}));
          dispatch(errorClear());
          navigate('/created');
        }else if(error === "Email or password is incorrect."){
          setCheck(true);
        }else {
          dispatch(setAlert({alert: true, message: error}));
          dispatch(errorClear());
        }
      }else if(user.status === 500){
        dispatch(setAlert({alert: true, message: "Server connection error"}));
        dispatch(errorClear());
      }else if(user.error !== ""){
        dispatch(setAlert({alert: true, message: "Network Error"}));
        dispatch(errorClear());
      }
    }, [user.loading])
    const handleChange =  (event) => {
      setCheck(false)
        const target = event.target;
        setLoginInfo({
            ...loginInfo, [target.name]: target.value
        });
        }
    
    return(
        <UserWrapper onSubmit={handleSubmit}>
          <StrLink>
          <GooLog
            onClick={log}
            type="light"
            label="Sign in with Google"
            style={{border: '1px solid rgba(17, 115, 171, 1)', background: 'transparent', borderRadius: 4, alignSelf: 'center'}}
          />
          </StrLink>
          <Header bt="14px" sz="1.5rem">OR</Header>
          <Header bt="14px" sz="1.5rem">LOGIN TO BEGIN SELLING AND RENTING PROPERTIES</Header>
          <Email placeholder="Email" warn={check} name="email" onChange={handleChange} required />
          {check? <Warn wr={"email"} sin warn={"weak"}>Incorrect email or password</Warn> : <></>}
          <MinorWrap>
            <Password placeholder="Password" warn={check} name="password" className="passin" onChange={handleChange} required />
            <Icon position="absolute" btm='true' src={show?eyes:eye} alt={show?"hide password":"show password"} onClick={() => {viewPassword("passin"); show? setShow(false):setShow(true)}}/>
          </MinorWrap>
          <FillBtn>
            {user.loading?
            <Spinner
           color={"white"}
           loading={true}
           size={25}
           aria-label="Loading Spinner"
           data-testid="loader"
           />:"Sign in"}</FillBtn>
          <StrLink to="/confirm">Forgot password?</StrLink>
          <SpecSpan to="/sign up">Don't have an account? Sign up</SpecSpan>
        </UserWrapper>
    )
}

export default SignIn;