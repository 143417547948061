import React, { useEffect } from 'react'
import { EmptyCont, Headers, LoadingCont, Spinner } from './style'
import { useDispatch, useSelector } from 'react-redux'
import PropertyCard from '../../components/carousel/homecard'
import NotFound from '../../components/agent/notFound'
import { useParams } from 'react-router-dom'
import { searchData } from '../../features/searchSlice/search'
 
const SearchResult = () => {
    let { title } = useParams();
    const data = useSelector((state) => state.search)
    const page = useSelector((state) => state.page.prevPage)
    const urlB = `${process.env.REACT_APP_BASE_APP_URL}/property/search/?search=${title}`
    const urlR = `${process.env.REACT_APP_BASE_APP_URL}/property/search/?search=${title}&property_status=For rent`
    const urlS = `${process.env.REACT_APP_BASE_APP_URL}/property/search/?search=${title}&property_status=For sale`
    
    const dispatch = useDispatch();
    const config = {
        "Content-Type": "application/json",
    }
    
    useEffect(() => {
        if(page === '/buy'){
            dispatch(searchData({url: urlS, config: config}));
        }else if (page === '/rent'){
            dispatch(searchData({url: urlR, config: config}));
        }else {
            dispatch(searchData({url: urlB, config: config}));
        }
    }, [title])

  return (
    <EmptyCont dir="column" ht>
        <Headers>{`Search results for "${title}"` }</Headers>
        {data.loading?
            <LoadingCont>
                <Spinner
                    color={"rgba(17, 115, 171, 1)"}
                    loading={true}
                    size={50}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    />
            </LoadingCont>
            :
            <>
                {data.data.length > 0? 
                <PropertyCard data={data.data} size={3}/>
                :
                <NotFound wt={'32%'}/>
                }
            </>
        }
    </EmptyCont>
  )
}

export default SearchResult