import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';



const initialState = {

    loading: false,
    data: [],
    error: '',
    status: "",
    clicked: false,
    googleLog: false,
    extras: {
      name: '',
      role: '',
      page: '',
      address: ''
    },
    alert: false,
    alertMessage: "",
    alertColor: ""
}
      

export const searchData  = createAsyncThunk('data/searchData', (objdata) => {
    const {url, config} = objdata;
    return axios
            .get(url, {headers: config}
            )
            .then(response => response.data) 
        })
const searchSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
      click: (state, action) => {
          state.clicked = action.payload !== '' ? action.payload : true
      },
      setAlert: (state, action) => {
        state.alert = action.payload.alert
        state.alertMessage = action.payload.message
        state.alertColor = action.payload.color
      },
      extras: (state, action) => {
        state.extras.name = action.payload.name
        state.extras.role = action.payload.role
        state.extras.page = action.payload.page
        state.extras.address = action.payload.address
      },
      clear: (state, action) => {
        state.loading = false
        state.data = []
        state.error = ''
        state.status = ''
      },
      clearS: (state, action) => {
        state.loading = false
        state.data = []
        state.error = ''
        state.status = ''
      }
    },
    extraReducers: builder => {
          builder.addCase(searchData.pending, state => {
            state.loading = true
            state.error = ''
            state.status= ""
            state.data = []
          })
          builder.addCase(searchData.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
            state.error = ''
            state.status = 200
          })
          builder.addCase(searchData.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.error.message
            state.status = 400
          });
    },
});


export default searchSlice.reducer
export const { click, extras, clear, clearS, setAlert } = searchSlice.actions