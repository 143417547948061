import { Carousel } from "../../components";
import { Filler } from '../../components/utility'
import { Header } from '../../layout'
import slide3 from "../../assets/header3.png"
import fill from '../../assets/filler2.png'
import google from '../../assets/icons/googles.svg'
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getData, clear } from "../../features/getSlice/get";
import { Spinner, LoadingCont } from "./style";
import { clearS } from "../../features/searchSlice/search"
import { useFetch } from "../../hooks/useFetch";

const Rent = () => {
    const dispatch = useDispatch();
    const set = useSelector((state) => state.get);
    const url = `${process.env.REACT_APP_BASE_APP_URL}/property/search/?property_status=For rent`
    const config = {"Content-Type": "application/json"}
    const [loading, data, error] = useFetch(`${process.env.REACT_APP_BASE_APP_URL}/property/search/?state=${set.address}&property_status=For rent`)

    useEffect(()=>{
        dispatch(clearS());
        dispatch(clear());
        dispatch(getData({url: url, config: config}));
    },[])
    return(
        <>
         <Header link={slide3} text={'RENT A PROPERTY FROM ANYWHERE IN NIGERIA'} />
         {set.loading?
           <LoadingCont>
            <Spinner
           color={"rgba(17, 115, 171, 1)"}
           loading={true}
           size={50}
           aria-label="Loading Spinner"
           data-testid="loader"
           />
           </LoadingCont>
            :
         <Carousel 
               Htext="Rent a property"
               text='Rent Properties'
               txt='For rent'
               data={set.data}
               data2={data}
               action='Rent it'
               tag="Close to you"
         />
           }
            <Filler 
               head='Want to rent out your property?'
               ptext='Are you a property owner looking to maximize your rental income and minimize your hassles? Look no further! At Pinearth, we provide a seamless and efficient platform that connects property owners with potential tenants, revolutionizing the way rental properties are managed.'
               btntext ='Sign up to get started'
               location="/sign up"
               btntext2='Sign up with Google'
               imsrc={fill} 
               isrc={google}  
            /> 
        </>
    )
}


export default Rent