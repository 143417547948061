import styled from "styled-components";



export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: rgb(0, 0, 0, 0.5);
    align-items: center;
    position: fixed;
    width: 100vw;
    bottom: 0;
    height: 101vh;
    z-index: 1;
`
export const MainWrap = styled.section`
    width: 70%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 3%;
`
export const MainImg = styled.div`
    width: 67%;
    margin: auto;
    height: 36rem;
    background: url(${({src}) => src});
    background-size: cover;
    background-repeat: no-repeat;

`
export const Swipe = styled.button`
    background: transparent;
    position: absolute;
    border: none;
    width: 5%;
    right: ${({rt}) => rt};
    left: ${({lt}) => lt};
    align-self: center;
    cursor: pointer;
`

export const ThumbNailWrap = styled.section`
    display: flex;
    justify-content: space-evenly;
    width: 100%;

`

export const ThumbNail = styled.span`
    background: url(${({src}) => src});
    background-size: cover;
    height: 4rem;
    width: 6%;
    cursor: pointer;
    &.active {
        border: 1px solid white
    }
`

export const Close = styled.img`
    height: 40px;
    cursor: pointer;
    align-self: ${({cls}) => cls?"flex-end":""};
    padding-right: ${({cls}) => cls?"5%":""};
    padding-top: ${({cls}) => cls?"1rem":""};
    opacity: 0.4;
    &:hover{
        opacity: 1;
        /* box-shadow: 1px 1px 4px red; */
    }
`