import { PartnerWrapper, PartnerHead, PartnerContainer, PartnerList, PartnerLogo } from "./style";
import gaurd from "../../assets/partners/guard.svg"
import bd from "../../assets/partners/busday.svg"
import tcbl from "../../assets/partners/tcabal.svg"
import tc from "../../assets/partners/tcxx.svg"
import tp from "../../assets/partners/techpnt.svg"
import Tag from "../utility/tags";

const Partners = () => {
    const partners = [
        {
            id: 1,
            src: gaurd
        }, 
        {
            id: 2,
            src: bd
        }, 
        {
            id: 3,
            src: tcbl
        }, 
        {
            id: 4,
            src: tc
        }, 
        {
            id:5,
            src: tp
         }]

    return(
        <PartnerWrapper>
            <Tag text= "Featured on"/>
            <PartnerHead>Meet our partners</PartnerHead>
            <PartnerContainer>
                {partners.map((partner) => (
                    <PartnerList key={partner.id}>
                        <PartnerLogo src={partner.src} />
                    </PartnerList>
                ))}
            </PartnerContainer>
        </PartnerWrapper>
    )
}

export default Partners;