import { AgentWrapper, AgentList, BackButton } from "../style";
import { AgentCard } from "../found/agentCard";
import { Tag } from "../../utility";
import { ShortenStr } from "../../utility/drag_drop";
import NotFound from "../notFound";
import ArrowLeft from "../../../assets/icons/js-icons/arrow_left";
import { useDispatch } from "react-redux";
import { click } from "../../../features/searchSlice/search";


const SearchedAgents = ({data, text, role, extra}) => {
    const dispatch = useDispatch();
    return(
        <>
        {data.length > 0 ?
            <AgentWrapper pos={"relative"}>
                <BackButton onClick={() => dispatch(click(false))}>
                    <ArrowLeft color='rgba(17, 115, 171, 1)' />
                </BackButton>
                <Tag text={extra} />
                <AgentList variant="column">
                    {data && data.map((item, index) => (
                        <AgentCard key={index} person={["Agent", "Developer", "Landlord"].includes(item.role)? item.name || item.user.first_name + ' ' + item.user.last_name : item.company_name} address={ShortenStr(item.address, 20)} rating={item.ratings} profile={item.profile_photo} phone={item.phone_no} id={item.id} title={role} jads={false} />
                    ))}
                </AgentList>
            </AgentWrapper>
            :
            <>
                <NotFound text={extra} />
            </>
        }
        </>
    )
}

export default SearchedAgents;