import { AgentWrapper, AgentList, Slider } from "../style";
import { AgentCard } from "./agentCard";
import { Tag } from "../../utility";
import { Sliders } from "../../scroll_btn";
import { useRef } from "react";
import { ShortenStr } from "../../utility/drag_drop";
import NotFound from "../notFound";


const DisplayAgents = ({data, text, role, extra}) => {
    const boxRef = useRef(null);
    // let val
    // function screenSize(){
    //     var w = window.innerWidth;
    //     if(w <= 400){
    //         val = 15
    //     } else {
    //         val = 20;
    //     }
    // }
    // window.addEventListener('resize', () => {
    //     screenSize();
    // })
    return(
        <>
        {data.length > 0 ?
            <AgentWrapper pos={"relative"}>
                <>
                <Tag text="Featured" />
                <Slider>
                    <Sliders dir={boxRef} />
                    <AgentList variant="row" ref={boxRef}>
                        {data && data.map((item, index) => (
                            <AgentCard key={index} person={["Agent", "Developer", "Landlord"].includes(item.role)? ShortenStr(item.name, 15) || ShortenStr(item.user.first_name + ' ' + item.user.last_name, 15) : ShortenStr(item.company_name, 15)} rating={item.ratings} profile={item.profile_photo} phone={item.phone_no} id={item.id} title={role} text={text} address={item.address} jads={true} />
                        ))}
                    </AgentList>
                </Slider>
                </>
                <>
                    <Tag text={extra} />
                    <AgentList variant="column">
                        {data && data.map((item, index) => (
                            <AgentCard key={index} person={["Agent", "Developer", "Landlord"].includes(item.role)? ShortenStr(item.name, 15) || ShortenStr(item.user.first_name + ' ' + item.user.last_name, 15) : ShortenStr(item.company_name, 15)} address={ShortenStr(item.address, 15)} rating={item.ratings} profile={item.profile_photo} phone={item.phone_no} id={item.id} title={role} jads={false} />
                        ))}
                    </AgentList>
                </>
            </AgentWrapper>
            :
            <>
                <NotFound text={extra} />
            </>
        }
        </>
    )
}

export default DisplayAgents