import llord from '../assets/roles/llord.svg'
import agentR from '../assets/roles/agentR.svg'
import deve from '../assets/roles/dev.svg'
import hot from '../assets/roles/hotel.svg'
import ban from '../assets/roles/bank.svg'
import shortlet from '../assets/roles/shortlet.svg'
import center from '../assets/roles/center.svg'

export const WriteUps = {
    landlord:{
        heading: "Become a registered Landlord",
        text: "Join our community of successful property owners and experience seamless management, verified tenant connections, and expert resources tailored just for you. Let's build your property success story together. Register now and embark on a journey to elevated real estate excellence!",
        img: llord
    },
    agent: {
        heading: "Agent listing",
        text: "Welcome to  Pinearth ! Elevate your real estate career by becoming a registered agent. Unleash the power of our platform to connect with clients, showcase your listings, and access valuable resources. Join our community of top-notch agents committed to success. Your journey to real estate excellence begins with a simple registration. Sign up now and let your achievements soar!",
        img: agentR
    },
    dev: {
        heading: "Are you a real estate Developer?",
        text: "Embark on a groundbreaking journey with pinearth ! As a real estate developer, your vision shapes landscapes. Register now to unlock exclusive opportunities, connect with strategic partners, and showcase your projects to a global audience. Join our league of visionary developers who redefine the future of real estate. Your innovation starts here. Register as a Real Estate Developer and pave the way for unparalleled success!",
        img: deve
    },
    hotel: {
        heading: "Pride your hospitality!",
        text: "Where luxury meets opportunity! Register your hotel and showcase it’s unique charm to a global audience. Amplify your bookings, reach discerning travelers, and boost your brand visibility. Join our exclusive network of hospitality partners and unlock the full potential of your property. Elevate the guest experience – register your hotel now and let the world experience the extraordinary with you!",
        img: hot
    },
    bank: {
        heading: "Empower Dreams, Fuel Growth!",
        text: "Partner with pinearth as a registered bank specializing in mortgage loans and funding opportunities. Join forces with a dynamic real estate community, connecting with developers, aspiring homeowners, and savvy investors. Your institution plays a pivotal role in shaping real estate success stories. Register now to be a key player in building futures, one mortgage at a time. Let's create lasting legacies together!",
        img: ban
    },
    short: {
        heading: "Unleash the power of short term rentals!",
        text: "Unleash the power of short term rentals! Register your property on pinearth and showcase it to a world of travelers seeking unique, short-term stays. Amplify your bookings, connect with a diverse audience, and maximize your property's potential. Join our community of shortlet providers and redefine the way the world experiences hospitality. Your next guest is just a registration away. Sign up now and let your space tell it’s  own extraordinary story",
        img: shortlet
    },
    event_center: {
        heading: "Unlock Limitless Event Possibilities",
        text: "Partner with Pinearth, your premier destination for exceptional event spaces and unparalleled service. Specializing in providing top-tier event venues, we invite you to join forces with a thriving events community, connecting with event planners, businesses, and individuals seeking memorable and seamless experiences. Your institution will play a crucial role in shaping unforgettable moments and successful events.",
        img: center
    }

}