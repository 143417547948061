import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';



const initialState = {

    loading: false,
    data: [],
    error: '',
    clicked: false,
    googleLog: false,
    address: "",
    extras: {
      name: '',
      role: '',
      page: '',
      address: ''
    },
    data3: [],
    data4: [],
    data5: [],
    data6: []
}
      

export const getData  = createAsyncThunk('data/getData', (objdata) => {
    const {url, config} = objdata;
    return axios
            .get(url, {headers: config}
            )
            .then(response => response.data) 
        })
const getSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
      click: (state, action) => {
          state.clicked = true
      },
      locate: (state, action) => {
        state.address = action.payload
    },
      googCheck : (state, action) => {
        state.googleLog = action.payload
      },
      extras: (state, action) => {
        state.extras.name = action.payload.name
        state.extras.role = action.payload.role
        state.extras.page = action.payload.page
        state.extras.address = action.payload.address
      },
      clear: (state, action) => {
        state.loading = false
        state.data = []
        state.error = ''
      },
      updateData: (state, action) => {
        state.data3 = action.payload.data3
        state.data4 = action.payload.data4
        state.data5 = action.payload.data5
        state.data6 = action.payload.data6
      }
    },
    extraReducers: builder => {
          builder.addCase(getData.pending, state => {
            state.loading = true
            state.data = []
            state.error = ''
          })
          builder.addCase(getData.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
            state.error = ''
            state.loggedIn = true
            state.clicked = false
          })
          builder.addCase(getData.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.error.message
          });
    },
});


export default getSlice.reducer
export const { click, extras, googCheck, clear, locate, updateData } = getSlice.actions