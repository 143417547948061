import { ProgressBar, Banners, ProgressBorder, FormHeader, VariantFormWrapper, FormButton, SubHeader, TextF } from "../style";
import { WriteUps } from "../../../data/roles";
import { useNavigate, useParams } from "react-router-dom";


const Desc = () => {
    let { role } = useParams();
    const navigate = useNavigate();
    let heading;
    let writeUp;
    let image;
    switch (role){
        case 'a Landlord':
                heading = WriteUps.landlord.heading
                writeUp = WriteUps.landlord.text
                image = WriteUps.landlord.img
            break;
        case 'an Agent':
                heading = WriteUps.agent.heading
                writeUp = WriteUps.agent.text
                image = WriteUps.agent.img
            break;
        case 'a Developer':
                heading = WriteUps.dev.heading
                writeUp = WriteUps.dev.text
                image = WriteUps.dev.img
            break;
        case 'a Hotel':
                heading = WriteUps.hotel.heading
                writeUp = WriteUps.hotel.text
                image = WriteUps.hotel.img
            break;
        case 'a Bank':
                heading = WriteUps.bank.heading
                writeUp = WriteUps.bank.text
                image = WriteUps.bank.img
            break;
        case 'a Short-let':
                heading = WriteUps.short.heading
                writeUp = WriteUps.short.text
                image = WriteUps.short.img
            break;
        case 'an Event center':
                heading = WriteUps.event_center.heading
                writeUp = WriteUps.event_center.text
                image = WriteUps.event_center.img
            break;
        default:
            console.error(`Unexpected role: ${role}`);
    }

  return (
    <VariantFormWrapper>
        <FormHeader>Register as {role}</FormHeader>
        <ProgressBorder>
            <ProgressBar wt="20%"></ProgressBar>
        </ProgressBorder>
        <SubHeader>{heading}</SubHeader>
        <TextF>{writeUp}</TextF>
        {image && <Banners src={ image } alt="role" />}
        <FormButton er wt="90%"  onClick={() => navigate(`/register/${role}/i`)}>Register Now</FormButton>
    </VariantFormWrapper>
  )
}

export default Desc;