import { UserContainer, Header, UserP, LoaderSecondary, LoaderPrimary, RedText } from "../../style";
import { Outlet, useLocation} from 'react-router-dom';


const Upload = () => {
    const location = useLocation();
    const page = location.pathname;
    // useEffect(() => {
    //   if(page.prevPage !== "/address" || page.prevPage !== "/uppath" || page.prevPage !== "/preview"){
    //     navigate("/sign up");
    //   }
    // }, [])
    return(
      <>
        <LoaderPrimary >
          <LoaderSecondary loadwt="90%">
          </LoaderSecondary>
        </LoaderPrimary>
        <UserContainer>
          <Header adrs="adrs" sz="1.5rem">UPLOAD YOUR ID</Header>
          <UserP sz="1.5rem">We need to verify your identity to be sure you are who you say you are
          {page === '/upload/id' && <RedText>*png, *jpg, only</RedText>}
          </UserP>
          <Outlet />
        </UserContainer>
      </>
    )
}

export default Upload;