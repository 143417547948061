import { ListBar } from './style'

// const NumProgress = ({fs, sn, td, ft, ff, st}) => {
//     // const points = document.querySelectorAll('.point')
//     // function upDate () {
//     //     for(var i=0; i < value; i++){
//     //         // if(i < value){
//     //         points[i].style.background = "rgba(17, 115, 171, 1)"
//     //         points[i].style.color = "white"
//     // }
//     // }
//     return (
//         <ListRow>
//             <ListPoint to="/list/1" color={fs} >1</ListPoint>
//             <ListPoint to="/list/2" color={sn} >2</ListPoint>
//             <ListPoint to="/list/3" color={td} >3</ListPoint>
//             <ListPoint to="/list/4" color={ft} >4</ListPoint>
//             <ListPoint to="/list/5" color={ff} >5</ListPoint>
//             <ListPoint to="/list/6" color={st} >6</ListPoint>
//         </ListRow>
//     )
// }

// export default NumProgress

const NumProgress = ({wt}) => {
    // const points = document.querySelectorAll('.point')
    // function upDate () {
    //     for(var i=0; i < value; i++){
    //         // if(i < value){
    //         points[i].style.background = "rgba(17, 115, 171, 1)"
    //         points[i].style.color = "white"
    // }
    // }
    return (
        <ListBar>
            <ListBar wt={wt} filler={true}></ListBar>
        </ListBar>
    )
}

export default NumProgress


