import { useEffect, useRef, useState } from 'react';
import Display from '../../../components/propBlocks';
import { useDispatch, useSelector } from 'react-redux'
import ContactForm from '../../../components/contact';
import { reload, singleData } from "../../../features/singleSlice/single";
import { Wrapper, LoadingCont, Spinner } from './style';
import { decode, replaceStr } from "../../../components/utility/drag_drop";
import { useParams } from 'react-router-dom';
// import datas from "../../../data.json"


const ViewProperty = () => {
  const state = useSelector((state) => state.single);
  const data = useSelector((state) => state.single.singledata);
  const { id } = useParams();
  // const id = searchParams.get('q');
  const url = `${process.env.REACT_APP_BASE_APP_URL}/property/view/${decode(id)}/`;
  const config = { "Content-Type": "application/json"};
  const dispatch = useDispatch();
  const [newData, setNewData] = useState({
    house_view: [],
    living_room: [],
    bed_room: [],
    toilet: [],
    kitchen: [],
    documents: [],
    house_plan: [],
    size: [],
    land_image: [],
    video: [],
    main: []
  });
  const roledata = useRef(null);
  const phone = useRef(null);

  useEffect(() => {
    dispatch(singleData({ url: url, config: config }));
    if(state.reload){
      dispatch(reload(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.reload])
  function MassReplaceStr(other=[]) {
    console.log("arr:", other)
    other.map(item => setNewData((prevArray) => (
      {
        ...prevArray, [Object.keys(item)]: [...prevArray[Object.keys(item)], replaceStr(Object.values(item))],
        main: [...prevArray.main, replaceStr(Object.values(item))]
      }
    )))
  }
  
  useEffect(() => {
    function checkForData(){
      if(data && JSON.stringify(data) !== '{}'){
        switch (data.owner.role){
          case 'Landlord':
              roledata.current = data.owner.first_name + " " + data.owner.last_name
              phone.current = data.phone_no
              break;
          case 'Agent':
              roledata.current = data.agent.company_name
              phone.current = data.phone_no
              break;
          case 'Developer':
              roledata.current = data.developer.company_name
              phone.current = data.phone_no
              break;
          case 'Hotel':
              roledata.current = data.hotel.name
              phone.current = data.phone_no
              break;
          case 'Bank':
              roledata.current = data.bank.name
              phone.current = data.phone_no
              break;
          case 'Short-Let':
              roledata.current = data.owner.first_name + " " + data.owner.last_name
              phone.current = data.phone_no
              break;
          case 'Event center':
              roledata.current = data.event_center.company_name
              phone.current = data.phone_no
              break;
          default:
            roledata.current  = 'loading'
        }
        MassReplaceStr([...data.house_view, ...data.living_room, ...data.bed_room, ...data.toilet, ...data.kitchen, ...data.house_plan, ...data.documents, ...data.size, ...data.land_image, ...data.property_video]);
        console.log("data:", newData)
      }
    }
    checkForData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  // useEffect(() =>  {
  //   if(state.error !== "" && !state.loading){
  //     navigate(-1)
  //   }

  // }, [state.loading])
  return (
    <>
      {state.loading  || JSON.stringify(data) === '{}'?
        <LoadingCont>
          <Spinner
            color={"rgba(17, 115, 171, 1)"}
            loading={true}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </LoadingCont>
       :
        <Wrapper>
          <Display data={data} main={newData} phone={phone.current} />
          <ContactForm name={roledata.current}  email={data.owner?.email || "loading@pinearth.com"} todo={"Schedule a visit"} role={data.owner?.role || "loading role"} wt="25%" />
          {/* <p>{data.owner.role} {roledata.current} {newData}</p> */}
        </Wrapper>
      }
    </>
  )
}

export default ViewProperty