import styled from 'styled-components'
import ClipLoader from "react-spinners/ClipLoader";
import { NavLink as Link } from 'react-router-dom';


export const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: ${({wt}) => wt? wt:'31%'};
    margin: auto;
    gap: 2rem;
    margin-top: 3rem;
    position: ${({pos}) => pos};
    flex-wrap: wrap;
    align-content: center;
    @media screen and (max-width: 812px){
        width: ${({wt}) => wt === '46%'? '54%':'37%'};
    }
    @media screen and (max-width: 748px){
        width: ${({wt}) => wt === '46%'? '67%':'37%'};
    }
    @media screen and (max-width: 630px){
        width: ${({wt}) => wt === '46%'? '67%':'45%'};
    }
    @media screen and (max-width: 600px){
        width: 69%;
    }
    @media screen and (max-width: 430px){
        width: 88%;
    }
    /* @media screen and (max-width: 1054px){
        width: ${({wt}) => wt === '21%'? '23%':'28%'};
    }
    @media screen and (max-width: 1022px){
        width: ${({wt}) => wt === '21%'? '18%':'28%'};
    }
    @media screen and (max-width: 938px){
        width: ${({wt}) => wt === '21%'? '20%':'28%'};
    }
    @media screen and (max-width: 830px){
        width: ${({wt}) => wt === '21%'? '22%':'28%'};
    }
    @media screen and (max-width: 800px){
        width: ${({wt}) => wt === '21%'? '23%':'36%'};
    }
    @media screen and (max-width: 737px){
        width: ${({wt}) => wt === '21%'? '25%':'36%'};
    }
    @media screen and (max-width: 671px){
        width: ${({wt}) => wt === '21%'? '27%':'36%'};
    }
    @media screen and (max-width: 627px){
        width: ${({wt}) => wt === '21%'? '30%':'36%'};
    }
    @media screen and (max-width: 600px){
        width: ${({wt}) => wt === '21%'? '32%':'49%'};
    }
    @media screen and (max-width: 520px){
        width: ${({wt}) => wt === '21%'? '36%':'49%'};
    }
    @media screen and (max-width: 500px){
        width: ${({wt}) => wt === '21%'? '40%':'60%'};
    }
    @media screen and (max-width: 424px){
        width: ${({wt}) => wt === '21%'? '46%':'60%'};
    }
    @media screen and (max-width: 400px){
        width: ${({wt}) => wt === '21%'? '48%':'76%'};
    }
    @media screen and (max-width: 348px){
        width: ${({wt}) => wt === '21%'? '52%':'76%'};
    } */
`
export const ListFlexed = styled(ListWrapper)`
    flex-direction: ${({dir}) => dir? dir:'row'};
    flex-wrap: wrap;
    /* margin-left: ${({row}) => row? '-17%':''}; */
    width: ${({row}) => row? '100%':'100%'};
`
export const ListLink = styled(Link)`
    text-decoration: none;
    color: rgba(17, 115, 171, 1);
    text-align: center;
`
export const ListBox = styled.div`
    background: rgba(17, 115, 171, 1);
    color: white;
    height: 2.67rem;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    width: 2.5rem;
    padding: 0 8%;
    justify-content: center;
`
export const ListRounded = styled.div`
    background: rgba(17, 115, 171, 1);
    color: white;
    height: 2.67rem;
    border-radius: 18px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    width: 6rem;
    justify-content: center;
    align-self: center;
`

export const SpecCol = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    margin: auto;
    width: ${props => props.width};
    &.hcol{
        gap: ${({gp}) => gp? gp : '1rem'};
        margin: 0;
    }
`

export const ListConnector = styled.div`
    background: rgba(17, 115, 171, 1);
    color: white;
    height: 1rem;
    width: 0.3rem;
    margin: 0 auto; 
`
export const ListBoxWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const Spinner = styled(ClipLoader)`
    align-self: center;
    justify-self: center;
`

export const ListContainer = styled.section`
    display: flex;
    flex-wrap: wrap;
`
export const ListColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: ${({wt}) => wt};
    margin: ${({none}) => none? '0' : 'auto'};
    @media screen and (max-width: 600px){
        width: 85%;
    }
`

export const ListInput = styled.input`
    background: ${({role}) => role === 'hotel'? 'none': 'rgba(238, 238, 238, 1)'};
    border: ${({role}) => role === 'hotel'? '1px solid #1173AB' : 'none'};
    border-radius: 6px;
    height: ${({role}) => role === 'hotel'? '2.5rem':'3rem'};
    padding-left: 3%;
    width: ${({wt}) => wt};
    &:focus {
    border: 1px solid #3498db;
    outline: none; /* Remove default focus outline */
    /* Add any other styles you want when the input is focused */
  }
`
export const ListSelect = styled.select`
    width: ${({wt}) => wt? wt :'max-content'};
    margin-top: ${({bts}) => bts? '8px':''};
    height: ${({min}) => min? '3.1rem':'2.7rem'};
    border: none;
    padding: ${({pd}) => pd? '0 4%':''};
    border-radius: ${({min}) => min? '4px' :'9px'};
    background: rgba(217, 217, 217, 1);
`

export const ListOption = styled.option``

export const ListHeader = styled.h2`
    font-size: 1.6rem;
    text-align: center;
`
export const ListSubHeader = styled.p`
    font-size: 1.4rem;
    font-weight: 600;
    text-align: center;
`

export const ListLabel = styled.label`
    font-weight: ${({light}) => light? '':'700'};
    text-align: left;
    width: ${({light}) => light?'69%':''};
`

export const ListStrong = styled.strong`
    font-size: ${({sz}) => sz};
`

export const ListP = styled.p`
    text-align: ${({lt}) => lt? 'left':'center'};
    color: ${({color}) => color? color:'black'};
    font-size: ${({role}) => role === 'hotel'? '0.7rem':'1rem'};
`

export const ListPred = styled(ListP)`
    font-size: 10px;
    color: red;
`

export const ListPoint = styled.span`
    width: 10%;
    text-align: center;
    font-weight: 700;
    height: fit-content;
    border-radius: 6px;
    background: ${({color}) => color? "rgba(17, 115, 171, 1)":"rgba(230, 241, 247, 1)"};
    color: ${({color}) => color? "white":"black"};
`
export const ListRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: ${({btn}) => btn? btn: ''};
    margin: ${({btn}) => btn? 'auto': ''};
    &.hrow {
        flex-wrap: wrap;
        align-content: center;
    }
`
export const ListFilter = styled.div`
    display: flex;
    height: 5rem;
    gap: 2%;
    width: 75%;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 4px;
`

export const ListButton = styled.button`
    height: 45px;
    width: ${({set}) => set? "36%":"26%"};
    border: none;
    color: white;
    background: ${props => props.var?"rgba(17, 115, 171, 1)":"rgba(0, 0, 0, 0.5)"};
    border-radius: 3px;
    align-self: ${({set}) => set? "center": ""};
    cursor: pointer;
    &.across {
        width: 65%;
        /* margin-bottom: 9rem; */
    }
    &:hover{
        background: ${props => props.var?"rgb(9 99 151)" : "rgb(37 36 36 / 50%)"};
    }
`

export const ListDrag = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 150px;
    background: rgba(238, 238, 238, 1);
    justify-content: space-evenly;
    border-radius: 5px;
    cursor: pointer;
    @media screen and (max-width: 600px){
     height: 200px;   
    }
    @media screen and (max-width: 342px){
     height: 169px;   
    }
`

export const ListIcon = styled.img`
    height: 40px;
`
export const ImgCount = styled.span``
export const ListImg = styled.img`
    height: 25rem;
`
export const ListForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 70%;
    margin: auto;
    @media screen and (max-width: 500px){
     width: 100%;
    }
`

export const Up = styled.input.attrs({
    type: "file"
})`
   width: 30%;
   border: 1px solid black;
   border-radius: 2px;
   display: none;
`

export const UButton = styled.button`
    width: 24%;
    background: transparent;
    border: 1px solid grey;
    border-radius: 7px;
`

export const Find = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 1%;
    background: rgba(238, 238, 238, 1);
    align-items: center;
    border-radius: 5px;
    width: ${props => props.wt};
`

export const FindWrapper = styled.div`
    display: flex;
    position: relative;
`

export const MinorIcon = styled.img`
    height: 1.8rem;
    width: 1.8rem;
    margin: ${({mg}) => mg? 'auto': ''};
    padding-left: ${({pl}) => pl? '3.5%': ''};
    border-radius: ${({av}) => av? '999px': ''};
`

export const MinorButton = styled.button`
    width: ${({wt}) => wt};
    height: 2.2rem;
    color: white;
    border: none;
    display: flex;
    background: ${({red}) => red? "rgba(222, 24, 24, 1)" : "rgba(17, 115, 171, 1)"};
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

export const DropDown = styled.div`
    display: flex;
    width: 100%;
    height: 3.5rem;
    background: white;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
`
export const DropDownColumn = styled(DropDown)`
    border-radius: 8px;
    flex-direction: column;
    padding: 2.5%;
    height: ${props => props.ht || '235px'};
    box-shadow: 0px 3px 13px rgba(0, 0, 0, 0.25);
    width: 100%;
    gap: 1rem;
    overflow-x: auto;
    position: absolute;
    z-index: 2;
    top: 4rem;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 2px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }
    &::-webkit-scrollbar-thumb {
        background: #888; 
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #555;       
    }
    @media screen and (max-width: 1233px){
        bottom: -16rem;   
    }
    @media screen and (max-width: 1024px){
        bottom: -23rem;   
    }
`

export const ExtraText = styled.p`
    text-align: center;
    margin: auto;
`
export const ExtraSpan = styled.span`
    display: flex;
    justify-content: space-between;
`
export const AppsWrapper = styled.div`
    display: flex;
    gap: 1%;
`
export const AppsContainer = styled.div`
    width: max-content;
    display: flex;
    flex-wrap: wrap;
    padding: 3%;
    align-content: center;
    height: 1rem;
    padding: 2%;
    border-radius: 14px;
    background: #1173AB;
    color: white;
`
export const AppsIcon = styled.img`
    height: 0.9rem;
    align-self: center;
    margin-right: 5px;
    cursor: pointer;
`