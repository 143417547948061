import { FilterContainer, FilterSelect, FilterOption, Button, SearchIcon, FilterInput, Spinner } from "./style";
import glass from '../../assets/icons/searchwhite.svg';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { house_types } from "../../data/type";
import { states } from "../../data/area";
// import { GeoapifyGeocoderAutocomplete, GeoapifyContext } from '@geoapify/react-geocoder-autocomplete';
// import './minimal.css';
// import './round-borders.css';
import ico from '../../assets/icons/arrow-down.svg'




const Filter = () => {
    const[filter, setFilter] = useState({
        state: '',
        status: '',
        type: '',
        price: ''
    })
    const [searchParams, setSearchParams] = useSearchParams();
    const[isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const page = useSelector((state) => state.page.currentPage);
    const handleChange = (e) =>{
        const target = e.target
        setFilter({...filter, [target.name]: target.value});
    }

    // const key = `${process.env.REACT_APP_GEOPIFY}`

    // function onPlaceSelect(value) {
    //     console.log("this:", value);
    //     setFilter({...filter, area: value});
    //   }
     
    // function userInput(value) {
    //     console.log(value);
    //     setFilter({...filter, area: value});
    // }

    // function onSuggestionChange(value) {
    //     console.log(value);
    // }
    const data = useSelector((state) => state.search);
    // const url = new URL(`https://pinearth.com/filter?`)
    // const searchParams = new URLSearchParams(url.search);

    let url = '/filter?'
    const handleSubmit = () => {
        let numParams;
        numParams = 0
        const obj = {}
        for ( const [key, value] of Object.entries(filter)){
            if(value !== '' && numParams === 0){
                obj[key] = value
                url = url + `${key}=${value}`
                numParams++
            }else if(value !== '' && numParams >= 1){
                obj[key] = value
                url = url + `&${key}=${value}`
                numParams++
            }
        }
       page !== '/filter'? 
        navigate(url)
        :setSearchParams(obj)
       setIsLoading(true);
    }

    useEffect(() => {
        if(!data.loading){
            setIsLoading(false);
        }
    }, [data.loading])
    
    return(
        <FilterContainer>
            <FilterSelect name="state" url={ico} onChange={handleChange} defaultValue={"Choose state"}>
                <FilterOption disabled value={"Choose state"} >
                    Choose state
                </FilterOption>
                {states.map((state, index) => (
                    <FilterOption key={index} value={state} >{state}</FilterOption>
                ))}
            </FilterSelect>
             {/* <GeoapifyContext apiKey={key}>
                <GeoapifyGeocoderAutocomplete
                    placeholder="Area"
                    type={'city'}
                    lang={'en'}
                    countryCodes={'ng'}
                    limit={10}
                    // // filterByCountryCode={'ng'}
                    // biasByCountryCode={'ng',}
                    allowNonVerifiedHouseNumber={true}
                    allowNonVerifiedStreet={true}
                    placeSelect={onPlaceSelect}
                    onUserInput={userInput}
                    suggestionsChange={onSuggestionChange}
                />
            </GeoapifyContext> */}
            <FilterSelect name="status" url={ico} onChange={handleChange} defaultValue={"Property status"}>
                <FilterOption disabled value={"Property status"} >
                   Property status    
                </FilterOption>
                <FilterOption  value={"For rent"} >
                    For Rent
                </FilterOption>
                <FilterOption value={"For sale"} >
                    For Sale
                </FilterOption>
                <FilterOption value={"Tools"} >
                    Tools
                </FilterOption>
                <FilterOption value={"Land"} >
                    Land
                </FilterOption>
                <FilterOption value={"Short-let"} >
                    Short-let
                </FilterOption>
            </FilterSelect>
            <FilterSelect name="type" url={ico} onChange={handleChange} defaultValue={"Property type"}> 
                <FilterOption disabled value={"Property type"} >
                  Property type    
                </FilterOption>
                {house_types.map((house_type, index) => (
                    <FilterOption key={index} value={house_type} >{house_type}</FilterOption>
                ))}
            </FilterSelect>
            <FilterInput name="price" placeholder="Property price"  onChange={handleChange} />
            <Button onClick={handleSubmit}>
                {!isLoading?
                <SearchIcon src={glass} alt="search" />
                :
                <Spinner
                color={"white"}
                loading={true}
                size={16}
                aria-label="Loading Spinner"
                data-testid="loader"
                />}
                Find now
           </Button>
        </FilterContainer>
    )
}

export default Filter;