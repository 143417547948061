import { FooterContainer, ListContainer, List, Icons, FooterButton, FootP, FooterSearch, FlipSide, FooterPara, FooterFloat, Header, Flip, Logo, FooterFiller, FooterWrap, FooterP, ListLink, FooterLetter, FooterFloatButton } from "./style";
import right from '../../assets/icons/right.svg'
import logo from '../../assets/darklogo.png'
import arrow from '../../assets/icons/arrow.svg'
import filler from '../../assets/filler333.png'
import locat from '../../assets/icons/locat.svg'
import call from '../../assets/icons/call.svg'
import mail from '../../assets/icons/email.svg'
import insta from '../../assets/icons/insta.svg'
import sapp from '../../assets/icons/sapp.svg'
import  x from '../../assets/icons/twitterx.svg'
import { useSelector } from 'react-redux'



const Footer = () => {
    const user = useSelector((state) => state.signin);
    function topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }

    return (
       <>
        <FooterFloat>
            <FlipSide size="text">
                <Header>Looking for a dream home?</Header>
                <FooterPara>We can help you realize your dream of a new home</FooterPara>
            </FlipSide>
            <FooterFloatButton>
                <Flip to="/">
                    <FootP>
                        Explore properties 
                    </FootP>
                    <Icons src={right} />
                </Flip>
            </FooterFloatButton>
        </FooterFloat>
        <FooterWrap id="footer">
            <FooterContainer>
                <ListContainer wit="md">
                    <List><Logo src={logo} /></List>
                    <List>We are a digital real estate marketing 
                        platform and the first of it's kind in Nigeria</List>
                    <List gap='true' ><Icons rt='true' src={locat} alt="location"/>Nigeria</List>
                    <List gap='true' ><Icons rt='true' src={call} alt="phone_no" /> +234 706 204 9556 </List>
                    <List gap='true' ><Icons rt='true' src={mail} alt="email" /> pinearth.info@gmail.com</List>
                    <List gap='true' >
                        <ListLink to='https://www.instagram.com/_pinearth?igsh=MXE1Z3cxampxOXF3dQ=='>
                            <Icons src={insta} alt="instagram" />
                        </ListLink>
                        <ListLink to='https://wa.me/message/57675SHZFE2MN1'>
                            <Icons src={sapp} alt="whatsapp" />
                        </ListLink>  
                        <ListLink to='https://x.com/_pinearth?s=21'>
                            <Icons src={x} alt="X" />
                        </ListLink>
                        </List>
                </ListContainer>
                <ListContainer pd='true'>
                    <List><Header>Company</Header></List>
                    <List>About</List>
                    <List onClick={topFunction}>Service</List>
                    <List>Portfolio</List>
                    <List>FAQ</List>
                    {user.loggedIn && <ListLink to='/profile/help' onClick={topFunction}>Contact us</ListLink>}
                </ListContainer>
                <ListContainer pd='true'>
                    <List><Header>Services</Header></List>
                    <ListLink to="/rent" onClick={topFunction}>Houses</ListLink>
                    <ListLink to="/find/hotel" onClick={topFunction}>Hotels</ListLink>
                    <ListLink>News</ListLink>
                    {user.loggedIn && <ListLink to="/profile" onClick={topFunction}>My Account</ListLink>}
                    {user.loggedIn && <ListLink to="/profile/saved" onClick={topFunction}>Wish List</ListLink>}
                </ListContainer>
                <ListContainer wit="md" pd='true'>
                    <List><Header>Newsletter</Header></List>
                    <List>Subscribe to our weekly Newsletter and receive updates via email</List>
                    <FooterLetter>
                        <FooterSearch placeholder="Email address"/>
                        <FooterButton><Icons src={arrow} ht='true' alt="click to subscribe" /></FooterButton>
                    </FooterLetter>
                </ListContainer>
                <FooterFiller src={filler}/>
            </FooterContainer>
            <FooterP>All Rights Reserved @Pinearth 2023</FooterP>
        </FooterWrap>
       </>
    )

}


export default Footer;