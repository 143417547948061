import { UserWrapper, Header, FillBtn, UserImage } from "../style";
import adrs from '../../../assets/icons/links.svg'
import { useNavigate } from "react-router-dom";



const InvalidLink = () => {
    const navigate = useNavigate();
    
    return(
        <UserWrapper>
            <Header>Invalid or Expired link</Header>
            <UserImage src={adrs} alt="link" />
            <FillBtn onClick={() => navigate("/")}>Back to home</FillBtn>
        </UserWrapper>
    )
}

export default InvalidLink;