import React, { useState, useEffect, useRef } from 'react'
import { BubbleBadge, BubbleBadgeText, BubbleIcon, BubbleIconWrapper, BubbleWrap } from './style'
import bell from "../../assets/icons/bell.svg"
import blubell from "../../assets/icons/blubell.svg"
import { useNavigate } from "react-router-dom";
import { NotificationBtn, NotificationHeader, NotificationImg, NotificationItem, NotificationP, NotificationWrapper, SubWrapper } from './style'
import listed from '../../assets/listed.svg'
import { useSelector, useDispatch } from 'react-redux';
import { randomcodegenerator, putHandle, decode, handleRequest } from "../utility/drag_drop";
import { useFetch } from '../../hooks/useFetch';
import { setAlert } from '../../features/searchSlice/search';

const Bubble = () => {
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.signin.user);
  const loggedIn = useSelector((state) => state.signin.loggedIn);
  const [hide, setHide] = useState({
      busin: 3,
      prop: 3
  })
//   {text: "A new property is up for rent at Alakahia"}, {text: "A new property is up for rent at No. 4 New GRA"}, {text: "A new property is up for rent at Pipeline"}, {text: "A new property is up for rent at Nkpolu"}
// {src: defaulta, text: "Agent John Griff just listed a new property, want to see it ?"}, {src: defaulta, text: "Agent John Griff just listed a new property, want to see it ?"}, {src: defaulta, text: "Agent John Griff just listed a new property, want to see it ?"}, {src: defaulta, text: "Agent John Griff just listed a new property, want to see it ?"}, {src: defaulta, text: "Agent John Griff just listed a new property, want to see it ?"}
  const prop = []
  const business = []
  const gen = []
  const config = { 
    "Content-Type": "application/json",
    "Authorization": `JWT ${user? user.tokens.access : ""}`
    }
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function viewProp(item){
    const url = `${process.env.REACT_APP_BASE_APP_URL}/notification/seen/${item.id}/` 
    const phot = await putHandle({url: url, config: config})
    const response = handleRequest(phot, dispatch, config, user?.tokens.refresh)
    if(response === 'success'){
        prop.pop(item);
        const newId = randomcodegenerator(item.property_id);
        navigate(`/view?q=${decode(newId)}`);  
    }
  }
  
    async function viewAgent(item){
        let title;
        switch (item.role){
            case 'Landlord':
                title = "a landlord"
                break;
            case 'Agent':
                title = "an agent"
                break;
            case 'Developer':
                title = "a developer"
                break;
            case 'Hotel':
                title = "a hotel"
                break;
            case 'Bank':
                title = "a bank"
                break;
            case 'Short-let':
                title = "a short-let"
                break;
            case 'EventCenter':
                title = "an event center"
                break;
            default:
                console.error(`Unexpected role: ${item.role}`);
        }
        const newId = randomcodegenerator(item.agent_id);
        const url = `${process.env.REACT_APP_BASE_APP_URL}/notification/seen/${item.id}/` 
        const phot = await putHandle({url: url, config: config});
        if(phot.error === null){
            business.pop(item);
            navigate(`/contact/${title}/${item.name}?c=${decode(newId)}`);
        }else {
            dispatch(setAlert({alert: true, message: `${phot.error}`}));
        }
    }
    
    function view(title, value){
      setHide({
          ...hide, [title]: value
      })
    }

    const [loading, data, error] = useFetch(`${process.env.REACT_APP_BASE_APP_URL}/notification/view/`)

    data && data.forEach(item => {
        if(item.type === "business"){
            business.push(item);
        }else if(item.type === "property"){
            prop.push(item);
        }else {
            gen.push(item);
        }
    });

    useEffect(() => {
        if(loggedIn){
            const url = `${window.location.protocol !== 'https:'? 'ws:' : 'wss:'}//${process.env.REACT_APP_BASE_APP_URLW}/ws/notifications/${user.id}/`
            const chatSocket = new WebSocket(url);
            chatSocket.onopen = function(e) {
                console.log("[open] Connection established");
                console.log("Sending to server");
            }
            chatSocket.onmessage = (e) => {
                let data = JSON.parse(e.data)
                if(data.type === "business"){
                    business.push(data);
                }else if(data.type === "property"){
                    prop.push(data);
                }else {
                    gen.push(data);
                }
            }
            chatSocket.onclose = function(event) {
                if(event.wasClean) {
                  console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
                } else {
                  // e.g. server process killed or network down
                  // event.code is usually 1006 in this case
                  console.log('[close] Connection died');
                }
              };
            chatSocket.onerror = function(event) {
            };
            }
    }, [loggedIn])

    // window.addEventListener('scroll', function() {
    //     const notification = document.getElementById('notification');
    //     const footer = document.getElementById('footer');

    //     if(footer != null){    
    //         const footerRect = footer.getBoundingClientRect();
    //         const notificationRect = notification.getBoundingClientRect();

    //         // Check if the notification is overlapping the footer
    //         if (footerRect.top < window.innerHeight) {
    //             // If overlapping, change the position to absolute
    //             notification.style.position = 'absolute';
    //             notification.style.bottom = `${document.body.scrollHeight - footer.offsetTop + 20}px`; // 20px is the initial offset from bottom
    //         } else {
    //             // Otherwise, keep it fixed
    //             notification.style.position = 'fixed';
    //             notification.style.bottom = '20px';
    //         }
    //     }else {
    //         // Otherwise, keep it fixed
    //         notification.style.position = 'fixed';
    //         notification.style.bottom = '20px';
    //     }
    // });

    let val;
    const notifRef = useRef(null);

    const handleClickOutside = (event) => {
        if (!notifRef.current.contains(event.target) && open && val > 0){
            setOpen(false);
            val = 0
        } else 
            val = 1
    };

    useEffect(() => {
        // Function to handle click outside of search bar and dropdown
        
        val = 0
        // Attach event listener to document
        if(open){document.addEventListener('click', handleClickOutside)};

        // Cleanup: Remove event listener when component unmounts
        return () => {
        document.removeEventListener('click', handleClickOutside);
        };
    }, [open]);
  return (
    <>
        <BubbleWrap id="notification" ht={open}>
        {open && 
          <NotificationWrapper ref={notifRef} wt={prop.length === 0 || business.length === 0 ? '331.2px' : ''}>
            <SubWrapper>
                <NotificationItem bd='true'>
                    <NotificationHeader>Updates</NotificationHeader>
                </NotificationItem>
                {prop.length > 0?
                    <>
                        {
                            prop.slice(0, hide.prop).map((item, index) => (
                                <NotificationItem bd='true' key={index}>
                                    <NotificationImg src={listed}/>
                                    <NotificationP wt='true'>{item.text}</NotificationP>
                                    <NotificationBtn onClick={() => viewProp(item)}>
                                        <NotificationP color='rgba(17, 115, 171, 1)'>
                                            View
                                        </NotificationP>
                                    </NotificationBtn>
                                </NotificationItem>
                            ))
                        }
                        {prop.length > 3 && <NotificationItem center='true'>
                                <NotificationBtn onClick={hide.prop === 3? () => view("prop", prop.length) : () => view("prop", 3)}>
                                    <NotificationP color="rgba(17, 115, 171, 1)">{hide.prop === 3? "View all" : "View less"}</NotificationP>
                                </NotificationBtn>
                            </NotificationItem>}
                    </>
                    :
                    <NotificationItem>No new notifications</NotificationItem>
                }
                {
                    gen.map((item, index) => (
                        <NotificationItem key={index}>
                            <NotificationImg src={item.profile} />
                            <NotificationP wt='true'>{item.text}</NotificationP>
                            <NotificationBtn onClick={item.role !== "Pinearthling"? () => viewAgent(item) : ''}>
                                <NotificationP color='rgba(17, 115, 171, 1)'>
                                    View
                                </NotificationP>
                            </NotificationBtn>
                        </NotificationItem>
                    ))
                }
            </SubWrapper>
            <SubWrapper>
                <NotificationItem>
                    <NotificationHeader>Agents</NotificationHeader>
                </NotificationItem>
                {business.length > 0?
                    <>
                        {
                            business.slice(0, hide.busin).map((item, index) => (
                                <NotificationItem key={index}>
                                    <NotificationImg src={item.profile} />
                                    <NotificationP wt='true'>{item.text}</NotificationP>
                                    <NotificationBtn onClick={item.role !== "Pinearthling"? () => viewAgent(item) : ''}>
                                        <NotificationP color='rgba(17, 115, 171, 1)'>
                                            View
                                        </NotificationP>
                                    </NotificationBtn>
                                </NotificationItem>
                            ))
                        }
                        {business.length > 3 && <NotificationItem center='true'>
                                <NotificationBtn onClick={hide.busin === 3? () => view("busin", business.length) : () => view("busin", 3)}>
                                    <NotificationP color="rgba(17, 115, 171, 1)">{hide.busin === 3? "View all" : "View less"}</NotificationP>
                                </NotificationBtn>
                            </NotificationItem>}
                    </>
                    :
                    <NotificationItem>No new notifications</NotificationItem>
                }
            </SubWrapper>
          </NotificationWrapper>}
            <BubbleIconWrapper>
                <BubbleIcon src={open? bell : blubell} onClick={() => setOpen(!open)} alt="notifications" />
                {prop.length + business.length > 0 && !open &&
                    <BubbleBadge>
                        <BubbleBadgeText>{prop.length + business.length}</BubbleBadgeText>
                    </BubbleBadge>
                }
            </BubbleIconWrapper>
        </BubbleWrap>
    </>
  )
}

export default Bubble