import { useLocation } from "react-router-dom";
import { ProfileHeader } from "../style";


const Top = () => {
    const location = useLocation()
    const page = location.pathname
    const text = page.slice(9).replace(/%20/g, " ").replace(/\//g, " ");
    const arr = text.split(" ");
    for(var i = 0; i < arr.length; i++){
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const header = arr.join(" ")
    return (

        <>
        <ProfileHeader>{text? header: "Profile"}</ProfileHeader>
        </>
    )
}

export default Top;