import { CardWrapper, CardContainer, CardDisplay, CardProfile, DisplayBtn, Variant, VarLower, FadedBtn, DigitSpan, Icon, CardHeader, CardDescp, LowerDesc, List, PickBtn, ContSpan, TopIcon, SoldOut, LowerButton, CardImgDisplay, FadedWrapper, CardAcross, EmptyCont, EmptyP, RowWrap, TagWrapper, SomeText, RowStar } from "./style";
import gallery from "../../../assets/icons/gallery.svg"
import geo from "../../../assets/icons/geo.svg"
import flat from "../../../assets/icons/flat.svg"
import room from "../../../assets/icons/rooms.svg"
import lot from "../../../assets/icons/size.svg"
import plot from "../../../assets/icons/plot.svg"
import snow from "../../../assets/icons/snow.svg"
import star from "../../../assets/icons/goldStar.svg"
import soldout from "../../../assets/soldout.svg"
import broken from "../../../assets/broken.svg"
import pinlogo from "../../../assets/pinlogo.png"
import { Sliders } from "../../scroll_btn";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { ShortenStr, addCommasToNumber, randomcodegenerator, replaceStr, timeconvert } from "../../utility/drag_drop";
import BusinessInfo from "./business";
import Comment from "./comment";
import MessageBox from "./message_box";


const PropertyCard = ({ data, text, action, wtw, wtc, gray, spanwt, size }) => {
  const WrapRef = useRef(null);
  const navigate = useNavigate();
  // const see = useSelector((state) => state.page.review);
  
  let count = 0
  function view(item){
    document.cookie = `preference=${count++}; Domain=.pinearth.com; Path=/`;
    const newId = randomcodegenerator(item);
    navigate(`/view/${newId}`);  
  }

  function viewAgent(id, person, address){
    const newId = randomcodegenerator(id);
    navigate(`/contact/an event center/${person}?c=${newId}`);
  }

    return (
      <CardAcross>
      {data && data.length > 0 && <Sliders dir={WrapRef} />}
      {data && data.length > 0?
      <CardWrapper ref={WrapRef} wtw={wtw} >
      {data.map((item) => (
        <>
        <CardContainer key={item.id} wtc={wtc} >
           <CardDisplay 
              gray={gray} 
              onClick={['EventCenter'].includes(item.role)? () => viewAgent(item.id, item.company_name, item.address) : () => view(item.id)} >
              <CardImgDisplay 
                src=
                {
                  ['EventCenter'].includes(item.role)? 
                      item.photos.length > 0 ? 
                        item.photos[0].photo
                      : broken 
                    : ['Hotel'].includes(item.role)? 
                        item.bed_room.length > 0 ? 
                          replaceStr(item.bed_room[0].bed_room)
                        : broken 
                      : ['Land'].includes(item.property_type)? 
                          item.land_image.length > 0 ? 
                            replaceStr(item.land_image[0].land_image)
                          : broken 
                        : item.house_view.length > 0? 
                            replaceStr(item.house_view[0].house_view) 
                            : broken} />
              <FadedWrapper pos={['EventCenter', 'Short-let', 'Developer'].includes(item.role)? 'end' : 'space-between'}>
                {!['EventCenter', 'Short-let', 'Developer'].includes(item.role) && 
                  <RowWrap student={item.student}>
                    {item.student === 'Yes' &&
                      <TagWrapper>
                        <RowStar src={star} />
                        <SomeText>Student Lodge</SomeText>
                      </TagWrapper>
                    }
                    <DisplayBtn>{item.property_status}</DisplayBtn>
                  </RowWrap>
                }
                {gray && <SoldOut src={soldout} alt="unavailable" />}
                <ContSpan>
                    <FadedBtn>
                        <TopIcon src={gallery} alt="images"/>
                        <DigitSpan>
                        {['EventCenter'].includes(item.role)? item.photos.length : item.house_view.length + item.living_room.length + item.bed_room.length + item.toilet.length + item.kitchen.length + item.documents.length + item.size.length + item.house_plan.length}
                        </DigitSpan>
                    </FadedBtn>
                    <FadedBtn><TopIcon src={geo} alt="location"/></FadedBtn>
                </ContSpan>
              </FadedWrapper>
           </CardDisplay>
              <RowWrap student='Yes'>
                <CardHeader cl="rgba(17, 115, 171, 1)">
                  {
                  ['EventCenter'].includes(item.role)? 
                    `NGN${addCommasToNumber(item.price_per_day)}/ ${item.duration}` 
                    : ['Room'].includes(item.property_type)?  
                        `NGN${addCommasToNumber(item.property_price)}/ ${item.duration}`
                        : `NGN${addCommasToNumber(item.property_price)}${item?.duration === null || item.property_status === 'For sale'? "" : '/ ' + item.duration}`
                  }</CardHeader>
                  <SomeText col="0.8rem">{item.created_at? timeconvert(item.created_at): "new"}</SomeText>
                </RowWrap>
              <CardHeader onClick={['EventCenter'].includes(item.role)? () => viewAgent(item.id, item.company_name, item.address) : () => view(item.id)}>{['EventCenter'].includes(item.role)? `${item.company_name} ` : ShortenStr(`${item.title} in ${['Room'].includes(item.property_type)? item.hotel.company_name : item.address}`, 28)}</CardHeader>
              <CardDescp left="3%" post="left" desc="desc" bt>{item.desc !== undefined? ShortenStr(item.desc, 60) : ""}</CardDescp>
              <LowerDesc ht={['EventCenter'].includes(item.role) || ['Room'].includes(item.property_type)? true: false} hgt={['Tools'].includes(item.property_status)? '1.2rem':''}>
                {['Room'].includes(item.property_type)?
                  <>
                    {item.aircon === "Yes" && <List width="47%"><Variant><Icon src={snow}/>air conditioner</Variant></List>}
                    {item.heater === "Yes" && <List width="47%"><Variant><Icon src={lot}/>water heater</Variant></List>}
                  </>
                  :
                  ['EventCenter'].includes(item.role)?
                  <>
                    <List width="47%"><Variant><Icon src={lot}/>{item.hall_capacity + " "}sqr ft hall capacity</Variant></List>
                    <List width="47%"><Variant><Icon src={lot}/>{item.parking_space + " "}sqr ft parking space</Variant></List>
                  </>
                  :
                  ['Tools'].includes(item.property_status)?
                  <>
                  </>
                  :
                  ['Land'].includes(item.property_status)?
                  <>
                    <List width="47%"><Variant><Icon src={plot}/>{item.no_of_plots + " "}plots</Variant></List>
                    <List width="47%"><Variant><Icon src={lot}/>{item.lot_size + " "}sqr feet</Variant></List>
                  </>
                  :
                  <>
                    <List><Variant><Icon src={flat}/>{ShortenStr(`${item?.no_of_rooms || 0} rooms`, 8)}</Variant></List>
                    <List><Variant><Icon src={room}/>{ShortenStr(`${item?.no_of_bathrooms || 0} bathrooms`, 10)}</Variant></List>
                    <List><Variant><Icon src={lot}/>{ShortenStr(`${item?.lot_size || 0} sqr ft`, 9)}</Variant></List>
                  </>
                }
              </LowerDesc>
              {['EventCenter'].includes(item.role) ?
                <VarLower>
                  <List width="13%" text="y" al="pro" cur="pointer" onClick={() => viewAgent(item.id, item.company_name, item.address)}><CardProfile src={item?.profile_photo !== undefined && item?.profile_photo !== null ? item.profile_photo : pinlogo} /></List>
                  <List width="45%" text="y" size="10px" cur="pointer" dir="dir" onClick={() => viewAgent(item.id, item.company_name, item.address)}>
                      <CardDescp post="left" size="0.9rem">{ShortenStr(item?.name || "Pinearth", 11) || ShortenStr(item?.company_name || "Pinearth", 11)}</CardDescp>
                      <CardDescp post="left"  color="rgba(17, 115, 171, 1)" >Event center</CardDescp>
                  </List>
                  <List text="y" al="pro"><PickBtn onClick={() => view(item.id)}>Book</PickBtn></List>
                </VarLower>
                :
                <VarLower>
                  <BusinessInfo owner={item.owner} agent={item.agent} lad={item.landlord} dev={item.developer} hot={item.hotel} short={item.short_let} event={item.event_center} />
                  <List text="y" al="pro"><PickBtn onClick={() => view(item.id)}>{["For rent", "Shortlet"].includes(item.property_status)? "Rent it" :["Tools", "Land", "Room"].includes(item.property_status)? "View" : "Buy it"}</PickBtn></List>
                </VarLower>
              }
              <Comment number={item.reviews.length} pk={item.id} />
              <MessageBox pk={item.id} total={item.reviews.length} reviews={item.reviews} />
        </CardContainer>
        {/* {!val && <Messages pk={item.id} total={item.reviews.length} reviews={item.reviews} />} */}
        </>
        ))}
     </CardWrapper>
     :
        <EmptyCont>
            <EmptyP>
                No Listed Properties
            </EmptyP>
        </EmptyCont>}
      </CardAcross>
    )
}

export default PropertyCard;