// export const years = [
//     1, 2, 3, 4, 5, 6, 7, 8, 9, 
// ]

export const print = (value) => {
    const years = []
    for(var i = 0; i < value; i++){
        years.push(i)
    }return years
}


export const Calendar = {
    "01" : 31,
    "02": 28,
    "03": 31,
    "04": 30,
    "05": 31,
    "06": 30,
    "07": 31,
    "08": 31,
    "09": 30,
    "10": 31,
    "11": 30,
    "12": 31,
}