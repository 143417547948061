import { useEffect, useState, useRef } from "react";
import { FormButton, FormHeader, FormDrag, FormP, FormInput, FormLabel, FormStrong, Spinner, VariantFormWrapper, ProgressBar, ProgressBorder, FormSpan, Warn, Icons, FormSelect, FormOption } from "../style"
import { ListDrag, ListIcon, ImgCount, ListInput, ListLabel, ListStrong, AppsWrapper, AppsContainer, AppsIcon, ExtraText, ListP, SpecCol } from '../../../pages/miscellaneous/list/style'
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import image from "../../../assets/icons/images.svg";
import close from "../../../assets/icons/closew.svg"
import { role, roleClear } from "../../../features/registerSlice/rdataSlice";
import { postData, errorClear, successClear } from "../../../features/postSlice/post";
import { setAlert } from "../../../features/searchSlice/search";
import { fornext } from "../../../features/pageSlice/page";
import doc from '../../../assets/icons/docs.svg'
import Cookies from "js-cookie";
import { signoutRequest } from "../../../features/purge/purge";
import { store } from "../../../app/store";
import { packToForm2, handleDragLeave, handleDragOver, eventcenter, shortlet } from "../../utility/drag_drop";
import { states } from "../../../data/area";



const DoubleP = () => {
    let { roles } = useParams();
    const user = useSelector((state) => state.signin.user);
    const url = `${process.env.REACT_APP_BASE_APP_URL}/role/${roles.toLowerCase()}/` 
    const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`
    const error = useSelector((state) => state.post);
    const dispatch = useDispatch();
    const val = useSelector((state) => state.data.role);
    const navigate = useNavigate();
    const config = { 
        "Content-Type": "multipart/form-data",
        "Authorization": `JWT ${user.tokens.access}`
    }

    const [check, setCheck] = useState({
        no: false
    });
    
    const[reg, setReg]=useState({
        name: val.name,
        email: val.email,
        address: val.address,
        phone_no: val.phone_no,
        company_id: val.company_id,
        company_name: val.company_name,
        company_reg: val.company_reg,
        state: val.state,
        hall_capacity: val.hall_capacity,
        parking_space: val.parking_space,
        iphotos: val.iphotos,
        iname: [...val.iname],
    });
    const handleChange = (e) => {
        e.preventDefault();
        const target = e.target
        if(target.name === "phone_no" && target.value.startsWith("0")){
            let newValue = target.value.toString().replace("0", "+234")
            setReg({...reg, [target.name]: newValue})    
        }else {
            setReg({...reg, [target.name]: target.value});
        }
        // dispatch(role(reg));
    }
    
    useEffect(() => {
        if(reg.company_id !== null || reg.company_name !== ""  || reg.company_reg !== ""  || reg.address !== ""  || reg.name !== ""  || reg.phone_no !== ""  || reg.email !== ""  || reg.hall_capacity !==""  || reg.parking_space !=="" || reg.iname.length > 0){
            const data = ['an Event center'].includes(roles) ? eventcenter(reg) : ['a Short-let'].includes(roles) ? shortlet(reg) : reg
            dispatch(role(data));
        }
    }, [reg])

    function uploadImg(val){
        dispatch(fornext({id: val, nextPage: `/register/${roles}/iv`}))
        navigate("/uppath");
    }

    function Back(e){
        e.preventDefault();
        if(!['an Agent', 'a Landlord'].includes(roles)){
            navigate(`/register/${roles}/i`);
        } else {
            navigate(-1);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // const FullData = {...val, ...reg}
        const text = ['a Short-let'].includes(roles) ? ['profile_photo'] : ['an Event center', 'a Hotel'].includes(roles) ? ['profile_photo', 'company_id', 'iphotos'] : ['profile_photo', 'company_id']
        const sin = ['a Short-let'].includes(roles) ? ['profile_photo'] : ['profile_photo', 'company_id']
        const isarr = ['an Event center'].includes(roles) ? ['iname']:[]
        const form = await packToForm2(val, text, isarr, sin, []);
        form.append('company_id', reg.company_id)
        dispatch(postData({url:url, data:form, config:config}));
    }

    const fileInputRef = useRef(null);

    const handleUploadButtonClick = () => {
        fileInputRef.current.click(); // Trigger the file input click event
    };
 
    const handleFileInputChange = (event) => {
        console.log(event.target.files[0])
        const prof = window.URL.createObjectURL(event.target.files[0])
        const target = event.target
        setReg({...reg, [target.name]: prof})
    }

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0]
        const prof = window.URL.createObjectURL(file)
        const target = event.target
        setReg({...reg, [target.getAttribute('data-name')]: prof})
    }

    const AddApps = (event) => {
        if(event.key === 'Enter'){
          setReg((PrevArray) => ({
            ...PrevArray,
            iname:  [...PrevArray.iname, event.target.value]
          }))
        }
        
      }
    
    const removeUappliance = (applianceToRemove) => {
    setReg((prevData) => ({
        ...prevData,
        iname: prevData.iname.filter(name => name !== applianceToRemove)
    }));
    };

    useEffect(() => {
        if (error.loading === false && error.error !== ""){
            if(error.status === 400){
                dispatch(setAlert({alert: true, message: error.error}));
                dispatch(errorClear());
              }else if (error.status === 401){
                dispatch(setAlert({alert: true, message: 'Session has expired'}));
                dispatch(postData({url: lout, config: {"Content-Type": "application/json"}, data: user.tokens}));
                dispatch(errorClear());
                Cookies.remove("data");
                store.dispatch(signoutRequest());
              }else{
                dispatch(setAlert({alert: true, message: 'Network Error'}));
                dispatch(errorClear());
              }
        } else if (error.loading === false && error.data.error === "This user has a role"){
            dispatch(setAlert({alert: true, message:'This user has a Business account'}));
            dispatch(successClear());
        }else if (error.loading === false && error.error === "" && error.success === true){
            dispatch(roleClear());
            navigate("/role created");
            dispatch(successClear());
        }
    }, [error.loading])

    return(
        <VariantFormWrapper>
            <FormHeader>Register as {roles} </FormHeader>
            <ProgressBorder>
                <ProgressBar wt="60%"></ProgressBar>
            </ProgressBorder>
            {['a Bank', 'a Short-let', 'a Hotel', 'a Developer'].includes(roles)?
                <>
                    {['a Bank', 'a Hotel'].includes(roles)?
                        <>
                        <FormLabel for="name"><FormStrong>{roles.slice(2, roles.length)} name </FormStrong></FormLabel>
                        <FormInput type="name" placeholder={val.name? val.name : "Johnson James"} name="name" onChange={handleChange} id=""  />
                        {['a Bank'].includes(roles) &&
                            <>
                                <FormLabel for="company_name"><FormStrong>Company name </FormStrong></FormLabel>
                                <FormInput type="company_name" placeholder={val.company_name? val.company_name : "E.g XYZ Corps"} name="company_name" onChange={handleChange} id="company_name"  />
                            </>
                        }
                        </>
                        :
                        <>
                        <FormLabel for="email"><FormStrong>Email Address</FormStrong></FormLabel>
                        <FormInput type="email" placeholder={val.email? val.email : "johnson@james.com"} name="email" onChange={handleChange} id="email"  />
                        </>
                    }
                    <FormLabel for="phone_no"><FormStrong>Phone Number</FormStrong></FormLabel>
                    <FormInput type="tel" placeholder={val.phone_no? val.phone_no : "+234749...."} onChange={handleChange} name="phone_no" id="phone_no"  />
                    {check.no? <Warn>phone number is incorrect</Warn>:<></>}
                    <FormLabel for="adrs"><FormStrong>{['a Hotel', 'a Bank', 'a Developer'].includes(roles)? "Office Address" : `${roles.slice(2, roles.length)} Address`}</FormStrong></FormLabel>
                    <FormInput type="text" placeholder={val.address? val.address : "6291 Elign St. Cotza Delavue 50200"} name="address" onChange={handleChange} id="adrs"  />
                    {['a Short-let'].includes(roles) && 
                        <>
                            <FormLabel for="state"><FormStrong>State</FormStrong></FormLabel>
                            {/* <FormInput type="text" placeholder={val.state? val.state : "E.g Rivers state"} name="state" onChange={handleChange} id="state"  /> */}
                            <FormSelect name="state" onChange={handleChange} id="state" defaultValue={val.state}>
                                {states.map((item, index) => (
                                    <FormOption key={index} value={item}>{item}</FormOption>
                                ))}
                            </FormSelect>
                        </>
                    }
                </>
                :
                <>
                <FormLabel for="adrs"><FormStrong>{['an Event center'].includes(roles) ? "Property Address" : "Office Address"}</FormStrong></FormLabel>
                <FormInput type="text" placeholder={val.address? val.address : "6291 Elign St. Cotza Delavue 50200"} name="address" onChange={handleChange} id="adrs"  />
                </>
            }
            {['an Event center'].includes(roles) &&
                <>
                    <FormLabel for="state"><FormStrong>State</FormStrong></FormLabel>
                    {/* <FormInput type="text" placeholder={val.state? val.state : "E.g Rivers state"} name="state" onChange={handleChange} id="state"  /> */}
                    <FormSelect name="state" onChange={handleChange} id="state" defaultValue={val.state}>
                        {states.map((item, index) => (
                            <FormOption key={index} value={item}>{item}</FormOption>
                        ))}
                    </FormSelect>
                    <FormLabel for="capacity"><FormStrong>Hall Capacity</FormStrong></FormLabel>
                    <FormInput type="text" placeholder={val.hall_capacity? val.hall_capacity : "E.g 173 Sqr feet"} name="hall_capacity" onChange={handleChange} id="capacity"  />
                    <FormLabel for="parking_space"><FormStrong>Parking Space</FormStrong></FormLabel>
                    <FormInput type="text" placeholder={val.parking_space? val.parking_space : "E.g 2000 Sqr feet"} name="parking_space" onChange={handleChange} id="parking_space"  />
                    <SpecCol className='hcol' gp='0' >
                    <ListLabel for="inames"><ListStrong>What appliances are on the property</ListStrong></ListLabel>
                    <ListP role='hotel' color='gray' lt>Press enter to add item to list</ListP>
                    </SpecCol>
                    <ListInput type="text" name="inames" id="inames" onKeyDown={AddApps} placeholder={"E.g a Fridge"}/>
                    {reg.iname && <AppsWrapper>
                    {reg.iname.map((item, index) => (
                        <AppsContainer key={index}>
                        <AppsIcon src={close} alt='close' onClick={() => removeUappliance(item)} />
                        <ExtraText>{item}</ExtraText>
                        </AppsContainer>
                    ))}
                    </AppsWrapper>}
                    <FormLabel for='iphotos'><FormStrong>Images of the Event Center</FormStrong><FormP>*image must be clear</FormP></FormLabel>
                    <ListDrag  data-name="iphotos"  onClick={reg.iphotos.length > 0 ? () => navigate('/preview') : () => uploadImg(8)} >
                    <ListIcon src={reg.iphotos.length > 0? doc: image} alt="drag and drop"/>
                    {reg.iphotos.length > 0 && 
                    <ImgCount> {reg.iphotos.length}</ImgCount>
                    
                    // <UButton>Upload</UButton>
                    }
                    </ListDrag>
                </>
            }
            {['an Agent', 'a Developer', 'a Hotel'].includes(roles) &&
            <>
            <FormLabel for='company_id'><FormStrong>Company ID</FormStrong><FormP>*image must be clear</FormP></FormLabel>
            <FormDrag onDragLeave={handleDragLeave} data-name="company_id" onDragOver={handleDragOver} onDrop={handleDrop} onClick={handleUploadButtonClick}>
                <Icons src={reg.company_id != null? reg.company_id : image} upP={reg.company_id != null ? true : false} alt="drop image"/>
                <FormInput type="file" onChange={handleFileInputChange} ref={fileInputRef} name="company_id" none accept="image/*" />
            </FormDrag>
            <FormLabel for="company_name"><FormStrong>Company Name</FormStrong></FormLabel>
            <FormInput type="text" placeholder={val.company_name? val.company_name : "Eg. XYZ Corps"} onChange={handleChange} name="company_name" id="company_name"  />
            <FormLabel for="company_reg"><FormStrong>Company Reg. Number</FormStrong></FormLabel>
            <FormInput type="text" placeholder={val.company_reg? val.company_reg : "E.g CRN1234567890"} onChange={handleChange} name="company_reg" id="company_reg"  />
            </>
            }
            {['a Bank', 'an Event center'].includes(roles) &&
            <>
            <FormLabel for='company_id'><FormStrong>Company ID</FormStrong><FormP>*image must be clear</FormP></FormLabel>
            <FormDrag onDragLeave={handleDragLeave} data-name="company_id" onDragOver={handleDragOver} onDrop={handleDrop} onClick={handleUploadButtonClick}>
                <Icons src={reg.company_id != null || val.company_id != null? reg.company_id : image} upP={reg.company_id != null || val.company_id != null ? true : false} alt="drop image"/>
                <FormInput type="file" onChange={handleFileInputChange} ref={fileInputRef} name="company_id" none accept="image/*" />
            </FormDrag>
            </>
            }
             <FormSpan>
                <FormButton left onClick={Back} >Back</FormButton>
                <FormButton onClick={handleSubmit}>
                {error.loading?
                        <Spinner
                            color={"white"}
                            loading={true}
                            size={25}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        : 
                        "Finish"
                }
                </FormButton>
            </FormSpan>
        </VariantFormWrapper>
    )
}

export default DoubleP;