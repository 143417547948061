import styled, { keyframes } from 'styled-components'


export const ServicesContainer = styled.div`
 display: flex;
 flex-direction: column;
 text-align: center;
 align-items: center;
 margin: auto;
 margin-bottom: 2rem;
 position: relative;
 justify-content: center;

`
export const ServWrapper= styled.div`
 display: flex;
 flex-direction: column;
 align-items: center;
 /* text-align: center;
 margin: auto;
 margin-bottom: 2rem;
 position: relative;
 justify-content: center; */

`

export const ServiceHeader = styled.h3`
 color: black;
 font-size: 1.3rem;
 @media screen and (max-width: 600px) {
      font-size: 2.7rem;
 }
`
export const ServiceWrap = styled.section`
 display: flex;
 gap: 10px;
 margin-bottom: 4rem;
    &::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }
    &::-webkit-scrollbar-thumb {
        background: #888; 
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #555;       
    }
    @media screen and (max-width: 600px) {
      width: 74%;
      margin: auto;
      overflow: auto;
      scroll-behavior: smooth;
      scroll-snap-type: x mandatory;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 2px;
    }

    }
`
export const ServSpan = styled.span`
   display: none;
   width: 18%;
   margin: auto;
 @media screen and (max-width: 600px){
    display: flex;
 }
`
const max = keyframes`
 from{
    opacity: 0;
    height: 0;
 }
 to{
    opacity: 1;
    height: 348px;
 }
`
export const ServiceCard = styled.div`
   display: flex;
   flex-direction: column;
   border-radius: 4px;
   width: 18%;
   height: 435px;
   margin: auto;
   justify-content: space-between;
   padding: 7px;
   align-items: center;
   text-align: center;
   @media screen and (max-width: 784px) {
      width: 18%;
   }
   @media screen and (max-width: 654px) {
      width: 21%;
   }
   @media screen and (max-width: 600px) {
      min-width: 93%;
      margin: auto;
      height: 650px;
      scroll-snap-align: center;
      // animation: ${max} 1s ease-in;
   }
`

export const ServiceImg = styled.img`
 height: 200px;
 width: 100%;
  @media screen and (max-width: 600px) {
   height: 250px;
  }
`
export const ServiceP = styled.p`
   text-align: center;
   font-size: 1.1rem;
   height: max-content;
   width: 85%;
   margin-bottom: 1rem;
   padding: 0;
`
export const ServiceBtn = styled.button`
   border: 1px solid rgba(17, 115, 171, 1);
   border-radius: 3px;
   color: rgba(17, 115, 171, 1);
   text-align: center;
   height: 40px;
   font-size: 1.2rem;
   background: transparent;
   width: 87%;
   cursor: pointer;
   @media screen and (max-width: 600px){
      height: 57px;
      width: 79%;
   }
`