import { AgentWrapper, AgentHeader, AgentStrong, AgentP } from "../style";
import SearchBar from '../../../components/searchbar'
import Tag from '../../../components/utility/tags'
import { useParams } from "react-router-dom";
import BusinessList from "../agentt";



const FindAgent = () => {
    const { role } = useParams();
    const varagent = role.toLowerCase() 
    return (
        <AgentWrapper ps="center"> 
            {role === "bank"
                ?
                <AgentHeader ftw="400"><AgentStrong>Apply for house</AgentStrong>{" loans"}</AgentHeader>
                :
                <AgentHeader ftw="400"><AgentStrong>Find {['landlord', 'developer', 'hotel', 'short-let'].includes(role)? ' a ': ' an '}</AgentStrong>{role}</AgentHeader>
            }
            <Tag text="Properties" lf={'6%'} />
            <AgentP size='true' thick='true' bt='true' pd='true'>Find an {role === "Bank"? "eligible bank": role} in your area</AgentP>
            <SearchBar filter={varagent} role={true} loc="find"/>
            <BusinessList role={role} />
        </AgentWrapper>
    )
}


export default FindAgent
