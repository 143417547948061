import { styled } from "styled-components";
import { NavLink as Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";



export const AgentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: ${props => props.size ? "64%" : "90%"};
    margin: ${props => props.no ? "" : "auto"};
    align-items: ${props => props.ps};
    position: ${({pos}) => pos};
    padding-top: ${props => props.top ? "0" : "18px"};
    @media screen and (max-width: 600px){
        width: ${props => props.size ? "64%" : "94%"};
    }
`
export const AgentWrap = styled(AgentWrapper)`
    width: 40%;
    margin-left: 1%;
    @media screen and (max-width: 684px){
        width: 45%;
    }
    @media screen and (max-width: 607px){
        width: 50%;
    }
    @media screen and (max-width: 547px){
        width: 55%;
    }
    @media screen and (max-width: 503px){
        width: 70%;
    }
`

export const AgentHeader = styled.h1`
 color: rgba(17, 115, 171, 1);
 font-size: 1.8rem;
 font-weight: ${({ ftw }) => ftw};
`

export const AgentStrong = styled.strong`
    font-size: 1.8rem;
`

export const AgentP = styled.p`
    margin: ${props => props.gin};    
    font-size: ${({ size }) => size ? "1.2rem" : "1rem"};
    font-weight: ${({ thick }) => thick ? "700" : ""};
    padding-bottom: ${({ pd }) => pd ? "2rem" : ""};
    @media screen and (max-width: 503px){
        margin-bottom: ${({ bt }) => bt ? "1rem" : ""}; 
    }        
    @media screen and (max-width: 414px){
        font-size: 1.2rem;
    }
    @media screen and (max-width: 353px){
        font-size: 1rem;
    }
`
export const BluP = styled(AgentP)`
    color: rgba(17, 115, 171, 1);
`

export const AgentImage = styled.img`
 border-radius: ${ props => props.variant === "profile" ? "999px" : "none"};
 height: ${ props => props.variant === "profile" ? "8rem" : "300px"};
 width: ${ props => props.variant === "profile" ? "8rem" : "300px"};
 /* margin-bottom: ${ props => props.bt ? "1rem": ""}; */
    @media screen and (max-width: 434px){
        align-self: ${ props => props.bt ? "center": ""};
    }
    @media screen and (max-width: 414px){
        height: ${ props => props.variant === "profile" ? "6rem" : "300px"};
        width: ${ props => props.variant === "profile" ? "6rem" : "300px"};
    }
`
export const AgentContainer = styled(Link)`
 display: flex;
 min-width: ${props => props.width === "no rate" ? "100%" : "40%"};
 gap: ${props => props.gp};
 height: 10rem;
 border: none;
 border-radius: ${props => props.width === "no rate" ? "" : "3px"};
 border-bottom: ${props => props.width === "no rate" ? "1px solid rgba(217, 217, 217, 1)" : ""};
 border-top: ${props => props.width === "no rate" ? "1px solid rgba(217, 217, 217, 1)" : ""};
 align-items: center;
 box-shadow: ${props => props.width === "no rate" ? "" : "0px 3px 5px rgba(217, 217, 217, 1)"};
 padding: 0 1%;
 text-decoration: none;
 color: black;
 justify-content: ${({ jc }) => jc};
 cursor: pointer;
 @media screen and (max-width: 860px){
    min-width: ${props => props.width === "no rate" ? "100%" : "46%"};
 }
 @media screen and (max-width: 660px){
    min-width: ${props => props.width === "no rate" ? "100%" : "48%"};
    gap: 0;
 }
 @media screen and (max-width: 600px){
    min-width: ${props => props.width === "no rate" ? "100%" : "57%"};
 }
 @media screen and (max-width: 503px){
    min-width: ${props => props.width === "no rate" ? "100%" : "78%"};
    padding: 3% 0;
    padding-left: 2%;
 }
 @media screen and (max-width: 478px){
    min-width: ${props => props.width === "no rate" ? "100%" : "85%"};
 }
 @media screen and (max-width: 414px){
    min-width: ${props => props.width === "no rate" ? "100%" : "94%"};
 }
 @media screen and (max-width: 478px){
    min-width: ${props => props.width === "no rate" ? "100%" : "85%"};
 }
 @media screen and (max-width: 382px){
    min-width: ${props => props.width === "no rate" ? "100%" : "99%"};
 }
`

export const AgentSpan = styled.div`
    width: 65%;
    display: flex;
    align-items: center;
    gap: 7%;
    /* justify-content: space-between; */
    @media screen and (max-width: 503px){
        width: 100%;
        gap: 1%;
 }
`
export const Spinner = styled(ClipLoader)`
    align-self: center;
    justify-self: center;
`

export const Loader = styled.div`
    height: 400px;
    display: grid;
`

export const AgentIcon = styled.img`
 height: ${props => props.right ? props.right : "0.8rem"};
 height: ${props => props.wt ? props.wt : "0.8rem"};
 padding-right: 6px;
 fill: rgba(0, 0, 0, 0.5);
 @media screen and (max-width: 600px){
    padding: ${({pd}) => pd? '' : '0 4px 3px 0'};
 }
`
export const AgentList = styled.div`
    display: flex;
    flex-direction: ${props => props.variant};
    gap: 1%;
    width: 85%;
    margin: auto;
    align-items: center;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding: 0.5rem;
    padding-bottom: 2rem;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        height: 0px;
    }
    @media screen and (max-width: 600px){
        gap: 4%;
        width: 100%;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            height: 2px;
        }
    }
    @media screen and (max-width: 434px){
        width: ${props => props.variant === "column"? "90%":"85%"};
    }
    @media screen and (max-width: 420px){
        width: ${props => props.variant === "column"? "90%":"92%"};
    }
    @media screen and (max-width: 388px){
        width: ${props => props.variant === "column"? "96%":"85%"};
    }
    @media screen and (max-width: 335px){
        width: ${props => props.variant === "column"? "99%":"85%"};
        padding: 0;
    }
    @media screen and (max-width: 335px){
        width: ${props => props.variant === "column"? "100%":"85%"};
    }
`
export const AgentButton = styled.button`
    width: 20%;
    border: none;
    border-radius: 4px;
    background: rgba(17, 115, 171, 1);
    color: white;
    height: 40px;
    cursor: pointer;
    @media screen and (max-width: 900px){
        width: 22%;  
    }

    @media screen and (max-width: 600px){
        width: ${({wt}) => wt? wt : '22%'};  
    }
    @media screen and (max-width: 503px){
        width: ${({wt}) => wt === '32%'? '40%' : '35%'};  
    }
`
export const AgentHeaderText = styled.h1``

export const AgentNext = styled.button`
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
    align-self: ${props => props.position};
    @media screen and (max-width: 503px){
    width: 10%;  
 }
    @media screen and (max-width: 325px){
        width: 14%;
    }
`
// linear-gradient(to bottom, #ddd4d4, #ddd4d4),

export const Slider = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
`
export const BackButton = styled.button`
    border: none;
    background: none;
    color: rgba(17, 115, 171, 1);
    cursor: pointer;
    text-decoration: none;
    align-self: flex-start;
    align-items: center;
`