import { useDispatch, useSelector } from "react-redux";
import { Text, UserSection, ProfileForm, ProfileInput, ProfileLabel, ProfileStrong, LoadingCont, Spinner, ProfileButton } from "../style"
import { useEffect, useState } from "react";
import { updateUserData } from "../../../features/signinSlice/signin";
import { postData } from "../../../features/postSlice/post"; 
import { putData, successClear, errorClear } from "../../../features/putSlice/put";
import { getHandle, handleRequest } from "../../utility/drag_drop";
import { store } from "../../../app/store";
import { signoutRequest } from "../../../features/purge/purge";
import { setAlert } from "../../../features/searchSlice/search";
import Cookies from 'js-cookie'
// import datas from "../../../data.json"




const Edit = () => {
    const data = useSelector((state) => state.signin.user);
    // const data = datas.users[0]
    const dispatch = useDispatch();
    
    const config = {
        "Content-Type": "application/json",
        "Authorization": data.tokens? `JWT ${data.tokens.access}` : null
    }

    const[values, setValues] = useState({
        first_name: '',
        middle_name: '',
        last_name: "",
        email: '',
        address: '',
        phone_no: ''
    })
    
    const[isLoading, setIsLoading] = useState(false);
    const url = `${process.env.REACT_APP_BASE_APP_URL}/user/${data.id}/update/`
    const msg = useSelector((state) => state.put);
    const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`
    const data_user = `${process.env.REACT_APP_BASE_APP_URL}/user/info/`

    const handleChange = (e) => {
        const target = e.target;
        setValues({...values, [target.name]: target.value})
    }

    async function updateClause(){
        setIsLoading(true);
        const user_data = await getHandle({url: data_user, config: config}); 
        const response = handleRequest(user_data, dispatch, config, data?.tokens.refresh)
        if(response == "success"){
            dispatch(updateUserData(user_data.data));
        }
        setIsLoading(false);
    }

    function handleSubmit(e){
        e.preventDefault();
        let newData = {}
        Object.keys(values).map((item, index) => {
            if(values[item] !== ""){
                newData[item] = values[item]
            }
        })
            
        if(JSON.stringify(newData) != "{}"){
            dispatch(putData({url: url, data: newData, config: config}));
        }else {
            dispatch(setAlert({alert: true, message: "No new changes were made"}));
        }
    }

    useEffect(() => {
        if (msg.loading === false && msg.error !== ""){
            if(msg.status === 400){
                dispatch(setAlert({alert: true, message: msg.error}));
                dispatch(errorClear());
            }else if (msg.status === 401){
                dispatch(setAlert({alert: true, message: 'Session has expired'}));
                dispatch(postData({url: lout, config: {"Content-Type": "application/json"}, data: data.tokens}));
                dispatch(errorClear());
                Cookies.remove("data");
                store.dispatch(signoutRequest());
            }else{
                dispatch(setAlert({alert: true, message: 'Network Error'}));
                dispatch(errorClear());
            }
        } else if (msg.loading === false && msg.error === "" && msg.success === true){
            dispatch(setAlert({alert: true, message: "Your profile has been saved", color: '#90EE90'}));
            dispatch(successClear());
            updateClause();
        }
    }, [msg.loading])
    return(
        <>
            {isLoading?
            <LoadingCont>
                <Spinner
                    color={"rgba(17, 115, 171, 1)"}
                    loading={true}
                    size={50}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </LoadingCont>
            :
            <>
            <ProfileForm>
                <ProfileLabel for="name"><ProfileStrong>Name:</ProfileStrong></ProfileLabel>
                <UserSection>
                    <Text placeholder={data.first_name} name="first_name" className="names" position="right" onChange={handleChange} />
                    <Text placeholder={data.middle_name} name="middle_name" className="names" position="right" onChange={handleChange} />
                    <Text placeholder={data.last_name} name="last_name" className="names" onChange={handleChange} />
                </UserSection>
                <ProfileLabel for="email"><ProfileStrong>Email:</ProfileStrong></ProfileLabel>
                <ProfileInput type="email" placeholder={data.email} name="email" id="email" onChange={handleChange} />
                <ProfileLabel for="adrs"><ProfileStrong>Address</ProfileStrong></ProfileLabel>
                <ProfileInput type="text" placeholder={data.profile?.address || "address"} name="address" id="adrs" onChange={handleChange} />
                <ProfileLabel for="phone_no">Phone no:</ProfileLabel>
                <ProfileInput type="number" placeholder={data.profile?.phone_no || "+234 000 000 0000"} name="phone_no" onChange={handleChange} id="phone_no" />
            </ProfileForm>
            <ProfileButton type="submit" otp="0 auto auto auto" onClick={handleSubmit}>
            {msg.loading? 
                <Spinner 
                color={"white"}
                loading={true}
                size={25}
                aria-label="Loading Spinner"
                data-testid="loader"
                    />
            :
            "Save"}</ProfileButton>
            </>}
        </>
    )
}


export default Edit;