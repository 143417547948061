import { isVideo } from "../utility/drag_drop"
import { ViewedImg, ViewedSrc, ViewedVideo } from "./style"


const DisplayView = ({src}) => {

  return (
    <>
        {isVideo(src)?
          <ViewedVideo controls>
            <ViewedSrc src={src} type="video/mp4" />
          </ViewedVideo>
        :
          <ViewedImg src={src} alt="display"/>
        }
    </>
  )
}

export default DisplayView