import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import GoogleButton from "react-google-button"


export const Spinner = styled(ClipLoader)`
    align-self: center;
    justify-self: center;
`
export const UserWrapper = styled.form`
    margin: auto;
    margin-top: 65px;
    width: ${({ set }) => set === "profile" ? "22%" : "26%"};
    display: flex;
    gap: 1rem;
    flex-direction: column;
    border: ${({ set }) => set === "profile" ? "1px solid rgba(217, 217, 217, 1)" : "none"};
    border-radius: 3px;
    padding: 3%;
    @media screen and (max-width: 1012px) {
        width: 32%;
    }
    /* @media screen and (max-width: 920px) {
        width: 32%;
    } */
    @media screen and (max-width: 822px) {
        width: 39%;
    }
    @media screen and (max-width: 677px) {
        width: 46%;
    }
    @media screen and (max-width: 600px) {
        width: 88%;
        height: ${({ht}) => ht};
        margin-top: 4rem;
    }
`

export const Button = styled.button`
    margin: auto;
    height: 40px;
    padding: 9px;
    color: ${props => props.src || "rgba(17, 115, 171, 1)"};
    font-size: 15px; 
    border: 1px solid ${props => props.src || "rgba(17, 115, 171, 1)"};
    border-radius: 2px;
    text-align: center;
    background: transparent;
    cursor: pointer;
`

export const Icon = styled.img`
    padding-right: ${ props => props.position === "right"? "14px": "0"};
    padding-left: ${ props => props.position === "left"? "2px": "0"};
    height: ${ props => props.ht === "lg"? "20px" : ""};
    /* height: ${ props => props.ht === "sm"? "55px" : "13px" }; */
    margin-bottom: ${ props => props.sm === "sm"? "20px" : "" };
    position: ${ props => props.position };
    align-self: ${ props => props.btm?"center": ""};
    z-index: ${ props => props.btm? "1" : ""};
    right: ${ props => props.btm? "0" : ""};
    margin-right: ${ props => props.btm? "10px" : ""};
    cursor: pointer;
    left: ${ props => props.stk? "93%" : "none"};
    @media screen and (max-width: 600px){
        height: ${ props => props.ht === "lg"? "40px" : ""};
    }
`
export const DragWrapper = styled.div`
    background: rgba(217, 217, 217, 0.2);
    padding: 30px;
    margin: auto;
    width: 50%;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Header = styled.h3`
    text-align: center;
    width: ${props => props.adrs ? "80%" : ""};
    margin: auto;
    font-size: ${({sm}) => sm? "1rem":"1.4rem"};
    margin-bottom: ${props => props.adrs ? "40px" : ""};
    margin-bottom: ${props => props.bt};
    margin-top: ${props => props.bt};
    font-weight: 700;
    @media screen and (max-width: 600px) {
        font-size: ${props => props.sz || '2.1rem'};
    }

`

export const Email = styled.input.attrs({
    type: "email"
})`
    padding: 0 10px;
    height: 40px;
    margin-bottom: 20px;
    background: rgba(238, 238, 238, 1);
    border: none;
    border-radius: 3px;
    padding-left: 3%;
    outline: none;
    border: ${({ warn }) => warn? "1px solid red" : "none"}; 
    @media screen and (max-width: 600px) {
        font-size: 1.1rem;
        height: 60px;
    }
`
export const SpecText = styled.p`
    color: grey;
    font-size: 0.9rem;
    align-self: flex-start;
    @media screen and (max-width: 600px) {
    font-size: 1.2rem;
    }
`
export const RanSpan = styled.span`
    position: relative;
    bottom: 4rem;
    align-self: flex-start;
`
export const Warn = styled.p`
    color: ${({ warn }) => warn === "weak" ? "red" : "green"};
    position: relative;
    bottom: ${({ sin }) => sin? "2rem":"4rem"};
    align-self: flex-end;
`

export const ReWarn = styled(Warn)`
    bottom: -3rem;
`

export const Password = styled(Email).attrs({
    type: "password"
})`
    width: 95%;
    transition: border 0.3s ease;
    /* border: ${({ warn }) => warn === "weak" ? "1px solid red" : "none"};     */
`

export const Text = styled(Email).attrs({
    type: "text"
})`
   width: ${props => props.size ? props.size : "33%"};
   margin-right: ${props => props.position === "right" ? "6px" : "none"};
   margin: auto;
   margin-bottom: ${props => props.adrs ? "26px" : "none"};
   @media screen and (max-width: 600px){
        &.address {
            margin: 0 auto;
            height: 60px;
        }
   }
`

export const Date = styled(Email).attrs({
    type: "date",
    pattern: "\d{4}-\d{2}-\d{2}"
})`
  appearance: none;
  -webkit-appearance: none;
  background-image: url('../../assets/icons/calendar.svg');
  background-position: left center;
  background-repeat: no-repeat;
`

export const Up = styled(Email).attrs({
    type: "file"
})`
   width: 30%;
   border: 1px solid black;
   border-radius: 2px;
   display: none;
`

export const Label = styled.label`
    font-weight: 700;
    margin-left: ${props => props.adrs ? "10%" : "none"};
    @media screen and (max-width: 800px) {
        font-size: 1.5rem;
    }
`

export const FillBtn = styled(Button).attrs({
    type: "submit"
})`
    height: 40px;
    border-radius: 23px;
    border: ${props => props.variant === "outline" ? "1px solid rgba(17, 115, 171, 1)" : "none"};
    background: ${props => props.variant === "outline" ? "" : "rgba(17, 115, 171, 1)"};
    color: ${props => props.variant === "outline" ? "black" : "white"};
    width: ${props => props.variant === "outline" ? "80%" : "42%"};
    margin-bottom: 9px;
    @media screen and (max-width: 600px){
        height: 56px;
        border-radius: 25px;
    }
`

export const SpecSpan = styled(Link)`
    text-align: ${props => props.align || 'center'};
    font-size: 0.8rem;
    text-decoration: none;
    color: black;
    display: flex;
    gap: 3%;
    margin: auto;
    width: ${({ wt }) => wt === "wt" ? "90%" : ""};
    align-items: center;
    padding-bottom: ${({sel}) => !sel?"3px":""};
    @media screen and (max-width: 600px) {
        font-size: 1.5rem;
    }
    @media screen and (max-width: 383px) {
        font-size: 1.3rem;
    }
`
export const StrLink = styled(Link)`
    font-size: 1rem;
    text-decoration: none;
    margin: auto;
    @media screen and (max-width: 600px) {
        font-size: 1.8rem;
    }
`

export const UserContainer = styled.div`
    margin: auto;
    width: 26%;
    margin-top: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(217, 217, 217, 1);
    padding: 0.7%;
    border-radius: 3px;
    @media screen and (max-width: 955px) {
    width: 32%;
    }
    @media screen and (max-width: 774px) {
    width: 40%;
    }
    @media screen and (max-width: 620px) {
    width: 50%;
    }
    @media screen and (max-width: 600px){
        width: 80%;
        height: 69vh;
    }
    /* @media screen and (max-width: 495px) {
    width: 60%;
    }
    @media screen and (max-width: 412px) {
    width: 70%;
    }
    @media screen and (max-width: 354px) {
    width: 85%;
    } */
`

export const UserP = styled.p`
    font-size: 16px;
    width: 74%;
    margin: auto;
    margin-bottom: 23px;
    text-align: center;
    @media screen and (max-width: 600px){
        font-size: ${props => props.sz || '2rem'};
    }
`
export const RedText = styled.p`
    color: ${({rs}) => rs? 'rgba(17, 115, 171, 1)':'red'};
    cursor: ${({rs}) => rs? 'pointer':''};
`

export const UserSection = styled.section`
    display: flex;
    gap: 2%;
    width:100%;
`

export const UserImage = styled.img`
    height: 110px;
    width: 110px;
    align-self: center;
    @media screen and (max-width: 600px){
        height: 24vh;
        width: 48%;
    }
`

export const SpecBtn = styled.button`
    width: 63%;
    margin: auto;
    height: 43px;
    margin-bottom: 17px;
    justify-content: space-between;
    border: 1px solid rgba(17, 115, 171, 1);
    font-weight: 700;
    text-align: center;
    border-radius: 16px;
    background: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
    @media screen and (max-width: 600px){
        width: 81%;
        height: 68px;
        border-radius: 25px;
    }
    @media screen and (max-width: 434px){
        width: 92%;
    }
`

export const RowContainer = styled.div`
    display: flex;
    padding: 2rem 0;
    /* margin-top: 2.5rem; */
    justify-content: space-evenly;
    background: #f7f7f6;
    @media screen and (max-width: 600px){
        background: white;
    }
`
export const ColumnContainer = styled.section`
    display: flex;
    flex-direction: column;
    width: 60%;
    height: ${({ht}) => ht? 'max-content': '700px'};
    background: white;
    @media screen and (max-width: 780px){
        width: 70%;
    }
    @media screen and (max-width: 600px){
        width: 83%;
    }
`

export const ProfileButton = styled.button`
    color: white;
    background: rgba(222, 24, 24, 1);
    border: none;
    border-radius: 6px;
    margin-top: 1rem;
    width: 20%;
    height: 40px;
    text-align: center;
    padding: 3px;
    align-self: center;
`
export const LoginG = styled.div`
    &.login {
        border: 1px solid rgba(17, 115, 171, 1);
        border-radius: 4px;
        background: transparent;
        cursor: pointer;
        box-shadow: none;
    }
`
export const GooLog = styled(GoogleButton)`
        border: 1px solid rgba(17, 115, 171, 1);
        border-radius: 8px;
        background: transparent;
        cursor: pointer;
        box-shadow: none;
       
`
export const LoaderPrimary = styled.div`
    height: 0.2rem;
    width: 100%;
    display: flex;
    background: rgba(238, 238, 238, 1);
`

export const LoaderSecondary = styled.div`
    height: 0.2rem;
    align-self: flex-start;
    width: ${({ loadwt }) => loadwt};
    background: rgba(17, 115, 171, 1);
`

export const MinorWrap = styled.div`
    position: relative;
    height: 40px;
    display: flex;
    margin-bottom: 4rem;
    @media screen and (max-width: 600px){
        height: 60px;
    }
`

export const ChoiceWrapper = styled.section`
    display: flex;
    flex-direction: column;
    gap: 5rem;
    padding: 8%;
    align-items: center;
`
export const ChoiceContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: auto;
    justify-content: space-between;
    @media screen and (max-width: 655px){
        flex-direction: column;
        margin: auto;
        gap: 2rem;
        align-items: center;
        justify-content: center;
    }
    /* @media screen and (max-width: 1243px){
        gap: 9%;
    }
    @media screen and (max-width: 1204px){
        gap: 9%;
    } */
`
export const ChoiceButton = styled.button`
    height: 16rem;
    width: 24rem;
    border: none;
    background: transparent;
`

export const ChoiceImg = styled.img`
    height: 16rem;
    width: 24rem;
`

export const Terms = styled.p`
    text-align: center;
`

export const TermLink = styled(Link)``