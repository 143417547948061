import React from 'react'
import { ProfileText, ProfileWrapper } from '../style'
// import { useParams } from 'react-router-dom'

const Tuts = () => {
    // const {who} = useParams();
  return (
    <ProfileWrapper>
        <ProfileText>None</ProfileText>
    </ProfileWrapper>
  )
}

export default Tuts;