import { SearchWrapper, Spinner, SearchInput, SearchIcon, Notify, DropDownColumn, DropDownTab, DropDownText } from "./style";
import glass from '../../assets/icons/search.svg'
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom" 
import { useDispatch, useSelector } from "react-redux"; 
import { extras, click } from "../../features/getSlice/get";
import { clear, searchData, setAlert } from "../../features/searchSlice/search";
import { getHandle, handleRequest, randomcodegenerator, ShortenStr } from "../utility/drag_drop";


const SearchBar = ({wt, role, loc, filter, mb}) => {
    const [search, setSearch] = useState({
        hist: ""
    });
    const [suggestions, setSuggestions] = useState({
        status: false,
        val: []
    });
    const[note, setNote]=useState(false);
    let val;
    const searchBarRef = useRef(null);
    const[notify, setNotify]=useState(false);
    const user = useSelector((state) => state.signin.user);
    const page = useSelector((state) => state.page.currentPage);
    const sech = useSelector((state) => state.search);
    const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`
    const navigate = useNavigate();
    const urlF = `${process.env.REACT_APP_BASE_APP_URL}/role/search_${filter}?search=${search.hist}`
    const dispatch = useDispatch()
    const config = {
        "Content-Type": "application/json",
        "Authorization": user.tokens? `JWT ${user.tokens.access}` : null
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        // dispatch(click())
        setSuggestions({status: false, val: []});
        if(search.hist !== ""){
            if(loc === 'find'){
                dispatch(searchData({url: urlF, config: config}));
                dispatch(click(true));
                dispatch(extras({role: filter, address: search.val}));
            }else if(page === '/buy'){
                navigate(`/search/${search.hist}`);
            }else if (page === '/rent'){
                navigate(`/search/${search.hist}`);
            }else {
                navigate(`/search/${search.hist}`);
            }
        } else {
            setNotify(true);
            setTimeout(() => {
                setNotify(false);
            }, 3000);
        }
    }
    const handleEnter = (e) => {
        if(e.key === 'Enter'){
            setSuggestions({status: false, val: []});
            if(search.hist !== ""){
                if(loc === 'find'){
                    dispatch(searchData({url: urlF, config: config}));
                    dispatch(click(true));
                    dispatch(extras({role: filter, address: search.hist}));
                }else if(page === '/buy'){
                    navigate(`/search/${search.hist}`);
                }else if (page === '/rent'){
                    navigate(`/search/${search.hist}`);
                }else {
                    navigate(`/search/${search.hist}`);
                }
            } else {
                setNotify(true);
                setTimeout(() => {
                    setNotify(false);
                }, 3000);
            }
        }else {
            
        }
    }

    function view(item){
        const newId = randomcodegenerator(item);
        setSuggestions({status: false, val: []});
        navigate(`/view/${newId}`);
    }

    useEffect(() => {
        if(['/sell', '/buy', '/'].includes(page)){
            if(!sech.loading && sech.status === 200 && sech.data.length === 0){
                setNote(true);
                setTimeout(() => {
                    setNote(false);
                }, 3000);
                dispatch(clear());
            }
            if(!sech.loading && sech.error === 'Network error'){
                dispatch(setAlert({alert: true, message: "You're not connected to a network"}));
                dispatch(clear());
            }
        }
    }, [sech.loading])

    async function lookUps(text){
        setSuggestions({...suggestions, status: true});
        const rdata = await getHandle({url: `${process.env.REACT_APP_BASE_APP_URL}/property/suggest/?filter=${text}`, config: config})
        const response = handleRequest(rdata, dispatch, config, user?.tokens.refresh)
        if(response === "success"){
            setSuggestions({...suggestions, status: true, val: rdata.data});
        }
    }

    const handleChange =  (event) => {
        const target = event.target;
        setSearch({ ...search, [target.name]: target.value });
        lookUps(target.value);
    }

    const handleClickOutside = (event) => {
        if (!searchBarRef.current.contains(event.target) && suggestions.status && val > 0){
            setSuggestions({status: false, val: []});
            val = 0
        } else 
            val = 1
      };
  
    useEffect(() => {
      // Function to handle click outside of search bar and dropdown
      
      val = 0
      // Attach event listener to document
      if(suggestions.status && !filter){document.addEventListener('click', handleClickOutside)};
  
      // Cleanup: Remove event listener when component unmounts
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [suggestions.status]);

    return (
        <SearchWrapper wt={wt} mb={mb} ref={searchBarRef} >
            {!filter?
                <>
                {!sech.loading?
                    <SearchIcon src={glass} alt="search" role={role} onClick={handleSubmit} />
                    :
                    <>
                    {['/', '/buy', '/rent'].includes(page)?
                        <Spinner size={13} loading={true} color={"rgba(17, 115, 171, 1)"}/>
                        :
                        <SearchIcon src={glass} alt="search" role={role} onClick={handleSubmit} />
                    }
                    </>
                }
                </>
                :
                <SearchIcon src={glass} alt="search" role={role} onClick={handleSubmit} />
            }
            {/* onBlur={() => setSuggestions({status: false, val: []})} */}
            <SearchInput placeholder="Enter an address, neighborhood or state" name="hist" role={role} onKeyDown={handleEnter} onChange={handleChange}  autoComplete="off" />
            {!filter && notify? <Notify className="alert">Please add an item to search for</Notify>: <></>}
            {!filter && note? <Notify className="not" >No properties found</Notify>: <></>}
            {!filter && suggestions.status &&
                <> 
                    {suggestions.val && suggestions.val.length > 0?
                        <DropDownColumn>
                            {suggestions.val.map((item, index) => (
                                <DropDownTab key={index} onClick={() => view(item.id)}>
                                    <DropDownText>{ShortenStr(`${item.address} in ${item.state}`, 45)}</DropDownText>
                                </DropDownTab>
                        ))}
                        </DropDownColumn>
                        :
                        <DropDownColumn>
                            <DropDownText>Not found....</DropDownText>
                        </DropDownColumn>
                    }
                </>}
        </SearchWrapper>
    )
}

export default SearchBar;