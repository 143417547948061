import { useEffect, useState } from "react";
import { FormWrapper, FormContainer, FormHead, Spinner, FormLabel, FormInput, FormArea, FormButton, FormP, FormSpan, SmallText } from "./style";
import { errorClear, postData, successClear } from "../../features/postSlice/post";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { store } from "../../app/store";
import { signoutRequest } from "../../features/purge/purge";
import Cookies from 'js-cookie'
import { setAlert } from "../../features/searchSlice/search";
import { copyToClipboard } from "../utility/drag_drop";




const ContactForm = ({name, address, phone, site, date, email, wt, todo, role}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const url = `${process.env.REACT_APP_BASE_APP_URL}/user/contact/${role}/`
    const user = useSelector((state) => state.signin.user);
    const loading = useSelector((state) => state.post.loading);
    const msg = useSelector((state) => state.post);
    const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`
    const config = {
        'Content-Type':'application/json',
        'Authorization': `JWT ${user && user.tokens? user.tokens.access: null}` 
    }
    const[details, setDetails] = useState({
        name: '',
        phone_no: '',
        email: '',
        message: '',
        recipient: email
    })
    const handleSubmit = (event) => {
        event.preventDefault();
        if(user && user.tokens){
            dispatch(postData({url: url, data: details, config: config }));
        } else {
            dispatch(setAlert({alert: true, message: "You must be signed in"}))
            navigate("/sign in");
        }
        
    }
    const handleChange = (event) => {
        const target = event.target
        setDetails({...details, [target.name]: target.value})
    }


    useEffect(() => {
        if(msg.data.message === "Email Sent"){
            dispatch(setAlert({alert: true, message: 'Message sent', color: '#90EE90'}));
            dispatch(errorClear());   
            dispatch(successClear());
        if(msg.status === 400){
            dispatch(setAlert({alert: true, message: msg.error}));
            dispatch(errorClear());
        }else if (msg.status === 401){
            dispatch(setAlert({alert: true, message: 'Session has expired'}));
            dispatch(postData({url: lout, config: {"Content-Type": "application/json"}, data: user.tokens}));
            dispatch(errorClear());
            Cookies.remove("data");
            store.dispatch(signoutRequest());
        }else if(msg.error !== ""){
            dispatch(setAlert({alert: true, message: 'Network Error'}));
            dispatch(errorClear());
        }
        }
    }, [loading])
    return(
        <FormWrapper wt={wt}>
            <FormContainer onSubmit={handleSubmit}>
            <FormHead>Contact{" " + name}</FormHead>
            <FormLabel for="name">Name</FormLabel>
            <FormInput variant="text" name="name" id="name" onChange={handleChange} required />
            <FormLabel for="number">Phone no.</FormLabel>
            <FormInput variant="number" name="phone_no" id="phone" no='true' onChange={handleChange} required />
            <FormLabel for="email">Email</FormLabel>
            <FormInput variant="email" name="email" id="email" onChange={handleChange} required />
            <FormLabel for="message">Message<FormSpan>(optional)</FormSpan></FormLabel>
            <FormArea id="message" name="message" onChange={handleChange}></FormArea>
            <FormButton variant="blue" type="submit">
                {
                loading? 
                    <Spinner
                        color={"white"}
                        loading={true}
                        size={25}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        />
                    :todo? 
                        todo
                        :"Contact"}</FormButton>
            </FormContainer>
            {phone ?
                <FormContainer>
                    <FormHead>Professional Information</FormHead>
                    <FormP>Broker address: {address}</FormP>
                    <FormP onClick={() => copyToClipboard(phone)}>
                        <SmallText>click to copy</SmallText>
                        <FormP>Cell phone: {phone}</FormP>
                    </FormP>
                    <FormP>Member since: {date}</FormP>
                    <FormButton>Report this agent</FormButton>
                </FormContainer>
                :
                <></>
            }
        </FormWrapper>
    )
} 

export default ContactForm