import { useDispatch, useSelector } from "react-redux";
import { FormButton, Icons, FormDrag, FormHeader, FormInput, FormLabel, FormText, FormWrapper, FormOption, FormSelect, ProgressBar, ProgressBorder, FormStrong, FormP, FormSpan } from "../style"
import { ListDrag, ListIcon, ImgCount } from '../../../pages/miscellaneous/list/style'
import image from "../../../assets/icons/images.svg";
import doc from '../../../assets/icons/docs.svg'
import { useNavigate, useParams } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { fornext } from "../../../features/pageSlice/page";
import { role } from "../../../features/registerSlice/rdataSlice";
import { handleDragLeave, handleDragOver } from "../../utility/drag_drop";
import { ConfirmState } from "../../utility/drag_drop";
import { setAlert } from "../../../features/searchSlice/search";



const Start = () => {
    const val = useSelector((state) => state.data.role);
    const[reg, setReg] = useState({
        profile_photo: val.profile_photo,
        about_you: val.about_you,
        specialties: val.specialties,
        phone_no: val.phone_no,
        company_name: val.company_name,
        additional_services: val.additional_services,
        price_per_day: val.price_per_day,
        is_security: val.is_security,
        iphotos: val.iphotos
    })
    let { roles } = useParams();
    const navigate = useNavigate();
    const handleChange = (e) => {
        e.preventDefault()
        const target = e.target
        if(target.name === "phone_no" && target.value.startsWith("0")){
            let newValue = target.value.toString().replace("0", "+234")
            setReg({...reg, [target.name]: newValue})    
        }else {
            setReg({...reg, [target.name]: target.value})
        }
        
    }
    const fileInputRef = useRef(null);

    const handleUploadButtonClick = () => {
        fileInputRef.current.click(); // Trigger the file input click event
    };
      
    function uploadImg(val){
        dispatch(fornext({id: val, nextPage: `/register/${roles}/i`}))
        navigate("/uppath");
    }

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0]
        const prof = window.URL.createObjectURL(file)
        const target = event.target
        setReg({...reg, [target.getAttribute('data-name')]: prof})
    }
      
    const handleFileInputChange = (event) => {
        const prof = window.URL.createObjectURL(event.target.files[0])
        const target = event.target
        setReg({...reg, [target.name]: prof})
    }

    const dispatch = useDispatch();

    useEffect(() => {
        if(reg.profile_photo !== null || reg.about_you !== ""  || reg.additional_services !== ""  || reg.company_name !== ""  || reg.is_security !== ""  || reg.phone_no !== ""  || reg.price_per_day !== ""  || reg.specialties !==""){
            dispatch(role(reg));
        }
    }, [reg])

    const handleSubmit = (e) => {
        e.preventDefault();
        const keys = ["an Event center"].includes(roles)? ['profile_photo', 'company_name', 'phone_no'] : ["an Agent"].includes(roles)? ['profile_photo', 'about_you', 'specialties'] : ["a Short-let"].includes(roles)? ['company_name', 'profile_photo'] : ['profile_photo', 'about_you']
        if(ConfirmState(keys, val).valid){
            if(!['an Agent', 'a Landlord'].includes(roles)){
                navigate(`/register/${roles}/iv`);
            } else {
                navigate(`/register/${roles}/ii`);
            }
    
        }else { 
            dispatch(setAlert({alert: true, message: `${ConfirmState(keys, val).key} is missing`}));
          }
    }
    return(
        <FormWrapper>
            <FormHeader>Register as {roles}</FormHeader>
            <ProgressBorder>
                <ProgressBar wt="40%"></ProgressBar>
            </ProgressBorder>
            {["an Event center"].includes(roles)?
                <>
                <FormLabel><FormStrong>Company logo</FormStrong><FormP>*image must be clear</FormP></FormLabel>
                <FormDrag  onDragLeave={handleDragLeave} data-name="profile_photo" onDragOver={handleDragOver} onDrop={handleDrop} onClick={handleUploadButtonClick} >
                <Icons src={reg.profile_photo != null? reg.profile_photo : image} upP={reg.profile_photo != null ? true : false} alt="drop image"/>
                <FormInput type="file" onChange={handleFileInputChange} ref={fileInputRef} name="profile_photo" none />
                </FormDrag>
                <FormLabel for="company_name"><FormStrong>Company Name</FormStrong></FormLabel>
                <FormInput type="text" placeholder={val.company_name? val.company_name : "Eg. XYZ Corps"} onChange={handleChange} name="company_name" id="company_name" />
                <FormLabel for="phone_no"><FormStrong>Phone Number</FormStrong></FormLabel>
                <FormInput type="tel" placeholder={val.phone_no? val.phone_no : "+234749...."} onChange={handleChange} name="phone_no" id="phone_no" />
                <FormLabel for="email"><FormStrong>Email Address</FormStrong></FormLabel>
                <FormInput type="email" placeholder={val.email? val.email : "johnson@james.com"} name="email" onChange={handleChange} id="" required />
                <FormLabel for="price_per_day"><FormStrong>Price per day</FormStrong></FormLabel>
                <FormInput type="text" placeholder={val.price_per_day? val.price_per_day : "500,000 NGN"} onChange={handleChange} name="price_per_day" id="price_per_day" />
                <FormLabel for="additional"><FormStrong>Additional Services</FormStrong></FormLabel>
                <FormInput type="text" placeholder={val.additional_services? val.additional_services : "500,000 NGN"} onChange={handleChange} name="additional_services" id="additional" />
                <FormLabel for="security"> <FormStrong>Is There Security?</FormStrong></FormLabel>
                <FormSelect name='is_security' id="security" onChange={handleChange} defaultValue={"Select desired option"}>
                    <FormOption disabled value="Select desired option">Select desired option</FormOption>
                    <FormOption value="Yes">Yes</FormOption>
                    <FormOption value="No">No</FormOption>
                </FormSelect>
                </>
                :
                <>
                    {["a Short-let"].includes(roles)?
                        <>
                        <FormLabel><FormStrong>Company name</FormStrong></FormLabel>
                        <FormInput name="company_name" onChange={handleChange} placeholder={ val.company_name? val.company_name : "Eg. XYZ Corps"} />
                        <FormLabel><FormStrong>{['a Hotel', 'a Bank'].includes(roles)? `${roles.slice(2, roles.length)} logo`: "Professional profile photo"}</FormStrong><FormP>*image must be clear</FormP></FormLabel>
                        <FormDrag  onDragLeave={handleDragLeave} data-name="profile_photo" onDragOver={handleDragOver} onDrop={handleDrop} onClick={handleUploadButtonClick} >
                        <Icons src={reg.profile_photo != null? reg.profile_photo : image} upP={reg.profile_photo != null ? true : false} alt="drop image"/>
                        <FormInput type="file" onChange={handleFileInputChange} ref={fileInputRef} name="profile_photo" none />
                        </FormDrag>
                        </>
                        :
                        <>
                            <FormLabel><FormStrong>{['a Hotel', 'a Bank'].includes(roles)? `${roles.slice(2, roles.length)} logo`: "Professional profile photo"}</FormStrong><FormP>*image must be clear</FormP></FormLabel>
                            <FormDrag  onDragLeave={handleDragLeave} data-name="profile_photo" onDragOver={handleDragOver} onDrop={handleDrop} onClick={handleUploadButtonClick} >
                            <Icons src={reg.profile_photo != null? reg.profile_photo : image} upP={reg.profile_photo != null ? true : false} alt="drop image"/>
                            <FormInput type="file" onChange={handleFileInputChange} ref={fileInputRef} name="profile_photo" accept="image/*" none />
                            </FormDrag>
                            <FormLabel><FormStrong>{['a Hotel'].includes(roles)? `About the ${roles.slice(2, roles.length)}`: "About Yourself"}</FormStrong><FormP>*(What Makes You Different As An Agent)</FormP></FormLabel>
                            <FormText name="about_you" onChange={handleChange} placeholder={ val.about_you? val.about_you : "I've been an agent for 30 yrs and...."} />
                            {['a Hotel'].includes(roles) &&
                            <>
                                <FormLabel for='iphotos'><FormStrong>Photos of the Hotel</FormStrong><FormP>*image must be clear</FormP></FormLabel>
                                <ListDrag  data-name="iphotos"  onClick={reg.iphotos.length > 0 ? () => navigate('/preview') : () => uploadImg(8)} >
                                    <ListIcon src={reg.iphotos.length > 0? doc: image} alt="drag and drop"/>
                                    {reg.iphotos.length > 0 && 
                                    <ImgCount> {reg.iphotos.length}</ImgCount>
                                    
                                    // <UButton>Upload</UButton>
                                    }
                                </ListDrag>
                            </>
                            }
                        </>
                    }
                </>
            }
            
            {roles ==="an Agent" &&
                <>
                <FormLabel><FormStrong>Specialties</FormStrong></FormLabel>
                <FormInput type="text" onChange={handleChange} name="specialties" placeholder={val.specialties? val.specialties : "e.g Buyers agent, 3 years housing agent"} spec />
                </>
            }
            <FormSpan><FormButton left="left" onClick={(e) => e.preventDefault()}>Back</FormButton><FormButton onClick={handleSubmit}>Next</FormButton></FormSpan>
        </FormWrapper>
    )
}

export default Start;