import { useEffect, useState, useRef } from "react";
import { FormButton, FormHeader, FormInput, FormLabel, FormStrong, FormDrag, Spinner, Icons, VariantFormWrapper, ProgressBar, ProgressBorder, FormSpan, FormP, Warn } from "../style"
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { role } from "../../../features/registerSlice/rdataSlice";
import { ConfirmState, handleDragLeave, handleDragOver } from "../../utility/drag_drop";
import image from "../../../assets/icons/images.svg";
import { setAlert } from "../../../features/searchSlice/search";




const Mid = () => {
    let { roles } = useParams();
    const val = useSelector((state) => state.data.role);
    const single = useSelector((state) => state.single);
    const [check, setCheck] = useState({
        email: false,
        no: false
    });
    const navigate = useNavigate();
    const[reg, setReg]=useState({
        name: val.name,
        email: val.email,
        phone_no: val.phone_no,
        company_id: val.company_id,
        company_name: val.company_name,
        company_reg: val.company_reg
    });

    const handleChange = (e) => {
        e.preventDefault();
        const target = e.target
        if(target.name === "phone_no" && target.value.startsWith("0")){
            let newValue = target.value.toString().replace("0", "+234")
            setReg({...reg, [target.name]: newValue})    
        }else {
            setCheck({...check, email: false});
            setReg({...reg, [target.name]: target.value});
        }
    }

    const fileInputRef = useRef(null);

    const handleUploadButtonClick = () => {
        fileInputRef.current.click(); // Trigger the file input click event
    };
 
    const handleFileInputChange = (event) => {
        console.log(event.target.files[0])
        const prof = window.URL.createObjectURL(event.target.files[0])
        const target = event.target
        setReg({...reg, [target.name]: prof})
    }

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0]
        const prof = window.URL.createObjectURL(file)
        const target = event.target
        setReg({...reg, [target.getAttribute('data-name')]: prof})
    }

    const dispatch = useDispatch();

    useEffect(() => {
        if(reg.email !== "" || reg.name !== "" || reg.phone_no !== "" || reg.company_id !== null || reg.company_name !== "" || reg.company_reg !== ""){
            dispatch(role(reg));
        }
    }, [reg])

    const handleSubmit = (event) => {
        event.preventDefault();
        const keys = ['an Agent'].includes(roles)? ['email', 'phone_no', 'company_id', 'company_name', 'company_reg'] : ['email', 'phone_no']
        if(ConfirmState(keys, val).valid){
            if(val.phone_no.length > 14){
                setCheck({...check, no: true});
            }  else {
                navigate(`/register/${roles}/iii`);
            }
        }else { 
            dispatch(setAlert({alert: true, message: `${ConfirmState(keys, val).key} is missing`}));
          }
    }

    function Back(e){
        e.preventDefault();
        navigate(-1)
    }
    // function AddProp(id, event){
    //     const target = event.target;
    //     const newIndex = parseInt(target.getAttribute('data-key'));
    //     const props = document.querySelectorAll(".prop")
    //     console.log("checks:", newIndex, props)
    //     const updatedProperties = reg.properties.includes(id)
    //     ? reg.properties.filter(prop => prop !== id)
    //     : [...reg.properties, id];

    //     setReg({ ...reg, properties: updatedProperties });

    //     props[newIndex].textContent = updatedProperties.includes(id) ? "-" : "+";
    // }
    return(
        <VariantFormWrapper>
            <FormHeader>Register as {roles} </FormHeader>
            <ProgressBorder>
                <ProgressBar wt="60%"></ProgressBar>
            </ProgressBorder>
            <FormLabel for="email"><FormStrong>Email Address</FormStrong></FormLabel>
            <FormInput type="email" placeholder={val.email? val.email : "johnson@james.com"} name="email" onChange={handleChange} id="" required />
            <FormLabel for="phone_no"><FormStrong>Phone Number</FormStrong></FormLabel>
            <FormInput type="tel" placeholder={val.phone_no? val.phone_no : "+234749...."} onChange={handleChange} name="phone_no" id="phone_no" required />
            {check.no? <Warn>phone number is incorrect</Warn>:<></>}
            {['an Agent'].includes(roles) &&
            <>
            <FormLabel for='company_id'><FormStrong>Company ID</FormStrong><FormP>*image must be clear</FormP></FormLabel>
            <FormDrag onDragLeave={handleDragLeave} data-name="company_id" onDragOver={handleDragOver} onDrop={handleDrop} onClick={handleUploadButtonClick}>
                <Icons src={reg.company_id != null? reg.company_id : image} upP={reg.company_id != null ? true : false} alt="drop image"/>
                <FormInput type="file" onChange={handleFileInputChange} ref={fileInputRef} name="company_id" none accept="image/*" />
            </FormDrag>
            <FormLabel for="company_name"><FormStrong>Company Name</FormStrong></FormLabel>
            <FormInput type="text" placeholder={val.company_name? val.company_name : "Eg. XYZ Corps"} onChange={handleChange} name="company_name" id="company_name" required />
            <FormLabel for="company_reg"><FormStrong>Company Reg. Number</FormStrong></FormLabel>
            <FormInput type="text" placeholder={val.company_reg? val.company_reg : "E.g CRN1234567890"} onChange={handleChange} name="company_reg" id="company_reg" required />
            </>
            }
            {/* <FormLabel for="listed"><FormStrong>Listed properties on Pinearth</FormStrong></FormLabel>
            {data && data.length > 0? 
              data.map((item, index) => (
                  <ListedProp>
                      <ListedImg src={replaceStr(item.house_view[0].house_view)} alt={item.title} />
                      <ListSpan>
                          <ListText color={"#FFC200"}>{item.title}</ListText>
                          <ListText>{ShortenStr(item.address, 17)}</ListText>
                      </ListSpan>
                      <AddRem onClick={(e) => AddProp(item.id, e)} data-key={index} className="prop">+</AddRem>
                  </ListedProp>
             ))
            :
             <FormP>You don't have any listed properties</FormP>
            } */}
            <FormSpan><FormButton left onClick={Back} >Back</FormButton><FormButton onClick={handleSubmit}>
            {single.loading?
            <Spinner
           color={"white"}
           loading={true}
           size={25}
           aria-label="Loading Spinner"
           data-testid="loader"
           />:"Next"}</FormButton></FormSpan>
        </VariantFormWrapper>
    )
}

export default Mid;