import { CardContainer, CardDisplay, DisplayBtn, LowerButton, Variant, VarLower, FadedBtn, DigitSpan, TopIcon, Icon, CardHeader, CardDescp, LowerDesc, List, CardProfile, PickBtn, ContSpan, FadedWrapper, CardImgDisplay, RowWrap, TagWrapper, SomeText, RowStar } from "./style";
import gallery from "../../../assets/icons/gallery.svg"
import geo from "../../../assets/icons/geo.svg"
import flat from "../../../assets/icons/flat.svg"
import plot from "../../../assets/icons/plot.svg"
import lot from "../../../assets/icons/size.svg"
import room from "../../../assets/icons/rooms.svg"
import size from "../../../assets/icons/size.svg"
import snow from "../../../assets/icons/snow.svg"
import star from "../../../assets/icons/goldStar.svg"
import broken from "../../../assets/broken.svg"
import pinlogo from "../../../assets/pinlogo.png"
import { useSelector } from "react-redux";
import { addCommasToNumber, ShortenStr } from "../../utility/drag_drop";



const DummyCard = ({ data }) => {
    const user = useSelector((state) => state.signin.user);
    const profiledata = useSelector((state) => state.profile.profiledata);
    return (
        <CardContainer key={data.id} single="true" >
           <CardDisplay single="true">
              <CardImgDisplay 
                src=
                {
                  ['Hotel'].includes(user.role)? 
                    data.ubed_room.length > 0 ? 
                      data.ubed_room[0]
                    : broken 
                  : ['Land'].includes(data.property_type)? 
                      data.uland_image.length > 0 ? 
                        data.uland_image[0]
                      : broken 
                    : data.uhouse_view.length > 0? 
                        data.uhouse_view[0]
                        : broken} 
              />
              <FadedWrapper pos={['Short-let', 'Developer'].includes(user.role)? 'end' : 'space-between'}>
                {!['Short-let', 'Developer'].includes(user.role) && 
                  <RowWrap student={data.student}>
                    {data.student === 'Yes' &&
                      <TagWrapper>
                        <RowStar src={star} />
                        <SomeText>Student Lodge</SomeText>
                      </TagWrapper>
                    }
                    <DisplayBtn>{data.property_status}</DisplayBtn>
                  </RowWrap>
                }
                <ContSpan>
                  <FadedBtn>
                        <TopIcon src={gallery} alt="images"/>
                    <DigitSpan>
                      {data.uhouse_view.length + data.uliving_room.length + data.ubed_room.length + data.utoilet.length + data.ukitchen.length + data.udocuments.length + data.usize.length + data.uhouse_plan.length}
                    </DigitSpan>
                    </FadedBtn>
                  <FadedBtn><TopIcon src={geo} alt="location"/></FadedBtn>
                </ContSpan>
              </FadedWrapper>
           </CardDisplay>
              <CardHeader cl="rgba(17, 115, 171, 1)">
              {
                ['Room'].includes(data.property_type)?  
                    `NGN${addCommasToNumber(data.property_price)}/ ${data.duration}`
                    : `NGN${addCommasToNumber(data.property_price)}/ ${data.duration}`
                }</CardHeader>
              <CardHeader>{ShortenStr(`${data.title} in ${['Room'].includes(data.property_type)? profiledata.company_name : data.address}`, 28)}</CardHeader>
              <CardDescp left="3%" post="left">{data.desc}</CardDescp>
              <LowerDesc  ht={['Room'].includes(data.property_type)? true: false}>
              {['Room'].includes(data.property_type)?
                  <>
                    {data.aircon === "Yes" && <List width="47%"><Variant><Icon src={snow}/>air conditioner</Variant></List>}
                    {data.heater === "Yes" && <List width="47%"><Variant><Icon src={lot}/>water heater</Variant></List>}
                  </>
                  :
                  ['Land'].includes(data.property_status)?
                  <>
                    <List width="47%"><Variant><Icon src={plot}/>{data.no_of_plots + " "}plots</Variant></List>
                    <List width="47%"><Variant><Icon src={lot}/>{data.lot_size + " "}sqr feet</Variant></List>
                  </>
                  :
                  <>
                  <List><Variant><Icon src={flat}/>{data.no_of_rooms + " "}bedroom flat</Variant></List>
                  <List><Variant><Icon src={room}/>{data.no_of_bathrooms + " "}bathrooms</Variant></List>
                  <List><Variant><Icon src={size}/>{data.lot_size + " "}sqr ft</Variant></List>
                </>
              }
              </LowerDesc>
              <VarLower>
                <List width="13%"><CardProfile src={profiledata?.profile_photo || pinlogo}/></List>
                <List width="45%" size="10px">
                  <CardDescp post="left" top="0.6rem" color="rgba(17, 115, 171, 1)" >Business</CardDescp>
                  <CardDescp post="left" top="-2.3rem" size="0.9rem">{profiledata? ShortenStr(profiledata?.company_name, 11) : "add to profile"}</CardDescp>
                </List>
                <List><PickBtn>{data.property_status === "For rent"? "Rent it" : data.property_status === "Room"? "View" : "Buy it"}</PickBtn></List>
              </VarLower>
        </CardContainer>
    )
}

export default DummyCard;