import styled from 'styled-components';



export const ToggleWrap = styled.label`
    position: relative;
    display: inline-block;
    width: 4.5rem;
    height: 2.2rem;
`
export const ToggleSpan = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
    &::before {
        position: absolute;
        content: "";
        height: 1.98rem;
        width: 45%;
        left: 5%;
        bottom: 5%;
        background: url(${({up}) => up});
        background-size: 2rem 2rem;
        background-repeat: no-repeat; 
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
    }
`
export const ToggleInput = styled.input`
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + ${ToggleSpan}{

        &::before{
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
        }
    }    
`
export const ToggleImg = styled.img`
    height: 20px;
    z-index: 1;
`
