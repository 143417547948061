import { Button, DragWrapper, VarIcon, Header, VariantW, Preview, TinyRow, GrayHeader } from "../style"
import cancel from '../../../assets/icons/cancel.svg';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { listAdd, role, update } from "../../../features/registerSlice/rdataSlice";



const ViewDel = () => {
    const val = useSelector((state) => state.data.list);
    const roleval = useSelector((state) => state.data.role);
    const user = useSelector((state) => state.data.user);
    const items = useSelector((state) => state.page)
    const dispatch = useDispatch();
    const [data, setData] = useState([])
    const navigate = useNavigate();
    let idata;
    useEffect(() => {
        switch (items.id){
            case 0:
                idata = val.uhouse_view
                break;
            case 1:
                idata = val.uliving_room
                break;
            case 2:
                idata = val.ubed_room
                break;
            case 3:
                idata = val.utoilet
                break;
            case 4:
                idata = val.ukitchen
                break;
            case 5:
                idata = val.udocuments
                break;
            case 6:
                idata = val.uhouse_plan
                break;
            case 7:
                idata = val.usize
                break;
            case 8:
                idata = roleval.iphotos
                break;
            case 9:
                idata = val.uland_image
                break;
            case 10:
                idata = [user["profile.upload_id"]]
                break;
            default:
                console.error(`Unexpected media: ${items.id}`);
        }
        setData(idata);
    }, [])
    
    function del(src){
        switch (items.id){
            case 0:
                dispatch(listAdd({"uhouse_view": val.uhouse_view.filter(prop => prop !== src)})) 
                idata = val.uhouse_view
                break;
            case 1:
                dispatch(listAdd({"uliving_room" : val.uliving_room.filter(prop => prop !== src)}));
                idata = val.uliving_room
                break;
            case 2:
                dispatch(listAdd({"ubed_room": val.ubed_room.filter(prop => prop !== src)}));
                idata = val.ubed_room
                break;
            case 3:
                dispatch(listAdd({"utoilet": val.utoilet.filter(prop => prop !== src)}));
                idata = val.utoilet
                break;
            case 4:
                dispatch(listAdd({"ukitchen": val.ukitchen.filter(prop => prop !== src)}));
                idata = val.ukitchen
                break;
            case 5:
                dispatch(listAdd({"udocuments": val.udocuments.filter(prop => prop !== src)}));
                idata = val.udocuments
                break;
            case 6:
                dispatch(listAdd({"uhouse_plan": val.uhouse_plan.filter(prop => prop !== src)}));
                idata = val.uhouse_plan
                break;
            case 7:
                dispatch(listAdd({"usize": val.usize.filter(prop => prop !== src)}));
                idata = val.usize
                break;
            case 8:
                dispatch(role({"iphotos": roleval.iphotos.filter(prop => prop !== src)}));
                idata = roleval.iphotos
                break;
            case 9:
                dispatch(listAdd({"uland_image": val.uland_image.filter(prop => prop !== src)}));
                idata = val.uland_image
                break;
            case 10:
                dispatch(update({"profile.upload_id": null}));
                idata = [user["profile.upload_id"]]
                break
            default:
                console.error(`Unexpected key: ${items.id}`);
        }
    }
    useEffect(() => {
        if(idata?.length === 0 || idata?.length === null){
            setData([]);
        }else{
            setData(idata);
        }
    }, [val, role, user])
    function close(){
        navigate(items.nextPage)
    }
  return (
    <VariantW className="preview">
        <Header>Delete selections</Header>
        <DragWrapper className="display">
            {data && data.length > 0?
                <>
                {data.map((item, index) => (
                    <Preview src={item} key={index}>
                        <VarIcon src={cancel} onClick={() => del(item)} alt="delete" />
                    </Preview>
                ))}
                </>
                :
                <GrayHeader>No Images</GrayHeader>
            }
        </DragWrapper>
        <TinyRow><Button onClick={() => navigate("/uppath")}>Reupload</Button><Button onClick={() => close()}>Back</Button></TinyRow>
    </VariantW>
  )
}

export default ViewDel;