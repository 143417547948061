import { StarWrapper } from "../style"
import Star from "./star/index"



const Rating = ({rating}) => {
    const stars = [ Star, Star, Star, Star, Star]
    const unrated = 5 - rating 
    return (
      <StarWrapper>
        {stars.slice(0, rating).map((Star, index) => (
            <Star fll="rgb(47 133 181)" key={index} />
          ))
        }
        {stars.slice(0, unrated).map((Star, index) => (
            <Star fll="rgba(0, 0, 0, 0.5)" key={index} />
          ))
        }
        {/* <span>({rating? rating:"No ratings"})</span> */}
      </StarWrapper>
   )}

export default Rating;