import React from 'react'
import { ButtonImg, DisplayButton, ProfileContainer } from '../style'
import user from "../../../assets/howuser.svg"
import bus from "../../../assets/howbus.svg"
import { useNavigate } from 'react-router-dom'

const How = () => {
  const navigate = useNavigate();

  return (
    <ProfileContainer>
        <DisplayButton onClick={() => navigate(`tuts/user`)}>
            <ButtonImg src={user} alt="how to use as a user"/>
        </DisplayButton>
        <DisplayButton onClick={() => navigate(`tuts/business`)}>
            <ButtonImg src={bus} alt="how to use as a business"/>
        </DisplayButton>
    </ProfileContainer>
  )
}

export default How