import { 
  UserWrapper, 
  Header, 
  Warn, 
  GooLog, 
  StrLink, 
  FillBtn, 
  Email, 
  Password, 
  SpecSpan, 
  Text, 
  Icon, 
  Label, 
  Date, 
  UserSection, 
  SpecText, RanSpan, MinorWrap, Spinner, 
  Terms,
  TermLink} from "../style";
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import eye from "../../../assets/icons/eye.svg";
import eyes from "../../../assets/icons/eye-slash.svg";
import { update } from "../../../features/registerSlice/rdataSlice";
import { getData, googCheck } from "../../../features/getSlice/get";
import { checkPassword, viewPassword } from "../../../components/utility/drag_drop";
import { setEmail } from "../../../features/pageSlice/page";
import { setAlert } from "../../../features/searchSlice/search";



const SignUp = () => {
  
    const [register, setRegister] = useState(
        {  
          first_name: "",
          middle_name: "",
          last_name: "",
          email: "",
          d_o_b: "",
          password: "",
          password2: ""
        }
        )
      const [warn, setWarn] = useState(false);
      const [check, setCheck] = useState(false);
        const onGoogleLoginSuccess = () => {
          const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
          const redirectUri = 'user/v1/auth/login/google/';
          const apiBaseURL = `${process.env.REACT_APP_BASE_APP_URL}`;
          // Scope indicates the info of the user needed from google, I have
          // only asked for the email, profile picture and full name
          const scope = [
            'https://www.googleapis.com/auth/userinfo.email',
            'https://www.googleapis.com/auth/userinfo.profile'
          ].join(' ');
          const params = {
            response_type: 'code',
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            redirect_uri: `${apiBaseURL}/${redirectUri}`,
            prompt: 'select_account',
            access_type: 'offline',
            scope
          };
          
          const urlParams = new URLSearchParams(params).toString();
          window.location = `${googleAuthUrl}?${urlParams}`;
          };
          const log = () => {
            onGoogleLoginSuccess();
            dispatch(googCheck(true));
          }
        
        const navigate = useNavigate();
        const dispatch = useDispatch();
        const data = useSelector((state) => state.get.data)
        const [show, setShow] = useState(false);
        const [valid, setValid] = useState({
          hasSpecialChar: false,
          hasNumeric: false, 
          hasUppercase: false, 
          isLongEnough: false
        })
        const handleSubmit = (event) => {
          event.preventDefault();
          if(!warn){
            dispatch(update(register));
            dispatch(setEmail(register.email));
          } else { 
            dispatch(setAlert({alert: true, message: "password is too weak"})); 
          }
          dispatch(getData({url: `${process.env.REACT_APP_BASE_APP_URL}/user/info/${register.email}/`, config: {"Content-Type": "application/json"}}))
        }
        useEffect(() => {
          if(data !== null && data.state === "email already exists"){
            setCheck(true)
          }else if(data && data.state === "email is not taken") {
            navigate("/address")
          }
        }, [data])

        const handleChange =  (event) => {
          const {name, value} = event.target;
          if(name === "password"){
            const [hasSpecialChar, hasNumeric, hasUppercase, isLongEnough] = checkPassword(value);
            console.log(value, hasUppercase)
            setValid({
              hasSpecialChar:hasSpecialChar, 
              hasNumeric:hasNumeric, 
              hasUppercase:hasUppercase, 
              isLongEnough:isLongEnough
            })
            if(hasSpecialChar && hasNumeric && hasUppercase && isLongEnough){
              setWarn(false);
              setRegister({
                ...register, [name]: value, password2: value 
              });
              } else {
                  setWarn(true)
              }
          }else if(name === "email"){
            setCheck(false)
            setRegister({
              ...register, [name]: value
          });
          }
          else { setRegister({
              ...register, [name]: value
          });
          }
        }

    return(
        <UserWrapper onSubmit={handleSubmit}>
          <GooLog
          onClick={log}
          type="light"
          label="Sign up with Google"
          style={{border: '1px solid rgba(17, 115, 171, 1)', background: 'transparent', borderRadius: 4, alignSelf: 'center'}}
           />
          <Header sm sz="1.5rem">OR</Header>
          <Header sz="1.5rem">Sign up</Header>
          <Label for="names">Name</Label>
          <UserSection>
          <Text placeholder="First name" name="first_name" className="names" position="right" onChange={handleChange} required />
          <Text placeholder="Middle name" name="middle_name" className="names" position="right" onChange={handleChange} />
          <Text placeholder="Last Name" name="last_name" className="names" onChange={handleChange} required />
          </UserSection>
          <Label for="email">Email address</Label>
          <Email placeholder="Email" name="email" id="email" onChange={handleChange} required />
          {check ? <Warn wr={"email"} warn={"weak"}>email already exists</Warn> : <></>}
          <Label for="date">Date of birth</Label>
          <Date placeholder="date of birth" name="d_o_b" id="date" onChange={handleChange} required />
          <Label for="pass">Password</Label>
          <MinorWrap>
            <Password placeholder="Password" name="password" className="passw" id="pass" onChange={handleChange} warn={warn} required />
            <Icon position="absolute" btm src={show?eyes:eye} alt={show?"hide password":"show password"} onClick={() => {viewPassword("passw"); show? setShow(false):setShow(true)}}/>
          </MinorWrap>
          
          {warn &&  <Warn warn={warn?"weak":"strong"}>{warn?"weak":"strong"}</Warn>}
          <RanSpan>
          {!valid.hasUppercase && <SpecText>*Password must contain Uppercase letters</SpecText>}
          {!valid.hasNumeric && <SpecText>*Password must contain numbers</SpecText>}
          {!valid.hasSpecialChar && <SpecText>*Password must contain special characters</SpecText>}
          {!valid.isLongEnough && <SpecText>*Password must be at least 8 characters long</SpecText>}
          </RanSpan>
          <FillBtn>
            {data.loading?
              <Spinner
                color={"white"}
                loading={true}
                size={25}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              :"Sign up"
            }
           </FillBtn>
          <StrLink to="/confirm" >Forgot password?</StrLink>
          <SpecSpan to="/sign in">Have an account? Sign in</SpecSpan>
          <Terms>By signing up you agree to our <TermLink to="/terms and conditions">Terms of service</TermLink> and <TermLink to="/policy">Privacy Policy</TermLink></Terms>
        </UserWrapper>
    )
}

export default SignUp;