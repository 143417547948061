import axios from "axios";
import { useState, useEffect } from "react";
import { store } from "../app/store";
import { signoutRequest } from "../features/purge/purge";
import Cookies from 'js-cookie'
import { setAlert } from "../features/searchSlice/search";
import { postData } from "../features/postSlice/post";
import { useDispatch, useSelector } from 'react-redux'


export const useFetch = (url, config_extra, loadOnStart = true) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(''); 
  const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`
  const user = useSelector((state) => state.signin);
  const dispatch = useDispatch();
  const config = {
    'Content-Type':'application/json',
    'Authorization': `${user.user && user.user.tokens? 'JWT ' + user.user.tokens.access: null}` 
  }
  useEffect(() => {
    if (loadOnStart) sendRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const request = () => {
    sendRequest();
  };
 
  const sendRequest = () => {
    setLoading(true);

    axios.get(url, 
        {
            headers: config_extra? config_extra : config
        })

    .then((response) => {
      setError('');
      if (response.data !== undefined){
      setData(response.data);}
    })
    .catch((error) => {
        setError(error.message);
        if(error.message === 'Network Error'){
            dispatch(setAlert({alert: true, message: "You're not connected to a network"}));
        }else if (error.response?.status === 401 && user.loggedIn){
            dispatch(setAlert({alert: true, message: 'Session has expired'}));
            dispatch(postData({url: lout, config: config, data: user.tokens}));
            Cookies.remove("data");
            store.dispatch(signoutRequest());
        }else if(error.response){
            dispatch(setAlert({alert: true, message: `${typeof error.response.data === 'object' ? error.response.data.detail : error.response.data.error.error}`}));
        }
    })
    .finally(() => setLoading(false));
  };

  return [loading, data, error, request];
};