import styled from 'styled-components';
import ClipLoader from "react-spinners/ClipLoader";



export const Icons = styled.img`
    align-self: center;
    width: ${({upP}) => upP ? "100%" : ""};
    height: ${({upP}) => upP ? "100%" : ""};
    border-radius: 6px;
    background-size: ${({upP}) => upP ? "cover" : ""};
    filter: ${({upP}) => upP? "rgba(0, 0, 0, 0.6)" : ""};
    /* height: 4rem; */
`
export const FormWrapper = styled.form`
    width: 27%;
    margin: auto;
    text-align: left;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    @media screen and (max-width: 600px){
        width: 63%;   
    }
    @media screen and (max-width: 425px){
        width: 76%;
    }
    @media screen and (max-width: 300px){
        width: 65%;
    }
`
export const VariantFormWrapper = styled.div`

    width: 27%;
    margin: auto;
    text-align: left;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    @media screen and (max-width: 600px){
        width: 70%;   
    }
    @media screen and (max-width: 500px){
        width: 80%;   
    }
    @media screen and (max-width: 300px){
    width: 90%;
}
`

export const FormHeader = styled.h1`
    font-size: 1.8rem;
    margin-top: 2rem;
    text-align: center;
    margin-bottom: ${({reg}) => reg? '8rem' : ''};
    padding: ${({reg}) => reg? '8%' : ''};
    border-bottom: ${({reg}) => reg? '1px solid rgba(17, 115, 171, 1)' : '' };
`

export const SubHeader = styled.h1`
    font-size: 1.7rem;
    margin-top: 2rem;
    color: rgba(17, 115, 171, 1);
    text-align: center; 
`

export const FormLabel = styled.label`
    display: flex;
    @media screen and (max-width: 600px){
        flex-direction: column;
    }
`
export const FormStrong = styled.strong`
    font-size: 1.2rem;
`
export const FormP = styled.p`
    color: red;
    font-size: 12px;
    position: relative;
    left: 1%;
    top: 3px;
`
export const FormDrag = styled.div`
    width: 100%;
    height: 12rem;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    background: rgba(238, 238, 238, 1);
`

export const FormInput = styled.input`
    display: ${props => props.none? "none": "block"};
    width: 100%;
    height: 40px;
    background: rgba(238, 238, 238, 1);
    border: none;
    border-radius: 3px;
    padding: 0 1% 0 2%;
    margin-bottom: ${props => props.spec? "70px":""};
`
export const FormText = styled.textarea`
    width: 92%;
    padding: 5%;
    background: rgba(238, 238, 238, 1);
    border-radius: 2px;
    border: none;
    height: 7rem;
    resize: none;
`
export const FormSpan = styled.span`
    display: flex;
    gap: 30%;
`

export const FormButton = styled.button`

    border: none;
    border-radius: 6px;
    height: 48px;
    width: ${({wt}) => wt? wt : "35%"};
    color: white;
    cursor: pointer;
    text-align: center;
    background: ${props => props.left? "rgba(0, 0, 0, 0.5)": "rgba(17, 115, 171, 1)"};
    align-self: ${({er}) => er?  "center" : "" };
`

export const ProgressBorder = styled.div`
    background: rgba(217, 217, 217, 1);
    border: none;
    border-radius: 2px;
    height: 6px;
    width: 100%;
`

export const ProgressBar = styled.div`
    background: rgba(17, 115, 171, 1);
    border: none;
    border-radius: 2px;
    height: 6px;
    width: ${props => props.wt};
`

export const ListedProp = styled.div`
    display: flex;
    height: 4rem;
    width: 70%;
    align-items: center;
    padding-right: 1%;
    border-radius: 7px;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.5);
`

export const ListedImg = styled.img`
    width: ${({er}) => er? "50%" : "30%"};
    height: ${({er}) => er?  "21rem" : "4rem" };
    align-self: ${({er}) => er?  "center" : "" };
    border-radius: 7px;
`
export const Banners = styled.img`
    width: ${({er}) => er?  "20%" : "70%" };
    align-self: center;
`
export const ListSpan = styled.span`
    display: flex;
    flex-direction: column;
    width: 57%;
`
export const  ListText = styled.p`
    color: ${({color}) => color? "rgba(255, 194, 0, 1)": "white"};
    font-size: ${({md}) => md? "1.2rem": "1rem"};
`
export const TextF = styled.p`
    text-align: center;
`
export const AddRem = styled.button`
    background: rgba(17, 115, 171, 1);
    width: 20px;
    color: white;
    width: 1.4rem;
    height: 1.4rem;
    display: flex;
    align-items: center;
    border-radius: 999px;
    border: none;
`

export const Warn = styled.p`
    color: red;
    position: relative;
    bottom: 2rem;
    align-self: flex-end;
`
export const Spinner = styled(ClipLoader)`
    align-self: center;
    justify-self: center;
`
export const FormSelect = styled.select`
    width: ${({wt}) => wt? wt :'max-content'};
    margin-top: ${({bts}) => bts? '8px':''};
    height: ${({min}) => min? '3.1rem':'2.7rem'};
    border: none;
    padding: ${({pd}) => pd? '0 4%':''};
    border-radius: ${({min}) => min? '4px' :'9px'};
    background: rgba(217, 217, 217, 1);
`

export const FormOption = styled.option``