import styled from 'styled-components'
import { NavLink as Link } from 'react-router-dom'

export const Wrapper = styled.section`
    display: flex;
    flex-direction: ${({col}) => col? "column":"row"};
    padding: ${({pd}) => pd?"1rem 0":""};
    padding-left: ${({pdlf}) => pdlf?"3%":""};
    gap: ${({gp}) => gp? gp : "1%"};
    width: ${({wt}) => wt};
    @media screen and (max-width: 600px) {
        width: 97%;
        padding-left: ${({pdlf}) => pdlf?"0":""};
    }
    @media screen and (max-width: 500px) {
        width: 95%;
        flex-wrap: ${({thb}) => thb? 'wrap':""};
        padding: ${({pd}) => pd?"1rem":""};
        gap: ${({gp}) => gp? gp : "3%"};
    }
`

export const Block = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2%;
    margin: auto;
    background: url(${({src}) => src});
    background-size: cover;
    width: ${({wt}) => wt};
    height: ${({ht}) => ht};
    justify-content: ${({src}) => src? "space-between":""};
    align-items: ${({src}) => src? "center":""};
`
export const ItemWrap = styled.span`
    display: flex;
    flex-direction: ${({dir}) => dir};
    gap: ${({wt}) => wt === '30%'? "" : "2rem"};
    margin: ${({aut}) => aut};
    width: ${({wt}) => wt};
    /* justify-content: ${({aut}) => aut === "auto"?"space-evenly":""}; */

    @media screen and (max-width: 500px){
        flex-direction: ${({details}) => details? 'column' : ''};
        width: 100%;
    }
`

export const Sign = styled.span`
    width: 20%;
    height: 2.86rem;
    color: ${({color}) => color};
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 180px;
    align-items: center;
    border: 3px solid ${({color}) => color};
    border-radius: 8px;
`
export const Header = styled.h1`
    color: ${({col}) => col? col:"grey"};
    font-size: ${({sm}) => sm? sm :"1rem"};
    margin-bottom: ${({nbtm}) => nbtm? "" : '1rem'};
    display: flex; 
    width: ${({mxc}) => mxc? 'max-content': ''};
    z-index: 1;
    &.top{
        width: 14%;
        font-size: 1.2rem;
        font-weight: 400;
    }
    @media screen and (max-width: 500px){
        width: ${({sh}) => sh? '62%': ''};
        font-size: ${({sm}) => sm === '1.3rem'? '1.6rem': '1.4rem'};
        font-weight: ${({sm}) => sm === '1.3rem'? '700': '100'};
        &.thumbs{
            font-size: 1rem;
        }
    }
`

export const Text = styled.p`
    color: ${({color}) => color};
    font-weight: 100;
    @media screen and (max-width: 500px){
        font-size: 1.4rem;
    }
`

export const Button = styled.button`
    color: white;
    background: rgba(17, 115, 171, 1);
    border: none;
    border-radius: 6px;
    width: 35%;
    height: 3rem;
    cursor: pointer;
`

export const Count = styled.span`
    color: white;
    align-self: flex-end;
`

export const MiniImg = styled.div`
    height: 4rem;
    width: 15%;
    padding: 0.5%;
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), black), url(${({src}) => src});
    background-size: cover;
    background-repeat: no-repeat;
    &.active {
        opacity: 0.4;
    }
    @media screen and (max-width: 500px){
        height: 7rem;
        width: 21%;
        margin-bottom: 1rem;
        justify-content: space-between;
    }
    @media screen and (max-width: 384px){
        height: 6rem;
    }
`
export const SpecCont = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: center;
    width: 94%;
    padding: 3%;
    height: 9rem;
`
export const Tag = styled.div`
    display: flex;
    color: white;
    width: max-content;
    padding: 0 5%;
    height: 3rem;
    border: 3px solid white;
    background: transparent;
    justify-content: center;
    border-radius: 29px;
    flex-wrap: wrap;
    font-size: 1.3rem;
    font-weight: 700;
    align-content: center;
    rotate: z -35deg;
`

export const Close = styled.img`
    width: 100%;
    cursor: pointer;
    opacity: 0.4;
    align-self: ${({pos}) => pos? 'flex-start': ''};
    &:hover{
        opacity: 1;
        /* box-shadow: 1px 1px 4px red; */
    }
` 
export const NWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const MainImg = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    height: 36rem;
    border-radius: 8px;
    position: relative;
    /* justify-content: space-between; */
    background: url(${({src}) => src});
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    box-shadow: 0px 4px 6px grey; 
    /* #D9D9D9 */
    @media screen and (max-width: 500px){
        height: 30rem;
    }
    @media screen and (max-width: 384px){
        height: 23rem;
    }
`
export const ViewedImg = styled.img`
    width: 100%;
    position: absolute;
    z-index: -1;
    height: 36rem;
    border-radius: 8px;
    @media screen and (max-width: 500px){
        height: 30rem;
    }
    @media screen and (max-width: 384px){
        height: 23rem;
    }
`
export const ViewedVideo = styled.video`
    width: 100%;
    position: absolute;
    z-index: -1;
    height: 36rem;
    border-radius: 8px;
    @media screen and (max-width: 500px){
        height: 30rem;
    }
    @media screen and (max-width: 384px){
        height: 23rem;
    }
`
export const ViewedSrc = styled.source``

export const Swipe = styled.button`
    background: transparent;
    border: none;
    width: 9%;
    cursor: pointer;
`
export const LowerDesc = styled.ul`
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 5%;
    padding: 0;
    @media screen and (max-width: 500px){
        padding-left: 4%;
    }
`
export const List = styled.li`
    font-size: 1rem;
    width: 8rem;
    margin-bottom: ${({text}) => text === "y"? "5px": ""};
`

export const Variant = styled.button`
    display: flex;
    border-radius: 11px;
    width: 100%;
    font-size: 0.57rem;
    align-items: center;
    border: 1px solid;
    padding: 5%;
    background: transparent;
`
export const Icon = styled.img`
    align-self: center;
    height: ${({title}) => title? '2rem' : '1rem'};
    width: ${({title}) => title? '2rem' : '1rem'};
    padding-right: ${({title}) => title? '0' : '3px'};
    /* padding: 0.8px 1.8px 0.8px 2.8px; */
    @media screen and (max-width: 500px){
        height: ${({title}) => title? '4rem' : '1.4rem'};
        width: ${({title}) => title? '4rem' : '1.4rem'};
    }
`

export const Map = styled.div`
    height: 10rem;
    width: 100%;
    background: url(${({src}) => src});
    @media screen and (max-width: 600px){
        width: 94%;
        margin: 0 auto;
    }
`
export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: 45%;
    @media screen and (max-width: 500px){
        width: 100%;
    }
`
export const CopyBtn = styled.button`
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 14%;
    padding: 0;
    border: 1px solid rgb(17, 115, 171);
    border-radius: 11px;
    background: transparent;
    display: flex;
    cursor: pointer;
    height: 24px;
    align-self: center;
    @media screen and (max-width: 600px){
        width: 19%;
    }
`

export const BookBtn = styled.button`
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
    height: max-content;
    padding: 0;
    border: none;
    background: transparent;
    display: flex;
    cursor: pointer;
    align-self: flex-start;
    position: absolute;
    bottom: 0;
    margin-left: 1rem;
    align-self: flex-start;
`

export const PropertyDetails = styled.section`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

export const PropertyDetailsHeader = styled.div`
    display: flex;
    border-bottom: 1px solid rgb(17, 115, 171);
`

export const PropertyDetailsTab = styled(Link)`
    text-decoration: none;
    color: rgb(17, 115, 171);
    background-color: white;
    width: 15%;
    font-size: 1rem;
    height: 40px;
    text-align: center;
    align-content: center;
    &.active {
        color: white;
        background-color: rgb(17, 115, 171);
        font-weight: 500;
    }

    @media screen and (max-width: 730px){
        width: 26%;
    }
`