import { NavContainer, LinkContainer, NavLink, NavLogo, MobileIcon, MobileMenu, MobSpan, Icon, NavP, DropItem, DropDown, ListBtn, IconsContainer } from "./style";
import SearchBar from '../searchbar'
import Logo from "../../assets/icons/Untitled2.png"
import profile from "../../assets/defaulta.png"
import profilew from "../../assets/icons/whitedefault.svg"
import menu from "../../assets/icons/burger.svg"
import arrow from "../../assets/icons/arrow-down.svg"
import barrow from "../../assets/icons/barrow-down.svg"
import close from "../../assets/icons/closes.svg"
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { set } from "../../features/pageSlice/page";
import { listClear } from "../../features/registerSlice/rdataSlice";
import { useEffect, useState, useRef } from "react";
import { getData } from "../../features/getSlice/get";
import { click } from "../../features/searchSlice/search";
import {nav_data, reg_drop, nav_data2, find_drop} from '../../data/nav'
import { roleClear } from "../../features/registerSlice/rdataSlice";
import Bubble from "../notification/bubble";



const Nav = () => {
    const [bar, setBar] = useState(false);
    const [find, setFind] = useState(false);
    const [open, setOpen] = useState(false);
    const [filtr, setFiltr] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    let val;
    const user = useSelector((state) => state.signin.user)
    const page = location.pathname;
    const isLoggedIn = useSelector((state) => state.signin.loggedIn);
    const handleFilter = (item) => {
        if(["buy", "sell", "rent"].includes(item)){
            setFiltr(item);
        } 
        !['Find a Business', 'For Businesses'].includes(item) &&
            setOpen(false);
    };

    function triggerDropDown(item){
        if(item === 'For Businesses' && bar){
            setBar(false);
        }else if(item === 'For Businesses' && !bar){
            setBar(true);
        }else if (item === 'Find a Business' && find){
            setFind(false);
        }else if (item === 'Find a Business' && !find){
            setFind(true);
        }
    }
    

    function triggerOpen(item){
        if(item === 'For Businesses'){
            setBar(true);
        }else if (item === 'Find a Business'){
            setFind(true);
        }
    }

    function triggerClose(item){
        if(item === 'For Businesses'){
            setBar(false);
        }else if (item === 'Find a Business'){
            setFind(false);
        }
    }

    useEffect(() => {
        const url = `${process.env.REACT_APP_BASE_APP_URL}/property/search/?property_status=${filtr}/`
        dispatch(getData(url));
    }, [filtr]);
    
    useEffect(() => {
        dispatch(set(page));
    }, [page]);


    const route = (item) => {
        if(["help"].includes(item)){
           return `profile/${item}`;
        }else  
            return  `/${item}`;
    }
    
   
    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (!dropdownRef.current.contains(event.target) && open && val > 0){
          setOpen(false);
          val = 0
        } else 
            val = 1
      };
  
    useEffect(() => {
      // Function to handle click outside of search bar and dropdown
      
      val = 0
      // Attach event listener to document
      if(open){document.addEventListener('click', handleClickOutside)};
  
      // Cleanup: Remove event listener when component unmounts
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [open]);

    // useEffect(() => {
    //     if(find && open){
    //         const item = document.querySelector('.Find');
    //         const sitem = document.querySelector('.find');
    //         item.insertAdjacentElement("afterend", sitem);
    //     }
    //     if(bar && open){
    //         const item = document.querySelector('.For');
    //         const sitem = document.querySelector('.bar');
    //         item.insertAdjacentElement("afterend", sitem);
    //     }
    // }, [bar, find])

    
    const burger = () => {
        setOpen(!open);
        setBar(false);
        setFind(false);
    }
    return ( 
        <>
            <NavContainer>
                <LinkContainer check='true' check2='true'>
                    <NavLink key={"logo"} to="/" className="logo"><NavLogo src={Logo} alt="logo" /></NavLink>
                    <SearchBar wt='true' mb='true' />
                    <MobileMenu check={open?"flex": "none"} className='menu' ref={dropdownRef} >
                        {isLoggedIn?
                            <MobSpan wt="100%">
                                <NavLink to="profile" vis='true' onClick={() => setOpen(false)}><NavLogo src={user?.profile && user?.profile?.avatar != null? user.profile.avatar : profilew} alt="profile picture" ht={'1.8rem'} mbm='true' /></NavLink>
                                <NavP>Hi,{" " + user.first_name}</NavP>
                            </MobSpan>
                            : 
                            <MobSpan wt="100%">
                                <NavLink key={"signin"}  vis='true' to="/sign in">Sign in</NavLink>
                                <NavLink key={"signup"}  vis='true' to="/sign up">/Sign up</NavLink>
                            </MobSpan>
                        }
                        {open && <SearchBar wt='true' />}
                        {!open && nav_data2.map((item, index) => {
                            return (
                                <NavLink key={index} className={item} to={['Find a Business', 'For Businesses'].includes(item)? "#":route(item.toLowerCase())} onClick={() => handleFilter(item)} onMouseEnter={() => triggerOpen(item)} onMouseLeave={() => triggerClose(item)}>
                                    <NavP>{item}</NavP>
                                    {['Find a Business', 'For Businesses'].includes(item) && <Icon src={open? barrow : arrow} alt="dropdown" />}
                                    {['Find a Business'].includes(item) && !open &&
                                    <DropDown className={find? 'find reveal' : 'find'}>
                                        {find_drop.map((item, f) => (
                                        <NavLink key={f} to={"/find/" + item.toLowerCase()} onClick={() => {setFind(false); dispatch(click(false))}} wt="100%" >
                                            <DropItem>{item}</DropItem>
                                        </NavLink>
                                        ))} 
                                    </DropDown>}
                                    {['For Businesses'].includes(item) && !open &&
                                    <DropDown className={bar? "bar reveal" : "bar"} name='desktop_regis' >
                                        {reg_drop.map((item, b) => (
                                        <NavLink key={b} to={`register/${item.slice(12)}`} onClick={() => {setBar(false); dispatch(roleClear())}} wt="100%" >
                                            <DropItem>{item}</DropItem>
                                        </NavLink>
                                        ))}
                                    </DropDown>}
                                </NavLink>
                            )}) 
                        }
                        {open && nav_data.map((item, index) => {
                            if(['Find a Business'].includes(item)){
                                return (
                                <>
                                    <NavLink key={index} to={(e) => {e.preventDefault(); return "#"}} onClick={() => triggerDropDown(item)}>
                                        <NavP>{item}</NavP>
                                        <Icon src={open? barrow : arrow} alt="dropdown" />
                                    </NavLink>
                                   <DropDown key={'f' + index} className={find? `find reveal` : 'find'}>
                                        {find_drop.map((item, f) => (
                                            <NavLink key={f} to={"/find/" + item.toLowerCase()} onClick={() => {setFind(false); dispatch(click(false))}} wt="100%" >
                                                <DropItem>{item}</DropItem>
                                            </NavLink>
                                        ))} 
                                    </DropDown>
                                </>
                                )
                            }else if(['For Businesses'].includes(item)){
                                return (
                                <>
                                    <NavLink key={index} to={(e) => {e.preventDefault(); return "#"}} onClick={() => triggerDropDown(item)}>
                                        <NavP>{item}</NavP>
                                        <Icon src={open? barrow : arrow} alt="dropdown" />
                                    </NavLink>
                                    <DropDown key={'b' + index} name='mb_regis' className={bar? 'bar reveal' : 'bar'}>
                                        {reg_drop.map((item, b) => (
                                            <NavLink key={b} to={`register/${item.slice(12)}`} onClick={() => {setBar(false); dispatch(roleClear())}} wt="100%" >
                                                <DropItem>{item}</DropItem>
                                            </NavLink>
                                        ))}
                                    </DropDown>
                                </>
                                )
                            }else
                                return (
                                    <NavLink key={index} className={item} hid={item === 'Settings' && !isLoggedIn? 'true' : 'false'} to={route(item.toLowerCase())} onClick={() => handleFilter(item)}  >
                                        <NavP>{item}</NavP>
                                    </NavLink>
                                )
                        })   
                        }
                        {user.has_role && !['EventCenter', 'Bank'].includes(user.role) && 
                            // <NavLink to="/list/1" wt={open? "100%":"16%"}>
                            <ListBtn wt={open? "100%":"16%"} onClick={() => {dispatch(listClear()); navigate("/list/1")}}>
                                List a property
                            </ListBtn>
                            // {/* </NavLink> */}
                        }
                        {isLoggedIn? 
                            <>
                                <NavLink to="profile" hid={'true'} ><NavLogo src={profile} alt="profile picture" ht={'1.8rem'}/></NavLink>
                                
                            </>
                            :
                            <NavLink to="sign in" hid={'true'} >Sign in</NavLink>
                        }
                    </MobileMenu>
                    <IconsContainer>
                    {isLoggedIn && <Bubble />}
                    {!open? <MobileIcon src={menu} alt="menu" onClick={() => burger()} /> : <MobileIcon src={close} alt="close" onClick={() => burger()} />}
                    </IconsContainer>
                </LinkContainer>
            </NavContainer>
        </>
    )
}

export default Nav;