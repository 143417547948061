import { FillerWrapper, FillerRight, FillerLeft, FillerHead, FillerP, Button, Icon, ExtraText } from "../style";
import { useDispatch } from "react-redux";
import { googCheck } from "../../../features/getSlice/get";


const Filler = ({head, ptext, btntext, btntext2, imsrc, isrc, find}) => {
    const dispatch = useDispatch()
    const onGoogleLoginSuccess = () => {
        const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
        const redirectUri = 'user/v1/auth/login/google/';
        const apiBaseURL = `${process.env.REACT_APP_BASE_APP_URL}`;
        // Scope indicates the info of the user needed from google, I have
        // only asked for the email, profile picture and full name
        const scope = [
          'https://www.googleapis.com/auth/userinfo.email',
          'https://www.googleapis.com/auth/userinfo.profile'
        ].join(' ');
        const params = {
          response_type: 'code',
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          redirect_uri: `${apiBaseURL}/${redirectUri}`,
          prompt: 'select_account',
          access_type: 'offline',
          scope
        };
      
        const urlParams = new URLSearchParams(params).toString();
        window.location = `${googleAuthUrl}?${urlParams}`;
      };
      const log = () => {
        onGoogleLoginSuccess()
        dispatch(googCheck(true))
      }
    return(
        <FillerWrapper>
            <FillerLeft>
                <FillerHead>{head}</FillerHead>
                <FillerP>{ptext}</FillerP>
                <Button to="/sign up" align='left' variant={btntext === "Sign up to get started"? "":"outlined"} >
                  <ExtraText>
                    {btntext}
                  </ExtraText>
                </Button>
                <Button variant="outlined" onClick={log} >
                  <Icon src={isrc} />
                  <ExtraText>
                    {btntext2}
                  </ExtraText>
                </Button>
                {find && <Button to="/rent">{find}</Button>}
            </FillerLeft>
            <FillerRight src={imsrc}></FillerRight>
        </FillerWrapper>
    )
}

export default Filler;