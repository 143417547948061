import { useNavigate } from "react-router-dom";
import { SellContainer, SellHeader, SellP, SellButton, SellLink } from "../style";
import { useDispatch, useSelector } from "react-redux";
import { listAdd } from '../../../../features/registerSlice/rdataSlice'
import { extras } from "../../../../features/getSlice/get";


const SellComp = ({head, text, size, btntext, ant, align}) => {
    const user = useSelector((state) => state.signin);
    const loggedIn = useSelector((state) => state.signin.loggedIn);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    function addRole(){
        dispatch(extras({role: 'an Agent'}))
        navigate('/register/an Agent')
    }

    return (
        <SellContainer variant={ant} align={align}>
            <SellHeader>{head}</SellHeader>
            <SellP>{text}</SellP>
            {btntext === "Find an Agent" ? 
            <SellLink onClick={() => navigate('/find/agent')}><SellButton size={size}>{btntext}</SellButton></SellLink>
            :
            <>
                <SellLink onClick={() => user && user.has_role && !['EventCenter', 'Bank'].includes(user.role)? navigate('/list/1') : addRole()}><SellButton size={size}>{btntext}</SellButton></SellLink>
                {loggedIn && <SellLink onClick={() => {dispatch(listAdd({property_type: 'Pinplug', property_status: 'Tools'})); navigate('/list/1')}}><SellButton size={size}>List a Pinplug</SellButton></SellLink>}
            </>
            }
        </SellContainer>
    )
}

export default SellComp