import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'



const initialState = {

  loading: false,
  profiledata: [],
  error: '',
  success: false
}


export const profileData  = createAsyncThunk('data/profileData', (objdata) => {
    const {url, config} = objdata;
    return axios
            .get(url, 
            {
            headers: config
            })
            .then(response => response.data) 
        })
const profileSlice = createSlice({
    name: 'data',
    initialState,
    extraReducers: builder => {
          builder.addCase(profileData.pending, state => {
            state.profiledata = []
            state.loading = true
            state.error = ''
            state.success = false
          })
          builder.addCase(profileData.fulfilled, (state, action) => {
            state.loading = false
            state.profiledata = action.payload
            state.error = ''
            state.success = true
          })
          builder.addCase(profileData.rejected, (state, action) => {
            state.loading = false
            state.profiledata = []
            state.error = action.error.message
          });
    },
});


export default profileSlice.reducer