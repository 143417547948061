import { Outlet, useLocation } from "react-router-dom"
import GlobalStyle from "../globalStyles";
import { Nav } from "../components"
import { Footer } from '../layout'
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHandle } from "../components/utility/drag_drop";
import ShowAlert from "../components/alert";
import { setAlert } from "../features/searchSlice/search";
import { locate } from "../features/getSlice/get";
import ViewGallery from "../components/lightbox";



const Base = () => {
    const location = useLocation();
    const page = location.pathname;
    const logged = useSelector((state) => state.signin.loggedIn);
    const view = useSelector((state) => state.data.view);
    const alert = useSelector((state) => state.search);
    const dispatch = useDispatch();
    const key = `${process.env.REACT_APP_GEOPIFY}`
    const successCallback = async (position) => {
        const lat = position.coords.latitude
        const long = position.coords.longitude
        const location = await getHandle({url: `https://api.geoapify.com/v1/geocode/reverse?lat=${lat}&lon=${long}&limit=1&apiKey=${key}`, config: {"Content-Type": "application/json"}})
        dispatch(locate(location.data?.features[0]?.properties.state));
        
    };
      
    const errorCallback = (error) => {
        console.log(error);
    };

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback)
    }, [])

    useEffect(() => {
        if(alert.alert){
            setTimeout(() => {
                dispatch(setAlert({alert: false, message: "", color: ""}))
            }, 5000)
        }
    }, [alert.alert])

    // useEffect(() => {
    //     if (msg === 'Request failed with status code 401'){
    //         dispatch(setAlert({alert: true, message: 'Session has expired'}));
    //         dispatch(postData({url: lout, config: config, data: user.tokens}));
    //         Cookies.remove("data");
    //         store.dispatch(signoutRequest());
    //     }
    // }, [loading])
    return(
       <>
        {view.open && <ViewGallery data={view.data} index={view.index} />}
        <GlobalStyle />
        <Nav />
        {alert.alert && <ShowAlert text={alert.alertMessage} color={alert.alertColor} />}
        <Outlet />
        {!["/sign%20in", "/sign%20up", "/address", "/upload", "/upload/id", "/uppath", "/preview", "/created"].includes(page) && <Footer />}

       </>
    )
}

export default Base