export const house_types = [
    "All",
    "Bungalow", 
    "Duplex",
    "Terrace Duplex",
    "Semi-detached Duplex",
    "Fully-detached Duplex",
    "Mansion",
    "Apartment/Condos",
    "Maisonette",
    "Pent-house",
    "Self-contain",
    "Short-let",
    "One Bedroom",
    "Room",
]