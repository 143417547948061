import styled from 'styled-components'
import ClipLoader from "react-spinners/ClipLoader";
import { GeoapifyGeocoderAutocomplete, GeoapifyContext } from '@geoapify/react-geocoder-autocomplete';
import '@geoapify/geocoder-autocomplete/styles/minimal.css';
import '@geoapify/geocoder-autocomplete/styles/round-borders.css';


export const FilterContainer = styled.div`
 display: flex;
 align-items: center;
 margin: auto;
//  width: 43%;
 border-radius: 42px;
 background: white;
 height: 41px;
 padding: 0.7%;
 @media screen and (max-width: 700px){
    width: 54%;
   }
 @media screen and (max-width: 600px){
   flex-wrap: wrap;
   width: 67%;
   border-radius: 7px;
   height: auto;
   padding: 4% 2%;
   gap: 3%;
   } 
   @media screen and (max-width: 425px){
    width: 90%;
   }
` 
export const LoadingCont = styled.section`
    height: 200px;
    display: grid;
`

export const Spinner = styled(ClipLoader)`
    align-self: center;
    justify-self: center;
`

export const FilterSelect = styled.select`
   border: 0.7px solid black;
   border-radius: 28px;
   text-align: center;
   height: 37px;
   width: 43%;
   margin-right: 1%;
   background: transparent; 
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   background-image: url(${({url}) => url});
   /* background-size: cover; */
   color: black;
   background-repeat: no-repeat;
   background-position: right center;
   background-size: 30px; /* Adjust the size of the custom arrow */
   padding-right: 30px;
   padding-left: 10px;
   /* padding: 8px 24px 8px 10px; */
   &:hover {
      border-color: #aaa;
   }
    
    /* Focus effect */
   &:focus {
      outline: rgba(17, 115, 171, 1);
   }
   &::-webkit-inner-spin-button, &::-webkit-outer-spin-button{
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
   }

 @media screen and (max-width: 600px){
   width: 47.5%;
   height: 45px;
   margin-bottom: 1rem;
} 
`

export const FilterOption = styled.option`
   text-align: left;
`

export const Button = styled.button.attrs({
    type: 'submit'
})`
   background: rgba(17, 115, 171, 1);
   color: white;
   border-radius: 28px;
   width: 43%;
   display: flex;
   height: 37px;
   justify-content: space-evenly;
   align-items: center;
   border: none;
   cursor: pointer;
   @media screen and (max-width: 765px){
   font-size: 0;
   justify-content: center;
   }
   @media screen and (max-width: 600px){
      font-size: 1rem;
      width: 100%;
      gap: 3%;
      margin-top: 1rem;
      height: 41px;
   }
   // @media screen and (max-width: 425px){
   //  width: 97.25%;
   // }
`

export const SearchIcon = styled.img`
    height: 1rem;
`

export const FilterInput = styled.input.attrs(() =>({
   type: 'number'
}))`
   border: 0.7px solid black;
   border-radius: 28px;
   text-align: center;
   text-align: left;
   height: 34px;
   width: 41%;
   margin-right: 1%;
   -webkit-appearance: none;
   appearance: none;
   -moz-appearance: textfield;
   padding: 0;
   padding-left: 5px;
   color: black;
   &:focus {
      outline: rgba(17, 115, 171, 1);
   }
   @media screen and (max-width: 600px){
      width: 45%;
      height: 41px;
      margin-bottom: 1rem;
   }
   @media screen and (max-width: 425px){
    width: 45.5%;
   }
`
export const AddressContainer = styled(GeoapifyContext)`
   width: 43%;
   margin-right: 1%;
`
export const AddressInput = styled(GeoapifyGeocoderAutocomplete)`
   border: 0.7px solid rgb(17, 115, 171);
   border-radius: 28px;
   text-align: center;
   height: 37px;
`