import { createSlice } from "@reduxjs/toolkit";




const initialState = {
    view: {
        open: false,
        index: 0,
        data: {}
    },
    "user": {
        "email": "",
        "password": "",
        "password2": "",
        "first_name": "",
        "last_name": "",
        "middle_name": "",
        "d_o_b": "",
        "address": "",
        "profile.upload_id": null
    },
    "role": {
        "profile_photo": null,
        "about_you": "",
        "specialties": "",
        "email": "",
        "phone_no": null,
        "name": "",
        "id_upload": null,
        "company_name": "",
        "company_reg": "",
        "company_id": null,
        "address": "",
        "price_per_day": "",
        "additional_services": "",
        "is_security": "",
        "state": "",
        "hall_capacity": "",
        "iphotos": [],
        "iname": [],
        "parking_space": ""
    },
    "list": {
        "edit": false,
        "title": "",
        "desc": "",
        "property_type": "",
        "no_of_rooms": "",
        "no_of_bathrooms": "",
        "lot_size": "",
        "address": "",
        "property_status": "For sale",
        "no_of_plots": 0,
        "property_price": 0,
        "income_per_month": 0,
        "years_built": 0,
        "years_renovated": 0,
        "years_reconstructed": 0,
        "parking_space": "",
        "appliance": "Yes",
        "duration": "Month",
        "room_no": "",
        "phone_no": null,
        "tag_id": null,
        "lr": "Yes",
        "wifi": "Yes",
        "aircon": "Yes",
        "heater": "Yes",
        "student": "",
        "extra_notes": "",
        "uextra_charges": [],
        "uland_image": [],
        "uhouse_view": [],
        "uliving_room": [],
        "ubed_room": [],
        "utoilet": [],
        "ukitchen": [],
        "udocuments": [],
        "uhouse_plan": [],
        "uvideo": null,
        "usize": [],
        "uappliances": [],
        "appliances": [],
        "owner_email": "",
        "owner_name": "",
        "avatar": null,
        "state": "",
        "latitude": 0,
        "longitude": 0,
        "room_title": "",
        "room_price": 0,
        "room_tag": "",
    }
    
}



const rdataSlice = createSlice({
    name: "data",
    initialState,
    reducers: {
        viewImage: (state, action) => {
            state.view.open = action.payload.open
            state.view.data = action.payload.data
            state.view.index = action.payload.index
        },
        update: (state, action) => {
            state.user = {
                ...state.user, // Keep the existing properties
                ...action.payload, // Overwrite with the new properties
            };
        },
        role: (state, action) => {
            state.role = {
                ...state.role, // Keep the existing properties
                ...action.payload, // Overwrite with the new properties
            };
        },
        listAdd: (state, action) => {
            state.list = {
                ...state.list, // Keep the existing properties
                ...action.payload, // Overwrite with the new properties
            };
        },
        listClear: (state, action) => {
                state.list.edit =  false
                state.list.title =  ""
                state.list.desc =  ""
                state.list.property_type =  ""
                state.list.no_of_plots = 0
                state.list.no_of_rooms =  ""
                state.list.no_of_bathrooms =  ""
                state.list.lot_size =  ""
                state.list.tag_id = ""
                state.list.address =  ""
                state.list.phone_no = ""
                state.list.property_status =  "For sale"
                state.list.property_price =  0
                state.list.income_per_month =  0
                state.list.years_built =  0
                state.list.years_renovated =  0
                state.list.years_reconstructed =  0
                state.list.parking_space =  ""
                state.list.appliance =  "Yes"
                state.list.location =  ""
                state.list.room_no =  ""
                state.list.lr =  "Yes"
                state.list.wifi =  "Yes"
                state.list.aircon =  "Yes"
                state.list.heater =  "Yes"
                state.list.extra_notes =  ""
                state.list.uextra_charges =  []
                state.list.uland_image =  []
                state.list.uhouse_view =  []
                state.list.uliving_room =  []
                state.list.ubed_room =  []
                state.list.utoilet =  []
                state.list.ukitchen =  []
                state.list.udocuments =  []
                state.list.uhouse_plan =  []
                state.list.usize =  []
                state.list.uappliances =  []
                state.list.appliances =  []
                state.list.owner_email =  ""
                state.list.owner_name =  ""
                state.list.avatar =  null
                state.list.uvideo = null
                state.list.latitude =  0
                state.list.longitude =  0
                state.list.room_price = 0
                state.list.room_title = ""
                state.list.room_tag = ""
                state.list.student = ""
                state.list.state = ""
        },
        roleClear: (state, action) => {
            state.role.profile_photo = null
            state.role.about_you = ""
            state.role.specialties = ""
            state.role.email = ""
            state.role.phone_no = null
            state.role.name = ""
            state.role.id_upload = null
            state.role.company_name = ""
            state.role.company_reg = ""
            state.role.company_id = null
            state.role.address = ""
            state.role.price_per_day = ""
            state.role.additional_services = ""
            state.role.is_security = ""
            state.role.state = ""
            state.role.hall_capacity = ""
            state.role.iphotos = []
            state.role.iname = []
            state.role.parking_space = ""
        }
    }
});

export default rdataSlice.reducer
export const { update, role, listAdd, roleClear, listClear, viewImage } = rdataSlice.actions