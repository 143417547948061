import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";


export const FillerWrapper = styled.div`
 display: flex;
 margin: auto;
 padding-top: 4rem;
 width: 80%;
 gap: 10%;
 @media screen and (max-width: 600px){
    flex-direction: column-reverse;
 }
`

export const FillerRight = styled.div`
 background: url(${(props => props.src)});
 background-size: 100%;
 background-repeat: no-repeat;
 width: 45%;
 height: 350px;
 margin: auto;
 @media screen and (max-width: 600px){
    height: 200px;
    width: 100%;
 }
`

export const FillerLeft = styled.div`
 display: flex;
 flex-direction: column;
 text-align: left;
 width: 40%;
 margin: auto;
 margin-top: 0;
 @media screen and (max-width: 600px){
    width: 100%;
 }
`
export const FillerHead = styled.h1`
 color: rgba(17, 115, 171, 1);
 font-size: 1.8rem;
 padding-top: 10px;
`

export const FillerP = styled.p`
   margin: 1rem 0;
   @media screen and (max-width: 600px){
      font-size: 15px;
   }   
`

export const Icon = styled.img`
 height: 12px;
 padding-right: 4px;
`

export const Button = styled(Link)`
    width: 44%;
    margin-bottom: 1rem;
    height: 43px;
    border-radius: ${props => props.variant === "outlined" ? "2px" : "4px"};
    text-align: ${ props => props.align || 'center'};
    border: ${props => props.variant === "outlined" ? "1px solid rgba(17, 115, 171, 1)" : "none"};
    color: ${props => props.variant === "outlined" ? "rgba(17, 115, 171, 1)" : "white"};
    background: ${props => props.variant === "outlined" ? "none" : "rgba(17, 115, 171, 1)"};
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
   @media screen and (max-width: 860px){
      height: 33px;
   }
   @media screen and (max-width: 738px){
      width: 52%;
   }
   @media screen and (max-width: 625px) {
      width: 58%;
   }
   @media screen and (max-width: 600px){
      height: 51px;
   }
   @media screen and (max-width: 341px){
      width: 68%;
   }
`
export const TagWrapper = styled.div`
   display: flex;
   background: rgba(230, 241, 247, 1);
   align-items: center;
   width: ${props => props.width ? props.width : "11%"};
   justify-content: center;
   margin: 3rem 0;
   margin-right: ${props => props.rt};
   position: ${({ pos }) => (pos)};
   top: ${({ pos }) => (pos ? "5rem" : "")};
   height: 40px;
   border: none;
   border-radius: 17px;
   @media screen and (max-width: 816px){
      width: 13%;
   }
   @media screen and (max-width: 690px){
      width: 16%;
   }
   @media screen and (max-width: 600px){
      width: 23%;
      height: 43px;
      margin-right: ${({rt}) => rt === '80%'? '63%': rt};
   }
   @media screen and (max-width: 590px){
      width: 30%;
      /* height: 20px; */
   }
   @media screen and (max-width: 600px){
      margin-left: ${({lf}) => lf};
   }
   @media screen and (max-width: 396px){
      width: 35%;
   }
   @media screen and (max-width: 340px) {
      width: 40%;
      margin-right: ${({rt}) => rt === '80%'? '50%': rt};
   }
`
export const TagCont = styled.p`
 color: rgba(17, 115, 171, 1);
 padding: 1rem;
  @media screen and (max-width: 590px){
    padding: 0.5rem;
 }
`
export const StarWrapper = styled.span`
    display: flex;
    gap: 5%;
`

export const Wrapper = styled.div`
    display: grid;
    background: rgb(0, 0, 0, 0.5);
    align-items: center;
    position: fixed;
    width: 100vw;
    bottom: 0;
    height: 101vh;
    z-index: 1;
`
export const Spinner = styled(ClipLoader)`
    align-self: center;
    justify-self: center;
`

export const ExtraText = styled.p`
   
`