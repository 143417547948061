import { createSlice } from "@reduxjs/toolkit";



const initialState = {
    currentPage: "",
    prevPage: "",
    id: "",
    nextPage: "",
    review: false,
    email: '',
    count: 0,
    tagData: []
}



const pageSlice = createSlice({
    name: "page",
    initialState,
    reducers: {
        set: (state, action) => {
            state.prevPage = state.currentPage
            state.currentPage = action.payload
        },
        preSet: (state, action) => {
            state.prevPage = action.payload
        },
        fornext: (state, action) => {
            state.id = action.payload.id
            state.nextPage = action.payload.nextPage
        },
        setView: (state, action) => {
            state.review = action.payload
        },
        setEmail: (state, action) => {
            state.email = action.payload
        },
        setCount: (state, action) => {
            state.count = action.payload
        },
        setTag: (state, action) => {
            state.tagData = action.payload
        }
    }
})


export default pageSlice.reducer
export const { set, fornext, setView, preSet, setEmail, setCount, setTag } = pageSlice.actions