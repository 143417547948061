import { UserWrapper, Header, UserP, FillBtn, Email, ReWarn, Spinner, Label, UserImage } from "../style";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { errorClear, postData, successClear } from "../../../features/postSlice/post";
import adrs from '../../../assets/icons/links.svg';
import reset from '../../../assets/icons/reset.svg';



const Confirm = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/user/reset_request/`
    const config = { "Content-Type": "application/json" }
    const post = useSelector((state) => state.post)
    const [loginInfo, setLoginInfo] = useState(
        {email: ""}
      )
    const dispatch = useDispatch();
    const[check, setCheck] = useState(false);
    const[warn, setWarn] = useState(false);
    const navigate = useNavigate();
    const handleSubmit = (event) => {
      event.preventDefault();
      dispatch(postData({url: url, data: loginInfo, config: config}))
    }
    useEffect(() => {
      if(!post.loading && post.data.message === 'Email Sent' && post.success){
      setCheck(true);
      setTimeout(() => {
        dispatch(successClear());
        setCheck(false);
        navigate("/");
      }, 3000)  
      }else if(!post.loading && post.error === 'Request failed with status code 400'){
        setWarn(true);
        dispatch(errorClear());
      }
    }, [post.loading])
    const handleChange =  (event) => {
      setWarn(false);
        const target = event.target;
        setLoginInfo({
            ...loginInfo, [target.name]: target.value
        });
        }
    
    function GoBack(){
      navigate("/");
      setCheck(false);

    }
    
    return(
        <UserWrapper onSubmit={handleSubmit}>
          {!check?
            <>
              <Header bt="14px" >Reset Password</Header>
              <UserP sz="1.5rem">Enter the email address associated with your account and we will send an email with instructions to reset your password</UserP>
              <UserImage src={adrs} alt="link" />
              {warn && <ReWarn wr={"email"} sin warn={"weak"}>Invalid email</ReWarn>}
              <Label for="email">Email address</Label>
              <Email placeholder="Email" warn={check} name="email" onChange={handleChange} required />
              <FillBtn>
                {post.loading?
                <Spinner
                  color={"white"}
                  loading={true}
                  size={25}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />:"Request reset"
                }
              </FillBtn>
            </>
            :
            <>
                <Header>We just sent a link to your email</Header>
                <UserImage src={reset} alt="link" />
                <FillBtn onClick={() => GoBack()}>Back to home</FillBtn>
            </>
          }
        </UserWrapper>
    )
}

export default Confirm;