import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import ErrorBoundary from './components/errors/boundary';
import {PrivateRoutes, RemoveLog, FileUploadProvider, NoRole, Page2, Page3, Page4, Page5, Page6} from "./utils";
import LandingPage from './pages/landingPage';
import Adrs from './pages/user/address'
import SignIn from './pages/user/signin'
import Confirm from "./pages/user/confirm";
import SignUp from './pages/user/signup'
import Home from "./pages/landingPages/home";
import Rent from "./pages/landingPages/rent";
import Buy from "./pages/landingPages/buy";
import { Upload, Select, Id } from "./pages/user/xristics";
import Base from "./pages/base";
import { SellPage, ContactPage, ViewProperty } from "./pages/miscellaneous";
import { FindAgent  } from "./components/agent";
import Profile from "./pages/user/profile";
import { ProBase, Edit, Listed, Security, Support } from "./components/profile";
import { Start, Mid, End, Created, Error, DoubleP } from "./components/inputs";
import { EdStart, EdMid, EdEnd } from "./components/inputs/role";
import { ListFirst, ListSecond, ListThird, ListFourth, ListFifth, ListSixth, Done} from "./pages/miscellaneous/list";
import { DragDrop, ViewDel } from "./components/upload";
import CreatedAcct from "./pages/user/created";
import Reset from "./pages/user/reset";
import InvalidLink from "./pages/user/invalid";
import SearchResult from "./pages/landingPages/search";
import How from "./components/profile/how";
import Tuts from "./components/profile/how/tuts";
import RoleChoice from "./pages/user/role";
import FilterResult from "./pages/landingPages/filter";
import Saved from "./components/profile/saved";
import Loading from "./components/profile/loading";
import Person from "./components/persondata";
import PropertyFeatures from "./components/propBlocks/features";
import PropertyComments from "./components/propBlocks/comments";
import RoomTags from "./pages/miscellaneous/list/tags";
import EditRoomTag from "./pages/miscellaneous/list/tags/edit";
import PrivacyPolicy from "./pages/landingPages/policy";
import TermsConditions from "./pages/landingPages/terms";
// import AutoComplete from "./components/autocomplete";
const LazyDesc = React.lazy(() => import('./components/inputs/desc'))
 
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
        <Route path="/" element={<Base />} exact>
            <Route path="" element={<LandingPage />} exact>
              <Route path="" element={<Home />}  exact/>
              <Route path="buy" element={<Buy />}  exact/>
              <Route path="rent" element={<Rent />}  exact/>
              <Route path="sell" element={ <SellPage />} exact />
              <Route path="filter/:state?/:status?/:type?/:price?" element={ <FilterResult />} exact />
            </Route>
            {/* <Route path="map" element={<AutoComplete />} exact /> */}
            <Route path="/terms and conditions" element={<TermsConditions />} exact />
            <Route path="/policy" element={<PrivacyPolicy />} exact />
            <Route path="search/:title" element={<SearchResult />} exact />
            <Route path="view/:id" element={<ViewProperty />} exact>
              <Route path="" element={<PropertyFeatures />} exact />
              <Route path="comments" element={<PropertyComments />} exact />
            </Route>
            <Route path="find/:role" element={ <FindAgent /> } exact />
            <Route path="/sign up" element={<SignUp />}  exact/>
            <Route path="/created" element={<CreatedAcct />} exact/>
            <Route path="/reset password" element={<Reset />} exact/>
            <Route path="/invalid" element={<InvalidLink />} exact/>
            <Route path="/address" element={<Adrs />}  exact/>
            <Route path="/upload" element={<Upload />} exact>
              <Route path="" element={<Select />} exact/>
              <Route path="id" element={<Id />} exact/>
            </Route>
            <Route path="/role" element={<RoleChoice />} exact />
            <Route element={<RemoveLog />}>
              <Route path="/sign in" element={<SignIn />}  exact/>
              <Route path="/confirm" element={<Confirm />}  exact/>
            </Route>
            <Route path="/404" element={<Error/>}  exact/>
            <Route path="*" element={<Navigate to="/404" />}/>
            <Route element={<PrivateRoutes />} >
              <Route path="contact/:role/:name/:c?" element={ <ContactPage /> }  exact/>
              <Route path="settings" element={ <Person /> } exact />
              <Route path="profile" element={<Profile />} exact >
                <Route path="" element={<ProBase />} exact/>
                <Route path="edit profile" element={<Edit/>}  exact/>
                <Route path="manage rentals" element={<Listed/>}  exact/>
                <Route path="saved" element={<Saved />} exact />
                <Route path="security" element={<Security/>}  exact/>
                <Route path="help" element={<Support/>}  exact/>
                <Route path="edit/:roles/profile" element={<EdStart/>}  exact/>
                <Route path="edit role-ii" element={<EdMid/>}  exact/>
                <Route path="edit role-iii" element={<EdEnd/>}  exact/>
                <Route path="how to use" element={<How />} exact />
                <Route path="tuts/:who" element={<Tuts />} exact />
              </Route>
              <Route path="register/:role" 
                      element={
                        <React.Suspense fallback={<Loading />}>
                          <LazyDesc/>
                        </React.Suspense>}  exact/>
              <Route path="register/:roles/i" element={<Start/>}  exact/>
              <Route path="register/:roles/ii" element={<Mid/>}  exact/>
              <Route path="register/:roles/iii" element={<End/>}  exact/>
              <Route path="register/:roles/iv" element={<DoubleP/>}  exact/>
              <Route path="role created" element={<Created/>}  exact/>
              
              <Route element={<NoRole />}>
                <Route path="list/1" element={<ListFirst/>}  exact/>
                <Route element={<Page2 />}>
                  <Route path="list/2" element={<ListSecond/>}  exact/>
                </Route>
                <Route path="list/tags" element={<RoomTags/>} exact />
                <Route path="list/tags/:pk" element={<EditRoomTag />} exact />
                <Route element={<Page3 />}>
                  <Route path="list/3" element={<ListThird/>}  exact/>
                </Route>
                <Route element={<Page4 />}>
                  <Route path="list/4" element={<ListFourth/>}  exact/>
                </Route>
                <Route element={<Page5 />}>
                  <Route path="list/5" element={<ListFifth/>}  exact/>
                </Route>
                <Route element={<Page6 />}>
                  <Route path="list/6" element={<ListSixth/>}  exact/>
                </Route>
                <Route path="list/done" element={<Done/>}  exact/>
              </Route>
            </Route>
            <Route path="uppath" element={<DragDrop/>}  exact/>
            <Route path="preview" element={<ViewDel/>}  exact/>
        </Route>
    </Route>
  )
);

function App() {
  return (
    <FileUploadProvider>
      <div className="App">
        <ErrorBoundary>
          <RouterProvider router={router}  exact/>
        </ErrorBoundary>
      </div>
    </FileUploadProvider>
  );
}

export default App;
