import { ProfileForm, ProfileInput, Spinner, ProfileLabel, ProfileButton, ProfileCount } from "../style"
import { useEffect, useState } from "react";
import { postData } from "../../../features/postSlice/post"; 
import { putData, successClear, errorClear } from "../../../features/putSlice/put";
import { store } from "../../../app/store";
import { signoutRequest } from "../../../features/purge/purge";
import { setAlert } from "../../../features/searchSlice/search";
import Cookies from 'js-cookie'
import { useDispatch, useSelector } from "react-redux";
import { checkPassword, handleRequest, postHandle, putHandle } from "../../utility/drag_drop";
import { setCount } from "../../../features/pageSlice/page";
import { editUpdate } from "../../../features/signinSlice/signin";


const Security = () => {
    const data = useSelector((state) => state.signin.user);
    const edit = useSelector((state) => state.signin.edit);
    const details = useSelector((state) => state.put);
    const dispatch = useDispatch();
    const config = {'Content-Type': 'multipart/form-data',
                    "Authorization": data.tokens? `JWT ${data.tokens.access}` : null
                    }
    const[values, setValues] = useState({
        email: '',
        otp: '',
        password: '',
        password2: ''
    })
    const[pass, setPass] = useState("");
    const[pload, setPloading] = useState(false);
    const[timer, setTimer] = useState(0);
    const[loading, setLoading] = useState(false);
    const url = `${process.env.REACT_APP_BASE_APP_URL}/user/${data.id}/update/`
    const url2 = `${process.env.REACT_APP_BASE_APP_URL}/user/password_reset/${data.id}/`
    const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`
    const initialCount = useSelector((state) => state.page.count);

    const handleChange = (e) => {
        e.preventDefault();
        const target = e.target
        if(target.name === "password2"){
            setPass(target.value)
        }else if(target.name === "password"){
            if(checkPassword(target.value)){
                if(target.value !== pass){
                dispatch(setAlert({alert: true, message: "password mismatch"}));
                } else{setValues({...values, [target.name]: target.value})}
            }
        }else {
        setValues({...values, [target.name]: target.value})
        }
    }
    
    const phoneUp = async () => {
        const dat = await putHandle({url: url, data: values, config: config});
        const response = handleRequest(dat, dispatch, config, data?.tokens.refresh)
        if(response === "success"){
            dispatch(setAlert({alert: true, message: 'Phone number updated', color: '#90EE90'}));
            setTimer(60); 
            updateCounter();
        }
    }

    const currentDate = new Date();
    
    function startCount(counted){
        if(counted !== 60 - timer){
            setTimer(60 - counted)
        }
        let timerId = setInterval(() => {
            setTimer((prevTimer) => {
            if(prevTimer <= 1){
                clearInterval(timerId);
                return 0;
            } else {
                return prevTimer-1
            }})
        }, 1300)
    }

    function updateCounter(){
        const currentTime = currentDate.getSeconds()
        if(initialCount === 0){
            dispatch(setCount(currentDate.getSeconds));
        }
        let counted;
        if(currentTime > initialCount){
            counted = currentTime - initialCount
        }else {
            counted = 60 - initialCount + currentTime
        } 
        startCount(counted);
    }

    const getOtp = async (e) => {
        dispatch(setCount(0));
        e.preventDefault();
        if(timer === 0){
            setLoading(!loading);
            const dat = await postHandle({url: `${process.env.REACT_APP_BASE_APP_URL}/user/request_otp/`, data: {'email': data.email}, config: config});
            const response = handleRequest(dat, dispatch, config, data?.tokens.refresh)
            if(response === "success"){
                dispatch(setAlert({alert: true, message: 'Otp sent, Please check your devices email and sms', color: '#90EE90'}));
                setTimer(60); 
                updateCounter();
            }
            setLoading(false);
        }
    }

    async function verifyOtp(e){
        e.preventDefault();
        const dat = await postHandle({url: `${process.env.REACT_APP_BASE_APP_URL}/user/verify_otp/`, data: {'otp': values.otp}, config: config});
        const response = handleRequest(dat, dispatch, config, data?.tokens.refresh)
        if(response === "success"){
            dispatch(editUpdate(dat.data));
            dispatch(setCount(0));
        }
    }

    function handleSubmit(e){
        e.preventDefault();
        if(values.password === "" && values.phone_no === ""){
            dispatch(setAlert({alert: true, message: 'Fill out a field'}));
        }else if(values.password !== "") {
            dispatch(putData({url: url2, data: {"password": values.password, "password2": values.password}, config: config}));
        }
        if(values.phone_no !== ""){
            phoneUp();
        }
        dispatch(editUpdate(""));
    }

    useEffect(() => {
        if (details.loading === false && details.error !== ""){
            if(details.status === 400){
                dispatch(setAlert({alert: true, message: details.error}));
                dispatch(errorClear());
            }else if (details.status === 401){
                dispatch(setAlert({alert: true, message: 'Session has expired'}));
                dispatch(postData({url: lout, config: {"Content-Type": "application/json"}, data: data.tokens}));
                dispatch(errorClear());
                Cookies.remove("data");
                store.dispatch(signoutRequest());
            }else{
                dispatch(setAlert({alert: true, message: 'Network Error'}));
                dispatch(errorClear());
            }
        } else if (details.loading === false && details.error === "" && details.success === true){
            dispatch(setAlert({alert: true, message: "Your details have been Changed", color: '#90EE90'}));
            dispatch(successClear());
        }
    }, [details.loading])

    return(
        <>
            {(!edit && edit.message !== "reset available") || edit === ""?
                <>
                {timer === 0?
                    <ProfileButton wt="25%" otp="auto auto 1rem auto" onClick={getOtp}>
                        {loading?
                            <Spinner 
                                color={"white"}
                                loading={true}
                                size={25}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                            :
                            "Request otp"
                        }
                    </ProfileButton>
                    :
                    <ProfileCount>wait {timer} seconds</ProfileCount>
                }
                <ProfileForm>
                    <ProfileLabel for="otp">Type in the six digit code sent to you</ProfileLabel>
                    <ProfileInput type="number" placeholder="444444" name="verify" id="otp" onChange={handleChange}/>
                    <ProfileButton er wt="35%" onClick={verifyOtp}>
                    {details.loading || pload ?
                        <Spinner 
                            color={"white"}
                            loading={true}
                            size={25}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        :
                        "Verify"
                    }
                    </ProfileButton>
                </ProfileForm>
                </>
                :
                <ProfileForm>
                <ProfileLabel for="psd">Change password</ProfileLabel>
                <ProfileInput type="password" placeholder="************" name="password2" id="psd" onChange={handleChange}/>
                <ProfileLabel for="rpsd">Repeat password</ProfileLabel>
                <ProfileInput type="password" placeholder="************" name="password" id="rpsd" onChange={handleChange} />
    
                <ProfileButton er wt="40%" onClick={handleSubmit}>
                    {details.loading || pload ?
                        <Spinner 
                            color={"white"}
                            loading={true}
                            size={25}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        :
                        "Save"
                    }
                </ProfileButton>
            </ProfileForm>
            }
        </>
    )
}


export default Security;