import styled from "styled-components";
import ClipLoader from "react-spinners/ClipLoader";

export const DragWrapper = styled.div`
    background: rgba(217, 217, 217, 0.2);
    padding: 48px;
    margin: auto;
    width: 50%;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.display {
        flex-wrap: wrap;
        overflow-y: scroll;
        flex-direction: row;
        width: 63%;
        gap: 6%;
        height: 6rem;
    }
`
export const Header = styled.h1`
    font-size: 1.5rem;
`
export const GrayHeader = styled(Header)`
    margin: auto;
    color: gray;
`

export const Ptag = styled.p`
    width: 50%;
    text-align: center;
`

export const BlankWrapper = styled.div`
    display: none;
    background: rgb(0, 0, 0, 0.5);
    align-items: center;
    position: fixed;
    width: 100vw;
    bottom: 0;
    height: 101vh;
    z-index: 1;
`
export const Icon = styled.img`

`
export const VarIcon = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    cursor: pointer;
`
export const Wrapper = styled.div`
    margin: auto;
    width: 30%;
    height: 50vh;
    margin-top: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    background: white;
    border: 1px dashed rgba(217, 217, 217, 1);
    padding: 3.7%;
    border-radius: 3px;
    align-items: center;
    @media screen and (max-width: 900px){
        width: 40%;
    }
    @media screen and (max-width: 700px){
        width: 52%;
    }
    @media screen and (max-width: 600px){
        width: 70%;
    }
    @media screen and (max-width: 500px){
        width: 93%;
    }
`
export const VariantW = styled.div`
    margin: auto;
    width: 30%;
    height: 50vh;
    margin-top: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    background: white;
    border: 1px solid rgba(217, 217, 217, 1);
    padding: 2.7%;
    border-radius: 3px;
    align-items: center;
    @media screen and (max-width: 900px){
        width: 40%;
    }
    @media screen and (max-width: 700px){
        width: 52%;
    }
    @media screen and (max-width: 600px){
        width: 70%;
    }
    @media screen and (max-width: 500px){
        width: 93%;
    }
`
export const Button = styled.button`
    height: 40px;
    border-radius: 23px;
    border: none;
    background: rgba(17, 115, 171, 1);
    color: white;
    width: 38%;
    /* margin-bottom: 24px; */
    cursor: pointer;
`

export const Preview = styled.span`
    background: url(${({src}) => src});
    background-size: cover;
    height: 5rem;
    width: 5rem;
    position: relative;
`

export const Input = styled.input.attrs({
    type: 'file',
})`
    display: none;
`

export const TinyRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`
export const Spinner = styled(ClipLoader)`
    align-self: center;
    justify-self: center;
`