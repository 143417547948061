import React from 'react'
import { APIProvider, Map, AdvancedMarker, Pin } from '@vis.gl/react-google-maps'
import { MapWrapper } from './style';

const DisplayMap = ({lat, lang}) => {
    const position = {lat: lat, lng: lang}; 
    const MAPKEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY
    const MAPID = process.env.REACT_APP_GOOGLE_MAP_ID
  return (
    <APIProvider apiKey={MAPKEY}>
      <MapWrapper>
      <Map defaultCenter={position} defaultZoom={13} mapId={MAPID}>
        <AdvancedMarker key={'Sydney'} position={position} >
          <Pin background={'#FBBC04'} glyphColor={'#000'} borderColor={'#000'} />
        </AdvancedMarker>
      </Map>
      </MapWrapper>
    </APIProvider>
  )
}

export default DisplayMap;