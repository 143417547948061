import styled from 'styled-components';

export const CardSliders = styled.section`
    display: flex;
    width: ${({wt}) => wt? wt : '100%'};
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 1;
`
export const SSliders = styled.section`
    display: none;
    width: ${({wt}) => wt? wt : '100%'};
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 1;
    @media screen and(max-width: 900px){
        display: flex;
    }
`

export const ScrollBtn = styled.button`    
    height: 40px;
    width: 40px;
    background: transparent;
    opacity: 0.4;
    padding: 0;
    border: none;
    position: ${({pos}) => pos ? pos : "relative"};
    right: ${props => props.right};
    left: ${props => props.left};
    top: ${props => props.btm};
    cursor: pointer;
    &:hover{
        opacity: 1;
    };
`
export const ScrollIcon = styled.img`
    height: 40px;
`

export const VariantScroll = styled(ScrollBtn)`
    top: ${props => props.btm? "-7rem": "8rem"};
`