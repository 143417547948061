import { useNavigate } from "react-router-dom"
import globe from "../../../../assets/globe.png"
import { ListButton, ListImg, ListWrapper, ListHeader } from "../style"

const Done = () => {
  const navigate = useNavigate()
  return (
    <ListWrapper>
      <ListHeader>Your Property Has Been Listed</ListHeader>
    <ListImg src={globe} />
    <ListButton var set onClick={() => navigate('/')} >Back to home</ListButton>
    </ListWrapper>
  )
}

export default Done