import styled from "styled-components"

export const AddressInput = styled.input`
    background: ${({role}) => role === 'hotel'? 'none': 'rgba(238, 238, 238, 1)'};
    border: ${({role}) => role === 'hotel'? '1px solid #1173AB' : 'none'};
    border-radius: 6px;
    height: ${({role}) => role === 'hotel'? '2.5rem':'3rem'};
    padding-left: 3%;
    width: 97%;
    &:focus {
    border: 1px solid #3498db;
    outline: none; /* Remove default focus outline */
    /* Add any other styles you want when the input is focused */
  }
`

export const ResultWrapper = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: none;
    border-radius: 4px;
    padding: 3%;
    scroll-behavior: smooth;
    overflow-x: auto;
    height: 150.17px;
    background: rgba(17, 115, 171, 1);
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 4px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }
    &::-webkit-scrollbar-thumb {
        background: #888; 
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #555;       
    }
`

export const ResultItems = styled.li`
    cursor: pointer;
    color: white;
`