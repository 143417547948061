import { useNavigate } from "react-router-dom"
import created from "../../../assets/icons/complete.svg"
import { Banners, FormWrapper, FormHeader, TextF } from "../style"
import { useEffect } from "react"


const Created = () => {
  const navigate = useNavigate();

  useEffect (() => {
    setTimeout(() => {
      navigate("/");
    }, 7000)
  }, [])
  return (
    <FormWrapper>
      <FormHeader reg>Registration complete</FormHeader>
      <Banners er src={created} alt="reviewing" />
      <FormHeader>We are reviewing your details</FormHeader>
      <TextF>And will get back to you soon</TextF>
      <TextF>Redirecting to homepage...</TextF>
    </FormWrapper>
  )
}

export default Created