import { ProfileList, ProfilePerties, ProfileText, ProfileStrong, SupportRocket, ProfileForm, ProfileLabel, ProfileInput, ProfileButton, Spinner, ProfileArea } from "../style"
import rocket from '../../../assets/rocket.png'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postData, errorClear, successClear } from "../../../features/postSlice/post"; 
import { store } from "../../../app/store";
import { signoutRequest } from "../../../features/purge/purge";
import { setAlert } from "../../../features/searchSlice/search";
import Cookies from 'js-cookie'



const Support = () => {
    const data = useSelector((state) => state.signin.user);
    const url = `${process.env.REACT_APP_BASE_APP_URL}/user/support/`
    const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`
    const msg = useSelector((state) => state.post);
    const dispatch = useDispatch();
    const config = {
        "Content-Type": "application/json",
        "Authorization": data.tokens? `JWT ${data.tokens.access}` : null
    }
    const[values, setValues] = useState({
        name: `${data.first_name} ${data.last_name}`,
        email: data.email,
        phone_no: data.phone_no,
        message: ''
    })

    const handleChange = (e) => {
        const target = e.target
        setValues({...values, [target.name]: target.value});
    }

    const handleSubmit = () => {
        dispatch(postData({url: url, data: values, config: config }));
    }

    useEffect(() => {
        if(msg.status === 400){
            dispatch(setAlert({alert: true, message: msg.error}));
            dispatch(errorClear());
        }else if (msg.status === 401){
            dispatch(setAlert({alert: true, message: 'Session has expired'}));
            dispatch(postData({url: lout, config: {"Content-Type": "application/json"}, data: data.tokens}));
            dispatch(errorClear());
            Cookies.remove("data");
            store.dispatch(signoutRequest());
        }else if (msg.loading === false && msg.error === "" && msg.success === true){
            dispatch(setAlert({alert: true, message: "Your message has been sent", color: '#90EE90'}));
            dispatch(successClear());
        }else if(msg.error !== ""){
            dispatch(setAlert({alert: true, message: 'Network Error'}));
            dispatch(errorClear());
        }
    }, [msg.loading])
    return (
        <ProfileList rket="support rocket">
            <SupportRocket>
                <ProfilePerties src={rocket} alt="support" />
                <ProfileText>Send your complaint to support@pinearth.com</ProfileText>
            </SupportRocket>
            <ProfileForm wt="100%">
                <ProfileLabel for="name"><ProfileStrong>Name</ProfileStrong></ProfileLabel>
                <ProfileInput type="text" placeholder={values.name} onChange={handleChange} name="name" id="name" />
                <ProfileLabel for="email"><ProfileStrong>Email</ProfileStrong></ProfileLabel>
                <ProfileInput type="email" placeholder={values.email} name="email" id="email" onChange={handleChange} />
                <ProfileLabel for="tel"><ProfileStrong>Phone no</ProfileStrong></ProfileLabel>
                <ProfileInput type="text" placeholder={values.phone_no} name="phone_no" id="tel" onChange={handleChange} />
                <ProfileLabel for="adrs"><ProfileStrong>Message</ProfileStrong></ProfileLabel>
                <ProfileArea id="message" name="message" onChange={handleChange}></ProfileArea>
            </ProfileForm>
            <ProfileButton wt="100%" onClick={handleSubmit} er>
            {msg.loading? 
                    <Spinner 
                    color={"white"}
                    loading={true}
                    size={25}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                        />
                :
                "Send"}
            </ProfileButton>
        </ProfileList>
    )
}

export default Support;