import React, { useEffect, useState } from 'react'
import { AddressInput, ResultItems, ResultWrapper } from './style';
import useOnclickOutside from "react-cool-onclickoutside";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
  } from "use-places-autocomplete";
import { useDispatch, useSelector } from 'react-redux';
import { listAdd } from '../../features/registerSlice/rdataSlice';


const AutoComplete = () => {
    const addressData = useSelector((state) => state.data.list.address);
    const dispatch = useDispatch();
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
      } = usePlacesAutocomplete({
        callbackName: "YOUR_CALLBACK_NAME",
        requestOptions: {
            // locationRestriction: {
            //     west: -122.44,
            //     north: 37.8,
            //     east: -122.39,
            //     south: 37.78,
            // },
            // origin: { lat: 37.7893, lng: -122.4039 },
            // includedPrimaryTypes: ["restaurant"],
            // language: "en-US",
            region: "ng",
            },
            debounce: 300,
      });
    const ref = useOnclickOutside(() => {
    // When the user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
    });

    const handleInput = (e) => {
        // Update the keyword of the input element
        setValue(e.target.value);
        dispatch(listAdd({address: e.target.value}));
    };
    const handleSelect = ({ description }) =>
        () => {
        // When the user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);
        dispatch(listAdd({address: description}));
        clearSuggestions();

        // Get latitude and longitude via utility functions
        getGeocode({ address: description }).then((results) => {
            const { lat, lng } = getLatLng(results[0]);
            dispatch(listAdd({latitude: lat, longitude: lng}));
            console.log("📍 Coordinates: ", { lat, lng });
        });
    };

    const renderSuggestions = () =>
        data.map((suggestion) => {
          const {
            place_id,
            structured_formatting: { main_text, secondary_text },
          } = suggestion;
    
          return (
            <ResultItems key={place_id} onClick={handleSelect(suggestion)}>
              <strong>{main_text}</strong> <small>{secondary_text}</small>
            </ResultItems>
          ); 
        });
    // Add an initial request body.
    // const [rdata, setRData] = useState({
    //     title: '',
    //     results: [],
    //     input: '',
    //     token: ''
    // })
    // const [request, setRequest] = useState({
    //     input: "",
    //     // locationRestriction: {
    //     //     west: -122.44,
    //     //     north: 37.8,
    //     //     east: -122.39,
    //     //     south: 37.78,
    //     // },
    //     // origin: { lat: 37.7893, lng: -122.4039 },
    //     // includedPrimaryTypes: ["restaurant"],
    //     language: "en-US",
    //     region: "ng",
    // });

    // useEffect(() => {
    //     setRData({
    //         token: new google.maps.places.AutocompleteSessionToken(),
    //         request: refreshToken(request)       
    //     })
    // }, [])

    // // async function init() {
    
    // // title = document.getElementById("title");
    // // results = document.getElementById("results");
    // // input = document.querySelector("input");
    // // input.addEventListener("input", makeAcRequest);
    
    // // }

    // async function makeAcRequest(e) {
    //     e.preventDefault();
    //     // Reset elements and exit if an empty string is received.
    //     // if (input.target.value == "") {
    //     //     title.innerText = "";
    //     //     results.replaceChildren();
    //     //     return;
    //     // }

    //     // Add the latest char sequence to the request.
    //     setRequest({
    //         ...request,
    //         input: e.target.value
    //     })

    //     // Fetch autocomplete suggestions and show them in a list.
    //     // @ts-ignore
    //     const { suggestions } =
    //         await google.maps.places.AutocompleteSuggestion.fetchAutocompleteSuggestions(
    //         request,
    //         );

    //     //title.innerText = 'Query predictions for "' + request.input + '"';
    //     // Clear the list first.
    //     //results.replaceChildren();
    //     setRData({
    //         ...rdata,
    //         results: [...suggestions]
    //     })
    //     // for (const suggestion of suggestions) {
    //     //     const placePrediction = suggestion.placePrediction;
    //     //     // Create a link for the place, add an event handler to fetch the place.
    //     //     const a = document.createElement("a");

    //     //     a.addEventListener("click", () => {
    //     //     onPlaceSelected(placePrediction.toPlace());
    //     //     });
    //     //     a.innerText = placePrediction.text.toString();

    //     //     // Create a new list element.
    //     //     const li = document.createElement("li");

    //     //     li.appendChild(a);
    //     //     results.appendChild(li);
    //     // }
    // }

    // // Event handler for clicking on a suggested place.
    // async function onPlaceSelected(place) {
    // await place.fetchFields({
    //     fields: ["displayName", "formattedAddress"],
    // });

    // setRData({
    //     ...rdata,
    //     title: place.displayName + ": " + place.formattedAddress
    // });
    // refreshToken(request);
    // }

    // // Helper function to refresh the session token.
    // async function refreshToken(request) {
    // // Create a new session token and add it to the request.
    //     setRData({
    //         ...rdata,
    //         token: new google.maps.places.AutocompleteSessionToken()
    //     })
    //     setRequest({
    //         ...request,
    //         sessionToken: rdata.token
    //     })
    //     return request;
    // }

    // window.init = init;
  return (
    // <>
    //     <AddressInput type='text' placeholder='address' name='address' id='address_input' onChange={makeAcRequest}/>
    //     <ResultWrapper>
    //         {rdata.results.length > 0 &&
    //             rdata.results.map((item, index) => (
    //                 <ResultItems onClick={onPlaceSelected(item.placePrediction?.toPlace())} key={index}>{item.placePrediction}</ResultItems>
    //             ))
    //         }
    //     </ResultWrapper>
    // </>
    <div ref={ref}>
      <AddressInput
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder={addressData? addressData : "E.g NO 4. JUNE AVE"}
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && <ResultWrapper>{renderSuggestions()}</ResultWrapper>}
    </div>
  )
}

export default AutoComplete