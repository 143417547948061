import { useEffect, useState, useRef } from "react";
import { FormButton, FormHeader, FormInput, FormLabel, FormWrapper, ProgressBar, FormP, ProgressBorder, FormSpan, FormSelect, FormOption, FormStrong, Spinner, FormDrag, Icons } from "../style"
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { roleClear } from "../../../features/registerSlice/rdataSlice";
import { packToForm } from "../../utility/drag_drop";
import { errorClear, postData, successClear } from "../../../features/postSlice/post";
import { handleDragLeave, handleDragOver } from "../../utility/drag_drop";
import image from "../../../assets/icons/images.svg";
import { store } from "../../../app/store";
import { signoutRequest } from "../../../features/purge/purge";
import Cookies from 'js-cookie'
import { setAlert } from "../../../features/searchSlice/search";
import { states } from "../../../data/area";


const End = () => {
    const val = useSelector((state) => state.data.role);
    const [reg, setReg]=useState({
        address: val.address,
        state: val.state,
        id_upload: val.id_upload
    })
    const user = useSelector((state) => state.signin.user);
    let { roles } = useParams();
    const error = useSelector((state) => state.post);
    const url = `${process.env.REACT_APP_BASE_APP_URL}/role/${roles.toLowerCase()}/` 
    const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`
    const config = { 
                    "Content-Type": "multipart/form-data",
                    "Authorization": `JWT ${user.tokens.access}`
                }
    const navigate = useNavigate();
    const handleChange = (e) => {
        e.preventDefault();
        const target = e.target;
        setReg({...reg, [target.name]: target.value});
    }
    
    const dispatch = useDispatch();
 
    const fileInputRef = useRef(null);
    const handleUploadButtonClick = () => {
        fileInputRef.current.click(); // Trigger the file input click event
      };
 
    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0]
        const prof = window.URL.createObjectURL(file)
        const target = event.target
        setReg({...reg, [target.getAttribute('data-name')]: prof})
      }
    const handleFileInputChange = (event) => {
        console.log(event.target.files[0]);
        const prof = window.URL.createObjectURL(event.target.files[0]);
        const target = event.target
        setReg({...reg, [target.name]: prof});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
            const FullData = {...val, ...reg}
            const text = ['an Agent'].includes(roles)? ["profile_photo", "company_id", "id_upload"] : ['a Landlord'].includes(roles)? ["profile_photo", "id_upload"] : ["profile_photo", "company_id"]
            const form = await packToForm(FullData, text);
            dispatch(postData({url:url, data:form, config:config}));
        }
        useEffect(() => {
            if (error.loading === false && error.error !== ""){
                if(error.status === 400){
                    dispatch(setAlert({alert: true, message: error.error}));
                    dispatch(errorClear());
                }else if (error.status === 401){
                    dispatch(setAlert({alert: true, message: 'Session has expired'}));
                    dispatch(postData({url: lout, config: {"Content-Type": "application/json"}, data: user.tokens}));
                    dispatch(errorClear());
                    Cookies.remove("data");
                    store.dispatch(signoutRequest());
                }else{
                    dispatch(setAlert({alert: true, message: 'Network Error'}));
                    dispatch(errorClear());
                }
            }else if (error.loading === false && error.data.error === "This user has a role"){
                dispatch(setAlert({alert: true, message:'This user has a Business account'}));
                dispatch(successClear());
            }else if (error.loading === false && error.error === "" && error.success === true){
                dispatch(roleClear());
                navigate("/role created");
                dispatch(successClear());
            }
        }, [error.loading])
    
        function Back(e){
            e.preventDefault();
            navigate(-1);
        }

    return(
        <FormWrapper>
            <FormHeader>Register as {roles} </FormHeader>
            <ProgressBorder>
                <ProgressBar wt="85%"></ProgressBar>
            </ProgressBorder>
            <FormLabel for="adrs"><FormStrong>Office Address</FormStrong></FormLabel>
            <FormInput type="text" placeholder={val.address? val.address : "6291 Elign St. Cotza Delavue 50200"} name="address" onChange={handleChange} id="adrs" />
            <FormLabel for="state"><FormStrong>State</FormStrong></FormLabel>
            {/* <FormInput type="text" placeholder={val.state? val.state : "E.g Rivers state"} name="state" onChange={handleChange} id="state"  /> */}
            <FormSelect name="state" onChange={handleChange} id="state" defaultValue={val.state}>
                {states.map((item, index) => (
                    <FormOption key={index} value={item}>{item}</FormOption>
                ))}
            </FormSelect>
            {['an Agent', 'a Landlord'].includes(roles) &&
            <>
            <FormLabel for="id_upload"><FormStrong>ID Card</FormStrong><FormP>*image must be clear</FormP></FormLabel>
            <FormDrag  onDragLeave={handleDragLeave} data-name="id_upload" onDragOver={handleDragOver} onDrop={handleDrop} onClick={handleUploadButtonClick} >
                <Icons src={reg.id_upload != null? reg.id_upload : image} upP={reg.id_upload != null ? true : false} alt="drop image"/>
                <FormInput type="file" onChange={handleFileInputChange} ref={fileInputRef} name="id_upload" none accept="image/*" />
            </FormDrag>
            </>
            }
            <FormSpan>
                <FormButton left onClick={Back} >Back</FormButton>
                <FormButton onClick={handleSubmit}>
                {error.loading?
                        <Spinner
                            color={"white"}
                            loading={true}
                            size={25}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        : 
                        "Finish"
                }
                </FormButton>
            </FormSpan>
        </FormWrapper>
    )
}

export default End;