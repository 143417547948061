import React from 'react'
import { LoadingCont, Spinner } from './style'

const Loading = () => {
  return (
    <LoadingCont>
        <Spinner
                color={"rgba(17, 115, 171, 1)"}
                loading={true}
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
                />
    </LoadingCont>
  )
}

export default Loading