import { SellWrapper, SellMini, SellContainer, SellHeader, SellP, SellImage } from "./style";
import SellComp from './sell'
import family from '../../../assets/Family.png'
import transport from '../../../assets/transport.png'
import { useSelector } from "react-redux";


const SellPage = () => {
    const user = useSelector((state) => state.signin.user)

    return(
        <SellWrapper>
            <SellContainer align="center" top>
                <SellHeader>Sell your home with confidence</SellHeader>
                <SellP>Pinearth making it simpler to sell your home and move forward</SellP>
                <SellImage src={family} alt="family"/> 
            </SellContainer>
            <SellMini>
                <SellContainer align="left">
                    <SellHeader>Sell with a partner agent or sell it yourself</SellHeader>
                    <SellP>Pinearth helps you sell your home, your way. Easily explore your selling options below and get personalized market value estimates — we can even help you choose the best option when you’re ready.</SellP>
                </SellContainer>
                <SellImage src={transport} alt="truck" ht="md" />
            </SellMini>
            <SellMini>
                <SellComp
                  head="Sell your property by yourself"
                  text="Not in a market with Pinearth’s new selling experience? Work with a real estate agent for selling support at every step, including prepping, listing and marketing your home."
                  size="30%"
                  btntext="Find an Agent"
                  align="left"
                />
                <SellComp 
                  head="Sell your property by yourself"
                  text="Not in a market with Pinearth’s new selling experience? Work with a real estate agent for selling support at every step, including prepping, listing and marketing your home."
                  btntext={user && user.has_role && !['EventCenter', 'Bank'].includes(user.role)? "List a property" : "Become an agent"}
                  ant="variant"
                  align="left"
                />
            </SellMini>
        </SellWrapper>
    )
}

export default SellPage