import styled from 'styled-components';



export const HeaderWrapper = styled.div`
 background: url(${props => props.src});
 display: flex;
 flex-direction: column;
 height: 298px;
`
export const Header = styled.h1`
    text-align: center;
    font-size: 1.81rem;
    width: 30%;
    align-self: center;
    margin-top: 3rem;
    color: white;
    @media screen and (max-width: 600px){
        display: none;
    }
    /* @media screen and (max-width: 600px) {
        width: 76%;
         font-size: 20px;
    }
    @media screen and (max-width: 375px) {
        width: 80%;
        font-size: 20px;
    } */
`