import styled from 'styled-components'
import ClipLoader from "react-spinners/ClipLoader";

export const ContactImg = styled.img`
    height: 140px;
    width: 140px;
    border-radius: 999px;
    @media screen and (max-width: 600px){
        height: 98px;
        width: 98px;
    }
`

export const ContactAcross = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
`

export const ContactHeader = styled.h1`
    font-size: 1.7rem;
    display: flex;
    margin-left: ${({lf}) => lf? "10%" : "" };
`

export const ContactP = styled.p`
    margin-bottom: ${({bt}) => bt? "2rem" : ""};
`

export const ContactWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: ${props => props.width};
    margin: ${props => props.place};
    margin-top: ${props => props.top? "6.5rem": ""}; 
    @media screen and (max-width: 600px) {
        flex-direction: ${({vt}) => vt};
        align-items: center;
        width: 90%;
    }
`
export const ContactInnerWrapper = styled.div`
    display: flex;
    gap: 5%;
    align-items: center;;
`

export const ContactInfoBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2%;
`
export const ContactContainer = styled.div`
    display: ${({pos}) => pos};
    flex-direction: ${({pos}) => pos? "column":""};
    text-align: left;
    width: ${props => props.width};
    gap: ${({gp}) => gp? '6rem':'' };
    @media screen and (max-width: 600px) {
        width: ${({wt}) => wt};
        gap: ${({gp}) => gp? '6rem':'1%' };
    }
`

export const ContactWrite = styled.button`
    border: 1px solid rgba(0, 0, 0, 0.5);
    color: rgba(0, 0, 0, 0.5);
    background: none;
    border-radius: 3px;
    height: 36px;
    width: 29%;
    text-align: center;
    align-self: center;
    @media screen and (max-width: 522px){
        width: 40%;
    }
`

export const ContactHighlight = styled(ContactP)`
    font-size: 10px;
    color: rgba(17, 115, 171, 1);
    text-align: center;
    cursor: pointer;
`

export const ContactBar = styled.div`
    display: flex;
    background: rgba(17, 115, 171, 1);
    text-align: left;
    color: white;
    padding: 0.3%;
    align-items: center;
`
export const ContactIcon = styled.img`
    padding: 0 1.5%;
    height: 20px;
    cursor: pointer;
`
export const Spinner = styled(ClipLoader)`
    align-self: center;
    justify-self: center;
`

export const LoadingCont = styled.section`
    height: 100vh;
    display: grid;
`

export const CenterContainer = styled.section`
    display: flex;
    flex-direction: ${({dir}) => dir};
    gap: 1rem;
    text-align: ${({al}) => al};
`

export const CenterIcon = styled.img`
    width: 24px;
    height: 24px;
`

export const CenterHeader = styled.h1`
    color: grey;
    font-size: 1rem;
    font-weight: 700;
`

export const CenterText = styled.p`
    font-weight: 700;
`

export const CenterInner = styled.ul`
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    gap: 4%;
`
export const CenterItem = styled.li``

export const CenterImage = styled.img`
    height: 300px;
    width: 300px;
`

export const CopyBtn = styled.button`
    /* height: 19px; */
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 45%;
    padding: 0;
    /* padding: 2%; */
    border: 2px solid rgb(17, 115, 171);
    border-radius: 11px;
    background: transparent;
    display: flex;
    cursor: pointer;
` 