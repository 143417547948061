import { AlertClose, AlertText, AlertWrapper } from "./style"
import close from "../../assets/icons/closew.svg"
import { useDispatch } from "react-redux";
import { setAlert } from "../../features/searchSlice/search";



const ShowAlert = ({text, color}) => {
    const dispatch = useDispatch();


    return(
        <AlertWrapper color={color}>
            <AlertText>{text}</AlertText>
            <AlertClose src={close} onClick={() => dispatch(setAlert(false))} alt="close" />
        </AlertWrapper>
    )
}

export default ShowAlert;