import React, { useEffect, useState } from 'react'
import { ListButton, 
  ListDrag, 
  ListHeader, 
  ListIcon, 
  ListInput, 
  ListLabel, 
  ListOption, 
  ListPred, 
  ListRow, 
  ListSelect, 
  ListWrapper, 
  ImgCount, 
  UButton, ExtraSpan, ListP, ListBoxWrapper, ListBox, ListConnector, ListSubHeader, SpecCol, Spinner, ListStrong, 
  ListLink} from '../style'
import galy from '../../../../assets/galy.png'
import doc from '../../../../assets/icons/docs.svg'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { listAdd, listClear } from '../../../../features/registerSlice/rdataSlice'
import { ConfirmState, packToForm2, getHandle, handleRequest } from "../../../../components/utility/drag_drop";
import NumProgress from '../../../../components/progress'
import { setAlert } from "../../../../features/searchSlice/search";
import { postData, errorClear, successClear } from "../../../../features/postSlice/post"; 
import { setTag } from '../../../../features/pageSlice/page';
import { store } from "../../../../app/store";
import { signoutRequest } from "../../../../features/purge/purge";
import Cookies from 'js-cookie'
import { fornext } from '../../../../features/pageSlice/page'
import { states } from '../../../../data/area'
// import datas from  '../../../../data.json'
import { Back } from '../btn'
import { tools_pinplug } from '../../../../data/formFields'
import {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import AutoComplete from '../../../../components/autocomplete'


const ListFirst = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.signin.user);
  const post = useSelector((state) => state.post);
  const prevpage = useSelector((state) => state.page.prevPage);
  const tags = useSelector((state) => state.page.tagData);
  // const user = datas.users[0]
  const navigate = useNavigate();
  const values = useSelector((state) => state.data.list);
  const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`
  const config = {
    "Content-Type": "multipart/form-data",
    "Authorization": `JWT ${user.tokens.access}`
  }
  
  const [data, setData] = useState({
    property_status: `${['/profile/manage%20rentals'].includes(prevpage)? values.property_status : ['Hotel'].includes(user.role)? 'Room' : ['Short-let'].includes(user.role)? 'Short-let': ['Pinearthling'].includes(user.role)? 'Tools' : ['Agent', 'Developer'].includes(user.role)? 'For sale': values.property_status}`,
    property_price: values.property_price,
    property_type: `${['/profile/manage%20rentals'].includes(prevpage)? values.property_type : ['Hotel'].includes(user.role)? 'Room': ['Short-let'].includes(user.role)? 'Short-let': ['Pinearthling'].includes(user.role)? 'Pinplug' : values.property_type}`,
    duration: `${['/profile/manage%20rentals'].includes(prevpage)? values.duration : ['Hotel'].includes(user.role)? "Night" : "Month"}`,
    no_of_plots: values.no_of_plots, 
    title: values.title, 
    desc: values.desc, 
    uhouse_view: values.uhouse_view,
    uland_image: values.uland_image,
    price: 0,
    phone_no: values.phone_no,
    state: values.state,
    // address: values.address,

  })

  useEffect(() => {
    if(data.property_status !== ""){
      dispatch(listAdd(data));
    }
  }, [data])

  const retrieve = async () => {
    const tag_list = await getHandle({url: `${process.env.REACT_APP_BASE_APP_URL}/role/tag/`, config: config})
    const response = handleRequest(tag_list, dispatch, config, user?.tokens.refresh);
    if(response === "success"){ 
      dispatch(setTag(tag_list.data));
    }
  }
  useEffect(() => {
    if(user.role === "Hotel"){
      retrieve();
    }
  }, [])

  const handleChange = (event) => {
    const target = event.target
    if(target.name === 'property_price'){
      setData({
        ...data, property_price: target.value
      })
    }else if(target.name === 'property_status'){
      if(target.value === 'Tools'){
        setData({
          ...data, property_status: target.value, property_type: 'Pinplug'
        })
      }else if(target.value === 'Land'){
        setData({
          ...data, property_status: target.value, property_type: 'Land'
        })
      }else{
        setData({
          ...data, property_status: target.value
        })
      }
    }else {
      setData({
      ...data, [target.name]: target.value
    })
  }
  }

  function uploadImg(val){
    dispatch(fornext({id: val, nextPage: "/list/1"}));
    navigate("/uppath");
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(['Pinplug', 'Land'].includes(data.property_type)){
      const key = ['Pinplug'].includes(data.property_type)? ['title', 'desc', 'property_price', 'phone_no', 'state', 'address'] : ['Land'].includes(data.property_type)? ['title', 'desc', 'lot_size', 'no_of_plots', 'property_price', 'state', 'address'] : []
      if(ConfirmState(key, values).valid){
        if(['Land'].includes(data.property_type)){
          navigate('/list/5');
        } else {
          if(values.latitude === 0){
            getGeocode({ address: values.address }).then((results) => {
              const { lat, lng } = getLatLng(results[0]);
              dispatch(listAdd({latitude: lat, longitude: lng}));
            });
          }
          const text = ['uhouse_view']
          const isarr = ''
          const sin = []
          const formData = await packToForm2(values, text, isarr, sin, tools_pinplug)
          dispatch(postData({url:`${process.env.REACT_APP_BASE_APP_URL}/property/create/pinplug/`, data:formData, config:config}))
        }
      }else {
        dispatch(setAlert({alert: true, message: `${ConfirmState(key, values).key} is missing`}))
      }

    }else if (['Hotel'].includes(user.role)){
      tags.length === 0 ? 
      dispatch(setAlert({alert: true, message: "Your room needs a tag"}))
      : navigate('/list/2');
    }else {
      const key =  ["Landlord", "Short-let"].includes(user.role) || ['Short-let', 'Land'].includes(data.property_type)?  ['title', 'desc', 'property_price', 'duration'] : ['property_status', 'title', 'desc', 'property_price']
      if(ConfirmState(key, values).valid){
        if(['Short-let'].includes(user.role) || ['Short-let'].includes(data.property_type)){
          navigate('/list/3');
        } else {
          values.edit? navigate('/list/3') : navigate('/list/2');
        }
        
      } else { 
        dispatch(setAlert({alert: true, message: `${ConfirmState(key, values).key} is missing`}));
      }
    }
  }
  
  const addTag = async (e) => {
    e.preventDefault();
    if(data.title !== "" && data.price !==""){
      dispatch(postData({url: `${process.env.REACT_APP_BASE_URL}/role/tag/`, data: {'title': data.title, 'price': data.price}, config: config }));
      retrieve();
    }else {
      dispatch(setAlert({alert: true, message: "Fill out all fields"}));
    }
  }

  useEffect(() => {
    if (post.loading === false && post.error !== ''){
      if(post.status === 400){
        dispatch(setAlert({alert: true, message: post.error}));
        dispatch(errorClear());
      }else if (post.status === 401){
        dispatch(setAlert({alert: true, message: 'Session has expired'}));
        dispatch(postData({url: lout, config: {"Content-Type": "application/json"}, data: user.tokens}));
        dispatch(errorClear());
        Cookies.remove("data");
        store.dispatch(signoutRequest());
      }else{
        dispatch(setAlert({alert: true, message: 'Network Error'}));
        dispatch(errorClear());
      }
    } else if(post.loading === false && post.error === '' && user.role === 'Hotel' && post.success === true){
      dispatch(setAlert({alert: true, message: "Tag created successfully", color: '#90EE90'}));
      dispatch(successClear());
    } else if(data.property_type === 'Pinplug' && post.loading === false && post.error === '' && post.success === true){
        dispatch(listClear());
        navigate('/list/done');  
        dispatch(successClear());
    }
  }, [post.loading])

  return (
    <ListWrapper wt={user.role === 'Hotel' &&'21%'}>
      {["Hotel"].includes(user.role)?  <ListHeader>Create room tags</ListHeader> : <ListHeader>List property</ListHeader>}
      <NumProgress wt={'10%'} />
      {
        ["Hotel"].includes(user.role)
        &&
        <>
        <ListSubHeader>Create different room prices</ListSubHeader>
        <ListP>To easily differentiate between your rooms, create price tags for each of them</ListP>
        <ListRow btn="100%">
          <ListBoxWrapper>
            <ListBox>
              <ListP role='hotel' color='white'>0</ListP>
            </ListBox>
              <ListConnector></ListConnector>
            <ListBox>
              <ListP role='hotel' color='white'>NGN</ListP>
            </ListBox>
          </ListBoxWrapper>
          <SpecCol>
            <ListInput type="text" name="title" onChange={handleChange} id="propn" role='hotel' wt='15rem' placeholder={"E.g premium rooms"} />
            <ListInput type="number" name="price" onChange={handleChange} id="propn" role='hotel' wt='15rem' placeholder={"#8000 Price per night"} />
          </SpecCol>
        </ListRow>
        <ListButton var className='across' set onClick={addTag} >Add a new price tag</ListButton>
        <ListLink to='/list/tags'>View Tags</ListLink>
      </>
      }
      {
      ["Agent", "Developer", 'Landlord'].includes(user.role)
      &&
      <>
      <ListLabel for="status_option" light>How do you want to list as: eg Rent, for sale or Shortlet</ListLabel>
      <ListSelect id="status_option" name="property_status" onChange={handleChange} defaultValue={values.property_status? values.property_status : "For sale"} >
        {/* <ListOption disabled defaultValue={"For sale"}>For sale</ListOption> */}
        <ListOption value="For rent">For rent</ListOption>
        <ListOption value="For sale">For sale</ListOption>
        <ListOption value="Tools">Tools</ListOption>
        <ListOption value="Land">Land</ListOption>
        {!['Landlord'].includes(user.role) && <ListOption value="Short-let">Short-let</ListOption>}
      </ListSelect>
      {/* <ListSelect id="type_option" name="property_type" onChange={handleChange} defaultValue={values.property_type? values.property_type : "Short-let"} >
        <ListOption disabled defaultValue={"Property type"}>Property type</ListOption>
        {!['Landlord'].includes(user.role) && <ListOption value="Short-let">Short-let</ListOption>}
        <ListOption value="Pinplug">Pinplug</ListOption>
        <ListOption value="Land">Land</ListOption>
        <ListOption value="Other">Other</ListOption>
      </ListSelect> */}
      </>
      }
      {
      ["Agent", "Landlord", "Short-let", "Developer"].includes(user.role)  && ['Other', 'Short-let', 'Land'].includes(data.property_type)
      &&
        <>
        <ListLabel for="propn">Name of property</ListLabel>
        <ListInput type="text" name="title" onChange={handleChange} id="propn" placeholder={values.title? values.title : "what is the property's name e.g Two bedroom in alakahia"} />
        <ListLabel for="desc">Description of property</ListLabel>
        <ListInput type="text" name="desc" onChange={handleChange} placeholder={values.desc? values.desc : 'what is special about this property?'} />
        </>
      }
      {data.property_status === 'Tools' && data.property_type === 'Pinplug'
        &&
        <>
        <ListLabel for="propn">Item name</ListLabel>
        <ListInput type="text" name="title" onChange={handleChange} id="propn" placeholder={values.title? values.title : "E.g Shovel"} />
        <ListLabel for="desc">Short description of item</ListLabel>
        <ListInput type="text" name="desc" onChange={handleChange} placeholder={values.desc? values.desc : 'E.g This shovel is so good fr'} />
        </>
      }
      {data.property_status === 'Tools' && data.property_type === 'Pinplug'
        &&
        <>
          <ListRow><ListLabel>Images of the item</ListLabel><ListPred>*6 images max</ListPred></ListRow>
          <ListDrag data-name="uhouse_view" onClick={data.uhouse_view.length > 0 ? () => navigate('/preview') : () => uploadImg(0)}>
          <ListIcon src={values.uhouse_view.length > 0 ? doc : galy} alt="drag and drop image" />
          {data.uhouse_view.length > 0? <ImgCount>{data.uhouse_view.length}</ImgCount>: <UButton>Upload</UButton>}
          </ListDrag>
          <ListLabel for="property_price">Price of item</ListLabel>
          <ListInput type="text" name="property_price" onChange={handleChange} id="property_price" placeholder={values.property_price? values.property_price : "E.g 200,000"} />
          <ListLabel for="phone_no">Phone number</ListLabel>
          <ListInput type="tel" name="phone_no" onChange={handleChange} id="phone_no" placeholder={values.phone_no? values.phone_no : "E.g +2349065xxxxx"} />
          <ListLabel for="adrs"><ListStrong>Address</ListStrong></ListLabel>
          {/* <ListInput type="text" name="address" placeholder={values.address? values.address : 'E.g NO 4. JUNE AVE'}  onChange={handleChange} />   */}
          <AutoComplete />
          <ListLabel for="state"><ListStrong>State</ListStrong></ListLabel>
          <ListSelect name="state" id="state" defaultValue={values.state} onChange={handleChange}>
            {states.map((item, index) => (
                <ListOption key={index} value={item}>{item}</ListOption>
            ))}
          </ListSelect>
        </>
      }
      {
      ["Agent", "Landlord", "Developer"].includes(user.role) && data.property_type === 'Land'
      &&
        <>
          <ListRow><ListLabel>Images of the land</ListLabel><ListPred>*10 images max</ListPred></ListRow>
          <ListDrag data-name="uland_image" onClick={data.uland_image.length > 0 ? () => navigate('/preview') : () => uploadImg(9)}>
          <ListIcon src={values.uland_image.length > 0 ? doc : galy} alt="drag and drop image" />
          {data.uland_image.length > 0? <ImgCount>{data.uland_image.length}</ImgCount>: <UButton>Upload</UButton>}
          </ListDrag>
          <ListLabel for="size"><ListStrong>Plot Size(Sqft)</ListStrong></ListLabel>
          <ListInput type="text" name="lot_size" id="size" placeholder={values.lot_size? values.lot_size : 'E.g 173 square feet'}   onChange={handleChange} />
          <ListLabel for="plot"><ListStrong>Number of plots</ListStrong></ListLabel>
          <ListInput type="number" name="no_of_plots" id="plot" placeholder={values.no_of_plots? values.no_of_plots : 'E.g 5 plots'}   onChange={handleChange} />
          <ListLabel for="property_price">Price</ListLabel>
          <ListInput type="text" name="property_price" onChange={handleChange} id="property_price" placeholder={values.property_price? values.property_price : "E.g 200,000"} />
          <ListLabel for="state"><ListStrong>State</ListStrong></ListLabel>
          <ListSelect name="state" id="state" defaultValue={values.state} onChange={handleChange}>
            {states.map((item, index) => (
                <ListOption key={index} value={item}>{item}</ListOption>
            ))}
          </ListSelect>
          <ListLabel for="adrs"><ListStrong>Address</ListStrong></ListLabel>
          <AutoComplete />
        </>
      }
      {
      ["Agent", "Developer"].includes(user.role) && data.property_status === 'For sale' && data.property_type === 'Other'
      &&
        <>
        <ListLabel for="property_price">Price of property</ListLabel>
        <ListInput type="text" name="property_price" onChange={handleChange} id="property_price" placeholder={values.property_price? values.property_price : "E.g 200,000"} />
        </>
      }
      {
      ["Landlord", "Short-let", "Agent", "Developer"].includes(user.role)  && ['For rent', 'Short-let'].includes(data.property_status)  && ['Other', 'Short-let'].includes(data.property_type)
      &&
      <>
        <ListLabel>Price of rent</ListLabel>
        <ExtraSpan>
        <ListInput type="number" placeholder={values.property_price? values.property_price : "E.g 200,000 /Month"} name="property_price" wt='62%' onChange={handleChange}/>
        <ListSelect id="option" min wt="33%" name="duration" onChange={handleChange} defaultValue={values.duration? values.duration : "Month"} >
        {/* <ListOption disabled defaultValue={"Month"}>Month</ListOption> */}
        <ListOption value="Night">Night</ListOption>
        <ListOption value="Month">Month</ListOption>
        <ListOption value="Year">Year</ListOption>
        </ListSelect> 
        </ExtraSpan>
      </>}
      {
      ["Agent", "Landlord", "Short-let", "Developer"].includes(user.role) && ['Other', 'Short-let'].includes(data.property_type)
      &&
        <>
        <ListRow><ListLabel>Images of the property</ListLabel><ListPred>*10 images max</ListPred></ListRow>
        <ListDrag data-name="uhouse_view" onClick={data.uhouse_view.length > 0 ? () => navigate('/preview') : () => uploadImg(0)}>
        <ListIcon src={values.uhouse_view.length > 0 ? doc : galy} alt="drag and drop image" />
        {data.uhouse_view.length > 0? <ImgCount>{data.uhouse_view.length}</ImgCount>: <UButton>Upload</UButton>}
        </ListDrag>
      </>
      }
      <ListRow>
        <Back path={'/'} />
        <ListButton var lt="39%" onClick={handleSubmit}>
          {post.loading?
            <Spinner
              color={"white"}
              loading={true}
              size={25}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            :
            'Next'}
        </ListButton>
      </ListRow>
    </ListWrapper>
  )
}

export default ListFirst;