import styled, { keyframes } from 'styled-components';
import { NavLink as Link } from 'react-router-dom';

const open = keyframes`
    from {
        opacity: 0;
        position: relative;
        top: -5rem;
    }
    to {
        opacity: 1;
        position: relative;
        top: -4rem;
    }
`
const reverse = keyframes`
    from {
      opacity: 1;
      position: relative;
      top: -4rem;
    }
    to {
      opacity: 0;
      position: relative;
      top: -5rem;
    }
`
export const NavContainer = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1%;
  @media screen and (max-width: 807px){
    height: 34px;
    justify-content: center;
  }
`
export const LinkContainer = styled.div`
  display: ${({check}) => check? "flex":"none"};
  gap: ${props => props.gap? "1%":"3%"};
  flex-direction: row;
  align-items: center;
  list-style: none;
    @media screen and (max-width: 807px){
      display: ${({check2}) => check2? "flex":"none"};
      /* gap: 26%; */
      justify-content: space-between;
    }
    @media screen and (max-width: 500px){
      gap: 0%;
 }
`

export const DropDown = styled.ul`
  display: none;
  gap: 0.1rem;
  flex-direction: column;
  align-items: center;
  list-style: none;
  width: 20%;
  background: #dfdddd;
  position: absolute;
  top: 2.7rem;
  box-shadow: 0px 1px 13px grey;
  padding: 0;
  z-index: 5;
  &.reveal {
    display: flex;
  }
  @media screen and (max-width: 807px){
    position: relative;
    top: -4rem;
    width: 100%;
    background: transparent;
    color: white;
    animation: ${open} 1s ease;
    box-shadow: none;

    &.reverse {
      animation: ${reverse} 1s ease;
    }
  }
    /* @media screen and (max-width: 900px){
      display: ${({check2}) => check2? "flex":"none"};
      gap: 26%;
    }
    @media screen and (max-width: 500px){
      gap: 0%;
 } */
`
export const DropItem = styled.li`
  display: flex;
  background: white;
  width: 95%;
  text-align: left;
  height: 40px;
  padding: 3% 0 3% 5%; 
  align-items: center; 
  &:hover {
    background: transparent;
    font-weight: 700;
  }
  @media screen and (max-width: 807px){
    height: 17px;
    font-size: 1.4rem;
    color: white;
    background: transparent;
  }
`
export const NavLink = styled(Link)`
  color: ${props => props.link === 'ft'? 'black' : 'rgba(0, 0, 0, 0.5)'};
  border-right: ${props => props.link === 'ft'? '1px solid black':'none'};
  padding-right: ${props => props.link === 'ft'? '1%':'none'};
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  width: ${({wt}) => wt};
  display: ${({vis}) => vis?"none":"flex"};
  &.active {
    color: rgba(17, 115, 171, 1);
  }
  @media screen and (max-width: 1052px){
    width: ${({wt}) => wt === '16%'? '18%': wt };
  }
  @media screen and (max-width: 1052px){
    width: ${({wt}) => wt === '16%'? '20%': wt };
  }
  @media screen and (max-width: 807px){
      border-bottom: ${({check}) => check? "1px solid white":"none"};
      width: ${({check}) => check? "100%":""};
      width: ${({wt}) => wt};
      font-size: ${({vis}) => vis? '1.9rem':'1.6rem'};
      display: ${({hid}) => hid === 'true' ? "none":"flex"};
      font-weight: ${({vis}) => vis?"700":""};
      color: white;
      &.profile{
        position: absolute;
        top: 0.3rem;
      }    
      &.active {
        color: rgb(181 222 245);
      }
    }
  @media screen and (max-width: 500px){
      &.logo{
        width: 17%;
      }
  }
    /* @media screen and (max-width: 425px){
      display: ${({hid}) => hid === "hid"?"none":"flex"};
    } */
`
export const NavLogo = styled.img`
  height: ${({ht}) => ht? ht : '2.5rem'};
  width: ${({ht}) => ht? ht : '6.5rem'};
 @media screen and (max-width: 807px){
    height: ${({mbm}) => mbm? '2.8rem' : '3rem'};
    width: ${({mbm}) => mbm? '2.8rem' : '8rem'};
 }
`
export const MobileIcon = styled.img`
  display: none;
  height: 16px;
  /* position: absolute; */
  /* padding-right: 15px; */
  /* right: 0; */
 @media screen and (max-width: 807px){
    display: block;
    transition: transform 0.3s ease-in;
    height: 27px;
    width: 23px;
 }
`
export const Icon = styled.img`
  height: 16px;
  padding-right: 15px;
  @media screen and (max-width: 600px){
        height: 36px;
    }
`

export const MobSpan = styled.span`
  display: none;
  @media screen and (max-width: 807px){
    display: flex;
    align-items: center;
    gap: 3%;
    width: ${({wt}) => wt};
  }
`
export const MobileMenu = styled.div`
    width: 57%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 810px){
      gap: 2%;
    }

    @media screen and (max-width: 775px){
      gap: 1%;
    }

    @media screen and (max-width: 740px){
      width: 60%;
    }

    @media screen and (max-width: 807px){
      display: ${({check}) => check};
      position: absolute;
      flex-direction: column;
      width: 58%;
      /* right: -6rem; */
      padding: 3%;
      left: 26rem;
      height: 80vh;
      background: rgb(17, 115, 171);
      color: white;
      border-radius: 11px;
      top: 6rem;
      align-items: flex-start;
      gap: 5rem;
      justify-content: unset;
      z-index: 5;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
      overflow-x: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1; 
      }
      &::-webkit-scrollbar-thumb {
        background: #888; 
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;       
      }
    }

    @media screen and (max-width: 666px){
      left: 23rem; 
    }

    @media screen and (max-width: 600px){
      left: 8rem;
      width: 71%;    
      height: 83vh;
      gap: 3.5rem;
    }

    @media screen and (max-width: 546px){
      left: 7rem;
    }

    @media screen and (max-width: 490px){
      width: 80%;
      left: 4rem;
    }

    @media screen and (max-width: 460px){
      width: 83%;
      left: 3rem;
    }

    @media screen and (max-width: 444px){
      left: 2rem;
    }

    @media screen and (max-width: 429px){
      width: 85%;
    }

    @media screen and (max-width: 320px){
      width: 88%;
      left: 1rem;
    }
`
export const NavP = styled.p`
  font-size: 1rem;
  @media screen and (max-width: 807px){
    font-size: 1.8rem;
}
`

export const ListBtn = styled.button`
  border-radius: 19px;
  background: rgba(17, 115, 171, 1);
  color: white;
  border: none;
  width: ${props => props.wt};
  font-size: 0.8rem;
  height: 40px;
  cursor: pointer;
  &:hover{
    background: rgb(38 115 159);
  }
  @media screen and (max-width: 900px){
    height: 32px;
  }
  @media screen and (max-width: 691px){
    font-size: 0.9rem;
  }
  @media screen and (max-width: 807px){
    height: 40px;
    background: white;
    color: rgba(17, 115, 171, 1);
    margin: auto;
    &:hover{
      background: #e1e1e1;
    }
  }
`

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8%;
`