import { ServicesContainer, ServiceWrap, ServiceCard, ServiceImg, ServiceHeader, ServiceP, ServiceBtn, ServWrapper } from "./style";
import serv1 from "../../assets/buy.svg"
import serv2 from "../../assets/sell.svg"
import serv3 from "../../assets/rent.svg"
import Tag from "../utility/tags";
import { ServSliders } from "../scroll_btn";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";


const Services = () => {
    const navigate = useNavigate();
    const ServRef = useRef(null);
    let services = [
        {
            id: 1,
            icon: serv1,
            head: "Buy a home",
            text: "Find your place with an immersive photo experience and the most listings, including things you won’t find anywhere else.",
            btn: "Browse homes",
            link: '/buy'
        },
        {
            id: 2,
            icon: serv2,
            head: "Sell a home",
            text: "No matter what path you take to sell your home, we can help you navigate a successful sale.",
            btn: "See your options",
            link: '/sell'
        },
        {
            id: 3,
            icon: serv3,
            head: "Rent a home",
            text: "We’re creating a seamless online experience – from shopping on the largest rental network, to applying, to paying rent.",
            btn: "Find rentals",
            link: '/rent'
        },
    ]
    return(
      <ServWrapper>
        <Tag text="Our Services" />
        <ServiceHeader>Our Main Focus</ServiceHeader>
       <ServicesContainer>
        <ServSliders dir={ServRef} wt="80%" />
       <ServiceWrap ref={ServRef}>
       {services.map((service) => (
            <ServiceCard key={service.id}>
                <ServiceImg src={service.icon} />
                <ServiceHeader>{service.head}</ServiceHeader>
                <ServiceP>{service.text}</ServiceP>
                <ServiceBtn onClick={() => navigate(`${service.link}`)}>{service.btn}</ServiceBtn>
            </ServiceCard>
        ))}
       </ServiceWrap>
       </ServicesContainer>
      </ServWrapper>
    )
}

export default Services;