import { Button, DragWrapper, Icon, Input, Wrapper, Header, Ptag } from "../style"
import { handleDragOver, handleDragLeave } from "../../utility/drag_drop";
import { useEffect, useRef, useState } from "react"
import upload from '../../../assets/icons/upload.svg';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { listAdd, role, update } from "../../../features/registerSlice/rdataSlice";


const DragDrop = () => {
    const [img, setImg] = useState({
        upload_id: []
      })
    const fileInputRef = useRef(null);
    const navigate = useNavigate(); 
    const items = useSelector((state) => state.page)
    const dispatch = useDispatch();
    const handleUploadButtonClick = () => {
        fileInputRef.current.click(); // Trigger the file input click event
        };
    const handleFileInputChange = (event) => {
        event.preventDefault();
        const files = event.target.files
    const updatedImages = [...img[event.target.name]];
        Array.from(files).forEach((item) => {
          updatedImages.push(window.URL.createObjectURL(item))
        })
        setImg({
            ...img,
            [event.target.name]: updatedImages
        })
        }
      const handleDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files
        const updatedImages = [...img[event.target.getAttribute('data-name')]];
        Array.from(files).forEach((item) => {
          updatedImages.push(window.URL.createObjectURL(item))
        })
        setImg({
            ...img,
            [event.target.getAttribute('data-name')]: updatedImages
        })
        }
        useEffect(() => {
           if(img.upload_id.length > 0){
            switch (items.id){
                case 0:
                    dispatch(listAdd({"uhouse_view": img.upload_id}))
                    break;
                case 1:
                    dispatch(listAdd({"uliving_room": img.upload_id}))
                    break;
                case 2:
                    dispatch(listAdd({"ubed_room": img.upload_id}))
                    break;
                case 3:
                    dispatch(listAdd({"utoilet": img.upload_id}))
                    break;
                case 4:
                    dispatch(listAdd({"ukitchen": img.upload_id}))
                    break;
                case 5:
                    dispatch(listAdd({"udocuments": img.upload_id}))
                    break;
                case 6:
                    dispatch(listAdd({"uhouse_plan": img.upload_id}))
                    break;
                case 7:
                    dispatch(listAdd({"usize": img.upload_id}))
                    break;
                case 8:
                    dispatch(role({"iphotos": img.upload_id}))
                    break;
                case 9:
                    dispatch(listAdd({"uland_image": img.upload_id}))
                    break;
                case 10:
                    dispatch(update({"profile.upload_id": img.upload_id[0]}))
                    break;
                default:
                    console.error(`Unexpected media: ${items.id}`);
            }
            navigate("/preview")
           }
        }, [img.upload_id])
        const handleSubmit = (e) => {
            e.preventDefault();
            navigate(items.nextPage);
        } 
  return (
        <Wrapper className="base">
            <Header>Drag and Drop</Header>
            <Ptag>Drag and drop your image or click to upload</Ptag>
            <DragWrapper onDragLeave={handleDragLeave} data-name="upload_id" onDragOver={handleDragOver} onDrop={handleDrop} onClick={handleUploadButtonClick} >
                <Icon sm="sm" src={upload} alt="upload" />
                <Input ref={fileInputRef} name="upload_id" onChange={handleFileInputChange} multiple={items.nextPage === "/upload/id"? false : true} accept="image/*" />
            </DragWrapper>
            <Button onClick={handleSubmit}>Close</Button>
        </Wrapper>
  )
}

export default DragDrop;
