import { ToggleInput, ToggleSpan, ToggleWrap } from "./style"
import up from '../../assets/icons/smile.svg'
import down from '../../assets/icons/frown.svg'
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { postData, errorClear } from "../../features/postSlice/post"
import { setAlert } from "../../features/searchSlice/search"
import { store } from "../../app/store"
import Cookies from "js-cookie"
import { signoutRequest } from "../../features/purge/purge"
import { handleRequest, putHandle } from "../utility/drag_drop"



const Toggle = ({id, status}) => {
    const[click, setClick] = useState(status);
    const[run, setRun] = useState(false);
    const dispatch = useDispatch();
    const url = `${process.env.REACT_APP_BASE_APP_URL}/property/status/`
    const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`
    const user = useSelector((state) => state.signin);
    const config = {
        "Content-Type": "application/json",
        "Authorization": `JWT ${user.user.tokens.access}` 
    }
    function wait(){
        if(click){
            setClick(false);
        } else {
            setClick(true);
        }
        setRun(true);
    }

    async function toggleTag(dir){
        const clic = await putHandle({url: `${url}${id}/${dir}/`, config: config});
        const response = handleRequest(clic, dispatch, config, user?.tokens.refresh);
        if(response === "success"){
            return true
        }else{
            return false
        }
    }
    useEffect(() => {
        if(run){
            if (click) {
                if(toggleTag(true)){
                    setClick(true);
                }else {
                    setClick(false);
                }
            } else {
                if(toggleTag(false)){
                    setClick(false);
                }else{
                    setClick(true);
                }
            }
            setRun(false);
        }
    }, [run])

    // useEffect(() => {
    //     if (user.loading === false && user.error !== ""){
    //       if(user.error === 'Request failed with status code 400'){
    //         dispatch(setAlert({alert: true, message: 'User data contains incorrect details'}));
    //         dispatch(errorClear());
    //       }else if(user.error === 'Network Error'){
    //         dispatch(setAlert({alert: true, message: "You're not connected to a network"}));
    //         dispatch(errorClear());
    //       }
    //     } else if (user.error === 'Request failed with status code 401'){
    //         dispatch(setAlert({alert: true, message:'Session has expired'}));
    //         dispatch(postData({url: lout, config: config, data: user.user.tokens}));
    //         dispatch(errorClear());
    //         Cookies.remove("data");
    //         store.dispatch(signoutRequest());
    //     }
    //   }, [user.loading])
    return(

        <ToggleWrap>
            <ToggleInput type="checkbox" defaultChecked={click? false: true} />
            <ToggleSpan onClick={() => wait()} up={click? up: down}></ToggleSpan>
        </ToggleWrap>
    )
}


export default Toggle;