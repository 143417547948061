import { List, CardDescp, CardProfile } from "../style"
import pinlogo from "../../../../assets/pinlogo.png"
import { ShortenStr, randomcodegenerator } from "../../../utility/drag_drop";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';

const BusinessInfo = ({owner, agent, developer, landlord, hotel, shortlet, eventcenter, bank, func}) => {
    const navigate = useNavigate();
    let data;
    let business;
    let title;
    switch (owner.role){
        case 'Landlord':
                data= landlord
                business= "Landlord"
                title= "a landlord"
            break;
        case 'Agent':
                data= agent
                business= "Land Agent"
                title= "an agent"
            break;
        case 'Developer':
                data= developer
                business= "Developer"
                title= "a developer"
            break;
        case 'Hotel':
                data= hotel
                business= "Hotel"
                title= "a hotel"
            break;
        case 'Bank':
                data= bank
                business= "Bank"
                title= "a bank"
            break;
        case 'Short-Let':
                data= shortlet
                business= "Short-Let"
                title= "a short-Let"
            break;
        case 'Event center':
                data= eventcenter
                business= "Event center"
                title= "an event center"
            break;
        default:
            data = {company_name: "Pinearth"}
            business = owner.role
            title = ""
    }

    function viewAgent(id){
        const name = ['Landlord', 'Short-let', 'Agent', 'Developer'].includes(owner.role)? `${owner.first_name + ' ' + owner.last_name}` : `${data?.company_name}`
        const newId = randomcodegenerator(id);
        navigate(`/contact/${title}/${name}?c=${newId}`)
      }

  return (
    <>
        <List width="13%" text="y" al="pro" cur="pointer" onClick={() => owner.role === "Pinearthling"? '' : viewAgent(data.id)}><CardProfile src={data?.profile_photo !== undefined && data?.profile_photo !== null ? data.profile_photo : pinlogo} /></List>
        <List width="45%" text="y" size="10px" cur="pointer" dir="dir" onClick={() => owner.role === "Pinearthling"? '' : viewAgent(data.id)}>
            {['Bank', 'Hotel'].includes(owner.role)?
                <CardDescp post="left" size="0.9rem">{ShortenStr(data?.name || "Pinearth", 11) || ShortenStr(data?.company_name || "Pinearth", 11)}</CardDescp>
                :
                <>
                {['Landlord', 'Short-let'].includes(owner.role)?
                    <CardDescp post="left" size="0.9rem">{ShortenStr(owner.first_name + ' ' + owner.last_name, 11)}</CardDescp>
                    :
                    <>
                        {
                            ['Agent', 'Developer'].includes(owner.role)?
                            <CardDescp post="left" size="0.9rem">{ShortenStr(owner.first_name + ' ' + owner.last_name, 11) || ShortenStr(data?.company_name, 11)}</CardDescp>
                            :
                            <CardDescp post="left" size="0.9rem">{ShortenStr(data?.company_name || "Pinearth", 11)}</CardDescp>
                        }
                    </>
                }
                </>
            }
            <CardDescp post="left"  color="rgba(17, 115, 171, 1)" >{business}</CardDescp>
        </List>
    </>
  )
}

export default BusinessInfo;