import React, { useEffect, useState } from 'react'
import 
{ 
    CommText, 
    PopHeader, 
    PopInput, 
    PopMessWrap, 
    PopUp, 
    PopWrapper, 
    RandoIcon, 
    UsersToTag, 
    UsersToTagItem,
    UsersToTagProfile, 
    UsersToTagWrapper } from '../style'
import dsend from "../../../../assets/icons/dsend.svg"
import closes from "../../../../assets/icons/closes.svg"
import { postData, errorClear, successClear } from '../../../../features/postSlice/post'
import { useSelector, useDispatch } from 'react-redux'
import { store } from "../../../../app/store";
import { signoutRequest } from "../../../../features/purge/purge";
import Cookies from 'js-cookie'
import { setAlert } from "../../../../features/searchSlice/search";
import { getHandle, handleRequest } from '../../../utility/drag_drop'
import TextBubble from './bubble'

const MessageBox = ({pk, total, reviews}) => {
    const thisClass = `rev${pk}`
    const inputClass = `comment${pk}`
    const comClass = `.comment${pk}`
    const url = `${process.env.REACT_APP_BASE_APP_URL}/property/comment/${pk}/` 
    const user = useSelector((state) => state.signin.user);
    const loading = useSelector((state) => state.post.loading);
    const msg = useSelector((state) => state.post);
    const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`
    const [load, setLoad] = useState(false);
    const [found, setFound] = useState([]);
    const [tags, setTags] = useState([]);
    const dispatch = useDispatch();
    const config = {
        "Content-Type": "application/json",
        "Authorization": `JWT ${user.tokens? user.tokens.access : null}`
    }
    const [data, setData] = useState({
        user: {
            first_name: user.first_name,
            last_name: user.last_name
        },
        comment: "",
        role: user.role,
        add_tags: [],
        tags: []
    })

    const [comments, setComment] = useState(reviews);
    const [indexToSlice, setIndexToSlice] = useState({
        fhalf: 0,
        shalf: 0
    });
    function tagPerson(item){
        let newTag = {
            person_id: item.id,
            name: item.first_name || item.company_name,
            role: item.role,
            property_id: pk
        }

        setTags((prevArray) => (
            [...prevArray, newTag]
        ));

        const val = document.querySelector(comClass).value 
        const fhalf = val.slice(0, indexToSlice.fhalf)
        const shalf = val.slice(indexToSlice.shalf, val.length)
        document.querySelector(comClass).value = fhalf + newTag.name + " " + shalf
        setData((prevData) => ({
            ...prevData, 
            comment: fhalf + newTag.name + " " + shalf,
            add_tags: [...prevData.add_tags, newTag],
            tags: [...prevData.add_tags, newTag]
        })); 
        setLoad(false);
    }

    async function searchUsers(query){
        const usersFound = await getHandle({url: `${process.env.REACT_APP_BASE_APP_URL}/property/filter/?q=${query}&place=people`, config: config})
        const response = handleRequest(usersFound, dispatch, config, user?.tokens.refresh)
        if(response === "success"){
            setFound(usersFound.data);
        }

    }

    function handleChange(e){
        e.preventDefault();
        const target = e.target
        let strArr = target.value
        const cursorIndex = target.selectionStart - 1
        if(strArr[cursorIndex] === "@"){
            setIndexToSlice({...indexToSlice, fhalf: cursorIndex + 1, shalf: cursorIndex + 1});
            setLoad(true);
        }
        if(load && strArr[indexToSlice.fhalf - 1] !== "@" ){
            setLoad(false);
        }
        if(load && strArr.includes("@")){
            let targetStr = strArr.slice(indexToSlice.fhalf, cursorIndex + 1);
            setIndexToSlice({...indexToSlice, shalf: cursorIndex + 1})
            searchUsers(targetStr);
        }
        if(strArr[cursorIndex] === " " && strArr[indexToSlice.fhalf - 1] === "@"){
            setLoad(false);
        }
        for(let i=0; i<tags.length; i++){
            if(!strArr.includes(tags[i].name)){
                setTags((prevData) => ([
                    ...prevData,
                    prevData.filter(tag => tag !== tags[i])
                ]));
                setData((prevData) => ({
                    ...prevData,
                    add_tags: prevData.add_tags.filter(tag => tag !== tags[i]),
                    tags: prevData.add_tags.filter(tag => tag !== tags[i])
                }));
            }
        }
        setData({
            ...data, [target.name]: target.value
        });
    }

    function handleSend(e){
        e.preventDefault();
        if(data.comment !== ""){
            setData(data);
            dispatch(postData({url: url, data: data, config: config}));
            setComment(prevState => [...prevState, data]);
            document.querySelector(comClass).value = ''
            setData((prevData) => ({
                ...prevData,
                add_tags: []
            }));
        }
    }

    useEffect(() => {
        if (msg.loading === false && msg.error !== ""){
            if(msg.status === 400){
                dispatch(setAlert({alert: true, message: msg.error}));
                dispatch(errorClear());
              }else if (msg.status === 401){
                dispatch(setAlert({alert: true, message: 'Session has expired'}));
                dispatch(postData({url: lout, config: {"Content-Type": "application/json"}, data: user.tokens}));
                dispatch(errorClear());
                Cookies.remove("data");
                store.dispatch(signoutRequest());
              }else{
                dispatch(setAlert({alert: true, message: 'Network Error'}));
                dispatch(errorClear());
              }
        } else if (msg.loading === false && msg.error === "" && msg.success === true){
            dispatch(setAlert({alert: true, message: 'sent', color: '#90EE90'}));
            dispatch(errorClear()); 
            dispatch(successClear());
        }
    }, [loading])

    function close(){
        const thatClass = `.rev${pk}`
        const vew = document.querySelector(thatClass)
        vew.style.display = "none"
    }
  return (
    <PopUp className={thisClass}>
        <PopWrapper dir="flex" top='true' wt="100%" position='space-between'>
            <PopHeader>({total}) Comments</PopHeader>
            <RandoIcon src={closes} alt="close" onClick={() => close()} />
        </PopWrapper>
        <PopMessWrap>
        {comments.length > 0 && comments.map((item, index) => (
            <TextBubble item={item} user={user} key={index} position='space-between' alt='true' wt='70%' />
        ))}
        {comments.length === 0 && <CommText> No comments yet</CommText>}
        </PopMessWrap>
        <PopWrapper dir="column" wt="100%" pos="relative">
            {load &&
                <UsersToTagWrapper>
                    {found.length > 0?
                        <>
                            {found.map((item, index) => (
                                <UsersToTag onClick={() => tagPerson(item)} key={index}>
                                    <UsersToTagProfile src={item.profile?.avatar || item.profile_photo} alt={item.company_name || item.first_name} />
                                    <UsersToTagItem>{item.company_name || item.first_name}</UsersToTagItem>
                                </UsersToTag>
                            ))}
                        </>
                        :
                        <UsersToTagItem>Empty</UsersToTagItem>
                    }
                </UsersToTagWrapper>
            }
            {user.first_name &&
                <>
                <PopInput type="text" name="comment" placeholder="Comment" id='comment' className={inputClass} onChange={handleChange} autoComplete="off" />
                <RandoIcon src={dsend} alt="send" pos="absolute" onClick={handleSend} />
                </>
            }
        </PopWrapper>
    </PopUp>
  )
}

export default MessageBox