import { ProfileList, ProfilePerties, ProfileText, PropertyBlocks, PropertyImg, PropertySlot, LoadingCont, Spinner, ProfileButton, ProfileWrapper, PropertyIcon } from "../style"
import Toggle from '../../toggle'
import right from '../../../assets/icons/right.svg'
import broken from "../../../assets/broken.svg"
import pencil from "../../../assets/icons/pencil.svg"
import { useSelector, useDispatch } from "react-redux"
import { randomcodegenerator, replaceStr } from "../../utility/drag_drop"
import { useEffect } from "react"
import { getData, clear } from "../../../features/getSlice/get";
import { postData } from "../../../features/postSlice/post";
import { store } from "../../../app/store";
import { signoutRequest } from "../../../features/purge/purge";
import Cookies from 'js-cookie'
import { setAlert } from "../../../features/searchSlice/search"
import { useNavigate } from "react-router-dom"
import { listAdd } from "../../../features/registerSlice/rdataSlice"


const Listed = () => {
    const user = useSelector((state) => state.signin.user)
    const state = useSelector((state) => state.get)
    const prop = `${process.env.REACT_APP_BASE_APP_URL}/property/viewmy/`
    const msg = useSelector((state) => state.get.error)
    const lout = `${process.env.REACT_APP_BASE_APP_URL}/user/logout/`
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const config = {
        "Content-Type": "application/json",
        "Authorization": `JWT ${user.tokens.access}`
    }

    useEffect(() => {
        dispatch(getData({ url: prop, config: config }));
    }, [])

    useEffect(() => {
        if (msg === 'Request failed with status code 401'){
            dispatch(setAlert({alert: true, message: 'Session has expired'}));
            dispatch(postData({url: lout, config: config, data: user.tokens}));
            Cookies.remove("data");
            store.dispatch(signoutRequest());
        }else if(msg === 'Network Error'){
            dispatch(setAlert({alert: true, message: "Network Error"}));
            dispatch(clear());
        }
    }, [msg])

    function editProperty(data){
        dispatch(listAdd({...data, edit: true}));
        navigate("/list/1");
    }

    function listNow(){
        if(user.has_role){
            navigate('/list/1');
        } else{
            dispatch(setAlert({alert: true, message: 'You do not have a business account'}));
        }
    }

    function viewProp(property_id){
        const newId = randomcodegenerator(property_id);
        navigate(`/view/${newId}`);
    }
    return(
        <>
         <ProfileList wt>
            {state.loading?
                <LoadingCont>
                    <Spinner
                        color={"rgba(17, 115, 171, 1)"}
                        loading={true}
                        size={50}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </LoadingCont>
               :
               <>
                {!state.loading && state.data.length === 0 ?
                    <ProfileWrapper>
                        <ProfileText>No listed properties yet</ProfileText>
                        <ProfileButton onClick={listNow} er='true'>List now</ProfileButton>
                    </ProfileWrapper>
                    :
                    <>
                        {
                            state.data.map((item) => (
                                <PropertySlot key={item.id}>
                                    <PropertyImg src={item.house_view.length !== 0 ? replaceStr(item.house_view[0].house_view) : broken} alt={item.title} />
                                    <PropertyBlocks wt="50%">
                                        <ProfileText ps wt="700" >{item.title}</ProfileText>
                                        <ProfileText ps sz="0.8rem">{item.desc}</ProfileText>
                                    </PropertyBlocks>
                                    <PropertyBlocks>
                                    
                                    <PropertyIcon src={pencil} alt="edit" onClick={() => editProperty(item)} />
                                    <Toggle id={item.id} status={item.available} />
                                    <ProfileText sz="0.8rem">{item.property_status}</ProfileText>
                                    </PropertyBlocks>
                                    <ProfilePerties src={right} onClick={() => viewProp(item.id)} alt="details" />
                                </PropertySlot>
                                ))
                        }
                    </>
                }
               </>
            }
         </ProfileList>
        </>
    )
}

export default Listed;