import { ScrollBtn, CardSliders, ScrollIcon, SSliders } from "./style";
import nex from "../../assets/icons/sliderR.svg"
import prv from "../../assets/icons/sliderL.svg"



export const NextBtn = ({dir, right, btm, left, pos}) => {
    return (
    <>
        <ScrollBtn onClick = {dir} right={right} btm={btm} left={left} pos={pos}>
            <ScrollIcon src={nex} alt="next" />
        </ScrollBtn>
    </>
  )
}
export const PrevBtn = ({dir, right, btm, pos}) => {
    return (
    <>
        <ScrollBtn onClick = {dir} right={right} btm={btm} pos={pos}>
            <ScrollIcon src={prv} alt="previous" />
        </ScrollBtn>
    </>
  )
}

export const next = (box) => {
    const width  = box.current.clientWidth; 
    box.current.scrollLeft = box.current.scrollLeft + width/2;
}
export const prev = (box) => {
    const width  = box.current.clientWidth; 
    box.current.scrollLeft = box.current.scrollLeft - width/2;
}
export const mix = (box) => {
    const width  = box.current.clientWidth; 
    if(box.current.scrollLeft === 0){
    box.current.scrollLeft = width - width;
    } else box.current.scrollLeft = box.current.scrollLeft - width/2;
}
export const getBox = ({name}) => {
    return document.querySelector(name)
} 

export const Sliders = ({dir}) => {
    return(
        <CardSliders><PrevBtn dir={() => prev(dir)} /> <NextBtn dir={() => next(dir)} /></CardSliders>
    )
}

export const ServSliders = ({dir, wt}) => {
    return(
        <SSliders wt={wt}><PrevBtn dir={() => mix(dir)} /> <NextBtn dir={() => next(dir)} /></SSliders>
    )
}