import { useSelector } from "react-redux"
import { CommentContainer, CommentEmpty } from './style'
import TextBubble from '../../carousel/homecard/message_box/bubble'

const PropertyComments = () => {
    const data = useSelector((state) => state.single.singledata);
    const user = useSelector((state) => state.signin.user);
  return (
    <CommentContainer>
        {data.reviews.length > 0 ? 
            <>
              {data.reviews.map((item, i) => (
                <TextBubble item={item} user={user} wt='55%' key={i} />
              ))}
            </>
            :
            <CommentEmpty>
              No comments yet
            </CommentEmpty>
        }
    </CommentContainer>
  )
}

export default PropertyComments;