import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'



const initialState = {

  loading: false,
  singledata: {},
  error: '',
  success: false,
  id: 0,
  title: '',
  reload: false
}


export const singleData  = createAsyncThunk('data/singleData', (objdata) => {
    const {url, config} = objdata;
    return axios
            .get(url, 
            {
            headers: config
            })
            .then(response => response.data) 
        })
const singleSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
      set: (state, action) => {
        state.id = action.payload.id
        state.title = action.payload.title
    },
      clear: (state, action) => {
        state.loading = false
        state.singledata = {}
        state.success = false
        state.error = ''
      },
      reload: (state, action) => {
        state.reload = action.payload
      }
    },
    extraReducers: builder => {
          builder.addCase(singleData.pending, state => {
            state.singledata = {}
            state.loading = true
            state.error = ''
            state.success = false
          })
          builder.addCase(singleData.fulfilled, (state, action) => {
            state.loading = false
            state.singledata = action.payload
            state.error = ''
            state.success = true
          })
          builder.addCase(singleData.rejected, (state, action) => {
            state.loading = false
            state.singledata = {}
            state.error = action.error.message
          });
    },
});


export default singleSlice.reducer
export const { set, clear, reload } = singleSlice.actions