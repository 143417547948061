import { useEffect, useState } from "react";
import { FormButton, FormInput, FormLabel, FormStrong, Spinner, VariantFormWrapper, FormSpan, Warn } from "../../style"
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { role } from "../../../../features/registerSlice/rdataSlice";
import { getData } from "../../../../features/getSlice/get";
import { singleData } from "../../../../features/singleSlice/single";




const EdMid = () => {
    const roles = useSelector((state) => state.get.extras.role);
    const user = useSelector((state) => state.signin.user);
    const single = useSelector((state) => state.single);
    const agent = roles.slice(4, roles.length).replace('%20', ' ')
    const url = `${process.env.REACT_APP_BASE_APP_URL}/property/viewmy/`
    const config = { 
        "Content-Type": "application/json",
        "Authorization": `JWT ${user.tokens.access}`
    }
    const [check, setCheck] = useState({
        email: false,
        no: false
    });
    useEffect(() => {
        dispatch(getData({url: url, config: config}))
    }, [])
    const profile = useSelector((state) => state.profileData)
    const navigate = useNavigate();
    const[reg, setReg]=useState({
        email:"",
        phone_no: "",
    });
    const handleChange = (e) => {
        e.preventDefault();
        const target = e.target
        if(target.name === "phone_no"){
            setCheck({...check, no: false});
            setReg({...reg, [target.name]: target.value})
        }
        setCheck({...check, email: false});
        setReg({...reg, [target.name]: target.value});
        dispatch(role(reg));
    }
    const dispatch = useDispatch();
    
    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(role(reg));
        dispatch(singleData({url:`${process.env.REACT_APP_BASE_APP_URL}/role/${agent.toLowerCase()}/${reg.email}/`, config: config}));
    }
    useEffect(() => {
        if(reg.phone_no.length > 14 || reg.phone_no.length < 11){
            setCheck({...check, no: true});
        }  else {
            navigate("edit role-iii")
        }
    }, [single])
    // function AddProp(id, event){
    //     const target = event.target;
    //     const newIndex = parseInt(target.getAttribute('data-key'));
    //     const props = document.querySelectorAll(".prop")
    //     console.log("checks:", newIndex, props)
    //     const updatedProperties = reg.properties.includes(id)
    //     ? reg.properties.filter(prop => prop !== id)
    //     : [...reg.properties, id];

    //     setReg({ ...reg, properties: updatedProperties });

    //     props[newIndex].textContent = updatedProperties.includes(id) ? "-" : "+";
    // }
    return(
        <VariantFormWrapper>
            <FormLabel for="email"><FormStrong>Email Address</FormStrong></FormLabel>
            <FormInput type="email" placeholder={profile.email} name="email" onChange={handleChange} id="" required />
            <FormLabel for="phone_no"><FormStrong>Phone Number</FormStrong></FormLabel>
            <FormInput type="tel" placeholder={profile.phone_no} onChange={handleChange} name="phone_no" id="phone_no" required />
            {check.no? <Warn>phone number is incorrect</Warn>:<></>}
            {/* <FormLabel for="listed"><FormStrong>Listed properties on Pinearth</FormStrong></FormLabel>
            {data && data.length > 0? 
              data.map((item, index) => (
                  <ListedProp>
                      <ListedImg src={replaceStr(item.house_view[0].house_view)} alt={item.title} />
                      <ListSpan>
                          <ListText color={"#FFC200"}>{item.title}</ListText>
                          <ListText>{ShortenStr(item.address, 17)}</ListText>
                      </ListSpan>
                      <AddRem onClick={(e) => AddProp(item.id, e)} data-key={index} className="prop">+</AddRem>
                  </ListedProp>
             ))
            :
             <FormP>You don't have any listed properties</FormP>
            } */}
            <FormSpan><FormButton left onClick={() => navigate(-1)} >Back</FormButton><FormButton onClick={handleSubmit}>
            {single.loading?
            <Spinner
           color={"white"}
           loading={true}
           size={25}
           aria-label="Loading Spinner"
           data-testid="loader"
           />:"Next"}</FormButton></FormSpan>
        </VariantFormWrapper>
    )
}

export default EdMid;