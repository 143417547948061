import { UserWrapper, Header, FillBtn, UserImage, RedText } from "../style";
import adrs from '../../../assets/icons/links.svg'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector} from 'react-redux'
import { postData, successClear, errorClear } from "../../../features/postSlice/post";
import { setAlert } from "../../../features/searchSlice/search";
import { useEffect, useState } from "react";
import { setCount } from "../../../features/pageSlice/page";

const CreatedAcct = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const email = useSelector((state) => state.page.email);
    const prevPage = useSelector((state) => state.page.prevPage);
    const loading = useSelector((state) => state.post.loading);
    const error = useSelector((state) => state.post);
    const initialCount = useSelector((state) => state.page.count);
    const currentDate = new Date();
    const [counter, setCounter] = useState(60);
    
    function startCount(counted){
        if(counted !== 60 - counter){
            setCounter(60 - counted)
        }
        let timerId = setInterval(() => {
            setCounter((prevCounter) => {
            if(prevCounter <= 1){
                clearInterval(timerId);
                return 0;
            } else {
                return prevCounter-1
            }})
        }, 1300)
    }
    function updateCounter(){
        const currentTime = currentDate.getSeconds()
        if(initialCount === 0){
            dispatch(setCount(currentDate.getSeconds));
        }
        let counted;
        if(currentTime > initialCount){
            counted = currentTime - initialCount
        }else {
            counted = 60 - initialCount + currentTime
        } 
        startCount(counted);
    }
    useEffect(() => {
        if(prevPage !== "/sign%20in" || prevPage !== '/upload/id'){
            navigate("/sign in");
        }else{
            if(prevPage === "/sign%20in"){
                dispatch(postData({url: `${process.env.REACT_APP_BASE_URL}/user/resendlink/${email}/`, config: { "Content-Type": "application/json" }}));
                setCounter(60);
                dispatch(setCount(0));
            }
        }
        updateCounter();
    }, [])

    function resend(){
        dispatch(postData({url: `${process.env.REACT_APP_BASE_URL}/user/resendlink/${email}/`, config: { "Content-Type": "application/json" }}));
        setCounter(60);
        dispatch(setCount(0));
        updateCounter();
    }

    // useEffect(() => {
    //     if(prevPage === "/sign in"){
    //         dispatch(postData({url: `${process.env.REACT_APP_BASE_URL}/user/resendlink/${email}/`, config: { "Content-Type": "application/json" }}));
    //         setCounter(60);
    //     }
    // }, [])

    useEffect(() => {
        dispatch(successClear());
        if (loading === false && error.error !== ""){
          if(error.error === 'Request failed with status code 400'){
            setCounter(60);
            dispatch(setCount(0));
            dispatch(setAlert({alert: true, message: 'Contact the developer'}));
            dispatch(errorClear());
          }else if(error.error === 'Network Error'){
            setCounter(60);
            dispatch(setCount(0));
            dispatch(setAlert({alert: true, message: "You're not connected to a network"}));
            dispatch(errorClear());
          }
        } else if(loading === false && error.error === "" && error.success === true){
            setCounter(60);
            dispatch(setCount(0));
            dispatch(setAlert({alert: true, message: 'Confirmation link sent', color: '#90EE90'}));
            dispatch(successClear())  ;
        }
      }, [loading])
    return(
        <UserWrapper>
            <Header sz="1.5rem">
                Please check your mail for an activation link, if you don't receive one click the link below in {counter} seconds
            </Header>
            <UserImage src={adrs} alt="link" />
            {counter === 0 && <RedText onClick={() => resend()} rs >Resend link</RedText>}
            <FillBtn onClick={() => navigate("/")}>Back to home</FillBtn>
        </UserWrapper>
    )
}

export default CreatedAcct;