import { TagWrapper, TagCont } from "../style";

const Tag = ({ text, width, rt, pos, lf }) => {
    return(
        <TagWrapper width={width} rt={rt} lf={lf} pos={pos}>
            <TagCont>{text}</TagCont>
        </TagWrapper>
    )
}

export default Tag;