import { FillBtn, Icon, SpecSpan, SpecBtn } from "../../style";
import person from "../../../../assets/icons/person.svg";
import car from "../../../../assets/icons/car.svg";
import right from "../../../../assets/icons/right.svg";



const Select = () => {
    return(
        <>
            <SpecBtn><SpecSpan wt="wt" to="id" sel><Icon ht="lg" src={person} />NIN/NATIONAL ID </SpecSpan><Icon src={right} /></SpecBtn>
            <SpecBtn><SpecSpan wt="wt" to="id" sel><Icon ht="lg" src={car} />DRIVERS LICENSE </SpecSpan><Icon src={right} /></SpecBtn>
            <SpecBtn><SpecSpan wt="wt" to="id" sel align='left'><Icon ht="lg" src={person} />INTERNATIONAL PASSPORT </SpecSpan><Icon src={right} /></SpecBtn>
            <FillBtn variant="fill">Next</FillBtn>
        </>
    )
}

export default Select;