import React, { useEffect } from 'react'
import { EmptyCont, Headers, LoadingCont, Spinner } from './style'
import { Header } from '../../layout';
import { useDispatch, useSelector } from 'react-redux'
import PropertyCard from '../../components/carousel/homecard'
import NotFound from '../../components/agent/notFound'
import { useSearchParams } from 'react-router-dom'
import { searchData } from '../../features/searchSlice/search'
import slide1 from "../../assets/header1.png";
import slide2 from "../../assets/header2.png";
import slide3 from "../../assets/header3.png"

const FilterResult = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const state = searchParams.get('state')
    const status = searchParams.get('status')
    const type = searchParams.get('type')
    const price = searchParams.get('price')
    const data = useSelector((state) => state.search);
    const url = `${process.env.REACT_APP_BASE_APP_URL}/property/look?state=${state === null? "" : state}&property_status=${status === null? "" : status}&property_type=${type === null? "" : type}&property_price=${price === null? "" : price}`
    const page = useSelector((state) => state.page.prevPage);
    const dispatch = useDispatch();
    const config = {
        "Content-Type": "application/json",
    }
    
    useEffect(() => {
        dispatch(searchData({url: url, config: config}));
    }, [state, status, type, price])

  return (
    <>
    {page === "/" && <Header link={slide2} text={'PURCHASE A HOUSE ANYWHERE IN NIGERIA'} />}
    {page === "/buy" && <Header link={slide1} text={'TAKE CHARGE OF YOUR HOUSING EXPERIENCE'}/>}
    {page === "/rent" && <Header link={slide3} text={'RENT A PROPERTY FROM ANYWHERE IN NIGERIA'} />}
    <EmptyCont dir="column" ht>
        <Headers>{`Search results for ${state != null? `"${state}"` : ''} ${status != null? `"${status}"`:''} ${type != null? `"${type}"`: ''} ${price != null? `"${price}"` : ''}` }</Headers>
        {data.loading?
            <LoadingCont>
                <Spinner
                    color={"rgba(17, 115, 171, 1)"}
                    loading={true}
                    size={50}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    />
            </LoadingCont>
            :
            <>
                {data.data.length > 0? 
                <PropertyCard data={data.data} size={3}/>
                :
                <NotFound wt={'32%'}/>
                }
            </>
        }
    </EmptyCont>
    </>
  )
}

export default FilterResult;