import styled, {keyframes} from 'styled-components';


const rotate = keyframes`
 from{
    bottom: -45rem;
 }
 to{
    bottom: 0;
 }
`

export const CardAcross = styled.section`
    display: flex;
    width: 94%; 
    align-items: center;
    position: relative;
    margin: auto;
`
export const CardSliders = styled.section`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 1;
`

export const CardWrapper = styled.div`
    display: flex;
    gap: 2%;
    align-items: center;
    width: ${({wtw}) => wtw? wtw: "100%"}; //84% margin: auto;
    height: 500px;
    scroll-behavior: smooth;
    overflow-x: auto;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        height: 0px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }
    &::-webkit-scrollbar-thumb {
        background: #888; 
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #555;       
    }
    @media screen and (max-width: 500px) {
     gap: 4%;   
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        height: 2px;
    }
    }
    @media screen and (max-width: 600px){
        height: 520px;
    }
    @media screen and (max-width: 450px){
        position: relative;
        overflow-y: visible;
    }
`
export const CardContainer = styled.div`
 display: flex;
 flex-direction: column;
 width: ${({single}) => single?"73%":"21%"};
 min-width: ${({wtc}) => wtc? wtc :"21%"}; //23.1
 height: ${({single}) => single? "28rem" : "30rem"};
 border-radius: 30px;
 font-size: 1rem;
 box-shadow: 0 6px 5px rgba(0, 0, 0, 0.15);
 position: relative;
 margin: ${({single}) => single?"auto":""};
    @media screen and (max-width: 900px){
        height: 325px;
    }
    @media screen and (max-width: 900px){
        min-width: ${({wtc}) => wtc? "60%" : "25%"};
    }
    @media screen and (max-width: 830px){
        min-width: ${({wtc}) => wtc? "65%" : "25%"};
    }
    @media screen and (max-width: 750px){
        min-width: ${({wtc}) => wtc? "70%" : "30%"};
    }
    @media screen and (max-width: 700px){
        min-width: ${({wtc}) => wtc? "75%" : "30%"};
    }
    @media screen and (max-width: 660px){
        min-width: ${({wtc}) => wtc? "80%" : "39%"};
    }
    @media screen and (max-width: 600px){
        min-width: ${({wtc}) => wtc? "80%" : "50%"};
        height: 449px;
    }
    @media screen and (max-width: 545px){
        min-width: ${({wtc}) => wtc? "65%" : "55%"};
    }
    @media screen and (max-width: 500px){
        min-width: ${({wtc}) => wtc? "75%" : "60%"};
    }
    @media screen and (max-width: 482px){
        min-width: ${({wtc}) => wtc? "85%" : "66%"};
    }
    @media screen and (max-width: 437px){
        min-width: ${({wtc}) => wtc? "85%" : "68%"};
    }
    @media screen and (max-width: 424px){
        min-width: ${({wtc}) => wtc? "85%" : "70%"};
    }
    @media screen and (max-width: 412px){
        min-width: ${({wtc}) => wtc? "85%" : "74%"};
    }
    @media screen and (max-width: 390px){
        min-width: ${({wtc}) => wtc? "85%" : "83%"};
    }
    @media screen and (max-width: 360px){
        width: ${({single}) => single?"82%":""};  
    }
    @media screen and (max-width: 347px){
        min-width: ${({single}) => single?"82%": "88%"};
    }
    @media screen and (max-width: 327px){
        min-width: ${({single}) => single?"82%": "93%"};
    }
    @media screen and (max-width: 320px){
        width: ${({single}) => single?"90%":""};  
    }
`

export const ModalDisplay = styled.div`
    display: ${({show}) => show};
    z-index: 1;
`
export const ModalContainer = styled.div`
    display: flex;
    overflow: hidden;
    width: 50%;
    height: max-content;
    align-items: center;
`

export const ModalImg = styled.img`
    width: fit-content;
`

export const CardDisplay = styled.div`
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
    height: ${({single}) => single? "15rem":"18rem"};
    justify-content: space-between;
    align-items: end;
    overflow-y: hidden;
`
export const CardImgDisplay = styled.img`
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    width: 100%;
    height: 14rem;
    filter: ${({gray}) => gray};
`

export const FadedWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 11rem;
    width: 94%;
    justify-content: ${({pos}) => pos? pos : 'space-between'};
    align-content: end;
    align-items: flex-end;
    flex-wrap: wrap;
    z-index: 1;
    padding: 6% 6% 0 0;
    margin: 0 auto 0 0;
`

export const DisplayBtn = styled.button`
    border-radius: 10px;
    width: 37%;
    height: 30px;
    border: none;
    padding: 1%;
    @media screen and (max-width: 340px){
        width: 40%;
    }
`

export const Variant = styled.button`
    display: flex;
    border-radius: 11px;
    width: 100%;
    font-size: 0.57rem;
    align-items: center;
    border: 1px solid;
    padding: 5%;
    background: transparent;
`

export const FadedBtn = styled.button`
    display: grid;
    opacity: 0.7;
    padding: 7%;
    border: none;
    border-radius: 3px;
    position: relative;
    align-items: center;
    margin-bottom: 0rem;
    height: 2rem;
`
export const DigitSpan = styled.span`
    position: absolute;
    top: 2rem;
    color: whitesmoke;
    justify-self: center;
    /* @media screen and (max-width: 1024px){
        padding-top: 2.4rem;
     } */
`
export const ContSpan = styled.div`
    display: flex;
    justify-content: space-between;
    height: 3rem;
    width: 36%;
    padding-left: 5%;
    /* @media screen and (max-width: 1102px){
        width: 28%;  
    }
    @media screen and (max-width: 600px){
        width: 23%;   
    } */
`

export const CardHeader = styled.h4`
    text-align: left;
    padding-left: 3%;
    color: ${props => props.cl};
    margin: 0;
`
export const CardDescp = styled.p`
 text-align: ${ props => props.post};
 color: ${ props => props.color? props.color : ""};
 margin-top: ${ props => props.top };
 padding-left: ${ props => props.left};
 padding-bottom: ${({bt}) => bt? "2%":""};
 font-size: ${({size}) => (size)};
 min-height: ${({desc}) => desc === "desc"? "2.4rem":""};
`
export const Icon = styled.img`
    align-self: center;
    height: 1rem;
    width: 0.8rem;
    padding: 0.8px 1.8px 0.8px 2.8px;
`
export const TopIcon = styled.img`
    align-self: center;
`

export const CardProfile = styled.img`
 border-radius: 999px;
 height: 30px;
 width: 30px;
    @media screen and (max-width: 500px){
        height: 24px;
        width: 24px;
    }
`
export const LowerDesc = styled.ul`
    list-style: none;
    display: flex;
    width: 98%;
    gap: ${({ht}) => ht? '':"2%"};
    justify-content: ${({ht}) => ht? 'space-evenly':""};
    margin: auto;
    padding: 9px 0px 6px 0px;
    align-items: center;
    height: ${({hgt}) => hgt};;
`
export const LowerButton = styled.button`
    width: 100%;
    color: white;
    background: rgba(17, 115, 171, 1);
    border: none;
    height: 3rem;
    &:hover{
        background: rgb(10 84 127);
    }
`

export const VarLower = styled(LowerDesc)`
        margin: auto;
        width: 89%;
        gap: 5%;
        /* padding-bottom: 15px; */
        @media screen and (max-width: 500px){
            gap: 9%;
        }
        @media screen and (max-width: 452px){
            gap: 5%;
            margin: auto;
            width: 98%;
        }
        @media screen and (max-width: 368px){
            gap: 3%;
            margin: auto;
            width: 98%;
        }
`

export const List = styled.li`
    font-size: ${props => props.size || "14px"};
    width: ${ props=> props.width || "32%" };
    display: ${({text}) => text === "y"? "flex": ""};
    flex-direction: ${({dir}) => dir === "dir"? "column":""};
    flex-wrap: wrap;
    cursor: ${({cur}) => cur};
    align-content: ${({al}) => al === "pro"?"center":""};
`

export const PickBtn = styled.button`
    border-radius: 6px;
    width: 100%;
    height: 2rem;
    border: none;
    padding: 1%;
    color: white;
    background: rgba(17, 115, 171, 1);
    cursor: pointer;
    &:hover{
        background: rgb(37 136 193);
    }
    @media screen and (max-width: 600px){
        height: 2.2rem;   
    }
`
export const PlaceBtn = styled.button`
    color: rgba(17, 115, 171, 1);
`
export const SoldOut = styled.img`
    height: 64px;
    margin: auto;
    padding-bottom: 2rem;
`

export const CommWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    gap: 3%;
    padding: 4%;
    background: rgba(17, 115, 171, 1);
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    height: 50px;
    cursor: pointer;
    &:hover{
        background: rgb(37 136 193);
    }
`
export const CommText = styled.p`
    font-size: ${({size}) => (size)};
`

export const PopUp = styled.div`
    background: white;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    height: 223px;
    padding: 5%;
    width: 90%;
    position: absolute;
    z-index: 2;
    bottom: 0;
    &.comments{
        display: flex;
    }
    @media screen and (max-width: 450px){
        position: fixed;
        height: 80vh;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        width: 97%;
        padding: 0;
        padding-top: 3%;
        border-radius: 9px;
        box-shadow: 0px -5px 5px #D9D9D9;
        animation: ${rotate} 1s ease;
    }
`
export const PopInput = styled.input`
    border: none;
    box-shadow: 0 1px 1px #D9D9D9;
    &:focus {
        outline: none;
    }
    @media screen and (max-width: 450px){
        padding-left: 3%;
        height: 40px;
    }
`
export const PopMessWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 175px;
    gap: 2%;
    scroll-behavior: smooth;
    overflow-x: auto;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 4px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }
    &::-webkit-scrollbar-thumb {
        background: #888; 
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #555;       
    }
    @media screen and (max-width: 450px){
        height: 500px;
    }
`

export const PopMessage = styled.div`
    background: ${({rev}) => rev ==='true'? "green" : 'rgba(17, 115, 171, 1)'};
    color: white;
    align-items: center;
    padding: 5%;
    border-top-right-radius: ${({rev}) => rev ==='true'? "0" : '17px'};
    border-top-left-radius: ${({rev}) => rev ==='true'? "17px" : '0'};
    border-bottom-left-radius: 21px;
    border-bottom-right-radius: 17px;
    height: max-content;
    width: 90%;
`
export const PopHeader = styled.h1`
    font-size: ${({sz}) => sz? "0.85rem":""};
    text-align: ${({rev}) => rev ==='true'? "end": "start"};
    @media screen and (max-width: 450px){
        padding-left: 3%;
    }
`

export const PopWrapper = styled.div`
    display: flex;
    position: ${({pos}) => pos};
    text-align: left;
    flex-direction: ${({dir}) => dir};
    align-items: ${({alt}) => alt? "center":""};
    align-self: ${({rev}) => rev ? "flex-end" : ''};
    /* gap: ${({rev}) => rev ? "5%" : ''}; */
    width: ${({wt}) => wt};
    margin-bottom: 3%;
    margin-right: ${({rev}) => rev ? "3%" : ''};
    justify-content: ${({position}) => position};
    gap: ${({position}) => position? '' : '2%'};
    border-bottom: ${({top}) => top? "1px solid #D9D9D9":""};
    @media screen and (max-width: 450px){
        border: none;
    }
`
export const RandoIcon = styled.img`
    cursor: pointer;
    height: 24px;
    width: 24px;
    position: ${({pos}) => pos};
    right: 0;
    z-index: 1;
    @media screen and (max-width: 450px){
        padding-right: 3%;
        height: 26px;
        width: 26px;
    }
`
export const EmptyCont = styled.section`
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: ${({dir}) => dir};
    justify-content: center;
    padding: 4% 0;
    height: ${({ht}) => ht? "" : "200px"};
`

export const EmptyP = styled.p`
    font-size: 4rem;
    font-weight: 700;
    color: #efebeb;
    @media screen and (max-width: 600px){
        font-size: 3rem;   
    }
`
export const TagWrapper = styled.div`
    display: flex;
    background: rgba(17, 115, 171, 1);
    border: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    align-items: center;
    height: 2rem;
    width: 42%;
    padding: 3%;
    gap: 5%;
`
export const RowWrap = styled.div`
    display: flex;
    justify-content: ${({student}) => student === 'Yes'? 'space-between' : 'flex-end'};
    width: 100%;
`
export const RowStar = styled.img``

export const SomeText = styled.p`
    color: ${({col}) => col ? "grey" : 'white' };
    font-size: ${({col}) => col};
    padding-right: ${({col}) => col? "4%": ""};
    text-align: left;
    align-self: center;
`

export const UsersToTagWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: 0px 1px 1px #d7d0d0;
    height: max-content;
    position: absolute;
    gap: 0.1rem;
    bottom: 22px;
    right: 0;
    width: 71%;
    z-index: 2;
    justify-content: center;
`
export const UsersToTag = styled.div`
    display: flex;
    gap: 2%;
    align-items: center;
    box-shadow: 0px 1px 3px #f3f3f3;
    padding: 1% 4%;
    &:hover {
        background: #f3f3f3;
    }
`

export const UsersToTagItem = styled.p`
    align-self: center;
`

export const UsersToTagProfile = styled.img`
    border-radius: 999px;
    height: 20px;
    width: 20px;
`