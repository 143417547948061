import { useState } from "react";
import { 
    ItemWrap, 
    Header, 
    Text, 
    LowerDesc, 
    Icon, 
    Map, 
    CopyBtn,
    Row } from "../style";
import { useSelector } from "react-redux"
import appliance from "../../../assets/icons/appliance.svg"
import features from "../../../assets/icons/features.svg"
import address from "../../../assets/icons/address.svg"
import contact from "../../../assets/icons/contact.svg"
import price from "../../../assets/icons/price.svg"
import map from "../../../assets/map.svg"
import { addCommasToNumber, copyToClipboard } from "../../utility/drag_drop";
import DisplayMap from "../map";




const PropertyFeatures = () => {
    // const [searchParams, setSearchParams] = useSearchParams();
    // const phone = searchParams.get('phone')
    const data = useSelector((state) => state.single.singledata);
    const [copy, setCopy] = useState(false);
    function clickCopy(){
        setCopy(!copy);
        copyToClipboard(data.phone_no);
        setTimeout(() => {
            setCopy(!copy)
        }, 5000)
    }
  return (
    <ItemWrap dir='column'>
        <ItemWrap dir="column">
            <Row>
                <Header nbtm='true' mxc='true' sh='true' col="black" sm="1.3rem">{data.title}</Header>
                <Icon src={contact} onClick={() => copyToClipboard()} title alt={`contact ${data.title}`} />
                <CopyBtn onClick={() => clickCopy()}>{copy? 'copied!':'copy'}</CopyBtn>
            </Row>
        </ItemWrap>
        <ItemWrap wt="100%" aut="auto" details='true' >
            <ItemWrap dir="column" wt="30%">
                <Header mxc='true'>
                    Description of property
                </Header>
                <Text>
                    {data.desc}
                </Text>
            </ItemWrap>
            {!["Tools"].includes(data.property_status) && 
                <>
                <ItemWrap dir="column" wt="30%">
                    <Header mxc='true'>
                        <Icon src={features} alt="property featuress" />
                        Property Features
                    </Header>
                    {["Land"].includes(data.property_status)?
                        <LowerDesc>
                            <Text>{data.no_of_plots + " "} plots of land</Text>
                            <Text>{data.lot_size + " "}sqr feet</Text>
                        </LowerDesc>
                        :
                        <LowerDesc>
                            <Text>{data.property_type}</Text>
                            <Text>{data.no_of_rooms + " "}bedroom</Text>
                            <Text>{data.lot_size + " "}sqr ft</Text>
                            <Text>{data.no_of_bathrooms + " "}Bathrooms</Text>
                        </LowerDesc>
                    }
                </ItemWrap>
                <ItemWrap dir="column" wt="30%">
                    <Header mxc='true'>
                        <Icon src={appliance} alt="property appliances" />
                        Appliances
                    </Header>
                    <LowerDesc>
                        {data.appliances ?
                            <>
                            {data.appliances.map((item, index) => (
                                <Text key={item.id}>{item.name}</Text>
                            ))}
                            </>
                            :
                            <Text>None</Text>
                        }
                    </LowerDesc>
                </ItemWrap>
                </>
            }
        </ItemWrap>
        <ItemWrap wt="100%" aut="auto" details='true'>
            <ItemWrap dir="column" wt="30%" >
                <Header mxc='true'>
                <Icon src={address} alt="address" />
                    Address
                </Header>
                <LowerDesc>
                    <Text>
                        {data.address}
                    </Text>
                </LowerDesc>
            </ItemWrap>
            {data.property_status === 'For sale' &&
            <ItemWrap dir="column" wt="30%" >
                <Header mxc='true'>
                <Icon src={price} alt="income per month" />
                    Income per month
                </Header>
                <LowerDesc>
                    <Text>
                        {addCommasToNumber(data.income_per_month)}
                    </Text>
                </LowerDesc>
            </ItemWrap>}

            <ItemWrap dir="column" wt="30%" >
                <Header mxc='true'>
                <Icon src={price} alt="price" />
                    Price
                </Header>
                <LowerDesc>
                    <Text>
                        {addCommasToNumber(data.property_price)}{data.property_status === "for rent"? "NGN PER YEAR":"NGN"}
                    </Text>
                </LowerDesc>
            </ItemWrap>
        </ItemWrap>
        {!["Tools"].includes(data.role) &&
        <ItemWrap dir="column">
            <Header>
                Map
            </Header>
            {!data.latitude?
                <Map src={map}></Map>
                :
                <DisplayMap lat={data.latitude} lang={data.longitude}/>
            }
        </ItemWrap>}
        
    </ItemWrap>
  )
}

export default PropertyFeatures