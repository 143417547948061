import styled from "styled-components";

export const CarouselWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
`

export const CarouselHeader = styled.h1`
    font-size: 2rem;
    margin-bottom: 1rem;
    @media screen and (max-width: 600px){
        font-size: 2.2rem;   
    }
`